import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import { SkeletonTable } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { translate } from 'utils';

const GenericTableAccordionContainer = styled.div`
  position: relative;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: auto;
`;

const StyledTableCell = styled(TableCell)`
  font-weight: 600 !important;
  padding: 10px 0;
  background-color: var(--grey-lighter);
  cursor: pointer;
`;

const TableRowComponent = ({
  hover, row, headers, parentApplication
}) => (
  <TableRow
    data-parentapplication={parentApplication}
    hover={hover}
    style={{ padding: 0, display: 'none' }}
  >
    {headers.map(column => (
      column.template(row)
    ))}
  </TableRow>
);

const expandApplication = (application, expandedItems) => {
  const allChildren = document.querySelectorAll(`[data-parentapplication=${application}]`);
  if (allChildren.length > 0) {
    const parent = document.querySelector(`[data-application=${application}]`);
    const arrowOpen = document.querySelector(`[data-application=${application}]`).querySelector('[data-open]');
    const arrowClose = document.querySelector(`[data-application=${application}]`).querySelector('[data-close]');
    const isItemExpanded = expandedItems.find(item => item === application);
    parent.setAttribute('data-open', String(Boolean(isItemExpanded)));
    allChildren && allChildren.forEach(child => {
      child.style.display = isItemExpanded ? 'table-row' : 'none';
    });
    arrowOpen && (arrowOpen.style.display = isItemExpanded ? 'block' : 'none');
    arrowClose && (arrowClose.style.display = isItemExpanded ? 'none' : 'block');
  }
};

const TableRowAccordionComponent = ({
  rows, application, headers, headersAccordion, onClick
}) => (
  <>
    {/* Customize the style of this parent TableRow with some padding and the arrow on the left */}
    <TableRow data-application={application} data-open={false} onClick={onClick}>
      {headersAccordion.map(column => (
        <StyledTableCell key={column.name} style={{ width: column.width || 'initial' }}>
          {column.template(rows[application])}
        </StyledTableCell>
      ))}
    </TableRow>
    {rows[application].languages
      .map(language => (
        <TableRowComponent
          headers={headers}
          key={language.hashId}
          parentApplication={application}
          row={language}
          style={{ padding: 0 }}
        />
      ))}
  </>
  );

export const GenericTableAccordion = ({
  rows, dataCy, dataTestid, dataTour, headers,
  id, isLoading, headersAccordion, defaultExpanded
}) => {
  const [expandedItems, setExpandedItems] = useState(defaultExpanded || []);

  useEffect(() => {
    Object.keys(rows).forEach(app => expandApplication(app, expandedItems));
  }, [expandedItems, rows]);

  const handleClickApplication = useCallback(event => {
    const { application } = event.currentTarget.dataset;
    const expandedCpy = [...expandedItems];
    if (expandedCpy.find(app => app === application) !== undefined) {
      setExpandedItems(expandedCpy.filter(app => app !== application));
    } else {
      setExpandedItems([...expandedCpy, application]);
    }
  }, [expandedItems, setExpandedItems]);

  const headersAccordionWithArrow = [{
    width: 20,
    name: 'visibleArrow',
    template: row => (
      <div key={`${row.application}_arrow`}>
        <FontAwesomeIcon data-close fixedWidth icon={faChevronRight} />
        <FontAwesomeIcon data-open fixedWidth icon={faChevronDown} style={{ display: 'none' }} />
      </div>
    )
  }, ...headersAccordion];
  const headersWithArrow = [{
    name: 'emptyArrow',
    template: row => (
      <TableCell key={`${row.application}_emptyArrow`} />
    )
  }, ...headers];

  return (
    <GenericTableAccordionContainer
      data-cy={dataCy}
      data-testid={dataTestid}
      data-tour={dataTour}
      id={id}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            {headersWithArrow.map(header => (
              <TableCell
                key={header.name}
                style={{ width: header.width || 'initial', backgroundColor: '#fafafa' }}
              >
                {header.label && translate(header.label)}
              </TableCell>
              ))}
          </TableRow>
        </TableHead>

        {(isLoading) ? (
          <TableBody>
            {[...Array(6).keys()].map(() => (
              <SkeletonTable columns={headers.length + 1} key={shortid.generate()} />
                  ))}
          </TableBody>
        )
        : (
          <TableBody>
            {Object.keys(rows).map(application => (
              <TableRowAccordionComponent
                application={application}
                headers={headersWithArrow}
                headersAccordion={headersAccordionWithArrow}
                key={application}
                rows={rows}
                onClick={handleClickApplication}
              />
              ))}
          </TableBody>
        )}
      </Table>

    </GenericTableAccordionContainer>
  );
};

GenericTableAccordion.propTypes = {
  dataCy: PropTypes.string,
  dataTour: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    isSortable: PropTypes.bool,
    sortOn: PropTypes.string,
    template: PropTypes.func
  })).isRequired,
  isLoading: PropTypes.bool,
  rows: PropTypes.shape({}).isRequired
};

GenericTableAccordion.defaultProps = {
  dataCy: '',
  dataTour: '',
  isLoading: false
};
