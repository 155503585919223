import { UserHelper } from 'utils/helpers/UserHelper';
import { APPLICATION_ROLES, TRANSLATION_VALIDATOR_ROLES, TRANSLATOR_ROLES } from 'utils/constants';

const findByLocale = (languages, locale) => languages.find(lang => lang.locale === locale);

const createLanguage = summary => ({
  ...summary,
  key: summary.countryKey
});

const formatSummary = summaryList => summaryList.reduce((result, singleSummary) => {
  if (!result[singleSummary.application]) {
    // eslint-disable-next-line no-param-reassign
    result[singleSummary.application] = {
      ...singleSummary,
      languages: [createLanguage(singleSummary)]
    };
  } else {
    const app = result[singleSummary.application];
    const language = findByLocale(app.languages, singleSummary.locale);
    if (!language) {
      app.languages.push(createLanguage(singleSummary));
    } else {
      language.missing += Number(singleSummary.missing);
      language.suggested += Number(singleSummary.suggested);
      language.total += Number(singleSummary.total);
    }
    app.missing += Number(singleSummary.missing);
    app.suggested += Number(singleSummary.suggested);
    app.total += Number(singleSummary.total);
  }
  return result;
}, {});

const isLanguageValidated = language => language.missing === 0 && language.suggested === 0;

const getActualAppName = appName => (appName.toUpperCase() === 'DATATECNEA'
  ? 'DATACOMMONS'
  : appName.toUpperCase());

const getTranslatorRoleForApp = appName => APPLICATION_ROLES[`${getActualAppName(appName)}_TRANSLATOR`];

const getTranslationValidatorRoleForApp = appName => APPLICATION_ROLES[`${getActualAppName(appName)}_TRANSLATION_VALIDATOR`];

const hasAccessForAppAndLanguage = ({ role, appName, locale }) => appName
      && locale
      && (UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN]) || (
        UserHelper.hasAccessRight([role]) && UserHelper.hasLanguageAccessForRole(role, locale)
      ));

const isValidatorForAppAndLanguage = (appName, locale) => hasAccessForAppAndLanguage({
  role: getTranslationValidatorRoleForApp(appName), appName, locale
});

const isTranslatorForAppAndLanguage = (appName, locale) => hasAccessForAppAndLanguage({
  role: getTranslatorRoleForApp(appName), appName, locale
});

const isValidator = () => UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN, ...TRANSLATION_VALIDATOR_ROLES]);

const isTranslator = () => UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN, ...TRANSLATOR_ROLES]);

export const TranslationHelper = {
  formatSummary,
  isLanguageValidated,
  isValidatorForAppAndLanguage,
  isTranslatorForAppAndLanguage,
  isValidator,
  isTranslator
};
