import {
  Grid, Paper
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useStores } from 'hooks';
import PropTypes from 'prop-types';
import {
  QuizEndPage, QuizContainer, QuizStartPage
} from '.';
import { QUIZ_STATUS } from './QuizHelper';

const Quiz = observer(({ element }) => {
  const { ended, displayingResults } = QUIZ_STATUS;

  const { examinationStore } = useStores();

  const { examinationState, currentPage } = examinationStore;

  useEffect(() => {
    if (currentPage && Object.keys(currentPage).length > 0) {
      examinationStore.setPageWithTraineeAnswers(currentPage);
      examinationStore.canGoNextPage();
    }
  }, [examinationStore, currentPage]);

  const {
    quizId, isQuizStarted, isQuizEnded
  } = examinationState;

  const renderSpecificQuizPart = useCallback(() => {
    if (quizId === element.id) {
      if (isQuizStarted) {
        return <QuizContainer />;
      }
      if (isQuizEnded) {
        return <QuizEndPage quizStatus={ended} />;
      }
    }
    if (!isQuizStarted && !isQuizEnded) {
      return <QuizStartPage />;
    }
    if (!isQuizStarted && isQuizEnded) {
      return <QuizEndPage quizStatus={displayingResults} />;
    }
    return null;
  }, [element, isQuizStarted, isQuizEnded, quizId, displayingResults, ended]);

  return (
    <Grid container direction="column" id={element.id}>
      <Grid item>
        <Paper style={{ padding: 20, margin: 10 }} variant="outlined">
          {renderSpecificQuizPart()}
        </Paper>
      </Grid>
    </Grid>
  );
});

export default Quiz;

Quiz.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  })
};

Quiz.defaultProps = {
  element: PropTypes.shape({
    id: '',
    title: '',
    description: ''
  })
};
