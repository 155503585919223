import React, { useCallback, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { useStores } from 'hooks';
import FormHeader from '../FormHeader/FormHeader';
import { GenericSwitch } from '../_commons';
import { asForm } from '../hoc';

const QuizPageEditionForm = observer(({ formData, updateProperty }) => {
  const { randomizePageOrder } = formData;

  const { quizStore } = useStores();
  const { totalPages, totalQuestions, totalCategories } = quizStore;

  const getTotalPagesAndQuestions = useCallback(() => {
    quizStore.getTotalPagesAndQuestions();
  }, [quizStore]);

  useEffect(() => {
    getTotalPagesAndQuestions();
  }, [getTotalPagesAndQuestions]);

  return (
    <form autoComplete="off" id="quizPageEditionForm" name="quizPagesEditionForm" style={{ padding: 10 }}>
      <FormHeader
        subtitle="quiz.editQuizInfos"
        title="quiz.pageSettings"
      />
      <Grid alignItems="center" container direction="row" justifyContent="center">
        <Grid item style={{ width: '100%' }}>
          <Paper style={{ padding: 10, marginBottom: 10 }} variant="outlined">
            <GenericSwitch
              checked={Boolean(randomizePageOrder)}
              leftSwitch={translate('common.no')}
              name="randomizePageOrder"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.pageOrder')}
              toolTip={null}
              value={randomizePageOrder}
              onChange={updateProperty}
            />
          </Paper>
        </Grid>
        <Grid alignItems="flex-start" container style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Paper style={{ padding: 10, backgroundColor: '#f2fafe' }} variant="outlined">
              <Typography>{`${translate('quiz.totalPages')} : ${totalPages}`}</Typography>
              <Typography>{`${translate('quiz.totalQuestions')} : ${totalQuestions}`}</Typography>
              <Typography>{`${translate('quiz.totalCategories')} : ${totalCategories}`}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});

QuizPageEditionForm.propTypes = {
  formData: PropTypes.shape({
    randomizePageOrder: PropTypes.bool
  }),
  updateProperty: PropTypes.func
};

export default asForm(QuizPageEditionForm);
