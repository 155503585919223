import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormState } from 'react-use-form-state';

import {
  DialogContent, Grid, FormControlLabel, Checkbox
} from '@material-ui/core';

import { translate } from 'utils';

import {
  CustomIcon, FormElement
} from 'components';

import { faLanguage, faPen } from '@fortawesome/pro-solid-svg-icons';
import { HeaderModal } from './_HeaderModal';
import { CustomActionButtons } from './_CustomActionButtons';

const UpdateUserForm = styled.form`
  .MuiToggleButton-root,
  .MuiToggleButton-root:hover {
    background-color: var(--white);
  }

  .MuiToggleButton-root.Mui-selected:first-child,
  .MuiToggleButton-root.Mui-selected:first-child:hover {
    background-color: var(--success-color);
  }

  .MuiToggleButton-root.Mui-selected:last-child,
  .MuiToggleButton-root.Mui-selected:last-child:hover {
    background-color: var(--error-color);
  }

  @media(min-width: 768px) {
    width: 600px;
  }
`;

export const UpdateRoleLanguagesModal = ({
  onClose, onConfirm, closeOnSubmit, languages, defaultLanguages
}) => {
  const [formState, { checkbox }] = useFormState({ locales: defaultLanguages.map(lng => lng.locale) || [] });
  const [allLanguages, setAllLanguages] = useState([]);

  useEffect(() => {
    setAllLanguages(languages);
  }, [languages]);

  const getSelectedLanguages = useCallback(selectedLocales => (
    selectedLocales.map(locale => {
      const fetchedLanguage = allLanguages.find(lng => lng.locale === locale);
      return { ...fetchedLanguage };
    })
  ), [allLanguages]);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    onConfirm(getSelectedLanguages(formState.values.locales));
    if (closeOnSubmit) {
      onClose();
    }
  }, [formState, closeOnSubmit, onClose, onConfirm, getSelectedLanguages]);

  return (
    <UpdateUserForm onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <Grid alignItems="center" container>
          <Grid item>
            <CustomIcon icon={faLanguage} secondIcon={faPen} />
          </Grid>
          <Grid item>
            {translate('modalUserUpdate.editLanguages')}
          </Grid>
        </Grid>
      </HeaderModal>

      <DialogContent>
        <FormElement label={translate('pageLanguages.title')}>
          <Grid container direction="column">
            {/* ROLES CHECKBOX MULTIPLE */}
            {allLanguages && allLanguages.map(language => (
              <Grid container item justifyContent="space-between" key={language.locale} spacing={2}>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox color="primary" value={language.locale} />}
                    key={language.locale}
                    label={translate(`languages.${language.locale}`)}
                    {...checkbox('locales', language.locale)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </FormElement>
      </DialogContent>

      <CustomActionButtons
        isValidated
        onClose={onClose}
      />
    </UpdateUserForm>
  );
};

UpdateRoleLanguagesModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultLanguages: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

UpdateRoleLanguagesModal.defaultProps = {
  closeOnSubmit: true
};
