import { translate } from 'utils';

const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

const resizeImage = reader => new Promise(resolve => {
  const img = document.createElement('img');
  img.src = reader.result;
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // eslint-disable-next-line prefer-destructuring
    let width = img.width;
    // eslint-disable-next-line prefer-destructuring
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;
    const ctxx = canvas.getContext('2d');
    ctxx.drawImage(img, 0, 0, width, height);

    resolve(canvas.toDataURL());
  };
});

const handleFileUpload = (event, needResize, maxSize) => new Promise((resolve, reject) => {
  const allFiles = event.target.files;
  if (allFiles) {
    const readerOnLoad = async (readerLoaded, currentFileLoaded) => {
      const fileUploaded = {
        name: currentFileLoaded.name,
        base64Content: readerLoaded.result
      };

      if (!needResize) return resolve(fileUploaded);

      const imgResized = await resizeImage(readerLoaded);

      return resolve({
        ...fileUploaded,
        base64Content: imgResized
      });
    };

    const reader = new FileReader();
    const currentFile = allFiles[0];
    const filesize = ((currentFile.size / 1024) / 1024).toFixed(2); // in Mo

    if (filesize <= maxSize || needResize) {
      reader.readAsDataURL(currentFile);
      reader.onload = () => readerOnLoad(reader, currentFile);
    } else {
      reject(Error(translate('errors.documentTooBig', { maxSize })));
    }
  }
});

const getExtension = name => {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(name)[1];
};

const getImageWithBase64 = (extension, base64Content) => (
  `data:image/${extension};base64,${base64Content}`
);

const getApplicationWithBase64 = (extension, base64Content) => (
  `data:application/${extension};base64,${base64Content}`
);

const getPNGWithBase64 = base64Content => getImageWithBase64('png', base64Content);

const getPDFWithBase64 = base64Content => getApplicationWithBase64('pdf', base64Content);

const getExcelWithBase64 = base64Content => getApplicationWithBase64('vnd.openxmlformats-officedocument.spreadsheetml.sheet', base64Content);

const getZipWithBase64 = base64Content => getApplicationWithBase64('zip', base64Content);

const getDocumentWithBase64 = (name, content) => {
  const extension = getExtension(name);
  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return getImageWithBase64(extension, content);
    case 'pdf':
      return getPDFWithBase64(content);
    case 'xlsx':
    case 'xls':
      return getExcelWithBase64(content);
    case 'rar':
    case 'zip':
      return getZipWithBase64(content);
    // Default to a png
    default:
      return getPNGWithBase64(content);
  }
};

const getDocumentWithoutBase64 = document => (
  document.base64Content.split(',')[1]
);

const getDocumentWithoutB64FromOnlyB64 = document => (
  document.split(',')[1]
);

const isPDFWithBase64 = data => (
  data.split(',')[0] === 'data:application/pdf;base64'
);

const downloadDocumentFromBase64 = (data, name) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = data;
  downloadLink.download = name;
  downloadLink.click();
};

const isPdf = document => getExtension(document.name) === 'pdf';

export const DocumentHelper = {
  handleFileUpload,
  resizeImage,
  getDocumentWithBase64,
  getPNGWithBase64,
  getDocumentWithoutBase64,
  getDocumentWithoutB64FromOnlyB64,
  getPDFWithBase64,
  getExcelWithBase64,
  getZipWithBase64,
  isPDFWithBase64,
  downloadDocumentFromBase64,
  isPdf
};
