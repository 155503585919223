import React, { Component } from 'react';
import { translate } from 'utils';
import { Grid } from '@material-ui/core';
import { PageTitle } from 'pages';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  componentDidCatch(error) {
    this.setState({ error: error.message });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <Grid alignItems="center" container justifyContent="center" style={{ height: '100vh', padding: '5rem' }}>
          <Grid item>
            <PageTitle title={error || translate('common.error')} />
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
