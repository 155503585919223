import { DocumentHelper } from 'utils';

export const quizModel = {
  title: '',
  code: '',
  reference: '',
  description: '',
  endText: '',
  logo: '',
  isCertifying: false,
  withIdentification: false,
  isIndependent: false,
  isPublic: false,
  allowRepeat: false,
  repeatLimit: 0,
  randomizePageOrder: false,
  showProgressBar: true,
  showTimerPanel: false,
  maxTimeToFinish: 0,
  goNextPageAutomatic: false,
  forceValidation: false,
  displayValidation: false,
  displayResults: true,
  displayRatingOnly: false,
  limitDate: null,
  pages: []
};

export const surveyModelBuilder = quiz => {
  const quizSurveyModel = {
    showTitle: false,
    logoWidth: '10px',
    logoHeight: '10px',
    logoFit: 'none',
    logoPosition: 'none',
    progressBarType: 'buttons',
    maxTimeToFinishPage: 0,
    ...quiz,
    // maxTimeToFinish: quiz.maxTimeToFinish * 60,
    logo: quiz.logo ? DocumentHelper.getPNGWithBase64(quiz.logo) : '',
    showProgressBar: quiz.showProgressBar ? 'top' : 'off',
    showNavigationButtons: 'bottom',
    ignoreValidation: quiz.displayValidation,
    // showTimerPanel: quiz.maxTimeToFinish > 0 ? 'top' : null,
    showTimerPanelMode: 'survey',
    completedHtml: '<h4></h4>'
  };

  return quizSurveyModel;
};

export const surveyCompleted = {
  mode: 'display',
  questionsOnPageMode: 'singlePage',
  showNavigationButtons: 'none',
  showProgressBar: 'off',
  showTimerPanel: 'none'
  // maxTimeToFinish: 0
};
