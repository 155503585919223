import {
  action,
  makeObservable,
  observable, runInAction
} from 'mobx';
import { UserService } from 'services';

class UserStore {
  constructor() {
    this.isConnected = false;
    this.isUpdating = false;
    this.keycloak = null;
    this.userConnected = null;
    this.scope = null;
    this.rolesLanguages = null;

    makeObservable(this, {
      isConnected: observable,
      isUpdating: observable,
      keycloak: observable,
      userConnected: observable,
      scope: observable,
      rolesLanguages: observable,
      connectUser: action,
      disconnectUser: action,
      getToken: action,
      isTokenExpired: action,
      refreshToken: action,
      keycloakLogin: action,
      updateStart: action,
      updateEnd: action
    });
  }

  updateStart() {
    this.isUpdating = true;
  }

  updateEnd() {
    this.isUpdating = false;
  }

  connectUser(keycloak) {
    this.keycloak = keycloak;

    if (keycloak && keycloak.tokenParsed) {
      this.isConnected = true;
      this.userConnected = keycloak.tokenParsed;

      UserService.getCurrentScope().then(resp => runInAction(() => {
        this.scope = resp;
        UserService.getRolesLanguages(resp.accountId).then(response => runInAction(() => {
          this.rolesLanguages = response;
          // eslint-disable-next-line
        })).catch(err => console.error(err));
        // eslint-disable-next-line
      })).catch(err => console.error(err));
    }
  }

  disconnectUser() {
    this.isConnected = false;
    this.userConnected = null;
    this.keycloak.clearToken();
    return this.keycloak.logout();
  }

  getToken() {
    return (this.keycloak && this.keycloak.token) || null;
  }

  isTokenExpired() {
    return this.keycloak ? this.keycloak.isTokenExpired() : null;
  }

  refreshToken() {
    return this.keycloak.updateToken();
  }

  keycloakLogin() {
    const currentCountry = localStorage.getItem('i18nextLng') || 'fr';
    return this.keycloak.login({ locale: currentCountry });
  }
}

export const userStore = new UserStore();
