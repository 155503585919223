import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const applicationUrl = `${API_V2_URL}/applications`;

// GET
const getOptions = () => RequestHelper.GET(`${applicationUrl}`);

const getOptionsForTranslation = () => RequestHelper.GET(`${applicationUrl}/translation-role/options`);

export const ApplicationService = {
  getOptions,
  getOptionsForTranslation
};
