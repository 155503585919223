import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { translate } from '../../../utils';
import { SelectField } from '../../_commons';
import { PurchaseOrderService } from '../../../services/PurchaseOrderService';

export const PurchaseOrderListFilters = ({ currentFilters, setCurrentFilters }) => {
  const institutionName = currentFilters.find(filt => filt.key === 'institutionName')?.label || '';
  const validationStatus = currentFilters.find(filt => filt.key === 'validationStatus') || '';
  const institutionIdentifier = currentFilters.find(filt => filt.key === 'identifier')?.label || '';
  const [validationStatuses, setValidationStatuses] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    PurchaseOrderService.getDocumentValidationStatuses()
      .then(resp => setValidationStatuses(resp))
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [enqueueSnackbar]);

  const handleChangeInput = useCallback(event => {
    const { name: filterName, value } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName), { key: filterName, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleSelect = useCallback((value, filterName) => {
    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName), {
        key: filterName,
        label: value.label || value,
        value: value.value
      }]);
    }
  }, [currentFilters, setCurrentFilters]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          fullWidth
          label={translate('pagePurchaseOrderList.listHeader.identifier')}
          name="identifier"
          value={institutionIdentifier}
          variant="outlined"
          onChange={handleChangeInput}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          fullWidth
          label={translate('pagePurchaseOrderList.listHeader.institutionName')}
          name="institutionName"
          value={institutionName}
          variant="outlined"
          onChange={handleChangeInput}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="common.purchaseOrderStatus"
          name="validationStatus"
          options={validationStatuses}
          value={validationStatus}
          onChange={value => handleSelect(value, 'validationStatus')}
        />
      </Grid>
    </Grid>
  );
};
