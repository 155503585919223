import {
  faChevronLeft, faChevronRight,
  faSpinner, faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Card, Checkbox, FormControlLabel, Grid,
  Radio, Step, StepLabel, Stepper, TextField, Tooltip, Typography
} from '@material-ui/core';
import {
  CustomTooltip,
  Datepicker,
  FormAddress,
  FormCountryIdentifier,
  FormElement, RequiredSelectHack, Wrapper
} from 'components';
import { useFetch, useModal } from 'hooks';
import PropTypes from 'prop-types';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import {
  ApplicationRolesService, CountryService, DataTecneaService, UserService
} from 'services';
import { userStore } from 'stores';
import styled from 'styled-components';
import { translate } from 'utils';
import { APPLICATION_ROLES, ROUTES } from 'utils/constants';
import { FormHelper, UserHelper } from 'utils/helpers';
import { PurchaseOrderCard } from '../_commons/PurchaseOrderCard';

const FormContainer = styled(Card)`
  margin: 2rem auto;
  padding: 0 2rem 2rem 2rem;
`;

const FORM_STEPS = [
  {
    key: 0,
    label: 'inscriptionForm.yourInfos'
  }, {
    key: 1,
    label: 'inscriptionForm.institutionInfos'
  }, {
    key: 2,
    label: 'inscriptionForm.accessApp'
  }
];

const EXCLUSIVE_DATABILAN_ACTIVITIES = ['DATABILAN_OPERATOR', 'DATABILAN_DISTRIBUTOR'];

const LAST_STEP = 2;

export const RegisterFormComponent = ({
  isLoading, allApplicationRoles, onSubmit
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeApps, setActiveApps] = useState([]);
  const [, setRefreshCounter] = useState(0);
  const [accountsNeededFieldName, setAccountsNeededFieldName] = useState('');
  const [identifierFields, setIdentifierFields] = useState([]);
  const [databilanAdditionalFiles, setDatabilanAdditionalFiles] = useState([]);
  const [datafluidesAdditionalFiles, setDatafluidesAdditionalFiles] = useState([]);
  const [databilanPurchaseOrders, setDatabilanPurchaseOrders] = useState([]);
  const [datafluidesPurchaseOrders, setDatafluidesPurchaseOrders] = useState([]);

  const currentLanguage = localStorage.getItem('i18nextLng') || 'fr';

  const [formState, {
    text, email, tel, radio, raw, number, checkbox
  }] = useFormState({
    validityDate: null,
    accessActivity: [],
    databilanAccountLimit: 1,
    dataformAccountLimit: 1,
    legalStatus: null,
    country: UserHelper.getDefaultCountry(null)
  });

  useEffect(() => {
    DataTecneaService.getActiveApplications()
      .then(resp => setActiveApps(resp));

    CountryService.getCountrySelectItems().then(resp => {
      formState.setField('country', resp.find(lg => lg.value === currentLanguage));
    });
    // eslint-disable-next-line
  }, [currentLanguage]);

  const canAdministrate = useCallback(() => UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN]), []);

  const handleChangeValidityDate = useCallback(date => formState.setField('validityDate', date), [formState]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      ...formState.values,
      identifiers: identifierFields,
      accessActivity: formState.values.accessActivity,
      address: {
        address1: formState.values.address1,
        address2: formState.values.address2,
        postalCode: formState.values.postalCode,
        city: formState.values.city,
        country: {
          ...formState.values.country,
          key: formState.values.country && formState.values.country.value
        }
      },
      purchaseOrders: { DATAFLUIDES: datafluidesPurchaseOrders[0]?.document, DATABILAN: databilanPurchaseOrders[0]?.document },
      additionalFiles: { DATAFLUIDES: { documents: datafluidesAdditionalFiles.map(doc => doc.document) }, DATABILAN: { documents: databilanAdditionalFiles.map(doc => doc.document) } }
    });
  }, [onSubmit, formState.values, identifierFields, datafluidesPurchaseOrders, databilanPurchaseOrders, datafluidesAdditionalFiles, databilanAdditionalFiles]);

  const goToNextStep = useCallback(() => setActiveStep(activeStep + 1), [activeStep]);

  const checkErrorsForActiveStep = useCallback(() => {
    switch (activeStep) {
      case 0:
        formState.touched.lastName = true;
        formState.touched.firstName = true;
        formState.touched.email = true;
        formState.touched.login = true;
        break;
      case 1:
        formState.touched.country = true;
        formState.touched.identifiers = true;
        formState.touched.identifierSuffix = true;
        formState.touched.legalStatus = true;
        formState.touched.companyName = true;
        formState.touched.institutionName = true;
        formState.touched.name = true;
        formState.touched.postalCode = true;
        formState.touched.city = true;
        formState.touched.address1 = true;
        break;
      default:
        break;
    }
    setRefreshCounter(refreshC => refreshC + 1);
  }, [activeStep, formState.touched]);

  const handleNextStep = useCallback(() => {
    const emailErrors = formState.errors.email;
    const passwordErrors = formState.errors.password;
    const isCurrentStepValid = document.forms.registerForm.reportValidity() && !emailErrors && !passwordErrors;
    checkErrorsForActiveStep();

    if (isCurrentStepValid) {
      // On the last step, submit the form
      if (activeStep === LAST_STEP) {
        return handleSubmit();
      }

      // Default go to next step
      return goToNextStep();
    }

    return null;
  }, [checkErrorsForActiveStep, formState, activeStep, handleSubmit, goToNextStep]);

  const switchName = useCallback(() => {
    if (formState.values.accessActivity.some(activity => activity.includes('DATABILAN'))) {
      setAccountsNeededFieldName('databilanAccountLimit');
    } else if (formState.values.accessActivity.includes('DATAFORM')) {
      setAccountsNeededFieldName('dataformAccountLimit');
    } else {
      setAccountsNeededFieldName('');
    }
  }, [formState]);

  useEffect(() => {
    switchName();
  }, [switchName, formState.values.accessActivity]);

  const handleBackStep = useCallback(() => setActiveStep(activeStep - 1), [activeStep]);

  const getFilesByActivity = useCallback((activity) => {
    if (activity.includes('DATAFLUIDES')) {
      return {
        purchaseOrderFiles: datafluidesPurchaseOrders,
        setPurchaseOrderFiles: setDatafluidesPurchaseOrders,
        additionalFiles: datafluidesAdditionalFiles,
        setAdditionalFiles: setDatafluidesAdditionalFiles
      };
    } if (activity.includes('DATABILAN')) {
      return {
        additionalFiles: databilanAdditionalFiles,
        setAdditionalFiles: setDatabilanAdditionalFiles,
        purchaseOrderFiles: databilanPurchaseOrders,
        setPurchaseOrderFiles: setDatabilanPurchaseOrders
      };
    }
    return null;
  }, [databilanAdditionalFiles, databilanPurchaseOrders, datafluidesAdditionalFiles, datafluidesPurchaseOrders]);

  const renderPurchaseOrderForms = useCallback(() => (
    <Grid container direction="row" justifyContent="space-around" style={{ maxWidth: '100%' }} wrap="nowrap">
      {formState.values.accessActivity.map((activity) => (
        <PurchaseOrderCard
          accountsNeededFieldName={accountsNeededFieldName}
          accountsNeededFieldNameProps={number}
          activity={activity}
          key={activity}
          {...getFilesByActivity(activity)}
          title={activeApps.find(app => app.value === activity).label}
          xs={formState.values.accessActivity ? 12 / formState.values.accessActivity.length : 12}/>
      ))}
    </Grid>
  ), [formState.values.accessActivity, accountsNeededFieldName, number, getFilesByActivity, activeApps]);

  const checkExclusivity = useCallback((e) => {
    const newActivity = e.target.value;

    if (formState.values.accessActivity.includes(newActivity)) {
      formState.setField('accessActivity', formState.values.accessActivity.filter(activity => activity !== newActivity));
    } else {
      let activitiesWithoutExclusivity;
      if (EXCLUSIVE_DATABILAN_ACTIVITIES.includes(newActivity)) {
          activitiesWithoutExclusivity = formState.values.accessActivity.filter(activity => !EXCLUSIVE_DATABILAN_ACTIVITIES.includes(activity));
          setDatabilanAdditionalFiles([]);
          setDatabilanPurchaseOrders([]);
        } else {
          activitiesWithoutExclusivity = formState.values.accessActivity;
          setDatafluidesAdditionalFiles([]);
          setDatafluidesPurchaseOrders([]);
        }

      formState.setField('accessActivity', [...activitiesWithoutExclusivity, e.target.value]);
    }
  }, [formState]);

  const renderStepContent = useCallback(step => {
    switch (step) {
      case 0:
        return (
          <Grid item>
            <FormElement>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    autoComplete="new-password"
                    autoFocus
                    error={(formState.touched?.lastName && !formState.values.lastName) || Boolean(formState.errors.lastName)}
                    label={translate('common.lastName')}
                    name="lastName"
                    required
                    {...text('lastName')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    autoComplete="new-password"
                    error={(formState.touched?.firstName && !formState.values.firstName) || Boolean(formState.errors.firstName)}
                    label={translate('common.firstName')}
                    name="firstName"
                    required
                    {...text('firstName')}
                  />
                </Grid>
              </Grid>
            </FormElement>
            <FormElement>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    autoComplete="new-password"
                    error={(formState.touched?.email && !formState.values.email) || Boolean(formState.errors.email)}
                    helperText={formState.errors.email}
                    label={translate('common.email')}
                    name="email"
                    required
                    {...email({
                      name: 'email',
                      // Additional validation for emails because useFormState validation stops at example@example
                      validate: value => FormHelper.validateEmail(value)
                    })}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    autoComplete="new-password"
                    error={(formState.touched?.login && !formState.values.login) || Boolean(formState.errors.login)}
                    label={translate('common.login')}
                    name="login"
                    required
                    {...text('login')}
                  />
                </Grid>
              </Grid>
            </FormElement>
            <FormElement>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    autoComplete="new-password"
                    error={formState.errors.phoneNumber}
                    label={translate('common.phone')}
                    name="phoneNumber"
                    {...tel('phoneNumber')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    autoComplete="new-password"
                    error={formState.errors.occupation}
                    inputProps={{ maxLength: 200 }}
                    label={translate('common.occupation')}
                    name="occupation"
                    {...text('occupation')}
                  />
                </Grid>
              </Grid>
            </FormElement>

            {canAdministrate() && allApplicationRoles.length > 0 && (
              <FormElement label={translate('modalUserUpdate.myAccess')}>
                <Grid container spacing={2}>
                  {allApplicationRoles && allApplicationRoles.map(role => (
                    <FormControlLabel
                      control={<Radio value={role.key} />}
                      key={role.key}
                      label={role.label}
                      {...radio('roles', role.key)}
                    />
                  ))}
                </Grid>
                <RequiredSelectHack value={formState.values.roles && formState.values.roles.length > 0 ? 'ok' : ''} />
              </FormElement>
            )}

            {canAdministrate() && (
              <FormElement>
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <Datepicker
                      disablePast
                      fullWidth
                      label="common.validityDate"
                      style={{ flex: 1 }}
                      value={formState.values.validityDate}
                      onChange={handleChangeValidityDate}
                    />
                  </Grid>
                  <Grid item>
                    <CustomTooltip text={translate('modalUser.tooltipValidityDate')} />
                  </Grid>
                </Grid>
              </FormElement>
            )}
            {/* This input is only used for forcing disabling autocomplete in chrome browser */}
            <input name="fakeInputForForcingDisablingAutocompleteChrome" style={{ display: 'none' }} type="text" />
          </Grid>
        );
      case 1:
        return (
          <Grid item>
            <FormCountryIdentifier
              formState={formState}
              identifierFields={identifierFields}
              raw={raw}
              setIdentifierFields={setIdentifierFields}
              text={text}
              withCompanyFields
            />
            <FormAddress
              formState={formState}
              isRequired
              text={text}
              withCountry={false}
            />
            {/* This input is only used for forcing disabling autocomplete in chrome browser */}
            <input name="fakeInputForForcingDisablingAutocompleteChrome1" style={{ display: 'none' }} type="text" />
          </Grid>
        );
      case 2:
        return (
          <Grid item style = {{ width: '100%' }}>
            <FormElement label={translate('inscriptionForm.selectApp')}>
              {activeApps.map(app => (
                <Fragment key={app.key}>
                  <FormControlLabel
                    control={<Checkbox
                      color="primary"
                      value={app.value}
                      onChange={checkExclusivity}
                      />}
                    data-cy={`checkbox-${app.key}`}
                    key={app.key}
                    label={
                      <Grid container direction="column" style={{ paddingTop: '6px' }}>
                        <Grid alignItems="center" container item spacing={1}>
                          <Grid item>
                            <img alt={`${app.key}_LOGO`}
                               src={`${process.env.PUBLIC_URL}/assets/images/favicons/${app.key.split('_')[0].toLowerCase()}.ico`}
                               style={{ width: '25px', height: '25px' }}
                          />
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {app.label}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {app.tooltip}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    style={{ alignItems: 'flex-start', paddingBottom: '20px' }}
                    {...checkbox('accessActivity', app.value)}
                  />
                </Fragment>
              ))}
              {renderPurchaseOrderForms()}
            </FormElement>
          </Grid>
        );
      default:
        return null;
    }
  }, [activeApps, allApplicationRoles, canAdministrate, checkExclusivity, checkbox, email,
    formState, handleChangeValidityDate, identifierFields, radio, raw, renderPurchaseOrderForms, tel, text]);

  const nextStepButton = useCallback((isDisabled) => (
    <Button
      color="primary"
      data-cy="handleNextStep"
      disabled={isDisabled}
      startIcon={activeStep === LAST_STEP
        ? <FontAwesomeIcon icon={faUserPlus}/> : <FontAwesomeIcon icon={faChevronRight}/>}
      variant="contained"
      onClick={handleNextStep}
    >
      {activeStep === LAST_STEP
      ? translate('button.createAccount')
      : translate('button.next')}
    </Button>
  ), [activeStep, handleNextStep]);

  return (
    <form name="registerForm" onSubmit={handleSubmit}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {FORM_STEPS.map(step => (
          <Step key={step.key}>
            <StepLabel>{translate(step.label)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Grid container direction="column">
        {renderStepContent(activeStep)}
      </Grid>

      <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '3rem' }}>
        <Grid item>
          <Button
            disabled={activeStep === 0}
            startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={handleBackStep}
          >
            {translate('button.previous')}
          </Button>
        </Grid>
        <Grid item>
          {isLoading ? (
            <Button color="primary" variant="contained">
              <FontAwesomeIcon icon={faSpinner} size="2x" spin />
            </Button>
          ) : (
            <>
              {activeStep === LAST_STEP && formState.values.accessActivity.length < 1
                ? <Tooltip title={translate('toolTip.noActivitySelected')}>
                  <span>
                    {nextStepButton(true)}
                  </span>
                </Tooltip>
                : nextStepButton(false)
              }
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

RegisterFormComponent.propTypes = {
  allApplicationRoles: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

RegisterFormComponent.defaultProps = {
  allApplicationRoles: [],
  isLoading: false
};

const RegisterForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const displayModal = useModal();
  const history = useHistory();

  const { response: allApplicationRoles } = useFetch(() => (userStore.isConnected
    ? ApplicationRolesService.getOptions()
    : []
  ), []);

  const registerUser = useCallback(formState => {
    setIsLoading(true);
    const confirmMessage = formState.accessActivity.length > 0 ? 'confirms.userList.createWithAccess' : 'confirms.userList.create';

    UserService.registerUser(formState).then(() => {
      displayModal({
        type: 'CONFIRM',
        text: translate(confirmMessage),
        onConfirm: () => history.push(ROUTES.HOME)
      });
    })
      .catch(error => displayModal({
        type: 'ERROR',
        text: error
      }))
      .finally(() => setIsLoading(false));
  }, [displayModal, history]);

  return (
    <Wrapper>
      <FormContainer>
        <RegisterFormComponent
          allApplicationRoles={allApplicationRoles}
          isLoading={isLoading}
          onSubmit={registerUser}
        />
      </FormContainer>
    </Wrapper>
  );
};

export default RegisterForm;
