import { Grid, TextField } from '@material-ui/core';
import { SelectField } from 'components';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createFilter } from 'react-select';
import { ApplicationRolesService, UserService } from 'services';
import { translate } from 'utils';
import { APP_NAMES_FRONT } from 'utils/constants';

export const UserListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const originApp = location.search && location.search.split('=')[1].toUpperCase();

  const [appNames, setAppNames] = useState([]);
  const [appRoles, setAppRoles] = useState([]);

  const originAppForRole = originApp !== undefined ? originApp : APP_NAMES_FRONT.DATATECNEA;

  const companyName = (currentFilters.find(filt => filt.key === 'companyName')
    && currentFilters.find(filt => filt.key === 'companyName').label) || '';
  const application = (currentFilters.find(filt => filt.key === 'application')
    && currentFilters.find(filt => filt.key === 'application').value) || null;

  const roles = (currentFilters.find(filt => filt.key === 'roles')
    && currentFilters.find(filt => filt.key === 'roles').value) || null;

  const getApplicationNames = useCallback(() => {
    UserService.getAppNamesOptions()
      .then(response => { setAppNames(response.filter(app => !['DATACOMMONS', 'DATAHYDROTEST'].includes(app.value))); })
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [enqueueSnackbar]);

  const getRolesForApp = useCallback(appName => {
    ApplicationRolesService.getOptions(appName)
      .then(response => setAppRoles(response))
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [enqueueSnackbar]);

  const handleChangeInput = useCallback(event => {
    const { name: filterName, value } = event.target;

    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName), { key: filterName, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleSelect = useCallback((value, filterName) => {
    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName), {
        key: filterName,
        label: value.label || value,
        value
      }]);
      if (filterName === 'application') {
        getRolesForApp(value.value);
      }
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== filterName)]);
      getRolesForApp(null);
    }
  }, [currentFilters, setCurrentFilters, getRolesForApp]);

  useEffect(() => {
    getApplicationNames();
    getRolesForApp(originAppForRole);
    // eslint-disable-next-line
  }, [originAppForRole]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          label={translate('common.userCompany')}
          name="companyName"
          value={companyName}
          variant="outlined"
          onChange={handleChangeInput}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="common.application"
          name="application"
          options={appNames}
          value={application}
          onChange={value => handleSelect(value, 'application')}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          closeMenuOnSelect={false}
          filterOption={createFilter({ stringify: option => `${option.label}` })}
          isMulti
          label="common.roles"
          name="roles"
          options={appRoles}
          value={roles}
          onChange={value => handleSelect(value, 'roles')}
        />
      </Grid>
    </Grid>
  );
};

UserListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
