import { RequestHelper, DocumentHelper } from 'utils';
import { API_V2_URL } from 'utils/constants';

const documentsUrl = `${API_V2_URL}/documents`;

// GET
/**
 * "identifier" param should be same as ExcelViewType enum in Datatecnea-API
 */
const getImportFile = (identifier) => RequestHelper.GET(`${documentsUrl}/import-files?identifier=${identifier}`).then((response) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = DocumentHelper.getExcelWithBase64(response.file);
    downloadLink.download = response.name;
    downloadLink.click();
  });

export const DocumentsService = {
  getImportFile
};
