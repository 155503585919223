import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { ExaminationService } from 'services';
import { buildImagePickers } from 'components/_commons/Quiz/QuizHelper';

export class ExaminationStore {
  constructor() {
    this.isLoading = false;
    this.isError = false;
    this.error = null;
    this.examination = {};
    this.maxPage = 0;
    this.isFreeAns = false;
    this.examinationState = {
      examinationId: '',
      quizId: '',
      isQuizStarted: false,
      isQuizEnded: false
    };
    this.questionsWithTraineeAnswers = [];
    this.pageWithTraineeAnswers = {};
    this.questionNumbers = [];
    this.isPageValidated = false;
    this.currentPageIndex = 0;
    this.currentPage = {};
    this.showCorrectedAnswers = false;
    this.isExaminationLoading = false;
    this.totalPages = 0;
    this.totalQuestions = 0;
    this.isQuestionValid = [];

    makeObservable(this, {
      isLoading: observable,
      examination: observable,
      isFreeAns: observable,
      isError: observable,
      error: observable,
      examinationState: observable,
      questionsWithTraineeAnswers: observable,
      pageWithTraineeAnswers: observable,
      isPageValidated: observable,
      questionNumbers: observable,
      maxPage: observable,
      currentPageIndex: observable,
      currentPage: observable,
      showCorrectedAnswers: observable,
      isExaminationLoading: observable,
      totalPages: observable,
      totalQuestions: observable,
      isQuestionValid: observable,
      loadExamination: action,
      getCorrectAnswers: action,
      getIncorrectAnswers: action,
      getFreeAnswers: action,
      setExamStatus: action,
      setExamination: action,
      setPageWithTraineeAnswers: action,
      setTraineeAnswers: action,
      getQuestionNumbering: action,
      canGoNextPage: action,
      finishQuizService: action,
      updateQuestionService: action,
      updatePageService: action,
      setCurrentPage: action,
      setNextPage: action,
      setPreviousPage: action,
      toggleShowResults: action,
      toggleLoadingExamination: action,
      getTotalPagesAndQuestions: action,
      toggleQuestionValidity: action
    });
  }

  isFreeAnswer(questionType) {
    const ANSWER_TYPES = {
      rating: 'rating',
      shortInput: 'text',
      longInput: 'comment'
    };

    const { rating, shortInput, longInput } = ANSWER_TYPES;

    if (questionType.localeCompare(rating) === 0
    || questionType.localeCompare(shortInput) === 0
    || questionType.localeCompare(longInput) === 0) {
      this.isFreeAns = true;
      return this.isFreeAns;
    }
    if (questionType.localeCompare(shortInput) === 0) {
      this.isFreeAns = true;
      return this.isFreeAns;
    }
    if (questionType.localeCompare(longInput) === 0) {
      this.isFreeAns = true;
      return this.isFreeAns;
    }
    return false;
  }

  /**
   * Fetches the finished examination details.
   */
  loadExamination(examId) {
    this.isLoading = true;
    return ExaminationService.getExamination(examId)
      .then(action(examination => {
        this.examination = {
          ...examination
        };
        buildImagePickers(this.examination.quiz);
        return this.examination;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  getCorrectAnswers() {
    const exam = this.examination;
    exam.quiz.pages.forEach(page => {
      page.questions = page.questions.filter(question => {
        if (question.traineeAnswer !== null && !this.isFreeAnswer(question.type)) {
          if (Array.isArray(question.correctAnswer)) {
            return JSON.stringify(question.traineeAnswer) === JSON.stringify(question.correctAnswer);
          }
          return question.traineeAnswer === question.correctAnswer;
        }
        return null;
      });
    });
    this.examination = exam;
    return this.examination;
  }

  getIncorrectAnswers() {
    const exam = this.examination;
    exam.quiz.pages.forEach(page => {
      page.questions = page.questions.filter(question => {
        if (!this.isFreeAnswer(question.type)) {
          if (Array.isArray(question.correctAnswer)) {
            return JSON.stringify(question.traineeAnswer) !== JSON.stringify(question.correctAnswer);
          }
          if (question.traineeAnswer === null) {
            return question;
          }
          return question.traineeAnswer !== question.correctAnswer;
        }
        return null;
      });
    });
    this.examination = exam;
    return this.examination;
  }

  getFreeAnswers() {
    const exam = this.examination;
    exam.quiz.pages.forEach(page => {
      page.questions = page.questions.filter(question => {
        if (this.isFreeAnswer(question.type)) {
          return question;
        }
        return null;
      });
    });
    this.examination = exam;
    return this.examination;
  }

  setExamStatus({
    isStarted, isEnded, examinationId, quizId
  }) {
    this.examinationState = {
      examinationId,
      quizId,
      isQuizStarted: isStarted,
      isQuizEnded: isEnded
    };

    return this.examinationState;
  }

  setExamination(examination) {
    this.examination = examination;
  }

  setPageWithTraineeAnswers(pageData) {
    this.pageWithTraineeAnswers = pageData;
  }

  setTraineeAnswers(currentPage, currentQuestion, traineeAnswer) {
    this.pageWithTraineeAnswers = currentPage;

    if (this.questionsWithTraineeAnswers && this.questionsWithTraineeAnswers.length === 0) {
      this.questionsWithTraineeAnswers = [
        {
          ...currentQuestion,
          traineeAnswer
        }
      ];
    } else {
      this.pageWithTraineeAnswers.questions.map(ques => {
        if (this.pageWithTraineeAnswers && this.pageWithTraineeAnswers.id === currentPage.id && ques.id === currentQuestion.id) {
          ques.traineeAnswer = traineeAnswer;
        }

        const isQuestionExists = this.pageWithTraineeAnswers.questions.find(q => q.id === currentQuestion.id);

        if (!isQuestionExists) {
          this.questionsWithTraineeAnswers.push({
            ...currentQuestion,
            traineeAnswer
          });
        }
        return this.questionsWithTraineeAnswers;
      });
    }

    currentPage.questions.filter(q => q.id === currentQuestion.id).forEach(q => {
      q.traineeAnswer = traineeAnswer;
    });

    this.pageWithTraineeAnswers = {
      ...currentPage
    };

    this.canGoNextPage();
    return this.pageWithTraineeAnswers;
  }

  updateQuestionService(pageIndex, questionId) {
    const questionWithTraineeAnswers = this.pageWithTraineeAnswers.questions.find(newQues => newQues.id === questionId);

    return ExaminationService.updateQuestionTest(questionWithTraineeAnswers)
      .then(response => {
        runInAction(() => this.examination.quiz.pages
          .filter(pg => pg.index === pageIndex)
          .map(page => {
            page.questions
              .filter(q => q.id === questionId)
              .map(question => {
                this.examination
                  .quiz
                  .pages[page.index]
                  .questions[page.questions.indexOf(question)] = {
                    ...response
                  };
                return question;
              });
            return this.examination;
          }));
      })
      .catch(error => {
        this.error = error;
      });
  }

  updatePageService(pageId) {
    return ExaminationService.updatePageTest(this.pageWithTraineeAnswers)
      .then(response => {
        runInAction(() => {
          this.examination.quiz.pages
            .filter(pg => pg.id === pageId)
            .map(pg => {
              this.examination.quiz.pages[pg.index] = response;
              return this.examination;
            });
        });
      })
      .catch(error => {
        this.error = error;
      });
  }

  finishQuizService() {
    return ExaminationService.finishQuizTest(this.examination)
      .then(response => {
        runInAction(() => {
          this.examination = response;
        });
        return response;
      })
      .catch(error => {
        this.error = error;
      });
  }

  getTotalPagesAndQuestions() {
    if (this.examination.quiz.pages) {
      this.totalPages = this.examination.quiz.pages.length;
      const questionInArray = this.examination.quiz.pages.map(page => page.questions && page.questions.length);
      this.totalQuestions = questionInArray.reduce((a, b) => a + b, 0);
    } else {
      this.totalPages = 0;
      this.totalQuestions = 0;
    }
  }

  getQuestionNumbering() {
    if (this.examination.quiz.pages) {
      this.examination.quiz.pages.map((page, pageIndx) => {
        const indexOfPreviousPage = pageIndx - 1;

        page.questions.map((question, questionIndex) => {
          const lastPageNumber = this.questionNumbers.length > 0
            && this.questionNumbers[this.questionNumbers.length - 1].questionNumber;

          // First page
          if (indexOfPreviousPage < 0) {
            this.questionNumbers.push({
              pageIndex: page.index,
              questionId: question.id,
              questionNumber: questionIndex + 1
            });
          // Not first page, first question
          } else {
            this.questionNumbers.push({
              pageIndex: page.index,
              questionId: question.id,
              questionNumber: lastPageNumber + 1
            });
          }
          return this.questionNumbers;
        });
        return this.questionNumbers;
      });
    }
    return null;
  }

  // Check if the current page is valid on render
  canGoNextPage() {
    if (Object.keys(this.pageWithTraineeAnswers).length === 0) return null;

    // Get required questions for page validation
    const requiredQuestions = [];
    this.pageWithTraineeAnswers.questions.forEach(q => {
      if (q.isRequired) {
        return requiredQuestions.push(q);
      }
      return null;
    });

    const isNotAnsweredExists = this.pageWithTraineeAnswers.questions
      .some(question => question.isAnswered === false || question.isAnswered === null);

    const isRequiredAnswered = requiredQuestions.every(question => question.isRequired
      && question.traineeAnswer
      && (question.traineeAnswer.length > 0
        || typeof question.traineeAnswer === 'number'
        || question.traineeAnswer instanceof Date));

    const isInvalidQuestionExists = this.isQuestionValid
      .some(q => q.status === false);

    if (this.examination.quiz.forceValidation && isNotAnsweredExists) {
      this.isPageValidated = false;
      return this.isPageValidated;
    }

    if (!isRequiredAnswered) {
      this.isPageValidated = false;
      return this.isPageValidated;
    }

    if (isInvalidQuestionExists) {
      this.isPageValidated = !isInvalidQuestionExists;
      return this.isPageValidated;
    }

    this.isPageValidated = true;
    return this.isPageValidated;
  }

  setCurrentPage(currentPage) {
    this.currentPage = currentPage;

    return this.currentPage;
  }

  setNextPage() {
    const pageIndex = this.currentPage.index;
    if (pageIndex < this.examination.quiz.pages.length - 1) {
      this.currentPage = this.examination.quiz.pages[pageIndex + 1];

      return this.currentPage;
    }
    return null;
  }

  setPreviousPage() {
    const pageIndex = this.currentPage.index;
    if (pageIndex >= 1) {
      this.currentPage = this.examination.quiz.pages[pageIndex - 1];

      return this.currentPage;
    }
    return null;
  }

  toggleShowResults(status) {
    this.showCorrectedAnswers = status;

    return this.showCorrectedAnswers;
  }

  toggleLoadingExamination(status) {
    this.isExaminationLoading = status;

    return this.isExaminationLoading;
  }

  toggleQuestionValidity({ questionType, status }) {
    if (this.isQuestionValid.length === 0) {
      this.isQuestionValid.push({ questionType, status });
    }

    const questionStatusFound = this.isQuestionValid.find(q => q.questionType === questionType);

    if (questionStatusFound) {
      this.isQuestionValid.forEach(q => {
        if (q.questionType === questionType) {
          q.status = status;
          return q;
        }
        return null;
      });
    } else {
      this.isQuestionValid.push({ questionType, status });
    }
    this.canGoNextPage();
    return this.isQuestionValid;
  }
}

export const examinationStore = new ExaminationStore();
