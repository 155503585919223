import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SIZE_MAPPER = {
  xs: '1.8rem',
  '1x': '2rem',
  sm: '2.2rem',
  lg: '2.6rem',
  '2x': '3rem',
  '3x': '4rem'
};

const StyledCustomIcon = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFontIcon = styled.span`
  color: ${props => props.color};

  && {
    font-size: ${props => props.size};
  }
`;

const CustomIcon = ({
  iconClass, icon, secondIcon, thirdIcon,
  color, size, secondSize, secondaryMargin, spin
}) => (
  <StyledCustomIcon>
    {icon && (
      <FontAwesomeIcon color={color} icon={icon} size={size} spin={spin} />
    )}

    {iconClass && !icon && (
      <StyledFontIcon
        className={iconClass}
        color={color}
        size={SIZE_MAPPER[size]}
      >
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
        <span className="path5" />
      </StyledFontIcon>
    )}

    {!spin && secondIcon && !thirdIcon && (
      <FontAwesomeIcon
        color={color}
        icon={secondIcon}
        size={secondSize}
        style={{ marginLeft: secondaryMargin }}
      />
    )}

    {!spin && secondIcon && thirdIcon && (
      <Grid
        alignItems="center"
        container
        direction="column"
        style={{ marginLeft: secondaryMargin }}
      >
        <FontAwesomeIcon
          color={color}
          icon={secondIcon}
          style={{ marginBottom: '0.2rem' }}
        />
        <FontAwesomeIcon
          color={color}
          icon={thirdIcon}
        />
      </Grid>
    )}
  </StyledCustomIcon>
);

CustomIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.shape({}),
  iconClass: PropTypes.string,
  secondIcon: PropTypes.shape({}),
  secondaryMargin: PropTypes.string,
  secondSize: PropTypes.string,
  size: PropTypes.string,
  spin: PropTypes.bool,
  thirdIcon: PropTypes.shape({})
};

CustomIcon.defaultProps = {
  color: 'inherit',
  icon: null,
  iconClass: null,
  secondaryMargin: '0.3rem',
  secondIcon: null,
  secondSize: 'xs',
  size: '1x',
  spin: false,
  thirdIcon: null
};

export default CustomIcon;
