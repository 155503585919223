import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';

import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { ChangeLanguageModal } from 'components/Modals/ChangeLanguageModal';
import { HeaderModal } from 'components/Modals/_HeaderModal';
import { SelectItemWithLeftElement } from 'components/_commons';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import { LanguageService } from 'services';
import { translate } from 'utils';

export const AddLanguageModal = ({ applicationName, onConfirm, onClose }) => {
  const [isFetchingLanguages, setIsFetchingLanguages] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    setIsFetchingLanguages(true);
    LanguageService.getRemainingLanguagesForApplication(`application=${applicationName}`)
      .then(resp => {
        setLanguages(resp);
        setLanguage(resp[0] ?? null);
      })
      .finally(() => setIsFetchingLanguages(false));
  }, [applicationName]);

  const handleSelectLanguage = useCallback(value => setLanguage(value), []);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    onConfirm(language);
    onClose();
  }, [onClose, onConfirm, language]);

  return (
    <form style={{ maxWidth: 500 }} onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faLanguage} />
        <Typography component="span">
          {translate('modalAddLanguageToApplication.title')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        {
          (!isFetchingLanguages && !language)
            ? (
              <Typography>
                <div
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('modalAddLanguageToApplication.empty')) }}
                />
              </Typography>
            ) : (
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom>
                    {translate('modalAddLanguageToApplication.overview')}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <SelectItemWithLeftElement
                    isLoading={isFetchingLanguages}
                    label="common.selectLanguage"
                    name="language"
                    options={languages}
                    required
                    value={language}
                    onChange={handleSelectLanguage}
                  />
                </Grid>
              </Grid>
            )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button color="primary" disabled={isFetchingLanguages || !language} type="submit">
          {translate('button.validate')}
        </Button>
      </DialogActions>
    </form>
  );
};

ChangeLanguageModal.propTypes = {
  applicationName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
