import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils';

const structureLevelUrl = `${API_V2_URL}/structure-levels`;

const moveStructureLevel = moveData => RequestHelper.PUT(`${structureLevelUrl}/move`, moveData);

export const StructureLevelService = {
  moveStructureLevel
};
