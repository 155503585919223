import {
  faEdit, faExternalLinkAlt, faListAlt, faQuestionSquare, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableCell, Grid, Button, IconButton, Typography, Tooltip
} from '@material-ui/core';
import {
  GenericTable, Wrapper, GenericListFilters, TextError, DeleteButton, SkeletonLine,
  Text
} from 'components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import { translate } from 'utils';
import { useModal } from 'hooks';
import { PageTitle } from 'pages';
import { CategoryService } from 'services';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { CATEGORY_FILTER_KEY, NEW_TAB_FROM_CATEGORY_LIST, ROUTES } from 'utils/constants';
import { StorageHelper } from 'utils/helpers';
import { CategoryListFilters } from './CategoryListFilters';

export const ColorPreviewContainer = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: ${props => props.color};
`;

const getListHeaders = (handleCreateEditCategory, handleDeleteCategory, handleSetInitFilter) => ([
  {
    name: 'name',
    label: translate('common.name'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.name}
      </TableCell>
    )
  }, {
    name: 'colorPreview',
    label: translate('common.color'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <ColorPreviewContainer color={row.colorHex} />
      </TableCell>
    )
  },
  {
    name: 'colorHex',
    label: translate('common.hexaCode'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Typography>{row.colorHex}</Typography>
      </TableCell>
    )
  },
  {
    name: 'openInNewTab',
    label: translate('question.viewFilteredQuestionList'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid alignItems="center" container direction="row" spacing={2}>
          <Grid item>
            <Text fontSize="1.4rem">
              {`${row.nbQuestions} ${translate('common.questionOrQuestions')}`}
            </Text>
          </Grid>
          <Grid item>
            <a
              href={`${ROUTES.ADMIN_QUESTION}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon color="primary" icon={faExternalLinkAlt} size="lg" onClick={() => handleSetInitFilter(row.name)} />
            </a>
          </Grid>
        </Grid>
      </TableCell>
    )
  },
  {
    name: 'categoryActions',
    label: translate('common.actions'),
    width: '100px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>

          <Grid item>
            <Tooltip title={translate('common.editItem')}>
              <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleCreateEditCategory(row); }}>
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('button.delete')}>
              <DeleteButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleDeleteCategory(row.id); }}>
                <FontAwesomeIcon color="var(--error-color)" icon={faTrashAlt} size="xs" />
              </DeleteButton>
            </Tooltip>
          </Grid>

        </Grid>
      </TableCell>
    )
  }
]);

export const CategoryList = observer(() => {
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [listSize, setListSize] = useState();

  const filterKey = 'categoryList';

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  const loadCategoryList = useCallback(() => {
    const finalFilters = {};
    filters.forEach(filter => {
      finalFilters[filter.key] = filter.label;
    });

    setIsLoading(true);

    CategoryService.getCategoriesList({ page: currentPage, freeSearch: search, ...finalFilters })
      .then(response => {
        let allCategories = response.content;
        if (currentPage > 0) {
          allCategories = [].concat(...categoryList, allCategories);
        }
        setCategoryList(allCategories);
        setMaxPage(response.totalPages);
        setListSize(response.totalElements);
      })
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [filters, currentPage, search, categoryList, enqueueSnackbar]);

  const refreshCategoryList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 0) {
      loadCategoryList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, loadCategoryList]);

  useEffect(() => {
    refreshCategoryList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffect(() => {
    loadCategoryList();
    // eslint-disable-next-line
  }, [currentPage]);

  const handleLoadMore = useCallback(() => {
    if (!isLoading && currentPage + 1 < maxPage) {
      return setCurrentPage(currentPage + 1);
    }
    return null;
  }, [isLoading, currentPage, maxPage]);

  const createCategory = useCallback(category => {
    if (category.id) {
      return CategoryService.updateCategory(category.id, category)
        .then(() => enqueueSnackbar(translate('confirms.categoryList.update'), { variant: 'success' }))
        .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }))
        .finally(() => refreshCategoryList());
    }

    return CategoryService.createCategory(category)
      .then(() => enqueueSnackbar(translate('confirms.categoryList.create'), { variant: 'success' }))
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }))
      .finally(() => refreshCategoryList());
  }, [refreshCategoryList, enqueueSnackbar]);

  const handleCreateEditCategory = useCallback(row => displayModal({
    type: 'CREATE_CATEGORY',
    onConfirm: createCategory,
    defaultValues: row.name ? row : {}
  }), [displayModal, createCategory]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <CategoryListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const handleDeleteCategory = useCallback(categoryId => {
    displayModal({
      type: 'WARNING',
      title: translate('warnings.warning'),
      text: translate('warnings.category.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        CategoryService.deleteCategory(categoryId)
          .then(() => {
            enqueueSnackbar(translate('confirms.categoryList.delete'), { variant: 'success' });
            if (currentPage > 1) setCurrentPage(0);
            else loadCategoryList();
          })
          .catch(error => enqueueSnackbar(error, { variant: 'error' }));
      }
    });
  }, [currentPage, displayModal, enqueueSnackbar, loadCategoryList]);

  const handleSetInitFilter = useCallback(categoryName => {
    StorageHelper.SET(NEW_TAB_FROM_CATEGORY_LIST, 'true');
    StorageHelper.SET(CATEGORY_FILTER_KEY, categoryName);
  }, []);

  const renderButtonContainer = () => (
    <div className="buttonsContainer">
      <Button
        color="primary"
        startIcon={<FontAwesomeIcon icon={faListAlt} />}
        variant="contained"
        onClick={handleCreateEditCategory}
      >
        {translate('button.createCategory')}
      </Button>
    </div>
  );

  return (
    <>
      <Wrapper>
        <PageTitle
          title={translate('pageCategoryList.title')}
          titleRight={renderButtonContainer()}
        />

        <GenericListFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-categoryList-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltipInfo="pageCategoryList.searchTooltip"
          withDrawer
        />

        <div data-tour="step-admin-questionList">
          {!isLoading && categoryList.length === 0
            ? (
              <Grid alignItems="center" container direction="column">
                <FontAwesomeIcon color="var(--primary-color)" icon={faQuestionSquare} size="3x" />
                <TextError>{translate('errors.noCategories')}</TextError>
              </Grid>
            ) : (
              <GenericTable
                dataCy="questionList"
                hasMore={currentPage + 1 < maxPage}
                headers={getListHeaders(handleCreateEditCategory, handleDeleteCategory, handleSetInitFilter)}
                isLoading={isLoading}
                loadMore={handleLoadMore}
                rows={categoryList}
                total={listSize}
              />
            )}
        </div>

        {isLoading && categoryList.length === 0 && <SkeletonLine />}
      </Wrapper>
    </>
  );
});
