import { TextField } from '@material-ui/core';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils';

import { buildDefaultAndTraineeAnswers } from '../../QuizHelper';

const TextQuestion = observer(({ currentQuestionData, isReadOnlyQuestion }) => {
  const { examinationStore } = useStores();

  const { currentPage } = examinationStore;

  const [currentValue, setCurrentValue] = useState('');

  const buildAnswers = useCallback(() => {
    setCurrentValue(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleChangeAnswer = useCallback(event => {
    const { value } = event.target;
    setCurrentValue(value);

    examinationStore.setTraineeAnswers(currentPage, currentQuestionData, value);
  }, [currentPage, currentQuestionData, examinationStore]);

  return (
    <>
      <TextField
        autoComplete="off"
        disabled={isReadOnlyQuestion}
        fullWidth
        name="textAnswer"
        placeholder={translate('common.writeAnswer')}
        required
        style={{ margin: '10px 0px' }}
        value={currentValue}
        variant="outlined"
        onChange={handleChangeAnswer}
      />
    </>
  );
});

export default TextQuestion;

TextQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

TextQuestion.defaultProps = {
  currentQuestionData: {}
};
