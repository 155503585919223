import { faAngleRight, faFilePdf, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider, FormControl, FormControlLabel, FormLabel, Grid,
  Paper, Radio, RadioGroup, Typography,
  makeStyles
} from '@material-ui/core';

import { QuizEndPage } from 'components/_commons';
import { QUIZ_STATUS, isAnonymous } from 'components/_commons/Quiz/QuizHelper';
import { differenceInMinutes } from 'date-fns';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { formatDate, translate } from 'utils';
import { handleExportToPDF } from '.';

const useStyles = makeStyles(() => ({
  resultsContainer: {
    borderTop: '1px solid var(--primary-color-light)'
  },
  leftSideContainer: {
    padding: '0 10px',
    boxShadow: '10px 0 5px -10px var(--primary-color-light)',
    maxWidth: '25em'
  },
  traineeFields: {
    fontWeight: 'bolder'
  },
  divider: {
    margin: '30px 0'
  }
}));

export const QuizResultsDetail = observer(({ examinationId }) => {
  const { displayingResults } = QUIZ_STATUS;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { examinationStore } = useStores();

  const [isAllAnswers, setIsAllAnswers] = useState(true);
  const [isCorrectAnswers, setIsCorrectAnswers] = useState(false);
  const [isIncorrectAnswers, setIsIncorrectAnswers] = useState(false);
  const [isFreeAnswer, setIsFreeAnswer] = useState(false);
  const [isQuizAnonymous, setIsQuizAnonymous] = useState({});

  const { examination, isLoading } = examinationStore;

  const {
    quiz, finalScore, startDate, endDate, unansweredQuestions, sequence, module
  } = examination;

  const loadExamResults = useCallback(() => {
    examinationStore.loadExamination(examinationId)
      .then(() => examinationStore.getQuestionNumbering())
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }));
  }, [examinationStore, examinationId, enqueueSnackbar]);

  useEffect(() => {
    examinationStore.toggleShowResults(true);
    loadExamResults();
  }, [examinationStore, loadExamResults]);

  useEffect(() => {
    setIsQuizAnonymous(isAnonymous(examination));
  }, [examination]);

  const startFormattedDate = startDate && formatDate(startDate);
  const examDuration = differenceInMinutes(new Date(endDate), new Date(startDate));

  const handleRadioChange = useCallback(event => {
    const { name } = event.target;

    if (name === 'isAllAnswers') {
      setIsAllAnswers(true);
      setIsCorrectAnswers(false);
      setIsIncorrectAnswers(false);
      setIsFreeAnswer(false);

      loadExamResults();
    }

    if (name === 'isCorrectAnswers') {
      setIsAllAnswers(false);
      setIsCorrectAnswers(true);
      setIsIncorrectAnswers(false);
      setIsFreeAnswer(false);

      examinationStore.loadExamination(examinationId)
        .then(() => {
          examinationStore.getCorrectAnswers();
        });
    }

    if (name === 'isIncorrectAnswers') {
      setIsAllAnswers(false);
      setIsCorrectAnswers(false);
      setIsIncorrectAnswers(true);
      setIsFreeAnswer(false);

      examinationStore.loadExamination(examinationId)
        .then(() => {
          examinationStore.getIncorrectAnswers();
        });
    }

    if (name === 'isFreeAnswer') {
      setIsAllAnswers(false);
      setIsCorrectAnswers(false);
      setIsIncorrectAnswers(false);
      setIsFreeAnswer(true);

      examinationStore.loadExamination(examinationId)
        .then(() => {
          examinationStore.getFreeAnswers();
        });
    }
  }, [examinationId, loadExamResults, examinationStore]);

  return (
    <>
      <PageTitle
        title={translate('pageQuizResultsList.quizResultsDetails')}
        titleRight={(
          <Button
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            style={{ marginRight: '1rem' }}
            onClick={() => handleExportToPDF(quiz)}
          >
            {translate('quiz.results.exportPDF')}
          </Button>
        )}
      />
      <Grid container direction="row" justifyContent="flex-end">
        <FormControl component="fieldset">
          <FormLabel component="legend">{translate('quiz.results.resultsFilter')}</FormLabel>
          <RadioGroup aria-label="quizResultsFilter" name="row-radio-buttons-group" row onChange={handleRadioChange}>
            <FormControlLabel
              control={<Radio checked={isAllAnswers} color="primary" name="isAllAnswers" />}
              label={translate('quiz.results.allAnswersFilter')}
              value="allAnswers"
            />
            <FormControlLabel
              control={<Radio checked={isCorrectAnswers} name="isCorrectAnswers" style={{ color: 'var(--success-color)' }} />}
              label={translate('quiz.results.correctAnswersFilter')}
              style={{ color: 'var(--success-color)' }}
              value="correctAnswers"
            />
            <FormControlLabel
              control={<Radio checked={isIncorrectAnswers} name="isIncorrectAnswers" style={{ color: 'var(--error-color)' }} />}
              label={translate('quiz.results.incorrectAnswersFilter')}
              style={{ color: 'var(--error-color)' }}
              value="incorrectAnswers"
            />
            <FormControlLabel
              control={<Radio checked={isFreeAnswer} color="primary" name="isFreeAnswer" />}
              label={translate('quiz.results.freeAnswers')}
              value="isFreeAnswer"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid
        className={classes.resultsContainer}
        container
        direction="row"
        id="resultsContainer"
        wrap="nowrap"
      >
        <Grid className={`${classes.leftSideContainer} pdf-export__header`} item xs={3}>
          <Grid alignItems="center" container direction="column" style={{ padding: '25px 0' }}>
            <Paper elevation={0} style={{ padding: 25, backgroundColor: 'var(--grey-lighter)' }}>
              <Grid alignItems="center" container direction="column">
                <Typography>{`${translate('quiz.globalScoreObtained')} :`}</Typography>
                <Typography className={classes.traineeFields} variant="h3">{finalScore}</Typography>
              </Grid>
            </Paper>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.traineeFields}>{`${translate('common.intern')} :`}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {isQuizAnonymous.status
                      ? translate('common.anonymous')
                      : isQuizAnonymous.field}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.traineeFields}>{`${translate('common.on')} :`}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{startFormattedDate}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.traineeFields}>{`${translate('common.in')} :`}</Typography>
                </Grid>
                <Grid item>
                  {!Number.isNaN(examDuration)
                  && <Typography>{translate('quiz.results.examDuration', { examDuration })}</Typography>}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid alignItems="center" container>
                <Grid item xs={9}>
                  <Typography className={classes.traineeFields}>{`${translate('question.noneAnsweredQuestions')} :`}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{unansweredQuestions}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid alignItems="center" container>
            <Grid item xs={4}>
              <Typography className={classes.traineeFields}>{`${translate('quiz.quizName')} :`}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{quiz && quiz.title}</Typography>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container direction="column" justifyContent="flex-start">
            <Grid item style={{ marginBottom: 10 }} xs={4}>
              <Typography className={classes.traineeFields}>{`${translate('common.attachedTo')} :`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <FontAwesomeIcon className="mr1" icon={faAngleRight} />
                {module && module.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ marginTop: 5, paddingLeft: 20 }}>
                <FontAwesomeIcon className="mr1" icon={faAngleRight} />
                {sequence && sequence.label}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="pdf-export__content" item style={{ padding: 20 }} xs={9}>
          <Typography
            className={classes.traineeFields}
            variant="h3"
          >
            {translate('quiz.results.correction')}
          </Typography>
          <Grid container spacing={2}>
            {isLoading ? (
              <>
                <Grid item>
                  <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="lg" spin />
                </Grid>
                <Grid item>
                  <Typography>{translate('quiz.results.resultsLoading')}</Typography>
                </Grid>
              </>
            ) : (
              <>
                {examination?.quiz && <QuizEndPage quizStatus={displayingResults} />}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

QuizResultsDetail.propTypes = {
  examinationId: PropTypes.string.isRequired
};
