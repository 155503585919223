import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionsUrl = `${API_V2_URL}/institutions`;

// GET
const getInstitution = institutionId => RequestHelper.GET(`${institutionsUrl}/${institutionId}`);

// POST
const getInstitutionsList = (filter, page = 0) => RequestHelper.POST(`${institutionsUrl}/filter?page=${page}`, filter);

const getInstitutionsOptions = (params = {}) => RequestHelper.POST(`${institutionsUrl}/options`, params);

const createInstitution = institution => RequestHelper.POST(`${institutionsUrl}`, institution);

const updateInstitution = (hashId, institution) => RequestHelper.PUT(`${institutionsUrl}/${hashId}`, institution);

const getInstitutionTree = (institutionId, activity) => RequestHelper.GET(
  `${institutionsUrl}/${institutionId}/tree?activity=${activity}`
);

// DELETE
const deleteInstitution = institutionId => RequestHelper.DELETE(`${institutionsUrl}/${institutionId}`);

export const InstitutionService = {
  getInstitution,
  getInstitutionsList,
  getInstitutionsOptions,
  createInstitution,
  updateInstitution,
  deleteInstitution,
  getInstitutionTree
};
