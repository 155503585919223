import { useEffect, useState } from 'react';
import { EmailValidator } from 'commons-validator-js';
import { translate } from 'utils';

const SELECT_ADAPTOR_TO_FRONT = ({ selectItem, translationKey = '' }) => selectItem && ({
  ...selectItem,
  value: selectItem.key,
  label: translationKey ? translate(`${translationKey}.${selectItem.label}`) : selectItem.label
});

const SELECT_ADAPTOR_TO_FRONT_MULTI = ({ selectItems, translationKey = '' }) => selectItems.map(selectItem => ({
  ...selectItem,
  value: selectItem.key,
  label: translationKey ? translate(`${translationKey}.${selectItem.label}`) : selectItem.label
}));

const SELECT_ADAPTOR_TO_BACK = selectItem => selectItem && ({
  ...selectItem,
  key: selectItem.value
});

const SELECT_ADAPTOR_TO_BACK_MULTI = selectItems => (selectItems ? selectItems.map(selectItem => ({
  ...selectItem,
  key: selectItem.value
})) : []);

const isFieldRequired = ({ fieldsToCheck = [] }) => fieldsToCheck.some(field => field);

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const isEmailValid = email => {
  if (!email) return true;
  const emailValidator = new EmailValidator();
  return emailValidator.isValid(email);
};

const validateEmail = email => {
  const isValid = isEmailValid(email);
  if (!isValid) {
    return translate('modalUser.invalidEmail');
  }
  return null;
};

const validateEmails = emails => {
  if (!emails) {
    return null;
  }
  const invalidEmails = emails.split(';')
      .map(email => (isEmailValid(email) ? null : email))
      .filter(email => email !== null);

  if (invalidEmails?.length) {
    return `${translate('common.contactEmails.invalid')} ${invalidEmails.join(', ')}`;
  }
  return null;
};

export const FormHelper = {
  SELECT_ADAPTOR_TO_FRONT,
  SELECT_ADAPTOR_TO_FRONT_MULTI,
  SELECT_ADAPTOR_TO_BACK,
  SELECT_ADAPTOR_TO_BACK_MULTI,
  useDebounce,
  isFieldRequired,
  validateEmail,
  validateEmails
};
