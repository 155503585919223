import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio, Tooltip
} from '@material-ui/core';
import { translate } from 'utils';

const GenericRadio = ({
  isCorrect, onHandleRadioChange
}) => (
  <Tooltip title={translate('pageQuestionList.isAnswerCorrect')}>
    <Radio
      checked={Boolean(isCorrect)}
      color="primary"
      name="isCorrect"
      onClick={onHandleRadioChange}
    />
  </Tooltip>
);

GenericRadio.propTypes = {
  isCorrect: PropTypes.bool,
  onHandleRadioChange: PropTypes.func.isRequired
};

GenericRadio.defaultProps = {
  isCorrect: false
};

export default GenericRadio;
