import { Dialog, Slide } from '@material-ui/core';
import { CookiesModal } from 'components/Modals/CookiesModal';
import { HistoryModal } from 'components/Modals/HistoryModal';
import { RejectPurchaseOrderModal } from 'components/Modals/RejectPurchaseOrderModal';
import PropTypes from 'prop-types';
import React from 'react';
import { ExportAnswersSingleQuizModal } from 'components/Modals/ExportAnswersSingleQuizModal';
import {
  AddLanguageModal,
  AddSubscriptionModal,
  BrowserPopupModal,
  CategoryModal,
  ChangeLanguageModal,
  ConfirmModal,
  CreateCompanyModal,
  CreateCountryModal,
  CreateGroupModal,
  CreateInstitutionGroupModal,
  CreateInstitutionModal,
  CreateLanguageModal,
  CreateLegalStatusModal,
  CreateUserModal,
  EditHTMLModal,
  ErrorModal,
  ImportModal,
  QuestionBlockModal,
  QuestionModal,
  QuizCertifyingWarningModal,
  QuizLoginModal,
  QuizTimeOutModal,
  TestRegexModal,
  UpdateRoleLanguagesModal,
  UpdateRolesModal,
  UpdateUserModal,
  WarningModal
} from '.';

const MODAL_TYPES = {
  ADD_LANGUAGE: AddLanguageModal,
  ADD_SUBSCRIPTION: AddSubscriptionModal,
  AUTHENTICATE_TRAINEE: QuizLoginModal,
  BROWSER_POPUP: BrowserPopupModal,
  CHANGE_LANGUAGE: ChangeLanguageModal,
  CREATE_GROUP: CreateGroupModal,
  CREATE_COMPANY: CreateCompanyModal,
  CREATE_INSTITUTION: CreateInstitutionModal,
  CREATE_LANGUAGE: CreateLanguageModal,
  CREATE_COUNTRY: CreateCountryModal,
  CREATE_LEGAL_STATUS: CreateLegalStatusModal,
  CREATE_CATEGORY: CategoryModal,
  CREATE_USER: CreateUserModal,
  CREATE_INSTITUTION_GROUP: CreateInstitutionGroupModal,
  EDIT_HTML: EditHTMLModal,
  QUIZ_TIME_OUT: QuizTimeOutModal,
  TEST_REGEX: TestRegexModal,
  IMPORT: ImportModal,
  UPDATE_USER: UpdateUserModal,
  UPDATE_ROLE_LANGUAGES: UpdateRoleLanguagesModal,
  UPDATE_ROLES: UpdateRolesModal,
  HISTORY_MODAL: HistoryModal,
  TRAINEE_CONFIRMATION_CERTIFYING_QUIZ: QuizCertifyingWarningModal,
  COOKIES_MODAL: CookiesModal,
  REJECT_PURCHASE_ORDER: RejectPurchaseOrderModal,
  ALL_ANSWERS_MODAL: ExportAnswersSingleQuizModal
};

const SECONDARY_MODAL_TYPES = {
  CONFIRM: ConfirmModal,
  ERROR: ErrorModal,
  WARNING: WarningModal
};

const CURRENT_LARGE_MODAL_TYPES = {
  CREATE_QUESTION: QuestionModal,
  CREATE_QUESTION_BLOCK: QuestionBlockModal
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const Modal = ({
  onClose, onSubmit, open, type, disableClose, ...rest
}) => {
  const isFullscreen = document.getElementsByTagName('body')[0].clientWidth <= 768;

  const CurrentModal = type && MODAL_TYPES[type];
  const CurrentSecondaryModal = type && SECONDARY_MODAL_TYPES[type];
  const CurrentLargeModal = type && CURRENT_LARGE_MODAL_TYPES[type];

  if (!CurrentModal && !CurrentSecondaryModal && !CurrentLargeModal) return null;

  return (
    <>
      {CurrentModal && (
        <Dialog
          disableEscapeKeyDown={disableClose}
          fullScreen={isFullscreen}
          maxWidth="lg"
          open={open}
          TransitionComponent={Transition}
          transitionDuration={300}
          onClose={(event, reason) => {
            // disableBackdropClick is now deprecated, onCLose reason needs to be used
            if (disableClose && reason === 'backdropClick') {
              return;
            }
            onClose(event, reason);
          }}
        >
          <CurrentModal {...rest} onClose={onClose} onSubmit={onSubmit} />
        </Dialog>
      )}
      {CurrentSecondaryModal && (
        <Dialog
          disableEscapeKeyDown={disableClose}
          fullScreen={false}
          maxWidth="lg"
          open={open}
          TransitionComponent={Transition}
          transitionDuration={300}
          onClose={(event, reason) => {
            // disableBackdropClick is now deprecated, onCLose reason needs to be used
            if (disableClose && reason === 'backdropClick') {
              return;
            }
            onClose(event, reason);
          }}
        >
          <CurrentSecondaryModal {...rest} onClose={onClose} onSubmit={onSubmit} />
        </Dialog>
      )}
      {CurrentLargeModal && (
        <Dialog
          disableEscapeKeyDown={disableClose}
          fullScreen={isFullscreen}
          fullWidth
          maxWidth="lg"
          open={open}
          TransitionComponent={Transition}
          transitionDuration={300}
          onClose={(event, reason) => {
            // disableBackdropClick is now deprecated, onCLose reason needs to be used
            if (disableClose && reason === 'backdropClick') {
              return;
            }
            onClose(event, reason);
          }}
        >
          <CurrentLargeModal {...rest} onClose={onClose} onSubmit={onSubmit} />
        </Dialog>
      )}
    </>
  );
};

Modal.propTypes = {
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  secondaryModal: PropTypes.shape({}),
  type: PropTypes.string
};

Modal.defaultProps = {
  disableClose: true,
  onClose: () => { },
  onSubmit: () => { },
  open: false,
  secondaryModal: null,
  type: 'CONFIRM'
};
