import {
  Button, Divider, Grid, Typography
} from '@material-ui/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DocumentHelper, translate } from 'utils';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStores, useModal } from 'hooks';
import {
  faBadgeCheck,
  faPollH, faRedo, faSpinner, faStar
} from '@fortawesome/pro-regular-svg-icons';
import { useSnackbar } from 'notistack';
import { runInAction } from 'mobx';
import Linkify from 'react-linkify';
import { Text } from 'components/_commons/Text';
import { QUIZ_STATUS } from '../QuizHelper';

const Header = styled.header`
  padding: 5px;
`;

const QuizHeader = observer(({ quizStatus }) => {
  const { notRunning, ended, displayingResults } = QUIZ_STATUS;

  const { enqueueSnackbar } = useSnackbar();
  const displayModal = useModal();

  const { examinationStore, quizStore, userStore } = useStores();

  const { examinationState, isExaminationLoading, examination } = examinationStore;
  const { isConnected } = userStore;

  const { isQuizStarted, isQuizEnded } = examinationState;

  const {
    quiz, quiz: {
      isCertifying, withIdentification, title, description, logo, displayRatingOnly, displayResults, allowRepeat, maxTimeToFinish,
      endText
    }
  } = examination;

  const renderQuizTitle = () => (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ marginBottom: '1rem' }}
      >
        {logo && (
          <Grid item lg={2}>
            <img alt={title} src={DocumentHelper.getPNGWithBase64(logo)} style={{ maxHeight: 150, maxWidth: 150 }} />
          </Grid>
        )}
        <Grid item lg={10}>
          <Typography component="h2" style={{ color: 'var(--tecnea-blue)' }} variant="h5">{title}</Typography>
          {description && <Typography style={{ whiteSpace: 'break-spaces' }}>{description}</Typography>}
        </Grid>
      </Grid>
    </>
  );

  const renderQuizStartAlert = () => (
    <>
      {isCertifying && (
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          style={{ padding: '5px 0px' }}
        >
          <Grid item>
            <FontAwesomeIcon color="var(--tecnea-blue)" icon={faBadgeCheck} size="2x" />
          </Grid>
          <Grid item>
            <strong>{translate('quiz.certifyingQuiz')}</strong>
          </Grid>
        </Grid>
      )}
      {maxTimeToFinish > 0 && (
        <Text fontWeight="bold">{translate('quiz.startQuizTime', { maxTimeToFinishMinutes: maxTimeToFinish })}</Text>
      )}
    </>
  );

  // Quiz display on quiz results tab
  if (quizStatus === displayingResults || !quiz) {
    return null;
  }

  // Quiz display on sequence edition
  // if (editionMode) {
  //   return renderQuizTitle();
  // }

  const startExamination = useCallback(() => {
    if (quizStore.quiz?.pages?.length > 0) {
      runInAction(() => {
        examinationStore.setExamStatus({
          isStarted: true,
          isEnded: false,
          examinationId: examination.id,
          quizId: quiz.id
        });
      });
    } else {
      enqueueSnackbar(translate('quiz.addPages'), { variant: 'error' });
    }

    examinationStore.toggleShowResults(false);
  }, [examinationStore, examination, quiz, quizStore, enqueueSnackbar]);

  const handleStart = useCallback(() => {
    if (isCertifying) {
      displayModal({
        type: 'TRAINEE_CONFIRMATION_CERTIFYING_QUIZ',
        afterClose: () => (withIdentification && !isConnected
          ? displayModal({
            type: 'AUTHENTICATE_TRAINEE',
            onConfirm: startExamination,
            defaultValues: {}
          }) : null),
        onConfirm: !withIdentification || isConnected ? startExamination : null,
        defaultValues: {}
      });
    } else if (withIdentification && !isConnected) {
      displayModal({
        type: 'AUTHENTICATE_TRAINEE',
        onConfirm: startExamination,
        defaultValues: {}
      });
    } else {
      startExamination();
    }
  }, [displayModal, isConnected, withIdentification, isCertifying, startExamination]);

  const handleRestartQuiz = useCallback(() => {
    examinationStore.setExamination(quizStore.quiz);

    examinationStore.setExamStatus({
      isStarted: false, isEnded: false, examinationId: examination.id, quizId: quiz.id
    });

    examinationStore.toggleShowResults(false);
  }, [examinationStore, examination, quiz, quizStore]);

  const handleShowResults = useCallback(() => {
    examinationStore.toggleShowResults(true);
  }, [examinationStore]);

  const renderStartButton = () => (
    <Button
      color="primary"
      disabled={isExaminationLoading}
      startIcon={<FontAwesomeIcon icon={isExaminationLoading ? faSpinner : faPollH} spin={isExaminationLoading} />}
      style={{ marginTop: '1rem' }}
      variant="contained"
      onClick={handleStart}
    >
      {translate('button.begin')}
    </Button>
  );

  const renderRestartButton = () => (
    <Button
      color="secondary"
      startIcon={<FontAwesomeIcon icon={faRedo} />}
      style={{ marginBottom: 10 }}
      variant="contained"
      onClick={handleRestartQuiz}
    >
      {translate('button.restart')}
    </Button>
  );

  const renderShowResultsButton = () => (
    <Button
      color="primary"
      startIcon={<FontAwesomeIcon icon={faStar} />}
      variant="contained"
      onClick={handleShowResults}
    >
      {translate('button.showResults')}
    </Button>
  );

  const componentDecorator = (decoratedHref, decoratedText, key) => (
    <a href={decoratedHref} key={key} rel="noopener noreferrer" target="_blank">
      {decoratedText}
    </a>
  );

  return (
    <Header>
      <Grid container direction="column">

        {title !== quizStore.quiz.title ? (
          <Grid container spacing={2}>
            <Grid item>
              <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="lg" spin />
            </Grid>
            <Grid item>
              <Typography>{translate('quiz.quizLoading')}</Typography>
            </Grid>
          </Grid>
        ) : (renderQuizTitle())}

        {displayRatingOnly && examination?.finalScore && (quizStatus === notRunning || quizStatus === ended) && (
          <Grid item style={{ margin: '15px 0px' }}>
            <Typography component="h3" style={{ color: 'var(--success-color)' }} variant="h5">
              {`${translate('quiz.globalScore')} : ${examination.finalScore}`}
            </Typography>
          </Grid>
        )}
        {quizStatus === ended && (
          <Grid item>
            <Typography component="h3" style={{ color: 'var(--success-color)' }} variant="h5">
              {translate('quiz.thanksForFinishing')}
            </Typography>
          </Grid>
        )}

        {quizStatus === ended && (
          <>
            {endText && endText.length > 0 && (
              <Grid item>
                <Linkify
                  // eslint-disable-next-line react/jsx-no-bind
                  componentDecorator={componentDecorator}
                >
                  {endText}
                </Linkify>
              </Grid>
            )}
          </>
        )}

        <Divider style={{ margin: '20px 0px' }} />

        {quizStatus === notRunning && renderQuizStartAlert()}

        <Grid item>
          {!isQuizStarted && !isQuizEnded && renderStartButton()}
        </Grid>

        <Grid item>
          {isQuizEnded && allowRepeat && renderRestartButton()}
        </Grid>

        <Grid item>
          {isQuizEnded && displayResults && renderShowResultsButton()}
        </Grid>
      </Grid>
      {quizStatus === ended && <Divider style={{ margin: '20px 0px' }} />}
    </Header>
  );
});

export default QuizHeader;

QuizHeader.propTypes = {
  quizStatus: PropTypes.string.isRequired
};
