import { faUserCheck, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { CustomIcon, FullWrapper } from 'components';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import Media from 'react-media';
import { Link, NavLink } from 'react-router-dom';
import shortid from 'shortid';
import styled from 'styled-components';
import { translate, UserHelper } from 'utils';
import { MENU_ITEMS, ROUTES } from 'utils/constants';

const StyledHeaderMenu = styled.nav`
  position: relative;
  height: var(--header-nav-height);
  background: var(--white);
  border-bottom: 1px solid var(--primary-color);
  z-index: var(--zindexBig);
`;

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  flex: 1;
  padding: 1.5rem;
  color: var(--header-grey);
  text-transform: uppercase;

  &.active,
  &:hover {
    color: var(--tecnea-blue);
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 4px;
      background: var(--tecnea-blue);
    }
  }

  svg {
    margin-right: 1rem;
  }
`;

export const HeaderMenu = ({ isConnected, connectUser }) => (
  <StyledHeaderMenu>
    <FullWrapper>
      <Grid alignItems="center" container justifyContent='flex-end' wrap='nowrap'>
        {
          !isConnected && isMobileOnly
          && (
          <Grid alignItems="center" container item spacing={2} xs={8}>
            <Grid item>
              <Link to={ROUTES.REGISTER}>
                <Button
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                  variant="contained"
                />
              </Link>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                data-cy="connectUser"
                startIcon={<FontAwesomeIcon icon={faUserCheck} />}
                variant="contained"
                onClick={connectUser}
              />
            </Grid>
          </Grid>
          )
        }
        {MENU_ITEMS.map((item, index) => (
          (item.accessRights.length > 0 && UserHelper.hasAccessRight(item.accessRights))
            || item.accessRights.length === 0) && (
            <Grid item key={shortid.generate()} style={{ minWidth: 0 }}>
              <StyledLink
                activeClassName="active"
                data-cy={item.title}
                data-tour={`step-header-menu-${index}`}
                to={item.url}
              >
                <CustomIcon
                  icon={item.icon}
                  secondaryMargin="-5px"
                  secondIcon={item.secondIcon}
                  secondSize="lg"
                  size="lg"
                  thirdIcon={item.thirdIcon}
                />
                <Media
                  query="(min-width: 768px)"
                  render={() => translate(item.title)}
                />
              </StyledLink>
            </Grid>
        ))}
      </Grid>
    </FullWrapper>
  </StyledHeaderMenu>
);
