import { faComments } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, Paper, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InputField } from 'components';
import { ChangeLanguageModal } from 'components/Modals/ChangeLanguageModal';
import { HeaderModal } from 'components/Modals/_HeaderModal';
import { SelectItemWithLeftElement, Text } from 'components/_commons';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils';
import { FRENCH_LOCALE } from 'utils/constants';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 15,
    height: 400
  },
  paperSecondColumn: {
    overflowY: 'auto'
  }
}));

export const CreateLanguageModal = observer(({
  language, languages, countries, isFetchingCountries, onConfirm, onClose, forbiddenLocales
}) => {
  const classes = useStyles();
  const [frenchName, setFrenchName] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const [locale, setLocale] = useState(language?.locale ?? '');
  const [isLocaleValid, setIsLocaleValid] = useState(true);
  const [isLocaleTaken, setIsLocaleTaken] = useState(false);
  const [label, setLabel] = useState(language?.label ?? '');
  const [isLabelValid, setIsLabelValid] = useState(true);
  const [translationNotFoundLabel, setTranslationNotFoundLabel] = useState(language?.translationNotFoundLabel ?? '');
  const [isTranslationNotFoundLabelValid, setIsTranslationNotFoundLabelValid] = useState(true);
  const [countrySelectItems, setCountrySelectItems] = useState([]);
  const [translations, setTranslations] = useState({});
  const [country, setCountry] = useState(language?.countryCode ? {
    value: language.countryCode,
    label: countries[language.countryCode]
  } : null);

  useEffect(() => {
    const result = Object.entries(countries).map(item => ({
      value: item[0],
      label: item[1]
    }));
    const newTranslations = {};
    language?.translations && language.translations.forEach(translation => {
      translation.code !== FRENCH_LOCALE
        ? (newTranslations[translation.code] = translation.label)
        : setFrenchName(translation.label);
    });
    setTranslations(newTranslations);
    setCountrySelectItems(result);
  }, [language, countries]);

  const toTranslationList = useCallback(() => {
    const translationList = Object.entries(translations).map(l => ({ code: l[0], label: l[1] }));
    translationList.push({ code: FRENCH_LOCALE, label: frenchName });
    !language && translationList.push({ code: locale.replaceAll('-', '_'), label: '' });
    return translationList;
  }, [language, translations, frenchName, locale]);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    onConfirm({
      ...language,
      name: frenchName.toUpperCase(),
      locale: locale.replaceAll('-', '_'),
      oldLocale: language?.locale,
      label,
      countryCode: country.value,
      translations: toTranslationList(),
      translationNotFoundLabel
    });
    onClose();
  }, [onClose, onConfirm, language, country, label, locale, frenchName, toTranslationList, translationNotFoundLabel]);

  const handleChangeFrenchName = useCallback(value => {
    setFrenchName(value);
    setIsNameValid(/[a-zA-Z _-]+$/g.test(value));
  }, []);

  const handleChangeLocale = useCallback(value => {
    setLocale(value);
    setIsLocaleValid(/^[a-z]{2}[_][A-Z]{2}$/g.test(value));
    setIsLocaleTaken(forbiddenLocales.includes(value.toUpperCase()) && value.toUpperCase() !== language?.locale?.toUpperCase());
  }, [forbiddenLocales, language]);

  const handleChangeLabel = useCallback(value => {
    setLabel(value);
    setIsLabelValid(Boolean(value));
  }, []);

  const handleChangeCountry = useCallback(value => {
    setCountry(value);
  }, []);

  const handleChangeTranslationNotFoundLabel = useCallback(value => {
    setTranslationNotFoundLabel(value);
    setIsTranslationNotFoundLabelValid(Boolean(value));
  }, []);

  const handleChangeTranslation = useCallback((editedLanguageLocale, value) => {
    const newTranslations = { ...translations };
    newTranslations[editedLanguageLocale] = value;
    setTranslations(newTranslations);
  }, [translations]);

  const displayInfoTabContent = useCallback(() => (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <InputField
          error={!isLocaleValid || isLocaleTaken}
          helperText={translate(`modalCreateLanguage.help.${isLocaleTaken ? 'localeTaken' : 'locale'}`)}
          label={translate('pageLanguages.column.locale')}
          name="language_locale"
          placeholder={translate('modalCreateLanguage.placeholder.locale')}
          required
          value={locale}
          onChange={(_, value) => handleChangeLocale(value)}
        />
      </Grid>
      <Grid item>
        <InputField
          error={!isLabelValid}
          helperText=" " // empty string to keep the same space between the fields
          label={translate('modalCreateLanguage.label')}
          name="language_label"
          placeholder={translate('modalCreateLanguage.placeholder.label')}
          required
          value={label}
          onChange={(_, value) => handleChangeLabel(value)}
        />
      </Grid>
      <Grid item>
        <SelectItemWithLeftElement
          isFlagElement
          isLoading={isFetchingCountries}
          label="modalCreateLanguage.country"
          name="language"
          options={countrySelectItems}
          placeholder="modalCreateLanguage.placeholder.country"
          required
          value={country}
          onChange={handleChangeCountry}
        />
      </Grid>
      <Grid item>
        <InputField
          error={!isTranslationNotFoundLabelValid}
          label={translate('modalCreateLanguage.translationNotFound')}
          name="translationNotFound_label"
          placeholder={translate('modalCreateLanguage.placeholder.translationNotFound')}
          required
          value={translationNotFoundLabel}
          onChange={(_, value) => handleChangeTranslationNotFoundLabel(value)}
        />
      </Grid>
    </Grid>
  ), [country, countrySelectItems, handleChangeCountry, handleChangeLabel, handleChangeLocale,
    handleChangeTranslationNotFoundLabel, translationNotFoundLabel, isTranslationNotFoundLabelValid,
    isFetchingCountries, isLabelValid, isLocaleValid, isLocaleTaken, label, locale]);

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faComments} />
        <Typography component="span">
          {language
            ? translate('modalCreateLanguage.titleModify')
            : translate('modalCreateLanguage.titleCreate')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ overflow: 'hidden', minWidth: '50em' }}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} variant="outlined">
              {displayInfoTabContent()}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={`${classes.paper} ${classes.paperSecondColumn}`} variant="outlined">
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Text fontWeight="bold" margin="0 0 8px 0">
                    {translate('modalCreateLanguage.frenchName')}
                  </Text>
                  <InputField
                    error={!isNameValid}
                    helperText={translate('modalCreateLanguage.help.name')}
                    label={translate(`languages.${FRENCH_LOCALE}`)}
                    name="language_name"
                    required
                    value={frenchName}
                    onChange={(_, value) => handleChangeFrenchName(value)}
                  />
                </Grid>
                <Grid container direction="column" item>
                  <Grid item>
                    <Text fontWeight="bold" margin="0 0 8px 0">
                      {translate('modalCreateLanguage.translations')}
                    </Text>
                  </Grid>
                  <Grid container direction="column" item spacing={3}>
                    {languages && languages.map(lng => (lng.locale !== FRENCH_LOCALE) && (
                      <Grid item key={`name-${lng.locale}`}>
                        <InputField
                          label={translate(`languages.${lng.locale}`)}
                          value={translations[lng.locale]}
                          onChange={(_, value) => handleChangeTranslation(lng.locale, value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!isNameValid || !isLocaleValid || !isLabelValid || !country || isLocaleTaken}
          type="submit"
        >
          {language
            ? translate('button.validate')
            : translate('button.create')}
        </Button>
      </DialogActions>
    </form>
  );
});

ChangeLanguageModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
