import { DocumentHelper, RequestHelper } from 'utils';
import { API_V2_URL, DATAFLUIDES_API_URL, setCurrentVersion } from 'utils/constants';

const datacommonsDocumentsUrl = `${API_V2_URL}/documents`;
const datacommonsUserSubscriptionsUrl = `${API_V2_URL}/user-subscriptions`;
const datafluidesPurchaseOrderUrl = `${DATAFLUIDES_API_URL}/${setCurrentVersion()}/purchase-orders`;
const datafluidesFormRequestUrl = `${DATAFLUIDES_API_URL}/${setCurrentVersion()}/requests`;
const datafluidesEnumUrl = `${DATAFLUIDES_API_URL}/${setCurrentVersion()}/enum-options`;

const filterPurchaseOrderList = (page = 0, filter = {}) => RequestHelper.POST(`${datafluidesPurchaseOrderUrl}/filter?page=${page}`, filter);

const getPurchaseOrderTemplate = (subgroup) => {
  RequestHelper.GET(`${datafluidesFormRequestUrl}/purchase-order-template/${subgroup}`)
      .then((response) => {
        DocumentHelper
            .downloadDocumentFromBase64(
                DocumentHelper.getPDFWithBase64(response.document.content),
                response.document.name
            );
      });
};

const getDatabilanPurchaseOrderTemplate = (type) => {
  RequestHelper.GET(`${datacommonsDocumentsUrl}/databilan-template/${type}`)
    .then((response) => {
      DocumentHelper
        .downloadDocumentFromBase64(
          DocumentHelper.getPDFWithBase64(response.file),
          response.name
        );
    });
};

const getDocumentValidationStatuses = () => RequestHelper.GET(
  `${datafluidesEnumUrl}/VALIDATION_STATUS`
);

const getPurchaseOrderDetail = requestId => RequestHelper.GET(
  `${datafluidesFormRequestUrl}/${requestId}/purchase-order-details`
);

const changePurchaseOrder = (requestId, newDoc) => RequestHelper.PUT(`${datacommonsUserSubscriptionsUrl}/${requestId}/purchase-order`, newDoc);

const changeAdditionalFiles = (requestId, newDocList) => RequestHelper.PUT(`${datacommonsUserSubscriptionsUrl}/${requestId}/additional-files`, newDocList);

const validatePurchaseOrder = requestId => RequestHelper.POST(`${datafluidesPurchaseOrderUrl}/validate?requestId=${requestId}`, {});

const rejectPurchaseOrder = (requestId, body) => RequestHelper.POST(`${datafluidesPurchaseOrderUrl}/reject?requestId=${requestId}`, body);

export const PurchaseOrderService = {
  changeAdditionalFiles,
  changePurchaseOrder,
  filterPurchaseOrderList,
  getPurchaseOrderTemplate,
  getDatabilanPurchaseOrderTemplate,
  getPurchaseOrderDetail,
  getDocumentValidationStatuses,
  validatePurchaseOrder,
  rejectPurchaseOrder
};
