import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';
import { CloseButton } from './_CloseButton';

export const HeaderModal = ({
  onClose, children, className
}) => (
  <DialogTitle className={className} style={{ paddingRight: '4rem' }}>
    {children}
    {onClose && <CloseButton onClose={onClose} />}
  </DialogTitle>
);

HeaderModal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  className: PropTypes.string
};

HeaderModal.defaultProps = {
  className: null,
  onClose: () => { }
};
