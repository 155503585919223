import { Button, Grid } from '@material-ui/core';
import { Quiz, Text } from 'components';
import FormHeader from 'components/forms/FormHeader/FormHeader';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ExaminationService } from 'services';

import {
  faRedo, faSpinner, faStepBackward, faStepForward
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { translate } from 'utils';

const QuizEditionView = observer(({ quizDisplay }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { editionStore, examinationStore, quizStore } = useStores();

  const { quiz, isValid } = quizStore;
  const { editionMode } = editionStore;
  const {
    examination, examinationState, currentPage, isExaminationLoading
  } = examinationStore;

  const { isQuizStarted, isQuizEnded } = examinationState;

  const [isInitBtnDisable, setIsInitBtnDisabled] = useState(true);
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);

  const toggleNavigationBtnStatuses = useCallback(() => {
    // Quiz not started
    if (!isQuizStarted && !isQuizEnded) {
      setIsInitBtnDisabled(true);
    } else {
      setIsInitBtnDisabled(false);
      setIsPrevBtnDisabled(false);
      setIsNextBtnDisabled(false);
    }

    // Quiz ended
    if (!isQuizStarted && isQuizEnded) {
      setIsInitBtnDisabled(false);
      setIsPrevBtnDisabled(true);
      setIsNextBtnDisabled(true);
    }

    const emptyExamination = examination?.quiz?.pages?.length === 0;
    const onePageInExamination = examination?.quiz?.pages?.length === 1;

    if (emptyExamination || onePageInExamination) {
      setIsPrevBtnDisabled(true);
      setIsNextBtnDisabled(true);
    } else {
      if (!isQuizStarted) {
        setIsPrevBtnDisabled(true);
        setIsNextBtnDisabled(true);
      }

      const firstPage = currentPage?.index === 0;

      if (firstPage) {
        setIsPrevBtnDisabled(true);
      }

      const lastPage = currentPage?.index === Number(examination?.quiz?.pages?.length) - 1;

      if (lastPage) {
        setIsNextBtnDisabled(true);
      }
    }
  }, [examination, isQuizStarted, isQuizEnded, currentPage]);

  useEffect(() => {
    toggleNavigationBtnStatuses();
  }, [toggleNavigationBtnStatuses]);

  const getExamination = useCallback(() => {
    examinationStore.toggleLoadingExamination(true);
    ExaminationService.getExaminationTest(quiz)
      .then(response => {
        examinationStore.setExamStatus({
          isStarted: false,
          isEnded: false,
          examinationId: response.id,
          quizId: response.quiz.id
        });
        examinationStore.setExamination(response);
      })
      .catch(error => enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => examinationStore.toggleLoadingExamination(false));
  }, [examinationStore, quiz, enqueueSnackbar]);

  const handleRefreshExam = useCallback(() => {
    if (isValid.status && Object.keys(quiz).length > 0) {
      getExamination();
    }
  }, [getExamination, quiz, isValid]);

  useEffect(() => {
    if (quiz.pages && quiz.pages.length > 0) {
      getExamination();
    }
  }, [editionMode, isValid, getExamination, quiz]);

  const handlePreviousPage = useCallback(() => {
    examinationStore.setPreviousPage();
  }, [examinationStore]);

  const handleNexPage = useCallback(() => {
    examinationStore.setNextPage();
  }, [examinationStore]);

  const renderPreviewNavigationBar = () => (
    <Grid container direction="row" justifyContent="flex-start" style={{ margin: 35 }}>
      <Grid item xs={12}>
        <FormHeader
          subSubTitle="quiz.viewQuizInfosNote"
          subtitle="quiz.viewQuizInfo"
          title="quiz.viewQuizTitle"
        />
      </Grid>
      <Grid alignItems="center" container direction="row" justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            disabled={isPrevBtnDisabled || isExaminationLoading}
            startIcon={<FontAwesomeIcon icon={faStepBackward} />}
            style={{ width: '19rem' }}
            variant="contained"
            onClick={handlePreviousPage}
          >
            {translate('quiz.prevPage')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={isInitBtnDisable || isExaminationLoading}
            startIcon={<FontAwesomeIcon icon={isExaminationLoading ? faSpinner : faRedo} spin={isExaminationLoading} />}
            style={{ width: '19rem' }}
            variant="contained"
            onClick={handleRefreshExam}
          >
            {translate('button.reset')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={isNextBtnDisabled || isExaminationLoading}
            endIcon={<FontAwesomeIcon icon={faStepForward} />}
            style={{ width: '19rem' }}
            variant="contained"
            onClick={handleNexPage}
          >
            {translate('quiz.nextPage')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {editionMode && renderPreviewNavigationBar()}
      <Grid container justifyContent="center" style={{ marginTop: 20 }}>
        {quiz && quiz.pages && quiz.pages.length > 0 ? (
          <Quiz element={quizDisplay} />
        ) : (
          <Text>{translate('quiz.noExamination')}</Text>
        )}
      </Grid>
    </Grid>
  );
});

export default QuizEditionView;

QuizEditionView.propTypes = {
  quizDisplay: PropTypes.shape({})
};

QuizEditionView.defaultProps = {
  quizDisplay: {}
};
