import { TextField } from '@material-ui/core';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormHelper, translate } from 'utils';

import { buildDefaultAndTraineeAnswers, QUESTION_TYPES_FROM_API } from '../../QuizHelper';

const EmailQuestion = observer(({ currentQuestionData, isReadOnlyQuestion }) => {
  const { emailInput } = QUESTION_TYPES_FROM_API;

  const { examinationStore } = useStores();

  const { currentPage } = examinationStore;

  const [currentValue, setCurrentValue] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [error, setError] = useState('');

  const buildAnswers = useCallback(() => {
    setCurrentValue(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleChangeAnswer = useCallback(event => {
    const { value } = event.target;

    const errorResult = FormHelper.validateEmail(value);
    setError(errorResult);

    setCurrentValue(value);

    if (errorResult && errorResult.length > 0) {
      setIsValidEmail(false);
      examinationStore.toggleQuestionValidity({ questionType: emailInput, status: false });
    } else {
      setIsValidEmail(true);
      examinationStore.toggleQuestionValidity({ questionType: emailInput, status: true });
    }

    examinationStore.setTraineeAnswers(currentPage, currentQuestionData, value);
  }, [currentPage, currentQuestionData, examinationStore, emailInput]);

  return (
    <>
      <TextField
        autoComplete="off"
        disabled={isReadOnlyQuestion}
        error={!isValidEmail && error.length > 0}
        fullWidth
        helperText={error}
        name="textAnswer"
        placeholder={translate('common.writeEmail')}
        required
        style={{ margin: '10px 0px' }}
        value={currentValue}
        variant="outlined"
        onChange={handleChangeAnswer}
      />
    </>
  );
});

export default EmailQuestion;

EmailQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

EmailQuestion.defaultProps = {
  currentQuestionData: {}
};
