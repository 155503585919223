import { Chip, TableCell } from '@material-ui/core';
import { GenericTable } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import { formatDate, translate } from 'utils';

const listHeaders = () => [
  {
    name: 'application',
    label: translate('common.application'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.application.label || '-'}
      </TableCell>
    )
  }, {
    name: 'date',
    label: translate('common.creationDate'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {(row.date && formatDate(row.date)) || '-'}
      </TableCell>
    )
  }, {
    name: 'wantedRoles',
    label: translate('pageUserDetail.wantedRoles'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Chip key={shortid.generate()} label={row.wantedRole.label} />
      </TableCell>
    )
  }, {
    name: 'accountsNeeded',
    label: translate('common.accountsNeeded'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.accountsNeeded || ''}
      </TableCell>
    )
  }
];

const listHeadersAdmin = () => [
  ...listHeaders(),
  {
    name: 'userAccount',
    label: translate('common.userName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.userName ?? '-'}
      </TableCell>
    )
  },
  {
    name: 'institutions',
    label: translate('common.institution'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.institutionName ?? ''}
      </TableCell>
    )
  },
  {
    name: 'companyName',
    label: translate('common.company'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.companyName ?? ''}
      </TableCell>
    )
  }
  // {
  //   name: 'delete',
  //   label: translate('button.delete'),
  //   isVisible: false,
  //   template: row => (
  //     <TableCell key={shortid.generate()}>
  //       <Fab size="small" onClick={() => handleSubscriptionDelete(row)}>
  //         <FontAwesomeIcon icon={faTrashAlt} />
  //       </Fab>
  //     </TableCell>
  //   )
  // }
];

const SubscriptionTable = ({
  subscriptions, dataCy, hasMore, loadMore, onRowClick, isFromAdmin, total, displayTotal, isLoading
}) => (
  <GenericTable
    dataCy={dataCy}
    displayTotal={displayTotal}
    hasMore={hasMore}
    headers={isFromAdmin ? listHeadersAdmin() : listHeaders()}
    isLoading={isLoading}
    loadMore={loadMore}
    rows={subscriptions}
    total={total}
    onRowClick={onRowClick}
  />
);

SubscriptionTable.propTypes = {
  dataCy: PropTypes.string,
  displayTotal: PropTypes.bool,
  handleSubscriptionDelete: PropTypes.func,
  hasMore: PropTypes.bool,
  isFromAdmin: PropTypes.bool,
  loadMore: PropTypes.func,
  onRowClick: PropTypes.func,
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number
};

SubscriptionTable.defaultProps = {
  dataCy: '',
  displayTotal: true,
  handleSubscriptionDelete: () => {},
  hasMore: false,
  isFromAdmin: false,
  loadMore: null,
  onRowClick: null,
  total: 0
};

export default SubscriptionTable;
