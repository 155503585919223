import React from 'react';
import { useParams } from 'react-router-dom';
import { QuizResultsDetail } from 'components/QuizResultsDetail';

const PageQuizResults = () => {
  const { examId } = useParams();

  return <QuizResultsDetail examinationId={examId} />;
};

export default PageQuizResults;
