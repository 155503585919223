import {
  Grid, Paper, Typography
} from '@material-ui/core';
import { Button, CustomTooltip } from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentHelper, translate } from 'utils';

import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormHeader from '../FormHeader/FormHeader';
import { GenericSwitch } from '../_commons';
import { asForm } from '../hoc';
import { InputField, InputFile } from '../inputs';

const QuizEditionForm = observer(({ formData, updateProperty }) => {
  const { quizStore } = useStores();
  const {
    totalPages, totalQuestions, totalCategories, isQuizSubmitted
  } = quizStore;

  const {
    isCertifying, withIdentification, isIndependent, isPublic, title, code, reference, description, endText,
    logo, allowRepeat, repeatLimit, showProgressBar, forceValidation, displayValidation, displayResults,
    displayRatingOnly, showTimerPanel, maxTimeToFinish
  } = formData;

  const [isProgressBarDisabled, setIsProgressBarDisabled] = useState(false);

  const getTotalPagesAndQuestions = useCallback(() => {
    quizStore.getTotalPagesAndQuestions();
  }, [quizStore]);

  const initQuizParameters = useCallback(() => {
    maxTimeToFinish && setIsProgressBarDisabled(true);
  }, [maxTimeToFinish]);

  useEffect(() => {
    formData && quizStore.isQuizFormValid(formData);
  }, [formData, quizStore]);

  useEffect(() => {
    initQuizParameters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTotalPagesAndQuestions();
  }, [quizStore, getTotalPagesAndQuestions]);

  useEffect(() => {
    if (Object.keys(quizStore.quiz).length === 0) {
      quizStore.newQuiz();
    }
  }, [quizStore]);

  const updatePropertyAndExamination = useCallback((key, value) => {
    let newValue = value;
    if (key === 'repeatLimit' && value < 2) {
      newValue = 2;
    }
    if (key === 'isPublic' && value === false) {
      updateProperty('withIdentification', false);
      quizStore.updateExamination('withIdentification');
    }
    updateProperty(key, newValue);
    quizStore.updateExamination(key);
  }, [updateProperty, quizStore]);

  const handleTimerPanel = useCallback((key, value) => {
    if (value) {
      updatePropertyAndExamination('maxTimeToFinish', 60);
      setIsProgressBarDisabled(true);
      updatePropertyAndExamination('showProgressBar', value);
    } else {
      updatePropertyAndExamination('maxTimeToFinish', 0);
      setIsProgressBarDisabled(false);
    }

    updatePropertyAndExamination(key, value);
  }, [updatePropertyAndExamination]);

  const handleImportFile = useCallback(value => {
    value && updatePropertyAndExamination('logo', DocumentHelper.getDocumentWithoutB64FromOnlyB64(value.base64Content));
  }, [updatePropertyAndExamination]);

  const handleDeleteLogo = useCallback(() => {
    updatePropertyAndExamination('logo', null);
  }, [updatePropertyAndExamination]);

  const handleAllowRepeat = useCallback((key, value) => {
    value ? updatePropertyAndExamination('repeatLimit', 2) : updatePropertyAndExamination('repeatLimit', null);

    updatePropertyAndExamination(key, value);
  }, [updatePropertyAndExamination]);

  const handleForceValidation = useCallback((key, value) => {
    !value && updatePropertyAndExamination('displayValidation', value);

    updatePropertyAndExamination(key, value);
  }, [updatePropertyAndExamination]);

  const handleIsCertifying = useCallback((key, value) => {
    if (value) {
      handleTimerPanel('showTimerPanel', value);
      handleForceValidation('forceValidation', value);
      handleAllowRepeat('allowRepeat', !value);

      updatePropertyAndExamination('displayValidation ', !value);
    }

    updatePropertyAndExamination(key, value);
  }, [updatePropertyAndExamination, handleTimerPanel, handleForceValidation, handleAllowRepeat]);

  return (
    <form autoComplete="off" id="quizEditionForm" name="quizEditionForm" style={{ padding: 10 }}>
      <FormHeader
        subtitle="quiz.editQuizInfos"
        title="quiz.quizSettings"
      />
      <Grid alignItems="center" container direction="row" justifyContent="center">

        <Paper style={{ padding: 10, marginBottom: 10, width: '100%' }} variant="outlined">

          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <GenericSwitch
                checked={Boolean(isCertifying)}
                leftSwitch={translate('common.no')}
                name="isCertifying"
                rightSwitch={translate('common.yes')}
                titleSwitch={translate('quiz.quizWithCertification')}
                toolTip={null}
                onChange={handleIsCertifying}
              />
            </Grid>
            <Grid item xs={2}>
              {isCertifying && (
                <Grid alignItems="center" container justifyContent="flex-end">
                  <FontAwesomeIcon color="var(--tecnea-blue)" icon={faBadgeCheck} size="2x" />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <Paper style={{ padding: 10, marginBottom: 10 }} variant="outlined">
            <InputField
              error={isQuizSubmitted && !title}
              inputProps={{ maxLength: 150 }}
              label={translate('common.title')}
              name="title"
              placeholder={translate('quiz.addShortName')}
              required
              value={title}
              onChange={updatePropertyAndExamination}
            />
            <InputField
              error={isQuizSubmitted && !code}
              inputProps={{ maxLength: 20 }}
              label={translate('common.code')}
              name="code"
              placeholder={translate('quiz.addCode')}
              required
              value={code}
              onChange={updatePropertyAndExamination}
            />
            <InputField
              inputProps={{ maxLength: 20 }}
              label={translate('common.reference')}
              name="reference"
              placeholder={translate('quiz.addReference')}
              value={reference}
              onChange={updateProperty}
            />
            <InputField
              inputProps={{ maxLength: 1000 }}
              label={translate('common.description')}
              minRows={8}
              multiline
              name="description"
              placeholder={translate('quiz.addShortDescription')}
              value={description}
              onChange={updatePropertyAndExamination}
            />
            <InputField
              inputProps={{ maxLength: 1000 }}
              label={translate('common.endText')}
              minRows={8}
              multiline
              name="endText"
              placeholder={translate('quiz.addEndText')}
              value={endText}
              onChange={updateProperty}
            />
            <Paper elevation={0} style={{ padding: 10 }} variant="elevation">
              {logo ? (
                <Button
                  type="danger"
                  onClick={handleDeleteLogo}
                >
                  {translate('quiz.deleteLogo')}
                </Button>
              ) : (
                <InputFile
                  fileAccepted=".jpg, .jpeg, .png"
                  handleAddDocument={handleImportFile}
                  id="importQuizImage"
                  label="quiz.addLogo"
                  labelButton="quiz.importLogo"
                  name="logo"
                  value={logo}
                />
              )}
            </Paper>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 10, marginBottom: 10 }} variant="outlined">
            <GenericSwitch
              checked={Boolean(isIndependent)}
              leftSwitch={translate('common.no')}
              name="isIndependent"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.independentQuiz')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.quizIndependent')} />}
              onChange={updatePropertyAndExamination}
            />
            <GenericSwitch
              checked={Boolean(isPublic)}
              leftSwitch={translate('common.no')}
              name="isPublic"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.publicQuiz')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.quizPublic')} />}
              onChange={updatePropertyAndExamination}
            />
            {isPublic && (
              <GenericSwitch
                checked={Boolean(withIdentification)}
                leftSwitch={translate('common.no')}
                name="withIdentification"
                rightSwitch={translate('common.yes')}
                titleSwitch={translate('quiz.withIdentification')}
                toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.quizIdentification')} />}
                onChange={updatePropertyAndExamination}
              />
            )}
            <GenericSwitch
              checked={Boolean(allowRepeat)}
              leftSwitch={translate('common.no')}
              name="allowRepeat"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.quizRepeatable')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.quizRepeatable')} />}
              onChange={handleAllowRepeat}
            />
            {allowRepeat ? (
              <Grid alignItems="center" container justifyContent="space-between">
                <Grid item>
                  <Typography variant="subtitle2">
                    {translate('quiz.numberOfQuizRepetitions')}
                    {' '}
                    <CustomTooltip size="xs" text={translate('quiz.tooltip.repeatLimit')} />
                  </Typography>
                </Grid>
                <Grid item>
                  <InputField
                    error={repeatLimit < 2}
                    inputProps={{ min: 2 }}
                    name="repeatLimit"
                    style={{ width: 100, padding: '10px 8px' }}
                    type="number"
                    value={repeatLimit === 0 || repeatLimit === '' ? null : repeatLimit}
                    variant="outlined"
                    onChange={updatePropertyAndExamination}
                  />
                </Grid>
              </Grid>
            ) : null}
            <GenericSwitch
              checked={Boolean(showTimerPanel)}
              leftSwitch={translate('common.no')}
              name="showTimerPanel"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.showTimerPanel')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.showTimerPanel')} />}
              onChange={handleTimerPanel}
            />
            {showTimerPanel && (
              <Grid alignItems="center" container justifyContent="space-between">
                <Grid item>
                  <Typography variant="subtitle2">
                    {translate('quiz.quizDurationForm')}
                  </Typography>
                </Grid>
                <Grid item>
                  <InputField
                    inputProps={{ min: 0 }}
                    name="maxTimeToFinish"
                    style={{ width: 100, padding: '10px 8px' }}
                    type="number"
                    value={Number(maxTimeToFinish) || 0}
                    variant="outlined"
                    onChange={updatePropertyAndExamination}
                  />
                </Grid>
              </Grid>
            )}
            {isProgressBarDisabled ? (
              <GenericSwitch
                checked
                disabled
                leftSwitch={translate('common.no')}
                name="showProgressBar"
                rightSwitch={translate('common.yes')}
                titleSwitch={translate('quiz.showProgressBar')}
                toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.showProgressBar')} />}
                onChange={updatePropertyAndExamination}
              />
            ) : (
              <GenericSwitch
                checked={Boolean(showProgressBar)}
                leftSwitch={translate('common.no')}
                name="showProgressBar"
                rightSwitch={translate('common.yes')}
                titleSwitch={translate('quiz.showProgressBar')}
                toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.showProgressBar')} />}
                onChange={updatePropertyAndExamination}
              />
            )}
            <GenericSwitch
              checked={Boolean(forceValidation)}
              leftSwitch={translate('common.no')}
              name="forceValidation"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.forceValidation')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.forceValidation')} />}
              onChange={handleForceValidation}
            />
            {forceValidation && (
            <GenericSwitch
              checked={Boolean(displayValidation)}
              leftSwitch={translate('common.no')}
              name="displayValidation"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.displayValidation')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.displayValidation')} />}
              onChange={updatePropertyAndExamination}
            />
            )}
            <GenericSwitch
              checked={Boolean(displayResults)}
              leftSwitch={translate('common.no')}
              name="displayResults"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.displayResultsAtTheEnd')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.displayResults')} />}
              onChange={updatePropertyAndExamination}
            />
            <GenericSwitch
              checked={Boolean(displayRatingOnly)}
              leftSwitch={translate('common.no')}
              name="displayRatingOnly"
              rightSwitch={translate('common.yes')}
              titleSwitch={translate('quiz.displayRating')}
              toolTip={<CustomTooltip size="xs" text={translate('quiz.tooltip.displayRatingOnly')} />}
              onChange={updatePropertyAndExamination}
            />
          </Paper>
        </Grid>
        <Grid alignItems="flex-start" container>
          <Grid item xs={12}>
            <Paper style={{ padding: 10, backgroundColor: '#f2fafe' }} variant="outlined">
              <Typography>{`${translate('quiz.totalPages')} : ${totalPages}`}</Typography>
              <Typography>{`${translate('quiz.totalQuestions')} : ${totalQuestions}`}</Typography>
              <Typography>{`${translate('quiz.totalCategories')} : ${totalCategories}`}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});

export default asForm(QuizEditionForm);

QuizEditionForm.propTypes = {
  formData: PropTypes.shape({
    title: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    isCertifying: PropTypes.bool,
    withIdentification: PropTypes.bool,
    allowRepeat: PropTypes.bool,
    repeatLimit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    showProgressBar: PropTypes.bool,
    showTimerPanel: PropTypes.bool,
    maxTimeToFinish: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    forceValidation: PropTypes.bool,
    displayValidation: PropTypes.bool,
    displayResults: PropTypes.bool,
    displayRatingOnly: PropTypes.bool
  }),
  updateProperty: PropTypes.func
};

QuizEditionForm.defaultProps = {
  formData: {
    title: '',
    code: '',
    description: '',
    logo: '',
    isCertifying: false,
    withIdentification: false,
    allowRepeat: false,
    repeatLimit: 0 || '0',
    showProgressBar: true,
    showTimerPanel: false,
    maxTimeToFinish: 0 || '0',
    forceValidation: false,
    displayValidation: false,
    displayResults: true,
    displayRatingOnly: false
  },
  updateProperty: () => { }
};
