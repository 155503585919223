import { createTheme } from '@material-ui/core';

export const DATATECNEA_THEME = createTheme({
  palette: {
    primary: {
      main: '#017db8'
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#017db8',
      dark: '#dde8f6'
    },
    error: {
      main: '#e2001a'
    }
  },
  typography: {
    htmlFontSize: 10,
    h1: {
      fontSize: '3.6rem',
      margin: '3rem 0'
    },
    h2: {
      fontSize: '2.4rem',
      margin: '2rem 0',
      textTransform: 'uppercase'
    },
    h3: {
      fontSize: '2.2rem',
      margin: '1rem 0'
    },
    h4: {
      fontSize: '2rem'
    },
    h5: {
      fontSize: '1.8rem'
    },
    h6: {
      fontSize: '1.6rem'
    }
  },
  overrides: {
    MuiFab: {
      root: {
        fontSize: '2rem'
      }
    }
  }
});
