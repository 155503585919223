import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStores } from 'hooks';
import {
  Grid, Typography
} from '@material-ui/core';
import shortid from 'shortid';
import { SelectField } from 'components/_commons/Form';
import { translate } from 'utils';
import { CheckMark } from '../_commons';
import { buildDefaultAndTraineeAnswers } from '../../QuizHelper';

const DropdownQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { isCorrect, isAnswered } = currentQuestionData;

  const { examinationStore } = useStores();

  const { currentPage } = examinationStore;

  const [defaultAnswers, setDefaultAnswers] = useState([]);
  const [currentSelectValue, setCurrentSelectValue] = useState({});

  const buildAnswers = useCallback(() => {
    setDefaultAnswers(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const getCurrentSelectValue = useCallback(() => {
    const currentValue = defaultAnswers.find(answer => answer.isItemAnswered === true);
    currentValue && setCurrentSelectValue({ label: currentValue.answerText, value: currentValue.value });
  }, [defaultAnswers]);

  useEffect(() => {
    getCurrentSelectValue();
  }, [getCurrentSelectValue]);

  const handleSelect = useCallback(option => {
    setCurrentSelectValue(option);

    examinationStore.setTraineeAnswers(currentPage, currentQuestionData, option.label);
  }, [currentPage, currentQuestionData, examinationStore]);

  return (
    <>
      {defaultAnswers && (
        <SelectField
          isClearable={false}
          isDisabled={isReadOnlyQuestion}
          isMulti={false}
          label=""
          name={`dropdown-answer-${currentQuestionData.id}`}
          options={defaultAnswers}
          value={Object.keys(currentSelectValue).length !== 0 ? currentSelectValue : { label: translate('question.selectAnswer'), value: 0 }}
          onChange={handleSelect}
        />
      )}
      {defaultAnswers.map(defaultAnswer => (
        <Grid container key={shortid.generate()}>
          {withCorrection && isAnswered && !isCorrect && (
            <Grid className="pdf-export__content__question--print-inline" container direction="row" spacing={2}>
              <Grid item>
                <CheckMark
                  isDefaultAnswerCorrect={defaultAnswer.isDefaultAnswerCorrect}
                  isQuestionAnswered={isAnswered}
                />
              </Grid>
              <Grid item>
                <Typography style={{ color: 'var( --disabled-color-dark)' }}>{defaultAnswer.answerText}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
});

export default DropdownQuestion;

DropdownQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

DropdownQuestion.defaultProps = {
  currentQuestionData: {}
};
