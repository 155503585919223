import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import shortid from 'shortid';

export const SkeletonTable = ({ columns = 4 }) => (
  <tr>
    {[...Array(columns).keys()].map(() => (
      <td key={shortid.generate()}><Skeleton style={{ height: '30px', width: '80%', margin: '6px' }} variant="rect" width="75%" /></td>
      ))}
  </tr>
);
