import {
  faChevronSquareLeft, faChevronSquareRight, faListAlt, faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Divider, Grid, Tooltip, Typography
} from '@material-ui/core';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils';

const QuizNavigationBar = observer(() => {
  const { enqueueSnackbar } = useSnackbar();

  const { quizStore, examinationStore } = useStores();

  const { examination, isPageValidated, currentPage } = examinationStore;

  const { quiz, quiz: { id, pages, forceValidation } } = examination;

  const [hidePrevBtn, setHidePrevBtn] = useState(false);
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isInvalidatedQuestionExists, setIsInvalidatedQuestionExists] = useState(false);

  useEffect(() => {
    examinationStore.getTotalPagesAndQuestions();
  }, [quizStore, currentPage, examinationStore]);

  useEffect(() => {
    setIsQuizFinished(currentPage?.index === Number(pages?.length) - 1);

    if (pages?.length === 1 || currentPage?.index === 0) {
      setHidePrevBtn(true);
    }
  }, [currentPage, pages, quizStore, setHidePrevBtn, examination]);

  // Avoid sending a request if the question is already validated
  const canValidatePage = useCallback(() => {
    setIsInvalidatedQuestionExists(currentPage?.questions?.some(question => question.isAnswered === false));
  }, [currentPage]);

  useEffect(() => {
    examinationStore.setPageWithTraineeAnswers(currentPage);
    canValidatePage();
  }, [examinationStore, currentPage, canValidatePage]);

  const scrollToQuizTop = useCallback(() => {
    const currentQuiz = document.getElementById(id);
    const currentQuizPosition = currentQuiz && currentQuiz.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: currentQuizPosition,
      behavior: 'smooth'
    });
  }, [id]);

  const handlePreviousPage = useCallback(() => {
    const pageIndex = currentPage.index;

    setIsQuizFinished(false);
    setIsPreviousLoading(true);

    examinationStore.updatePageService(pages[pageIndex].index)
      .then(() => {
        examinationStore.setExamStatus({
          isStarted: true, isEnded: false, examinationId: examination.id, quizId: quiz.id
        });
        examinationStore.setPreviousPage();
        examinationStore.getQuestionNumbering();
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => {
        setIsPreviousLoading(false);
        scrollToQuizTop();
      });

    scrollToQuizTop();
  }, [currentPage, pages, examinationStore, examination, quiz, scrollToQuizTop, enqueueSnackbar]);

  const handleNextPage = useCallback(() => {
    const pageIndex = currentPage.index;

    setIsNextLoading(true);
    examinationStore.updatePageService(pages[pageIndex].index)
      .then(() => {
        if (pageIndex < pages.length - 1) {
          setHidePrevBtn(false);
        }

        runInAction(() => {
          examinationStore.canGoNextPage();
          examinationStore.setNextPage();
          examinationStore.getQuestionNumbering();
        });
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => {
        setIsNextLoading(false);
        scrollToQuizTop();
      });
  }, [currentPage, pages, examinationStore, scrollToQuizTop, enqueueSnackbar]);

  const handleFinishQuiz = useCallback(() => {
    if (isInvalidatedQuestionExists) return;

    setIsFinishLoading(true);

    runInAction(() => {
      examinationStore.updatePageService(currentPage.index)
        .then(() => {
          examinationStore.finishQuizService()
            .then(() => {
              runInAction(() => {
                examinationStore.setExamStatus({
                  isStarted: false,
                  isEnded: true,
                  examinationId: examination.id,
                  quizId: quiz.id
                });
              });
            })
            .finally(() => {
              setIsFinishLoading(false);
              scrollToQuizTop();
            });
        });
    });
  }, [examinationStore, examination, isInvalidatedQuestionExists, currentPage, quiz, scrollToQuizTop]);

  return (
    <>
      <Divider style={{ marginBottom: 10 }} />

      {!isPageValidated && (
        <Typography align="right" style={{ color: 'var(--warning-color)' }}>
          {forceValidation ? translate('errors.validateAllQuestions') : translate('errors.answerToRequiredQuestions')}
        </Typography>
      )}

      <Grid container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item>
          {!hidePrevBtn && (
            <Button
              color="primary"
              disabled={isPreviousLoading}
              id="quiz-prev-btn"
              startIcon={<FontAwesomeIcon icon={isPreviousLoading ? faSpinner : faChevronSquareLeft} spin={isPreviousLoading} />}
              variant="contained"
              onClick={handlePreviousPage}
            >
              {translate('button.previous')}
            </Button>
          )}
        </Grid>
        {isQuizFinished ? (
          <Grid item>
            {isPageValidated ? (
              <Button
                color="primary"
                disabled={isFinishLoading}
                id="quiz-finish-btn"
                startIcon={<FontAwesomeIcon icon={isFinishLoading ? faSpinner : faListAlt} spin={isFinishLoading} />}
                variant="contained"
                onClick={handleFinishQuiz}
              >
                {translate('button.finish')}
              </Button>
            )
              : (
                <Tooltip
                  title={forceValidation
                    ? translate('errors.validateAllQuestions')
                    : translate('errors.answerToRequiredQuestions')}
                >
                  <span>
                    <Button
                      color="primary"
                      disabled
                      id="quiz-finish-btn"
                      startIcon={<FontAwesomeIcon icon={isFinishLoading ? faSpinner : faListAlt} spin={isFinishLoading} />}
                      variant="contained"
                    >
                      {translate('button.finish')}
                    </Button>
                  </span>
                </Tooltip>
              )}
          </Grid>
        )
          : (
            <Grid item>
              {isPageValidated ? (
                <Button
                  color="primary"
                  disabled={isNextLoading}
                  endIcon={<FontAwesomeIcon icon={isNextLoading ? faSpinner : faChevronSquareRight} spin={isNextLoading} />}
                  id="quiz-next-btn"
                  variant="contained"
                  onClick={handleNextPage}
                >
                  {translate('button.next')}
                </Button>
              )
                : (
                  <Tooltip
                    title={forceValidation
                      ? translate('errors.validateAllQuestions')
                      : translate('errors.answerToRequiredQuestions')}
                  >
                    <span>
                      <Button
                        color="primary"
                        disabled
                        endIcon={<FontAwesomeIcon icon={faChevronSquareRight} />}
                        id="quiz-next-btn"
                        variant="contained"
                      >
                        {translate('button.next')}
                      </Button>
                    </span>
                  </Tooltip>
                )}
            </Grid>
          )}
      </Grid>
    </>
  );
});

export default QuizNavigationBar;
