import i18n from 'i18next';
import { userStore } from 'stores';

import { translate } from 'utils/index';
import { httpSettings } from './request';

const hasExistingTranslation = message => {
  if (i18n.exists(`errors.${message}`)) {
    return translate(`errors.${message}`);
  }

  return message;
};

const processErrorList = errorList => {
  let processedMessage = '';
  errorList && errorList.map(errorItem => {
    processedMessage += `- ${errorItem} <br />`;
    return processedMessage;
  });
  return processedMessage;
};

export const processError = error => {
  if (error && error.message) {
    return hasExistingTranslation(error.message);
  }
  if (error && !Array.isArray(error) && typeof error === 'object') {
    let processedMessage = '';
    Object.keys(error).forEach(key => {
      if (Array.isArray(error[key])) {
        processedMessage += `<b>${key}:</b><br /> ${processErrorList(error[key])} <br />`;
        return processedMessage;
      }

      processedMessage = error[key].message;
      return processedMessage;
    });

    if (processedMessage) {
      return processedMessage;
    }
    return hasExistingTranslation('UNCATCHED_ERROR');
  }
  if (error && Array.isArray(error)) {
    return processErrorList(error);
  }
  if (error) {
    return hasExistingTranslation(error);
  }
  return hasExistingTranslation('UNCATCHED_ERROR');
};

const defaultResponseHandler = response => {
  const { status, headers } = response;

  switch (status) {
    case 200:
      return response.json();
    case 201: {
      if (headers.get('location')) {
        const location = headers.get('location').split('/');
        return location[location.length - 1];
      }
      return true;
    }
    case 202:
    case 204: return true;
    case 401:
      if (userStore.isTokenExpired()) {
        userStore.keycloakLogin();
        throw new Error(translate('errors.sessionExpired'));
      }

      throw new Error(translate('errors.noRights'));
    case 403:
      throw new Error(translate('errors.noRights'));
    case 404:
      throw new Error(translate('errors.NOT_FOUND_ERROR'));
    default:
      return response.json()
        .then(error => { throw processError(error); })
        .catch(error => { throw processError(error); });
  }
};

const GET = (url, headers) => fetch(url, httpSettings({
  headers,
  method: 'GET'
})).then(defaultResponseHandler);

const POST = (url, body, headers) => fetch(url, httpSettings({
  method: 'POST',
  headers,
  body
})).then(defaultResponseHandler);

const PUT = (url, body, headers) => fetch(url, httpSettings({
  method: 'PUT',
  headers,
  body
})).then(defaultResponseHandler);

const PATCH = (url, body, headers) => fetch(url, httpSettings({
  method: 'PATCH',
  headers,
  body
})).then(defaultResponseHandler);

const DELETE = (url, body, headers) => fetch(url, httpSettings({
  method: 'DELETE',
  headers,
  body
})).then(defaultResponseHandler);

export const RequestHelper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
};
