import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faRandom } from '@fortawesome/pro-regular-svg-icons';

const SwitchOrder = observer(({
  name, value, onChange
}) => {
  const handleChange = useCallback(event => {
    onChange(event.target.name, event.target.checked);
  }, [onChange]);

  return (
    <>
      <FormControlLabel
        control={(
          <Switch
            checked={value}
            color="primary"
            name={name}
            onChange={handleChange}
          />
        )}
        label={(
          <>
            <FontAwesomeIcon
              color={value ? 'var(--success-color)' : 'var(--warning-color)'}
              icon={value ? faRandom : faListOl}
              style={{ minWidth: 25 }}
            />
            {translate(value ? 'quiz.questionsRandomOrder' : 'quiz.questionsInitialOrder')}
          </>
        )}
      />
    </>
  );
});

SwitchOrder.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

export default SwitchOrder;
