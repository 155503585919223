import React from 'react';
import { QuizDetail } from 'components';
import { useParams } from 'react-router-dom';
import { useStores } from 'hooks';

const PageQuiz = () => {
  const { quizId } = useParams();
  const { editionStore } = useStores();
  const { quizView, editionMode } = editionStore;

  const editionView = quizView === true ? 'quiz' : 'quizPages';

  return (
    <QuizDetail
      defaultViewMode={editionView}
      isEditionMode={editionMode}
      quizId={quizId}
    />
  );
};
export default PageQuiz;
