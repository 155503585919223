import { CountryView } from 'models/CountryView';
import { IdentifierView } from 'models/IdentifierView';
import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const countriesUrl = `${API_V2_URL}/countries`;

const getCountryList = (limited = true) => RequestHelper.GET(`${countriesUrl}?limited=${limited}`);

const getCountryConfig = country => RequestHelper.GET(`${countriesUrl}/${country}`);

const getCountrySelectItems = () => RequestHelper.GET(`${countriesUrl}/select-items`);

const getLanguageList = () => RequestHelper.GET(`${countriesUrl}/languages`);

const getCountryById: (
  id: string
) => Promise<CountryView> = id => RequestHelper.GET(`${countriesUrl}/${id}`);

const save = countryView => RequestHelper.POST(`${countriesUrl}`, countryView);

const getIdentifiersByCountryId: (
  countryId: string
) => Promise<IdentifierView[]> = id => RequestHelper.GET(`${countriesUrl}/${id}/identifiers`);

const saveIdentifiers = identifierViews => RequestHelper.PUT(`${countriesUrl}/identifiers`, identifierViews);

const deleteIdentifier = id => RequestHelper.DELETE(`${countriesUrl}/identifiers/${id}`);

export const CountryService = {
  getCountryList,
  getCountryConfig,
  getLanguageList,
  getCountryById,
  getCountrySelectItems,
  getIdentifiersByCountryId,
  save,
  saveIdentifiers,
  deleteIdentifier
};
