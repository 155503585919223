import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { IconButton } from 'components/_commons/Button';
import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { DocumentHelper, translate } from 'utils';

const TextButton = ({
 label, tooltip, onClick, styles, ...props
}) => (
  <button
      style={{
        backgroundColor: 'transparent',
        color: 'var(--tecnea-blue-light)',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.6rem',
        textDecoration: 'underline',
        display: 'inline',
        margin: 0,
        padding: 0,
        width: '100%',
        textAlign: 'left',
        ...styles
      }}
      title={tooltip}
      type="button"
      onClick={onClick}
      {...props}
    >
    <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block'
        }}
      >
      {label}
    </span>
  </button>
  );

export const Document = (({
 name, url, deletable, handleDelete, tooltip, modalFullScreen = true
}) => {
  const showModal = useModal();

  const handleOpenPDFModal = useCallback(() => (
    showModal({
      type: 'DISPLAY_DOCUMENT',
      backdropClick: true,
      fullscreen: modalFullScreen,
      document: {
        name,
        base64Content: DocumentHelper.getDocumentWithBase64({ base64Content: url, name })
      }
    })), [modalFullScreen, name, showModal, url]);

  return (
    <Grid alignItems="center" container direction="row">
      <Grid item width="100%" xs={deletable ? 10 : 12}>
        <Grid alignItems="center" container direction="row" spacing={1}>
          <Grid item xs={12}>
            <TextButton
              label={name}
              tooltip={tooltip ? translate(tooltip) : name}
              onClick={handleOpenPDFModal}
            />
          </Grid>
        </Grid>

      </Grid>
      {deletable
        && (
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{ padding: 2 }}
              title={translate('button.delete')}
              onClick={handleDelete}
            >
              <FontAwesomeIcon
                color="var(--error-color)"
                icon={faTrashAlt}
                size="sm"
              />
            </IconButton>
          </Grid>
        )}

    </Grid>
  );
});
