import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { CountryService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { ItemOption, SelectField } from '..';

const CustomOption = ({ data, innerProps }) => (
  <ItemOption {...innerProps} isInsideSelect noPadding>
    <img
      alt={data.label}
      height="25"
      src={`${process.env.PUBLIC_URL}/assets/images/flags/${data.value}.svg`}
    />
    <Typography>
      {data.label}
    </Typography>
  </ItemOption>
);

const CustomValue = ({ data, innerProps }) => (
  <ItemOption {...innerProps} style={{ gridArea: ' 1/1/2/3' }}>
    <img
      alt={data.label}
      height="25"
      src={`${process.env.PUBLIC_URL}/assets/images/flags/${data.key}.svg`}
    />
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.label}
    </Typography>
  </ItemOption>
);

const SelectCountry = ({
  value, onChange, required
}) => {
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setIsFetchingCountries(true);
    CountryService.getCountrySelectItems().then(resp => {
      setCountries(resp);
    }).finally(() => setIsFetchingCountries(false));
  // eslint-disable-next-line
  }, []);

  const handleChange = useCallback(country => onChange(country), [onChange]);

  return (
    <>
      {isFetchingCountries ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <SelectField
          components={{ Option: CustomOption, SingleValue: CustomValue }}
          id="country"
          isClearable={false}
          label="common.selectCountry"
          name="country"
          options={countries}
          required={required}
          value={value}
          onChange={handleChange}
        />
      )}
    </>
  );
};

SelectCountry.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool
};

SelectCountry.defaultProps = {
  required: false,
  value: ''
};

export default SelectCountry;
