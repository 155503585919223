import {
  faCopy,
  faFile,
  faListOl, faPollH, faRandom
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Divider, Grid, Typography
} from '@material-ui/core';
import { CustomTooltip } from 'components/_commons';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'utils';
import { HeaderModal } from '../_HeaderModal';
import { QuestionManualSelect } from './QuestionManualSelect';
import { QuestionRandomSelect } from './QuestionRandomSelect';

const StyledTab = styled.div`
display: flex;
height: 90px;
border-radius: 5px;
padding: 5px 30px;
cursor: pointer;
background-color: ${props => (props.isSelected === true ? 'var(--tecnea-blue)' : 'var(--white)')};
color: ${props => (props.isSelected === true ? 'var(--white)' : 'var(--black)')};

&:hover {
  background-color: ${props => (props.isSelected === true ? 'var(--tecnea-blue-light)' : 'var(--primary-color-light)')};
  color: ${props => (props.isSelected === true ? 'var(--white)' : 'var(--black)')}; 
}
`;

export const QuestionBlockModal = observer(({ onClose, onConfirm, defaultValues }) => {
  const { quizStore } = useStores();
  const { tabs, isFormPageValid } = quizStore;

  const [tabNumber, setTabNumber] = useState(0);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) quizStore.toggleSelectedTab(tabNumber);

    return () => {
      isMounted = false;
    };
  }, [tabNumber, quizStore]);

  const handleChangeTab = value => {
    setTabNumber(value);
    quizStore.toggleSelectedTab(value);
  };

  const handleSubmit = useCallback(() => {
    onConfirm();

    return onClose();
  }, [onConfirm, onClose]);

  const manualSelectIcon = () => (
    <span className="fa-layers">
      <FontAwesomeIcon icon={faFile} transform="grow-35" />
      <FontAwesomeIcon icon={faListOl} transform="grow-5 down-3" />
    </span>
  );

  const randomSelectIcon = () => (
    <span className="fa-layers">
      <FontAwesomeIcon icon={faCopy} transform="grow-35" />
      <FontAwesomeIcon icon={faRandom} transform="grow-2 right-7" />
    </span>
  );

  const renderSpecificTab = value => {
    switch (value) {
      case 0:
        return (<QuestionManualSelect componentLocation="modal" formData={defaultValues} />);
      case 1:
        return (<QuestionRandomSelect formData={defaultValues} />);
      default:
        return null;
    }
  };

  const TabButton = observer(({
    tabIndex, tabIcon, title, subtitle, tooltip, onHandleChangeTab
  }) => (
    <StyledTab id={`tab-${tabIndex}`} isSelected={tabs[tabIndex].isSelected} onClick={onHandleChangeTab}>
      <Grid alignItems="center" container direction="row" justifyContent="space-between" spacing={2} wrap="nowrap">
        <Grid item xs>
          {tabIcon}
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ textTransform: 'uppercase' }} variant="body2">{title}</Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </Grid>
        {tooltip && (
          <Grid item xs={2}>
            <CustomTooltip color="var(--tecnea-blue-dark)" size="sm" text={tooltip} />
          </Grid>
        )}
      </Grid>
    </StyledTab>
  ));

  TabButton.propTypes = {
    tabIndex: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    onHandleChangeTab: PropTypes.func.isRequired,
    tabIcon: PropTypes.node.isRequired
  };

  TabButton.defaultProps = {
    tooltip: ''
  };

  return (
    <form name="questionBlockForm">
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faPollH} />
        <Typography component="span">
          {translate('quiz.searchOrCreateQuestion')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        <Grid container direction="row" spacing={2} wrap="nowrap">
          <Grid item style={{ paddingRight: 20 }} xs={4}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TabButton
                  subtitle={translate('quiz.nQuestionPerPageFormat')}
                  tabIcon={manualSelectIcon()}
                  tabIndex={0}
                  title={translate('quiz.manualQuestionSelection')}
                  tooltip={translate('quiz.tooltip.manualQuestionSelection')}
                  onHandleChangeTab={() => handleChangeTab(0)}
                />
              </Grid>
              <Grid item>
                <TabButton
                  subtitle={translate('quiz.oneQuestionPerPageFormat')}
                  tabIcon={randomSelectIcon()}
                  tabIndex={1}
                  title={translate('quiz.randomCategoryQuestionSelection')}
                  tooltip={translate('quiz.tooltip.randomQuestionSelection')}
                  onHandleChangeTab={() => handleChangeTab(1)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider flexItem orientation="vertical" style={{ marginRight: 20 }} />

          <Grid item style={{ width: 650, height: 620 }} xs={8}>
            {renderSpecificTab(tabNumber)}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!isFormPageValid}
          onClick={handleSubmit}
        >
          {translate('button.save')}
        </Button>
      </DialogActions>
    </form>
  );
});

QuestionBlockModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({})
};

QuestionBlockModal.defaultProps = {
  defaultValues: {}
};
