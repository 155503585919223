import { translate } from 'utils';
import { QuizResultsDetail } from './QuizResultsDetail';

export const handleExportToPDF = quiz => {
  const element = document.getElementById('resultsContainer');
  const printWindow = window.open('', '', 'height=800,width=1200');
  printWindow.document.write(`<html><head><title>${translate('pageQuizResultsList.quizResultsDetails')} : ${quiz.title}</title>`);
  printWindow.document.write(`<style type="text/css">
    :root {
      --tecnea-blue: #017db8;
      --tecnea-blue-dark: #02587E;
      --tecnea-blue-light: #009AE7;
      --primary-color: #878787;
      --primary-color-light: #a1a1a1;
      --primary-color-dark: #575757;
      --error-color: #e2001a;
      --warning-color: #ff762e;
      --success-color: #0ea04b;
      --grey-darker: #404040;
      --grey-dark: #737373;
      --grey: #a6a6a6;
      --grey-light: #d9d9d9;
      --grey-lighter: #ececec;
    }
    html {
      font-size: 62.5%;
    }
    body {
      font-size: 1.6rem;
      font-family: 'Roboto', 'OpenSans', Arial, Helvetica, sans-serif;
    }
    .mr1 {
      margin-right: 8px;
    }
    p, h3 {
      margin: 0;
    }
    h5 {
      margin: 5px 0;
    }
    fieldset {
      padding: 0;
      border: none;
    } 
    .pdf-export__header {
      display: flex;
      direction: row;
    }
    .pdf-export__header > div {
      flex: 1;
      padding: 10px;
      border-left: 1px solid var(--grey-light);
    }
    .pdf-export__header > div:first-of-type {
      padding: 10px !important;
      border-left: none;
    }
    .pdf-export__header .MuiGrid-item:first-of-type p {
      font-weight: 600;
    }
    .pdf-export__header svg {
      width: 6px !important;
      vertical-align: text-bottom;
    }
    .pdf-export__content svg {
      width: 14px !important;
      vertical-align: text-bottom;
      margin: 0px 10px;
    }
    .pdf-export__content {
      border-top: 1px solid var(--grey-light);
    }
    .pdf-export__content img {
      margin-bottom: 10px;
    }
    .pdf-export__header hr,
    .pdf-export__content h3 {
      display: none;
    }
    .pdf-export__content .pdf-export__content__question {
      margin: 15px 0;
    }
    .pdf-export__content > .pdf-export__content__question{
      padding: 5px;
    }
    .pdf-export__content > .pdf-export__content__question:nth-child(2n) {
      margin-top: 20px;
      background-color: #f4f4f4;
    }
    .pdf-export__content__question__image-question {
      display: flex;
      justify-content: flex-start;
    }
    .pdf-export__content__question__chip {
      border-radius: 5px;
      padding: 5px;
      margin: 0px 5px;
    }
    .pdf-export__content__question--print-inline {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .pdf-export__content__question--inline-margin {
      margin: 0px 5px;
    }
    .PrivateRadioButtonIcon-root-10, .PrivateRadioButtonIcon-checked-12, .MuiSvgIcon-root {
      display: none;
    }
    .vertical-margin {
       margin: 10px 0px;
    }
    </style></head><body>`);

  printWindow.document.write(element.innerHTML);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
};

export {
  QuizResultsDetail
};
