import { ListItem } from '@material-ui/core';
import { FormElement } from 'components';
import React, { useCallback } from 'react';
import Select, { MenuPlacement } from 'react-select';
import styled, { css } from 'styled-components';
import { translate } from 'utils';

export const StyledListItem = styled(({ children, ...props }) => <ListItem {...props}>{children}</ListItem>)`
  cursor: pointer;

  &:hover {
    ${props => !props.disabled && css`
      background-color: #DEEBFF
    `}
  }
`;

const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

const SelectField = ({
  closeMenuOnSelect = true,
  components,
  filterOption,
  height,
  isClearable = true,
  isDisabled = false,
  label,
  menuPlacement = 'top',
  messageNoOptions = 'warnings.noOptionsAvailable',
  name,
  noMargin = false,
  onChange,
  options = [],
  placeholder = 'common.selectOption',
  required = false,
  value,
  width,
  legendClassName,
  ...props
}: {
  closeMenuOnSelect?: boolean,
  components?,
  filterOption?,
  height?: string,
  isClearable?: boolean,
  isDisabled?: boolean,
  label?: string,
  legendClassName?: string,
  menuPlacement?: MenuPlacement,
  messageNoOptions?: string,
  name: string,
  noMargin?: boolean,
  onChange: (value) => void,
  options,
  placeholder?: string,
  required?: boolean,
  value,
  width?: string
}) => {
  const displayNoOptions = useCallback(() => translate(messageNoOptions), [messageNoOptions]);

  const handleChange = useCallback(val => onChange(val), [onChange]);

  const customStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: 2000
    }),
    control: (provided) => ({
      ...provided,
      height,
      width
    })
  };

  return (
    <FormElement className={legendClassName} label={label ? translate(label) : label} noMargin={noMargin} required={required} shrinked>
      <Select
        closeMenuOnSelect={closeMenuOnSelect}
        components={components}
        filterOption={filterOption}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isOptionDisabled={option => option.isDisabled}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        menuShouldScrollIntoView={false}
        name={name}
        noOptionsMessage={displayNoOptions}
        options={options}
        placeholder={translate(placeholder)}
        styles={customStyles}
        value={value}
        onChange={handleChange}
        {...props}
      />
      {required && (
        <StyledRequiredSelectHack
          autoComplete="off"
          defaultValue={value || ''}
          required
          tabIndex={-1}
        />
      )}
    </FormElement>
  );
};

export default SelectField;
