import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, DialogActions, DialogContent, Typography, TextField, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils';
import { FormElement, RequiredSelectHack } from 'components';
import { useFetch } from 'hooks';
import { DataTecneaService } from 'services';
import { faDesktopAlt } from '@fortawesome/pro-regular-svg-icons';
import { ACCESS_APPS } from 'utils/constants';
import shortid from 'shortid';
import { useFormState } from 'react-use-form-state';
import { HeaderModal } from './_HeaderModal';

export const AddSubscriptionModal = ({
  onConfirm, onClose, currentSub
}) => {
  const [formState, { text, number, raw }] = useFormState({
    accountsNeeded: 1,
    appSelected: null
  });

  const { response: allApplications, isLoading } = useFetch(() => DataTecneaService.getActiveApplications(), []);
  const allApps = allApplications.map(app => app.name);
  const availableApps = allApps.filter(app => !currentSub.find(current => current.application === app));
  const appAlreadyAvailable = allApps.length === 0
  ? ACCESS_APPS
  : ACCESS_APPS.filter(access => allApps.some(app => app === access));

  const handleValidateModal = useCallback(e => {
    e.preventDefault();

    const { accountsNeeded, appSelected, attestationNumber } = formState.values;

    if (!formState.values.appSelected) return null;

    onConfirm({
      application: appSelected.value,
      accountsNeeded,
      attestationNumber: attestationNumber || null
    });
    return onClose();
  }, [formState, onClose, onConfirm]);

  const transformToSelectItem = useCallback(apps => apps.map(app => ({
    value: app,
    label: app
  })), []);

  return (
    <form onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faDesktopAlt} />
        <Typography component="span">
          {translate('modalAddSubscription.title')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        {availableApps.length > 0 ? (
          <>
            <FormElement>
              <Select
                isClearable
                isLoading={isLoading}
                isSearchable
                menuPortalTarget={document.body}
                options={transformToSelectItem(availableApps)}
                placeholder={translate('modalAddSubscription.selectApp')}
                required
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                {...raw('appSelected')}
              />
              <RequiredSelectHack value={formState.values.appSelected || ''} />
            </FormElement>
            <FormElement>
              <TextField
                label={translate('common.accountsNeeded')}
                type="number"
                {...number('accountsNeeded')}
              />
              {formState.values.appSelected && formState.values.appSelected.value === 'DATABILAN' && (
                <TextField
                  error={formState.errors.attestationNumber && formState.errors.attestationNumber !== null}
                  label={translate('common.attestationNumber')}
                  name="attestationNumber"
                  required
                  {...text('attestationNumber')}
                />
              )}
            </FormElement>
          </>
        ) : (
          <>
            <Typography component="h2">{translate('modalAddSubscription.noApp')}</Typography>
            {appAlreadyAvailable.length > 0 && (
              <>
                <br />
                <Typography component="h3">{translate('modalAddSubscription.alreadyAvailable')}</Typography>
                <List dense>
                  {appAlreadyAvailable.map(app => (
                    <ListItem dense key={shortid.generate()}>
                      <ListItemIcon style={{ minWidth: '30px' }}><FontAwesomeIcon icon={faDesktopAlt} /></ListItemIcon>
                      <ListItemText>{app}</ListItemText>
                    </ListItem>
                    ))}
                </List>
              </>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={availableApps.length === 0}
          type="submit"
        >
          {translate('button.validate')}
        </Button>
      </DialogActions>
    </form>
  );
};

AddSubscriptionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentSub: PropTypes.arrayOf(PropTypes.shape({}))
};

AddSubscriptionModal.defaultProps = {
  currentSub: []
};
