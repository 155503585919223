/* eslint-disable react/jsx-no-bind */
import {
  faBoxesAlt,
  faCheck,
  faClipboardList,
  faLayerGroup,
  faMinusSquare, faPen,
  faPlus,
  faPlusSquare,
  faQuestionSquare,
  faSave,
  faSnowBlowing,
  faSnowflake,
  faSync, faTachometerAlt,
  faTasks,
  faTint,
  faUser,
  faWaterLower,
  faWaterRise,
  faWrench
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCog, faExclamationTriangle,
  faEye,
  faEyeSlash,
  faPen as faPenSolid,
  faPlus as faPlusSolid,
  faSignOutAlt
} from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  Divider,
  Fab,
  Grid,
  IconButton, InputLabel,
  Tab,
  Tabs,
  Tooltip
} from '@material-ui/core';
import { CustomIcon, Wrapper } from 'components';
import React, { useState } from 'react';
import shortid from 'shortid';

const PageTestButtons = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTabSecond, setCurrentTabSecond] = useState(0);

return (
  <Wrapper style={{ padding: '1rem 0 4rem 0' }}>
    <Grid container spacing={6} style={{ margin: '2rem 0' }}>
      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>PRIMAIRE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faUser} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Créer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faUser} secondIcon={faPenSolid} />}
              variant="contained"
            >
              Éditer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faTasks} secondIcon={faCog} />}
              variant="contained"
            >
              Paramètres
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faTasks} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faQuestionSquare} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon iconClass="icon-container" secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Créer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faWrench} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Créer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon iconClass="icon-leak-detector" secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon iconClass="icon-hazard" secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon iconClass="icon-container-warning" secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faPen} secondIcon={faSignOutAlt} />}
              variant="contained"
            >
              Quitter l&apos;édition
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faSave} secondIcon={faEyeSlash} />}
              variant="contained"
            >
              Enregistrer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faSave} secondIcon={faEye} />}
              variant="contained"
            >
              Publier
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>SECONDAIRE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faUser} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Créer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faUser} secondIcon={faPenSolid} />}
              variant="contained"
            >
              Éditer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faTasks} secondIcon={faCog} />}
              variant="contained"
            >
              Paramètres
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faTasks} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faQuestionSquare} secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon iconClass="icon-container" secondIcon={faPlusSolid} />}
              variant="contained"
            >
              Créer
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>TERTIAIRE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faUser} secondIcon={faPlusSolid} />}
            >
              Créer
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faUser} secondIcon={faPenSolid} />}
            >
              Éditer
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faTasks} secondIcon={faCog} />}
            >
              Paramètres
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faTasks} secondIcon={faPlusSolid} />}
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faQuestionSquare} secondIcon={faPlusSolid} />}
            >
              Insérer
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CustomIcon iconClass="icon-container" secondIcon={faPlusSolid} />}
            >
              Créer
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>ICON SEUL</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faUser} secondIcon={faPlusSolid} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faUser} secondIcon={faPenSolid} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faTasks} secondIcon={faCog} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faTasks} secondIcon={faPlusSolid} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faQuestionSquare} secondIcon={faPlusSolid} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon iconClass="icon-container" secondIcon={faPlusSolid} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>BOUTON MOBILE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Fab color="primary">
              <CustomIcon icon={faUser} secondIcon={faPlusSolid} />
            </Fab>
          </Grid>
          <Grid item>
            <Fab color="primary">
              <CustomIcon icon={faWrench} secondIcon={faPlusSolid} />
            </Fab>
          </Grid>
          <Grid item>
            <Fab color="primary">
              <CustomIcon icon={faQuestionSquare} secondIcon={faPlusSolid} />
            </Fab>
          </Grid>
          <Grid item>
            <Fab color="primary">
              <CustomIcon iconClass="icon-leak-detector" secondIcon={faPlusSolid} />
            </Fab>
          </Grid>
          <Grid item>
            <Fab color="primary">
              <CustomIcon iconClass="icon-container" secondIcon={faPlusSolid} />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Divider />

    <Grid container spacing={6} style={{ margin: '2rem 0' }}>
      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>PRIMAIRE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faPlus} />}
              variant="contained"
            >
              Créer une séquence
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              startIcon={<CustomIcon icon={faPen} />}
              variant="contained"
            >
              Éditer une séquence
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>SECONDAIRE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faPlus} />}
              variant="contained"
            >
              Créer une séquence
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<CustomIcon icon={faPen} />}
              variant="contained"
            >
              Éditer une séquence
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>TERTIAIRE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faPlus} />}
            >
              Créer une séquence
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CustomIcon icon={faPen} />}
            >
              Éditer une séquence
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>ICON SEUL</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faPlus} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Tooltip">
              <IconButton color="primary">
                <CustomIcon icon={faPen} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <InputLabel style={{ marginBottom: '2rem' }}>BOUTON MOBILE</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Fab color="primary">
              <CustomIcon icon={faPlus} />
            </Fab>
          </Grid>
          <Grid item>
            <Fab color="primary">
              <CustomIcon icon={faPen} />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Divider />

    <Tabs
      centered
      indicatorColor="primary"
      textColor="primary"
      value={currentTab}
      variant="fullWidth"
      onChange={(_, tab) => setCurrentTab(tab)}
    >
      <Tab
        icon={(
          <CustomIcon
            icon={faTachometerAlt}
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Stats"
      />
      <Tab
        icon={(
          <CustomIcon
            icon={faSync}
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Initialisation"
      />
      <Tab
        icon={(
          <CustomIcon
            icon={faSnowflake}
            secondIcon={faPlusSquare}
            size="2x"
            thirdIcon={faMinusSquare}
          />
        )}
        key={shortid.generate()}
        label="Fluides"
      />
      <Tab
        icon={(
          <CustomIcon
            iconClass="icon-container"
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Bouteilles"
      />
      <Tab
        icon={(
          <CustomIcon
            icon={faCheck}
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Validation"
      />
    </Tabs>

    <Tabs
      centered
      indicatorColor="primary"
      textColor="primary"
      value={currentTabSecond}
      onChange={(_, tab) => setCurrentTabSecond(tab)}
    >
      <Tab
        icon={(
          <CustomIcon
            icon={faClipboardList}
            secondIcon={faSnowBlowing}
            secondSize="1x"
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Bilan"
      />
      <Tab
        icon={(
          <CustomIcon
            icon={faClipboardList}
            secondIcon={faTint}
            secondSize="1x"
            size="2x"
            thirdIcon={faSnowflake}
          />
        )}
        key={shortid.generate()}
        label="Bilan"
      />
      <Tab
        icon={(
          <CustomIcon
            iconClass="icon-container"
            secondIcon={faLayerGroup}
            secondSize="1x"
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Stocks"
      />
      <Tab
        icon={(
          <CustomIcon
            iconClass="icon-container"
            secondIcon={faLayerGroup}
            secondSize="1x"
            size="2x"
            thirdIcon={faLayerGroup}
          />
        )}
        key={shortid.generate()}
        label="Stocks"
      />
      <Tab
        icon={(
          <CustomIcon
            iconClass="icon-container"
            secondIcon={faBoxesAlt}
            secondSize="1x"
            size="2x"
          />
        )}
        key={shortid.generate()}
        label="Stocks"
      />
      <Tab
        icon={(
          <CustomIcon
            iconClass="icon-container"
            secondIcon={faWaterRise}
            secondSize="1x"
            size="2x"
            thirdIcon={faWaterLower}
          />
        )}
        key={shortid.generate()}
        label="Stocks"
      />
    </Tabs>

    <Divider />

    <InputLabel style={{ marginTop: '4rem' }}>ILLUSTRATIONS</InputLabel>
    <Grid container spacing={6} style={{ margin: '1rem 0' }}>
      <Grid item>
        <InputLabel style={{ marginBottom: '1rem' }}>Fluide ajout</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <CustomIcon
              icon={faSnowflake}
              secondIcon={faPlusSquare}
              secondSize="1x"
              size="lg"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '1rem' }}>Fluide retrait</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <CustomIcon
              icon={faSnowflake}
              secondIcon={faMinusSquare}
              secondSize="1x"
              size="lg"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '1rem' }}>Stocks</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <CustomIcon
              iconClass="icon-stocks"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-stocks-v2"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-stocks-v2"
              secondIcon={faPlusSquare}
              secondSize="1x"
              size="lg"
              thirdIcon={faMinusSquare}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '1rem' }}>Bouteilles</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <CustomIcon
              iconClass="icon-container-03"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container-13"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container-23"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container"
              secondIcon={faPlusSquare}
              secondSize="1x"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container"
              secondIcon={faMinusSquare}
              secondSize="1x"
              size="lg"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '1rem' }}>Fuites</InputLabel>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <CustomIcon
              iconClass="icon-hazard"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container-warning"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              icon={faSnowflake}
              secondIcon={faExclamationTriangle}
              secondSize="1x"
              size="lg"
            />
          </Grid>
          <Grid item>
            <CustomIcon
              iconClass="icon-container-03"
              secondIcon={faExclamationTriangle}
              secondSize="1x"
              size="lg"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Wrapper>
);
};

export default PageTestButtons;
