import { CountryService } from 'services';
import React, { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import {
  faPlus, faFlag, faCheck, faTimes, faEdit
} from '@fortawesome/pro-solid-svg-icons';
import {
  Grid, IconButton, TableCell, Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextError } from 'components/Text';
import { translate } from 'utils';
import { PageTitle } from 'pages';
import { useModal } from 'hooks';
import {
  Button, CustomIcon, Flag, GenericListFilters, GenericTable, Wrapper
} from 'components/_commons';

import { useHistory } from 'react-router-dom';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { API_ROUTES } from 'utils/constants';
import { CountryListFilters } from './CountryListFilters';

const listHeaders = (countries, handleEdit) => [
  {
    name: 'state',
    label: translate('pageCountry.state'),
    template: row => (
      <TableCell key={`${row.hashId}_state`}>
        {row.activated ? (<CustomIcon color="var(--success-color)" icon={faCheck} />)
          : (<CustomIcon color="var(--error-color)" icon={faTimes} />)}
      </TableCell>
    )
  },
  {
    name: 'flag',
    label: translate('pageCountry.flag'),
    template: row => (
      <TableCell key={`${row.hashId}_flag`}>
        <Flag alt={`pageCountry.flag ${row.key}`} countryCode={row.key} />
      </TableCell>
    )
  },
  {
    name: 'name',
    label: translate('pageCountry.name'),
    template: row => (
      <TableCell key={`${row.hashId}_name`}>
        <Typography>{row.actualTranslation}</Typography>
      </TableCell>
    )
  },
  {
    name: 'legalStatusNumber',
    label: translate('pageCountry.legalStatusNumber'),
    template: row => (
      <TableCell key={`${row.hashId}_legalStatusNumber`}>
        <Typography>{row.numberOfLegalStatuses}</Typography>
      </TableCell>
    )
  },
  {
    name: 'edit',
    label: translate('pageCountry.edit'),
    template: row => (
      <TableCell key={`${row.hashId}_edit`}>
        <IconButton
          color="primary"
          edge="end"
          onClick={e => { e.stopPropagation(); handleEdit(row); }}
        >
          <FontAwesomeIcon icon={faEdit} size="xs" />
        </IconButton>
      </TableCell>
    )
  }

];

const CountryList = observer(() => {
  const displayModal = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = useCallback(country => {
    if (!country) return;
    history.push({
      pathname: API_ROUTES.ADMIN_COUNTRIES_DETAIL_COMPANY_RULES(country.id),
      state: {
        isEditionMode: true
      }
    });
  }, [history]);

  const loadCountries = useCallback(() => {
    setIsLoading(true);
    CountryService.getCountryList(false)
      .then(resp => setCountries(resp))
      .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadCountries();
  }, [loadCountries]);

  const handleCreate = useCallback(() => {
    displayModal({
      type: 'CREATE_COUNTRY',
      countries,
      onConfirm: countryCreated => {
        setIsLoading(true);
        CountryService.save(countryCreated)
          .then(resp => {
            enqueueSnackbar(translate('pageCountry.snackbar.created'), { variant: 'success' });
            history.push(API_ROUTES.ADMIN_COUNTRIES_DETAIL_COMPANY_RULES(resp));
          })
          .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }));
      }
    });
  }, [countries, displayModal, enqueueSnackbar, history]);

  const CreateCountryButton = (
    <Button
      color="primary"
      startIcon={<CustomIcon icon={faPlus} />}
      variant="contained"
      onClick={() => handleCreate()}
    >
      {translate('button.createCountry')}
    </Button>
  );

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <CountryListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const filterCountries = useCallback((searchBar, filt) => countries
    .filter(c => {
      const activatedFilter = filt[0];
      return (!activatedFilter || (activatedFilter?.value === c.activated))
          && (c.actualTranslation.toUpperCase().includes(searchBar.toUpperCase()) || c.region?.toUpperCase().includes(searchBar.toUpperCase()));
    })
    .sort((a, b) => a.actualTranslation.localeCompare(b.actualTranslation)), [countries]);

  return (
    <Wrapper>
      <PageTitle title={translate('pageCountry.title')} titleRight={CreateCountryButton} />
      <GenericListFilters
        ComponentFilter={renderFilters}
        dataTour="step-admin-countryFilters"
        filters={filters}
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
        tooltipInfo="pageCountry.searchTooltip"
        withDrawer
      />
      {(countries.length === 0 && isLoading) && (
        <Grid alignItems="center" container direction="column">
          <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="5x" spin />
        </Grid>
      )}
      {(countries.length === 0 && !isLoading) && (
        <Grid alignItems="center" container direction="column">
          <CustomIcon
            color="var(--primary-color)"
            icon={faFlag}
            secondSize="3x"
            size="3x"
          />
          <TextError>{translate('errors.noCountries')}</TextError>
        </Grid>
      )}
      {(countries.length !== 0) && (
        <GenericTable
          headers={listHeaders(countries, handleEdit)}
          rows={filterCountries(search, filters)}
        />
      )}
    </Wrapper>
  );
});

export default CountryList;
