import { faBalanceScale } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, Switch, Typography
} from '@material-ui/core';
import { InputField } from 'components';
import { HeaderModal } from 'components/Modals/_HeaderModal';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { translate } from 'utils';

export const CreateLegalStatusModal = observer(({
  givenLegalStatus, onConfirm, onClose
}) => {
  const [name, setName] = useState(givenLegalStatus?.name ?? '');
  const [isPublic, setIsPublic] = useState(givenLegalStatus?.isPublic ?? false);

  const handleChangeName = useCallback(value => {
    setName(value);
  }, []);

  const handleChangeIsPublic = useCallback((_, value) => {
    setIsPublic(value);
  }, []);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    const newLegalStatus = {
      ...givenLegalStatus,
      name,
      isPublic,
      activated: true
    };
    onConfirm(newLegalStatus);
    onClose();
  }, [givenLegalStatus, name, isPublic, onConfirm, onClose]);

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faBalanceScale} />
        <Typography component="span">
          {givenLegalStatus
            ? translate('modalCreateLegalStatus.titleModify')
            : translate('modalCreateLegalStatus.titleCreate')}
        </Typography>
      </HeaderModal>
      <DialogContent style={{ overflow: 'hidden', minWidth: '50em' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <InputField
              label={translate('pageCountry.legalStatus.name')}
              name="legal-status-name"
              value={name}
              onChange={(_, value) => handleChangeName(value)}
            />
          </Grid>
          <Grid item>
            <Grid alignItems="center" container>
              <Switch checked={isPublic} color="primary" onChange={handleChangeIsPublic} />
              <Grid item style={{ minWidth: '100px', textAlign: 'left' }}>
                {(isPublic) ? translate('common.publicCompany') : translate('common.privateCompany')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button color="primary" type="submit" >
          {givenLegalStatus
            ? translate('button.validate')
            : translate('button.create')}
        </Button>
      </DialogActions>
    </form>
  );
});
