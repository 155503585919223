import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-use-form-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  DialogContent, TextField, Grid, Typography
} from '@material-ui/core';
import { translate } from 'utils';

import { FormElement } from 'components/_commons';
import { HeaderModal } from './_HeaderModal';
import { CustomActionButtons } from './_CustomActionButtons';

export const CreateGroupModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formState, { text }] = useFormState({
    ...defaultValues
  });

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    setIsLoading(true);

    onConfirm({
      ...formState.values,
      hashId: defaultValues.hashId || null
    }).finally(() => setIsLoading(false));

    if (closeOnSubmit) {
      onClose();
    }
  }, [formState, closeOnSubmit, onClose, onConfirm, defaultValues]);

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faStoreAlt} />
        <Typography component="span">
          {defaultValues && defaultValues.groupName
            ? translate('modalGroup.editGroupe')
            : translate('modalGroup.newGroup')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        <Grid container direction="column">
          <FormElement>
            <TextField
              autoFocus
              error={formState.errors.name && formState.errors.name !== null}
              label={translate('common.groupName')}
              name="name"
              required
              {...text('name')}
            />
            <TextField
              error={formState.errors.identifier && formState.errors.identifier !== null}
              label={translate('common.groupIdentifier')}
              name="identifier"
              required
              {...text('identifier')}
            />
          </FormElement>
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isLoading={isLoading}
        isValidated={defaultValues.validated}
        onClose={onClose}
      />
    </form>
  );
};

CreateGroupModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({})
};

CreateGroupModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {}
};
