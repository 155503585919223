// Docs : https://geo.api.gouv.fr/adresse
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Chip, ClickAwayListener, Divider, Grid,
  IconButton, InputAdornment, TextField, Typography
} from '@material-ui/core';
import { FormHelper } from 'utils/helpers';
import { AddressService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAltSlash, faSearch, faSpinner, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FormElement, FormLine, SelectCountry } from 'components';
import { translate } from 'utils';

const AutocompleteContainer = styled.section`
  position: relative;
  z-index: 1;
`;

const AddressList = styled.ul`
  position: absolute;
  top: 56px;
  width: 100%;
  max-height: 250px;
  margin: 0;
  padding: 0;
  background: var(--white);
  border: 1px solid #eeeeee;
  overflow: auto;
`;

const AddressItem = styled.li`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  padding: 1rem;
  cursor: pointer;

  &:hover, &:focus {
    background-color: #eeeeee;
  }
`;

const ENTER_KEYCODE = 13;

const FormAddress = ({
  formState, text, withCountry, isRequired
}) => {
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressListIsVisible, setAddressListIsVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const debouncedQuery = FormHelper.useDebounce(query, 500);

  useEffect(() => {
    const getAddressList = async () => {
      if (debouncedQuery) {
        setIsSearching(true);
        const country = (formState.values.country && formState.values.country.countryCode) || '';

        try {
          const results = await AddressService.getAddressList(debouncedQuery, country)
            .then(response => response.features);
          setAddressList(results);
          setAddressListIsVisible(true);
          setIsSearching(false);
        } catch {
          setIsSearching(false);
        }
      } else {
        setAddressList([]);
      }
    };

    getAddressList();
  }, [debouncedQuery, formState.values.country]);

  useEffect(() => {
    if (selectedAddress && selectedAddress.city) {
      formState.setField('address1', selectedAddress.address1);
      formState.setField('postalCode', selectedAddress.postcode);
      formState.setField('city', selectedAddress.city);
      withCountry && formState.setField('country', selectedAddress.country);
      setAddressListIsVisible(false);
    }
  // eslint-disable-next-line
  }, [selectedAddress]);

  const handleChangeQuery = useCallback(event => setQuery(event.target.value), []);

  const handleClearQuery = useCallback(() => {
    setQuery('');
    setAddressList([]);
    setIsSearching(false);
  }, []);

  const handleShowAddressList = useCallback(() => setAddressListIsVisible(true), []);

  const handleHideAddressList = useCallback(() => setAddressListIsVisible(false), []);

  const handleSelectAddress = useCallback(address => {
    const postcode = address.context.find(ctx => ctx.id.includes('postcode'));
    const city = address.context.find(ctx => ctx.id.includes('place'));
    const country = address.context.find(ctx => ctx.id.includes('country'));

    const addressFormatted = {
      address1: address.text,
      postcode: postcode ? postcode.text : '',
      city: city ? city.text : '',
      country: country ? country.text : ''
    };

    setSelectedAddress(addressFormatted);
    handleHideAddressList();
  }, [handleHideAddressList]);

  const handleSelectCountry = value => formState.setField('country', value);

  return (
    <FormElement label={translate('common.address')}>
      <ClickAwayListener onClickAway={handleHideAddressList}>
        <AutocompleteContainer>
          <TextField
            autoComplete="new-password"
            fullWidth
            InputProps={{
              endAdornment: query !== '' && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClearQuery}>
                    <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="xs" />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {isSearching
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : <FontAwesomeIcon color="var(--primary-color)" icon={faSearch} />}
                </InputAdornment>
              )
            }}
            label={translate('common.addressAutocomplete')}
            value={query}
            variant="outlined"
            onChange={handleChangeQuery}
            onFocus={handleShowAddressList}
          />
          {addressListIsVisible && (
            <AddressList>
              {addressList.length > 0 && addressList.map(address => (
                <Fragment key={address.id}>
                  <AddressItem
                    tabIndex={0}
                    onClick={() => handleSelectAddress(address)}
                    onKeyDown={e => e.keyCode === ENTER_KEYCODE && handleSelectAddress(address)}
                  >
                    <div style={{ minWidth: '120px' }}>
                      <Chip label={translate(`common.${address.place_type[0]}`)} />
                    </div>
                    <div>
                      <Typography style={{ fontWeight: 600 }}>{address.place_name}</Typography>
                      {/* <Typography variant="subtitle2">{address.properties.context}</Typography> */}
                    </div>
                  </AddressItem>
                  <Divider />
                </Fragment>
              ))}
              {addressList.length === 0 && debouncedQuery && !isSearching && (
                <Typography align="center" style={{ padding: '2rem' }}>
                  <FontAwesomeIcon icon={faMapMarkerAltSlash} size="2x" />
                  <br />
                  {translate('errors.noAddressList')}
                </Typography>
              )}
            </AddressList>
          )}
        </AutocompleteContainer>
      </ClickAwayListener>
      <FormLine>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              error={(formState.touched?.address1 && !formState.values.address1) || formState.errors.address1}
              label={translate('common.address')}
              name="address1"
              required={isRequired || FormHelper.isFieldRequired({
                fieldsToCheck: [formState.values.postalCode, formState.values.city]
              })}
              {...text('address1')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              label={translate('common.address2')}
              name="address2"
              {...text('address2')}
            />
          </Grid>
        </Grid>
      </FormLine>
      <FormLine>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={4} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={(formState.touched?.postalCode && !formState.values?.postalCode) || formState.errors.postalCode}
                  label={translate('common.postalCode')}
                  name="postalCode"
                  required={isRequired || FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.city]
                  })}
                  {...text('postalCode')}
                />
              </Grid>
              <Grid item sm={8} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={(formState.touched?.city && !formState.values.city) || formState.errors.city}
                  label={translate('common.city')}
                  name="city"
                  required={isRequired || FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.postalCode]
                  })}
                  {...text('city')}
                />
              </Grid>
            </Grid>
          </Grid>
          {withCountry && (
            <Grid item xs={12}>
              <SelectCountry
                required
                value={formState.values.country || null}
                onChange={handleSelectCountry}
              />
            </Grid>
          )}
        </Grid>
      </FormLine>
    </FormElement>
  );
};

FormAddress.propTypes = {
  isRequired: PropTypes.bool,
  formState: PropTypes.shape({}).isRequired,
  text: PropTypes.func.isRequired,
  withCountry: PropTypes.bool
};

FormAddress.defaultProps = {
  isRequired: false,
  withCountry: true
};

export default FormAddress;
