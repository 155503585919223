import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  QuizHeader, QuizNavigationBar, QuizPage, QuizProgressBar, QuizQuestion
} from 'components/_commons';
import { QUIZ_STATUS } from '../QuizHelper';

const Footer = styled.footer`
  margin: 20px 0px 10px 0px;
`;

const QuizContainer = observer(() => {
  const { running } = QUIZ_STATUS;

  const { examinationStore } = useStores();

  const { examination, currentPage } = examinationStore;

  const { quiz: { showProgressBar, pages } } = examination;

  return (
    <section>
      <QuizHeader quizStatus={running} />

      {currentPage && Object.keys(currentPage).length > 0 && (
        <Grid container direction="column">
          {showProgressBar && (<QuizProgressBar />)}

          <QuizPage
            key={currentPage.id || currentPage.index}
            quizStatus={running}
          >
            {currentPage.questions.map(question => (
              <QuizQuestion
                currentQuestionData={question}
                key={question.id}
                quizStatus={running}
              />
            ))}
          </QuizPage>
        </Grid>
      )}
      <Footer>
        {pages && currentPage && (
          <QuizNavigationBar />
        )}
      </Footer>
    </section>
  );
});

export default QuizContainer;
