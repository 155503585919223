import { subYears } from 'date-fns';

const getDateOneYearInThePast = () => {
  const date = subYears(new Date(), 1);
  date.setHours(0, 0, 0, 0);
  return date;
};

const isIsoDate = str => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

export const DateHelper = {
  getDateOneYearInThePast,
  isIsoDate
};
