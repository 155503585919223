import {
  Button, Chip,
  Grid, ListItemText,
  TextField,
  Tooltip, makeStyles
} from '@material-ui/core';
import { InfoTooltip, StyledListItem, Text } from 'components/_commons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { translate } from 'utils';

import {
  faExternalLinkAlt, faMinus, faPlus
} from '@fortawesome/pro-regular-svg-icons';
import { } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPreviewContainer } from 'components/CategoryList';
import debounce from 'debounce-promise';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { CategoryService } from 'services';
import styled from 'styled-components';
import { CATEGORY_FILTER_KEY, ROUTES } from 'utils/constants';

import { findSmallestCategories } from 'components/_commons/Quiz/QuizHelper';
import { runInAction } from 'mobx';
import { StorageHelper } from 'utils/helpers';

import { CustomListItem } from './CustomListItem';

const useStyles = makeStyles(() => ({
  itemContainer: {
    height: 340,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0px 5px',
    marginBottom: '5px'
  }
}));

const StyledInput = styled(TextField)`
  width: 50px;

  input{
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;  
  };
`;

const StyledButton = styled(Button)`
  background-color: var(--tecnea-blue)!important;

  &:disabled {
    background-color: var(--primary-color-light)!important;
  }
`;

export const QuestionRandomSelect = observer(({ formData }) => {
  const { index } = formData;

  const classes = useStyles();
  const { quizStore } = useStores();
  const { quiz, updatedPage } = quizStore;

  const [questionCategories, setQuestionCategories] = useState(formData.categoryOptions || []);
  const [selectedQuestionCategories, setSelectedQuestionCategories] = useState([]);
  const [nbQuestions, setNbQuestions] = useState(formData.nbQuestions === undefined ? 1 : formData.nbQuestions);
  const [isNbQuestionsMinusBtnDisabled, setIsNbQuestionsMinusBtnDisabled] = useState(false);
  const [isNbQuestionsPlusBtnDisabled, setIsNbQuestionsPlusBtnDisabled] = useState(false);
  const [nbCategories, setNbCategories] = useState(formData.nbCategories === undefined ? 1 : formData.nbCategories);
  const [isNbCategoriesMinusBtnDisabled, setIsCategoriesMinusBtnDisabled] = useState(false);
  const [isNbCategoriesPlusBtnDisabled, setIsNbCategoriesPlusBtnDisabled] = useState(false);
  const [totalQuestionCount, setTotalQuestionCount] = useState(0);
  const [smallestCategories, setSmallestCategories] = useState([]);
  const [error, setError] = useState('');
  const [totalCategories, setTotalCategories] = useState(questionCategories.length || 0);
  const [itemColors, setItemColors] = useState([]);

  useEffect(() => {
    setTotalCategories(questionCategories.length);
  }, [questionCategories]);

  const toggleItemListColor = useCallback(() => {
    setItemColors(smallestCategories.map(elt => ({
      id: elt.id,
      color: 'var(--error-color)'
    })));
  }, [smallestCategories]);

  useEffect(() => {
    toggleItemListColor();
  }, [toggleItemListColor]);

  const getSmallestCategories = useCallback(() => {
    const { questionCountSum, smallestCat } = findSmallestCategories(Object.keys(formData).length > 0 ? formData : updatedPage);

    setTotalQuestionCount(questionCountSum);
    setSmallestCategories(smallestCat);

    toggleItemListColor();
  }, [formData, updatedPage, toggleItemListColor]);

  useEffect(() => {
    getSmallestCategories();
    // eslint-disable-next-line
  }, []);

  const checkFormValidity = useCallback(() => {
    smallestCategories.length > 0
      ? setError(translate('quiz.tooFewQuestions', { nbQuestions, totalQuestionCount, nbCategories }))
      : setError('');

    if (updatedPage?.isRandom) {
      updatedPage?.categoryOptions?.length === 0 || smallestCategories.length > 0
        ? quizStore.setIsQuizFormPageValid(false)
        : quizStore.setIsQuizFormPageValid(true);
    }
  }, [quizStore, updatedPage, smallestCategories, nbCategories, nbQuestions, totalQuestionCount]);

  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);

  const updateNbQuesAndCat = useCallback((key, value) => {
    if (key === 'nbQuestions') {
      setNbQuestions(value);
    }
    if (key === 'nbCategories') {
      setNbCategories(value);
    }
  }, []);

  // Update data whether it comes from a quiz form or Modal.
  const updatePage = useCallback((key, data) => {
    if (Object.keys(formData).length > 0) {
      runInAction(() => {
        quiz.pages[index][key] = data[key];
      });
      updateNbQuesAndCat(key, data[key]);
    } else {
      quizStore.updatePage({ isRandom: true, ...data });
      quizStore.updateContentInCurrentPage(index);
      updateNbQuesAndCat(key, data[key]);
    }
  }, [formData, quizStore, quiz, index, updateNbQuesAndCat]);

  const handleAddQuestionCategory = useCallback(value => {
    const newQuestionCategories = [...questionCategories];

    newQuestionCategories.push(value);
    setQuestionCategories(newQuestionCategories);

    updatePage('nbCategories', { nbCategories: newQuestionCategories.length });

    const newQuestionCategoriesTmp = [...selectedQuestionCategories];
    newQuestionCategoriesTmp.push(value);
    setSelectedQuestionCategories(newQuestionCategoriesTmp);

    updatePage('categoryOptions', {
      nbQuestions: newQuestionCategories.length,
      nbCategories: newQuestionCategories.length,
      categoryOptions: newQuestionCategories
    });

    getSmallestCategories();
    checkFormValidity();
  }, [
    questionCategories,
    selectedQuestionCategories,
    updatePage,
    checkFormValidity,
    getSmallestCategories
  ]);

  const handleFilterOptions = useCallback(opt => !questionCategories.find(q => q.id === opt.data.id), [questionCategories]);

  const handleDeleteQuestionCategory = useCallback(questionId => {
    quizStore.toggleDeletingQuestion(true);

    const newQuestionCategoriesList = [...questionCategories].filter(ques => ques.id !== questionId);

    setQuestionCategories(newQuestionCategoriesList);
    setSelectedQuestionCategories(newQuestionCategoriesList);

    updatePage('nbCategories', { nbCategories: newQuestionCategoriesList.length === 0 ? nbCategories : newQuestionCategoriesList.length });

    updatePage('categoryOptions', { categoryOptions: newQuestionCategoriesList });

    getSmallestCategories();
    checkFormValidity();
  }, [questionCategories, quizStore, nbCategories, updatePage, checkFormValidity, getSmallestCategories]);

  const toggleNbQuestionsBtnState = useCallback(() => {
    getSmallestCategories();

    if (questionCategories.length <= 0) {
      updatePage('nbQuestions', { nbQuestions: 1 });

      setIsNbQuestionsPlusBtnDisabled(true);
      setIsNbQuestionsMinusBtnDisabled(true);
    } else if (questionCategories.length === 1) {
      setIsNbQuestionsPlusBtnDisabled(nbQuestions >= totalQuestionCount);
      setIsNbQuestionsMinusBtnDisabled(nbQuestions <= 1);
    } else {
      setIsNbQuestionsPlusBtnDisabled(nbQuestions >= totalQuestionCount);
      setIsNbQuestionsMinusBtnDisabled(nbQuestions <= nbCategories);
    }
    // eslint-disable-next-line
  }, [questionCategories, totalQuestionCount, nbQuestions, nbCategories, updatePage]);

  const toggleNbCategoriesBtnState = useCallback(() => {
    setIsNbCategoriesPlusBtnDisabled(nbCategories === questionCategories.length);
    setIsCategoriesMinusBtnDisabled(nbCategories < 2);
  }, [nbCategories, questionCategories]);

  const initialValueOfNbQuestions = useCallback(() => {
    getSmallestCategories();
    checkFormValidity();

    if (nbCategories >= 2 && nbQuestions <= nbCategories) {
      updatePage('nbQuestions', { nbQuestions: nbCategories });
    }
    // eslint-disable-next-line
  }, [nbQuestions, nbCategories, totalQuestionCount, updatePage]);

  useEffect(() => {
    initialValueOfNbQuestions();
    toggleNbQuestionsBtnState();
    toggleNbCategoriesBtnState();
  }, [initialValueOfNbQuestions, toggleNbQuestionsBtnState, toggleNbCategoriesBtnState]);

  const handleNbQuestionsChange = useCallback(event => {
    const { value } = event.target;

    let newValue;
    if (Number(value) === 0) {
      newValue = 1;
    } else if (Number(value) <= nbCategories) {
      newValue = nbCategories;
    } else if (Number(value) >= totalQuestionCount) {
      newValue = totalQuestionCount;
    } else {
      newValue = Number(value);
    }

    updatePage('nbQuestions', { nbQuestions: newValue });

    getSmallestCategories();
    checkFormValidity();
  }, [updatePage, checkFormValidity, getSmallestCategories, nbCategories, totalQuestionCount]);

  const incrementNbQuestions = useCallback(() => {
    const newValue = Number(nbQuestions + 1);

    updatePage('nbQuestions', { nbQuestions: newValue });

    getSmallestCategories();
    checkFormValidity();
  }, [nbQuestions, updatePage, checkFormValidity, getSmallestCategories]);

  const decrementNbQuestions = useCallback(() => {
    if (nbQuestions > 1) {
      const newValue = Number(nbQuestions - 1);

      updatePage('nbQuestions', { nbQuestions: newValue });
    }

    getSmallestCategories();
    checkFormValidity();
  }, [nbQuestions, updatePage, checkFormValidity, getSmallestCategories]);

  const handleNbCategoriesChange = useCallback(event => {
    const { value } = event.target;
    let newValue;

    if (value < 1) {
      newValue = 1;
    } else if (value > questionCategories.length) {
      newValue = questionCategories.length;
    }

    updatePage('nbCategories', { nbCategories: newValue });

    getSmallestCategories();
    checkFormValidity();
  }, [questionCategories, updatePage, checkFormValidity, getSmallestCategories]);

  const incrementNbCategories = useCallback(() => {
    if (nbCategories < questionCategories.length) {
      const newValue = Number(nbCategories + 1);

      updatePage('nbCategories', { nbCategories: questionCategories.length >= 2 ? newValue : 1 });
    }

    getSmallestCategories();
    checkFormValidity();
  }, [nbCategories, questionCategories, updatePage, checkFormValidity, getSmallestCategories]);

  const decrementNbCategories = useCallback(() => {
    if (nbCategories > 1) {
      const newValue = Number(nbCategories - 1);

      updatePage('nbCategories', { nbCategories: questionCategories.length >= 2 ? newValue : 1 });
    }

    getSmallestCategories();
    checkFormValidity();
  }, [nbCategories, questionCategories, updatePage, checkFormValidity, getSmallestCategories]);

  const getAsyncOptions = useCallback(inputValue => new Promise(resolve => {
    CategoryService
      .getCategoryListForOptions({ input: inputValue })
      .then(response => resolve(response.content));
  }), []);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      return e.preventDefault();
    }
    return null;
  };

  const handleSetInitFilter = useCallback(categoryName => {
    StorageHelper.SET(CATEGORY_FILTER_KEY, categoryName);
  }, []);

  const openInNewTab = (questionCount, categoryName) => (
    <Grid container direction="row">
      <Grid item>
        <Text fontSize="12px" fontWeight="bold">
          (
          {`${questionCount} ${translate('common.questions')} `}
          <a
            href={`${ROUTES.ADMIN_QUESTION}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon color="primary" icon={faExternalLinkAlt} onClick={() => handleSetInitFilter(categoryName)} />
          </a>
          )
        </Text>
      </Grid>
    </Grid>
  );

  const renderSpecificChip = () => {
    if (questionCategories.length === 1) {
      return (
        <Chip
          label={translate('common.mandatory')}
          style={{ backgroundColor: 'var(--warning-color)', fontWeight: 'bold' }}
        />
      );
    }
    return (
      <Chip
        label={translate('common.optional')}
        style={{ backgroundColor: 'var(--primary-color)', fontWeight: 'bold' }}
      />
    );
  };

  const LabelWithIncrDecrBtn = ({
    label, onIncrement, onDecrement, value, handleChangeValue, isMinusBtnDisabled, isPlusBtnDisabled, withError, textError
  }) => (
    <Grid alignItems="center" container direction="row" justifyContent="flex-end" spacing={2}>

      {withError ? (
        <>
          {textError ? (
            <>
              <Grid item>
                <Text color="var(--error-color)" fontWeight="bold">
                  <InfoTooltip iconColor="var(--error-color)" label={textError} />
                  {' '}
                  {label}
                </Text>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Text fontWeight="bold">{label}</Text>
            </Grid>
          )}
        </>
      ) : (
        <Grid item>
          <Text fontWeight="bold">{label}</Text>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <StyledButton disabled={isMinusBtnDisabled} onClick={onDecrement}>
              <FontAwesomeIcon color="var(--white)" icon={faMinus} />
            </StyledButton>
          </Grid>

          <Grid item>
            <StyledInput
              autoComplete="off"
              name="nbQuestions"
              type="number"
              value={Number(value) < 1 ? 1 : Number(value)}
              onChange={handleChangeValue}
            />
          </Grid>

          <Grid item>
            <StyledButton disabled={isPlusBtnDisabled} onClick={onIncrement}>
              <FontAwesomeIcon color="var(--white)" icon={faPlus} />
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );

  LabelWithIncrDecrBtn.propTypes = {
    label: PropTypes.string.isRequired,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    isMinusBtnDisabled: PropTypes.bool,
    isPlusBtnDisabled: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    handleChangeValue: PropTypes.func.isRequired,
    withError: PropTypes.bool,
    textError: PropTypes.string
  };

  LabelWithIncrDecrBtn.defaultProps = {
    isMinusBtnDisabled: false,
    isPlusBtnDisabled: false,
    textError: null,
    withError: false
  };

  const CustomOptionQuestionCategory = ({ data, innerProps }) => (
    <StyledListItem {...innerProps}>
      <ListItemText primary={data.name} />
    </StyledListItem>
  );

  return (
    <>
      <Grid alignItems="center" container direction="row" justifyContent="flex-start">
        <Grid item style={{ marginTop: 20 }}>
          <Text fontWeight="bold">{translate('quiz.searchCategoryQuestions')}</Text>
        </Grid>
        <Grid item style={{ width: '70%', marginBottom: 20 }}>
          <Tooltip placement="top" title={translate('quiz.tooltip.categorySearchToolTip')}>
            <AsyncSelect
              cacheOptions
              closeMenuOnSelect
              components={{ Option: CustomOptionQuestionCategory }}
              defaultOptions
              filterOption={handleFilterOptions}
              loadingMessage={() => translate('common.loading')}
              loadOptions={inputValue => debouncedLoadOptions(inputValue)}
              name="questionCategories"
              noOptionsMessage={() => translate('errors.noOptions')}
              placeholder={translate('quiz.addCategories')}
              styles={{ menu: base => ({ ...base, zIndex: 2000 }) }}
              value={translate('quiz.addCategories')}
              onChange={handleAddQuestionCategory}
              onKeyDown={handleKeyDown}
            />
          </Tooltip>
        </Grid>

        {questionCategories && questionCategories.length === 0
          ? (
            <Grid
              alignItems="center"
              className={classes.itemContainer}
              container
              direction="column"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs>
                <img alt="add_category_image" src={`${process.env.PUBLIC_URL}/assets/images/add-category.png`} />
              </Grid>
              <Grid item xs>
                <Text color="var(--grey)" fontWeight="bold">{translate('quiz.addCategoriesMessage')}</Text>
              </Grid>
            </Grid>
          )
          : (
            <Grid
              className={classes.itemContainer}
              item
              xs={12}
            >
              <Text fontWeight="bold" textTransform="uppercase">{translate('pageQuizList.selectedCategories')}</Text>
              {questionCategories && questionCategories.length > 0 && (
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item>
                    <Text color="var(--grey-darker)" fontSize="14px" fontWeight="bold">
                      {nbQuestions === 1 ? (
                        translate('quiz.oneQuestionAndCategoriesSelectedInfo', { nbQuestions, nbCategories, totalCategories })
                      ) : (
                        translate('quiz.questionsAndCategoriesSelectedInfo', { nbQuestions, nbCategories, totalCategories })
                      )}
                    </Text>
                  </Grid>
                </Grid>
              )}
              {questionCategories.map(questionCategory => (
                <CustomListItem
                  isItemColorChanged={itemColors.some(item => (item.id === questionCategory.id))}
                  key={questionCategory.id}
                  startIcon={<ColorPreviewContainer color={questionCategory.colorHex} />}
                  tagIcon1={openInNewTab(questionCategory.questionCount, questionCategory.name)}
                  tagIcon2={renderSpecificChip()}
                  title={questionCategory.name}
                  onDelete={() => handleDeleteQuestionCategory(questionCategory.id)}
                />
              ))}
            </Grid>
          )}
      </Grid>

      <Grid
        container
        direction="row"
        style={{
          borderTop: '1px solid',
          borderColor: 'var(--grey-lighter)'
        }}
      >
        <LabelWithIncrDecrBtn
          handleChangeValue={handleNbQuestionsChange}
          isMinusBtnDisabled={isNbQuestionsMinusBtnDisabled}
          isPlusBtnDisabled={isNbQuestionsPlusBtnDisabled}
          label={translate('quiz.randomSelectedQuestionNumber')}
          name="nbQuestions"
          textError={error}
          value={Number(nbQuestions)}
          withError
          onDecrement={decrementNbQuestions}
          onIncrement={incrementNbQuestions}
        />

        {questionCategories.length >= 2 && (
          <LabelWithIncrDecrBtn
            handleChangeValue={handleNbCategoriesChange}
            isMinusBtnDisabled={isNbCategoriesMinusBtnDisabled}
            isPlusBtnDisabled={isNbCategoriesPlusBtnDisabled}
            label={translate('quiz.coveredCategoriesNumber')}
            name="nbCategories"
            value={Number(nbCategories)}
            onDecrement={decrementNbCategories}
            onIncrement={incrementNbCategories}
          />
        )}
      </Grid>
    </>
  );
});

QuestionRandomSelect.propTypes = {
  formData: PropTypes.shape({})
};

QuestionRandomSelect.defaultProps = {
  formData: {}
};
