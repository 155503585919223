import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, DialogTitle, Grid, Typography
} from '@material-ui/core';
import { InputField } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { translate } from 'utils';
import { DOCUMENT_VALIDATION_STATUS } from 'utils/constants';

export const RejectPurchaseOrderModal = ({
  onClose, onConfirm, onCancel, purchaseOrderId, purchaseOrderComplementIds
}) => {
  const handleClose = useCallback(() => onClose(), [onClose]);
  const defaultValues = {};
  defaultValues[purchaseOrderId] = { comment: '', validationStatus: DOCUMENT_VALIDATION_STATUS.VALID };
  purchaseOrderComplementIds.forEach(purchaseOrderComplementId => {
    defaultValues[purchaseOrderComplementId] = { comment: '', validationStatus: DOCUMENT_VALIDATION_STATUS.VALID };
  });
  const [purchaseOrderValidations, setPurchaseOrderValidations] = useState(defaultValues);

  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [onCancel, handleClose]);

  const handleConfirm = useCallback(() => {
    onConfirm(purchaseOrderValidations);
    handleClose();
  }, [onConfirm, handleClose, purchaseOrderValidations]);

  const updatePurchaseOrderValidations = (id, value) => setPurchaseOrderValidations(currentPurchaseOrderValidation => (
    {
      ...currentPurchaseOrderValidation,
      [id]: {
        validationStatus: value ? DOCUMENT_VALIDATION_STATUS.INVALID : DOCUMENT_VALIDATION_STATUS.VALID,
        comment: value
      }
    }
  ));

  return (
    <>
      <DialogTitle className="warningModal">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {translate('warnings.warning')}
      </DialogTitle>

      <DialogContent style={{ minWidth: '500px', maxWidth: '1000px' }}>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography>
              {translate('pagePurchaseOrderDetail.reject.details')}
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography>
              {translate('common.purchaseOrder')}
            </Typography>
          </Grid>
          <Grid item>
            <InputField
              label={translate('common.justifyRejectionComment')}
              value={purchaseOrderValidations[purchaseOrderId]?.comment}
              onChange={(_, value) => updatePurchaseOrderValidations(purchaseOrderId, value)}

            />
          </Grid>
          {purchaseOrderComplementIds.map((purchaseOrderComplementId, index) => (
            <>
              <Grid item>
                <Typography>
                  {`${translate('common.purchaseOrderComplement')} ${index + 1}`}
                </Typography>
              </Grid>
              <Grid item>
                <InputField
                  label={translate('common.justifyRejectionComment')}
                  value={purchaseOrderValidations[purchaseOrderComplementId]?.comment}
                  onChange={(_, value) => updatePurchaseOrderValidations(purchaseOrderComplementId, value)}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!Object.values(purchaseOrderValidations)
            .some(validation => validation.validationStatus === DOCUMENT_VALIDATION_STATUS.INVALID)}
          id="warningModalConfirm"
          onClick={onConfirm ? handleConfirm : handleClose}
        >
          {translate('button.validate')}
        </Button>
      </DialogActions>
    </>
  );
};

RejectPurchaseOrderModal.propTypes = {
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  purchaseOrderId: PropTypes.string.isRequired,
  purchaseOrderComplementIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

RejectPurchaseOrderModal.defaultProps = {
  onCancel: () => {},
  onClose: () => {}
};
