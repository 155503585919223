// Docs Keycloak : https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
import Keycloak from 'keycloak-js'; // Docs : https://www.npmjs.com/package/keycloak-js

import {
  KEYCLOAK_AUTH_SERVER_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_RESOURCE
} from 'utils/constants';

const initOptions = {
  realm: KEYCLOAK_REALM,
  url: KEYCLOAK_AUTH_SERVER_URL,
  clientId: KEYCLOAK_RESOURCE,
  'public-client': true,
  'confidential-port': 0
};

const keycloak = new Keycloak(initOptions);

const init = () => new Promise((resolve, reject) => {
  keycloak.init({
    onLoad: 'check-sso'
  })
    .then(() => resolve(keycloak))
    .catch(error => reject(error));
});

const currentKeycloakInstance = () => keycloak;

export const KeyCloakUtils = {
  init,
  currentKeycloakInstance
};
