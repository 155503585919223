import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { translate } from 'utils';
import { isValid } from 'date-fns';

export const Datepicker = ({
  dataCy, disabled, fullWidth, label, value, onChange, onError, ...config
}) => {
  const handleChange = useCallback(date => onChange(date), [onChange]);
  const handleError = useCallback(error => onError(error), [onError]);

  return (
    <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        cancelLabel={translate('button.cancel')}
        clearable
        clearLabel={translate('button.delete')}
        data-cy={dataCy}
        disabled={disabled}
        format="dd/MM/yyyy"
        fullWidth={fullWidth}
        invalidDateMessage={value && !isValid(value) && translate('errors.invalidDate')}
        KeyboardButtonProps={{
          'aria-label': translate(label)
        }}
        label={translate(label)}
        maxDateMessage={translate('warnings.date.maxDate')}
        minDateMessage={translate('warnings.date.minDate')}
        value={value}
        onChange={handleChange}
        onError={handleError}
        {...config}
      />
    </MuiPickersUtilsProvider>
  );
};

Datepicker.propTypes = {
  dataCy: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ])
};

Datepicker.defaultProps = {
  dataCy: '',
  disableFuture: false,
  disablePast: false,
  error: false,
  fullWidth: false,
  label: 'common.date',
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01'),
  name: '',
  onChange: () => {},
  onError: () => {},
  required: false,
  value: null
};

export const DateTimepicker = ({
  dataCy, label, onChange, value, ...config
}) => {
  const handleChange = useCallback(date => onChange(date), [onChange]);

  return (
    <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        animateYearScrolling
        autoOk
        cancelLabel={translate('button.cancel')}
        clearable
        clearLabel={translate('button.delete')}
        data-cy={dataCy}
        format="dd/MM/yyyy HH:mm"
        invalidDateMessage={translate('errors.invalidDate')}
        KeyboardButtonProps={{
          'aria-label': translate(label)
        }}
        label={translate(label)}
        maxDateMessage={translate('warnings.date.maxDate')}
        minDateMessage={translate('warnings.date.minDate')}
        value={value}
        onChange={handleChange}
        {...config}
      />
    </MuiPickersUtilsProvider>
  );
};

DateTimepicker.propTypes = {
  ampm: PropTypes.bool,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date)
};

DateTimepicker.defaultProps = {
  ampm: false,
  dataCy: '',
  disabled: false,
  disableFuture: false,
  disablePast: false,
  error: false,
  label: 'common.date',
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01'),
  name: '',
  onChange: () => {},
  required: false,
  value: ''
};
