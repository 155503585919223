import { RequestHelper } from 'utils';
import { DATAFORM_API_URL, setCurrentVersion } from 'utils/constants';

const examinationUrl = `${DATAFORM_API_URL}/${setCurrentVersion(1)}/examinations`;
const traineeUrl = `${DATAFORM_API_URL}/${setCurrentVersion(1)}/trainees`;

// GET RESULTS LIST
const getQuizResultsList = (filters, page = 0) => RequestHelper.POST(`${examinationUrl}/filter?page=${page}`, filters);

// GET
const getExamination = examId => RequestHelper.GET(`${examinationUrl}/${examId}?forAdmin=true`);

// GET TRAINEES LIST
const getTraineesList = () => RequestHelper.GET(`${traineeUrl}`);

const exportQuizResults = filters => RequestHelper.POST(`${examinationUrl}/export`, filters);

const exportQuizResultsByMail = filters => RequestHelper.POST(`${examinationUrl}/export-asynchronous-result`, filters);

const exportQuizAnswers = filters => RequestHelper.POST(`${examinationUrl}/export-answers`, filters);

const exportSingleQuizAllAnswers = (modelId, filters) => RequestHelper.POST(`${examinationUrl}/export-all-answers/${modelId}`, filters);

const getExaminationTest = quizView => RequestHelper.POST(`${examinationUrl}/test`, quizView);

const updateQuestionTest = question => RequestHelper
  .POST(`${examinationUrl}/test-save-question`, question);

const updatePageTest = page => RequestHelper
  .POST(`${examinationUrl}/test-save-page`, page);

const finishQuizTest = examination => RequestHelper.POST(`${examinationUrl}/test-finish`, examination);

export const ExaminationService = {
  getQuizResultsList,
  getExamination,
  getTraineesList,
  exportQuizResults,
  exportQuizResultsByMail,
  exportQuizAnswers,
  exportSingleQuizAllAnswers,
  getExaminationTest,
  updateQuestionTest,
  updatePageTest,
  finishQuizTest
};
