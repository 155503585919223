import { faBuilding, faSitemap, faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { StyledListItem } from 'components/_commons/Form/SelectField/SelectField';
import { ItemOption } from 'components/_commons/Form/index';
import { isEmpty } from 'lodash';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

function getStructureLevelIcon(data) {
  return {
    INSTITUTION_MODEL: faBuilding,
    COMPANY: faStoreAlt,
    INSTITUTION_GROUP: faSitemap
  }[data.type];
}

const getActivityLabel = data => (data.activity ? ` (${data.activity.label})` : '');

export const StructureLevelOption = ({ data, innerProps }) => {
  const icon = getStructureLevelIcon(data);
  const indent = '&nbsp;'.repeat(data.indentNumber * 6);
  const label = data.type === 'COMPANY' ? `<b>${data.label}</b>` : data.label;
  const activityLabel = getActivityLabel(data);
  return (
    <StyledListItem disabled={data.isDisabled} {...innerProps}>
      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(indent) }} />
      <FontAwesomeIcon color="var(--tecnea-blue)" fixedWidth icon={icon} size="sm" style={{ marginRight: 10 }} />
      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(`${label}${activityLabel}`) }} />
    </StyledListItem>
  );
};

export const StructureLevelValue = ({ data, innerProps }) => {
  if (isEmpty(data)) {
    return '';
  }
  const icon = getStructureLevelIcon(data);
  const activityLabel = getActivityLabel(data);
  return (
    <ItemOption {...innerProps} style={{ gridArea: ' 1/1/2/3' }}>
      <FontAwesomeIcon color="var(--tecnea-blue)" fixedWidth icon={icon} size="sm" style={{ marginRight: 10 }} />
      <Typography>
        {`${data.label}${activityLabel}`}
      </Typography>
    </ItemOption>
  );
};
