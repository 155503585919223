import { translate } from 'utils';
import { QUESTION_TYPE } from 'utils/constants';

const {
  mcq, dropDown, yesNo, trueFalse, imagePicker, rating, shortInput, longInput, dateInput, emailInput, phoneInput
} = QUESTION_TYPE;

export const buildAnswerType = answerType => {
  let answerDefault;
  switch (answerType) {
    case (mcq):
    case (dropDown):
      answerDefault = {
        answerText: '',
        isCorrect: false
      };
      return answerDefault;
    case (yesNo):
      answerDefault = [
        { answerText: translate('common.yes'), isCorrect: false },
        { answerText: translate('common.no'), isCorrect: false }
      ];
      return answerDefault;
    case (trueFalse):
      answerDefault = [
        { answerText: translate('question.trueAnswer'), isCorrect: false },
        { answerText: translate('question.falseAnswer'), isCorrect: false }
      ];
      return answerDefault;
    case (imagePicker):
      answerDefault = [{
        answerPicture: '',
        isCorrect: false
      }];
      return answerDefault;
    case (rating):
      answerDefault = [{
        minValue: 1,
        maxValue: 5,
        minValueLabel: '',
        maxValueLabel: '',
        isCorrect: null
      }];
      return answerDefault;
    case (shortInput):
    case (longInput):
    case (dateInput):
    case (emailInput):
    case (phoneInput):
      answerDefault = [{
        answerText: '',
        isCorrect: null
      }];
      return answerDefault;
    default:
      return null;
  }
};

export const defaultMcqAnswer = [
  {
    answerText: '',
    isCorrect: false
  },
  {
    answerText: '',
    isCorrect: false
  },
  {
    answerText: '',
    isCorrect: false
  },
  {
    answerText: '',
    isCorrect: false
  }
];
