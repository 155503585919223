import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { EDITION_PANEL_TYPES } from 'utils/constants';
import QuizEditionForm from '../QuizEditionForm/QuizEditionForm';
import QuizPageEditionForm from '../QuizPageEditionForm/QuizPageEditionForm';

const ParentForm = observer(({ data, type }) => {
  const renderSpecificForm = () => {
    switch (type) {
      case EDITION_PANEL_TYPES.QUIZ:
        return <QuizEditionForm formData={data} />;
      case EDITION_PANEL_TYPES.QUIZ_PAGES:
        return <QuizPageEditionForm formData={data} />;
      default:
        return '';
    }
  };

  return (
    <section>
      {renderSpecificForm()}
    </section>
  );
});

ParentForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired
};

export default ParentForm;
