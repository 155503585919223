import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  ListItemText,
  TextField
} from '@material-ui/core';
import {
  FormLine, RequiredSelectHack, StyledListItem
} from 'components';
import { SelectLabel } from 'components/Text';
import { FormHelper, translate } from 'utils';

import debounce from 'debounce-promise';
import { useStores } from 'hooks';
import AsyncSelect from 'react-select/async';

import { CompanyService } from 'services';
import { HeaderModal } from './_HeaderModal';

export const CreateUserModal = ({
  onClose, onConfirm, closeOnSubmit
}) => {
  const [formState, { text, email, checkbox }] = useFormState({
    accountActivated: false,
    mainCompany: null,
    roles: [],
    email: null
  });

  const { userStore } = useStores();
  const { scope } = userStore;
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (scope?.company) {
      setSelectedCompany(scope?.company);
    }
  }, [scope]);

  const handleAddCompany = useCallback(value => {
    setSelectedCompany(value);
  }, []);

  const handleValidateModal = e => {
    e.preventDefault();

    const emailErrors = formState.errors.email;

    if (!emailErrors) {
      onConfirm({
        login: formState.values.login,
        password: formState.values.password,
        person: {
          firstName: formState.values.firstName,
          lastName: formState.values.lastName,
          mainCompany: selectedCompany,
          contact: {
            contactEmails: formState.values.contactEmails,
            technicalEmail: formState.values.email
          }
        },
        accountActivated: formState.values.accountActivated
      });
    }

    if (closeOnSubmit) {
      onClose();
    }
  };

  useEffect(() => {
    if (!formState.values.accountActivated) {
      formState.errors.login = null;
    }
    // eslint-disable-next-line
  }, [formState.values.accountActivated, formState.errors.login]);

  const getAsyncCompaniesOptions = useCallback(inputValue => new Promise(resolve => {
    CompanyService
      .getCompanyOptions({ freeSearch: inputValue })
      .then(response => resolve(response));
  }), []);

  const debouncedCompaniesLoadOptions = debounce(getAsyncCompaniesOptions, 500);

  const CustomOption = ({ data, innerProps }) => (
    <StyledListItem {...innerProps}>
      <ListItemText primary={data.label} />
    </StyledListItem>
  );

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <>
          <FontAwesomeIcon icon={faUserPlus} />
          {translate('modalUser.newUser')}
        </>
      </HeaderModal>

      <DialogContent>
        <Grid container direction="column" style={{ minWidth: 400 }}>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={translate('common.userAccount')}
            {...checkbox('accountActivated')}
          />
          <TextField
            autoComplete="off"
            autoFocus
            error={formState.errors.lastName && formState.errors.lastName !== null}
            label={translate('common.lastName')}
            name="lastName"
            required
            {...text('lastName')}
          />
          <TextField
            autoComplete="new-password"
            error={formState.errors.firstName && formState.errors.firstName !== null}
            label={translate('common.firstName')}
            name="firstName"
            required
            {...text('firstName')}
          />
          <TextField
              autoComplete="new-password"
              error={formState.errors.contactEmails && formState.errors.contactEmails !== null}
              helperText={formState.errors.contactEmails}
              label={translate('common.contactEmails.modal')}
              name="contactEmails"
              {...text({
                name: 'contactEmails',
                // Additional validation for emails because useFormState validation stops at example@example
                validate: value => FormHelper.validateEmails(value)
              })}
          />

          <SelectLabel>{translate('common.company')}</SelectLabel>
          <FormLine>
            <AsyncSelect
              cacheOptions
              closeMenuOnSelect
              components={{ Option: CustomOption }}
              defaultOptions
              isClearable
              loadingMessage={() => translate('common.loading')}
              loadOptions={inputValue => debouncedCompaniesLoadOptions(inputValue)}
              menuPortalTarget={document.body}
              name="companies"
              noOptionsMessage={() => translate('errors.noOptions')}
              placeholder={translate('common.searchByInstitution')}
              required
              styles={{
                menu: base => ({ ...base, zIndex: 2000 }),
                menuPortal: basePortal => ({ ...basePortal, zIndex: 2000 })
              }}
              value={selectedCompany}
              onChange={handleAddCompany}
            />
          </FormLine>
          <RequiredSelectHack value={selectedCompany || ''} />
          {formState.values.accountActivated && (
          <>
            <TextField
                autoComplete="new-password"
                error={formState.errors.email && formState.errors.email !== null}
                helperText={formState.errors.email}
                label={translate('common.userModal.technicalEmail')}
                name="email"
                required
                type="email"
                {...email({
                  name: 'email',
                  // Additional validation for emails because useFormState validation stops at example@example
                  validate: value => FormHelper.validateEmail(value)
                })}
            />
            {/* This input is only used for forcing disabling autocomplete in chrome browser */}
            <input name="fakeInputForForcingDisablingAutocompleteChrome3" style={{ display: 'none' }} type="text" />
            <TextField
              autoComplete="new-password"
              disabled={!formState.values.accountActivated}
              error={formState.errors.login && formState.errors.login !== null}
              label={translate('common.login')}
              name="login"
              required={formState.values.accountActivated}
              {...text('login')}
            />
          </>
          )}

        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('common.create')}</Button>
      </DialogActions>
    </form>
  );
};

CreateUserModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

CreateUserModal.defaultProps = {
  closeOnSubmit: true
};
