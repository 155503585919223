import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  faBalanceScale,
  faEdit,
  faPlus
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, TableCell, Typography
} from '@material-ui/core';
import { TextError } from 'components/Text';
import {
  Button, CustomIcon, GenericListFilters, GenericTable, Wrapper
} from 'components/_commons';
import { useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { LegalStatusView } from 'models/LegalStatusView';
import { PageTitle } from 'pages';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'utils';

const listHeaders = (handleEdit, handleDelete) => [
  {
    name: 'name',
    label: translate('pageCountry.legalStatus.name'),
    template: row => (
      <TableCell key={`${row.name}_name`}>
        <Typography>{row.name}</Typography>
      </TableCell>
    ),
    width: '100%'
  },
  {
    name: 'isPublic',
    label: translate('pageCountry.legalStatus.isPublic'),
    template: row => (
      <TableCell key={`${row.name}_public`}>
        <Typography>{translate(row.isPublic ? 'common.yes' : 'common.no')}</Typography>
      </TableCell>
    )
  },
  {
    name: 'edit',
    label: translate('pageCountry.legalStatus.edit'),
    template: row => (
      <TableCell key={`${row.name}_edit`}>
        <IconButton
          color="primary"
          edge="end"
          onClick={e => {
            e.stopPropagation();
            handleEdit(row);
          }}
        >
          <FontAwesomeIcon icon={faEdit} size="xs" />
        </IconButton>
      </TableCell>
    )
  },
  {
    name: 'delete',
    label: translate('pageCountry.legalStatus.delete'),
    template: row => (
      <TableCell key={`${row.name}_delete`}>
        <IconButton
          color="primary"
          edge="end"
          onClick={e => {
            e.stopPropagation();
            handleDelete(row);
          }}
        >
          <FontAwesomeIcon icon={faTrash} size="xs" />
        </IconButton>
      </TableCell>
    )
  }
];

export const LegalStatusList = observer(({
  isLoading,
  legalStatuses,
  setLegalStatuses
}: {
  isLoading: boolean,
  legalStatuses: LegalStatusView[],
  setLegalStatuses: (statuses: LegalStatusView[]) => void
}) => {
  const displayModal = useModal();
  const [search, setSearch] = useState('');
  const { countryId } = useParams();

  const getNextIndex = useCallback(() => {
    const statuses = [...legalStatuses];
    if (!statuses || statuses.length === 0) return 1;
    statuses.sort((a, b) => b.index - a.index);
    if (statuses[statuses.length - 1].index < 1) return 1;
    const setLs = new Set(statuses.map(ls => ls.index));
    const length = setLs.size;
    for (let i = 1; i <= length; i++) {
      if (!setLs.has(i)) {
        return i;
      }
    }
    return length + 1;
  }, [legalStatuses]);

  const handleCreate = useCallback(() => {
    displayModal({
      type: 'CREATE_LEGAL_STATUS',
      onConfirm: legalStatusCreated => {
        const newLegalStatusList = [...legalStatuses];
        newLegalStatusList.push({ ...legalStatusCreated, countryId, index: getNextIndex() });
        setLegalStatuses(newLegalStatusList);
      }
    });
  }, [displayModal, legalStatuses, countryId, setLegalStatuses, getNextIndex]);

  const handleEdit = useCallback((legalStatusInEdition: LegalStatusView) => {
    displayModal({
      type: 'CREATE_LEGAL_STATUS',
      givenLegalStatus: legalStatusInEdition,
      onConfirm: legalStatusEdited => {
        const newLegalStatusList = legalStatuses.filter(ls => ls.index !== legalStatusEdited.index);
        newLegalStatusList.push(legalStatusEdited);
        setLegalStatuses(newLegalStatusList);
      }
    });
  }, [displayModal, legalStatuses, setLegalStatuses]);

  const handleDelete = useCallback(suppressedLegalStatus => {
    displayModal({
      type: 'WARNING',
      text: `${translate('pageCountry.legalStatus.confirmation.delete.text')} ${suppressedLegalStatus.name}`,
      title: translate('pageCountry.legalStatus.confirmation.delete.title'),
      buttonCancel: <Button>{translate('button.cancel')}</Button>,
      onConfirm: () => {
        const newLegalStatusList = legalStatuses.filter(ls => ls.index !== suppressedLegalStatus.index);
        newLegalStatusList.push({ ...suppressedLegalStatus, activated: false });
        setLegalStatuses(newLegalStatusList);
      }
    });
  }, [displayModal, legalStatuses, setLegalStatuses]);

  const CreateLegalStatusButton = (
    <Button
      color="primary"
      startIcon={<CustomIcon icon={faPlus} />}
      variant="contained"
      onClick={() => handleCreate()}
    >
      {translate('button.createLegalStatus')}
    </Button>
  );

  return (
    <Wrapper>
      <PageTitle title={translate('pageCountry.legalStatus.title')} titleRight={CreateLegalStatusButton} />
      <GenericListFilters
        search={search}
        setSearch={setSearch}
      />
      {(legalStatuses.length === 0 && isLoading) && (
        <Grid alignItems="center" container direction="column">
          <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="5x" spin />
        </Grid>
      )}
      {(legalStatuses.length === 0 && !isLoading) && (
        <Grid alignItems="center" container direction="column">
          <CustomIcon
            color="var(--primary-color)"
            icon={faBalanceScale}
            secondSize="3x"
            size="3x"
          />
          <TextError>{translate('errors.noLegalStatuses')}</TextError>
        </Grid>
      )}
      {(legalStatuses.length !== 0) && (
        <GenericTable
          dataCy="legalStatusList"
          headers={listHeaders(handleEdit, handleDelete)}
          rows={legalStatuses
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(ls => ls.name.includes(search) && ls.activated === true)}
        />
      )}
    </Wrapper>
  );
});
