import {
  faCopy, faEdit, faEye, faFileExport, faHistory, faLink, faPollH, faSpinner, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Grid,
  IconButton, TableCell,
  Tooltip
} from '@material-ui/core';
import {
  DeleteButton,
  GenericListFilters,
  GenericTable,
  SkeletonLine,
  TextError,
  Wrapper
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QuizService } from 'services';
import shortid from 'shortid';
import { DocumentHelper, formatDate, translate } from 'utils';
import { API_ROUTES, DATAFORM_URL, ROUTES } from 'utils/constants';

import { QuizListFilters } from './QuizListFilters';

const getListHeaders = (
  handleViewQuiz,
  handleViewQuizHistory,
  handleEditQuiz,
  handleCopyQuiz,
  handleCreateLink,
  handleDeleteQuiz
) => ([
  {
    name: 'title',
    label: translate('common.title'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.title}
      </TableCell>
    )
  },
  {
    name: 'description',
    label: translate('common.description'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.description || ''}
      </TableCell>
    )
  }, {
    name: 'lastModification',
    label: translate('common.lastModification'),
    width: '178px',
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${formatDate(row.creationDate) || ''}`}
        <br />
        {`${row.creationUser || ''}`}
      </TableCell>
    )
  }, {
    name: 'code',
    label: translate('common.code'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.code || ''}
      </TableCell>
    )
  },
  {
    name: 'quizActions',
    label: translate('common.actions'),
    width: '306px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>

          <Grid item>
            <Tooltip title={translate('common.preview')}>
              <IconButton edge="end" onClick={e => { e.stopPropagation(); handleViewQuiz(row); }}>
                <FontAwesomeIcon icon={faEye} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('common.editItem')}>
              <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleEditQuiz(row); }}>
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('common.copyItem')}>
              <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleCopyQuiz(row); }}>
                <FontAwesomeIcon icon={faCopy} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('common.showItemHistory')}>
              <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleViewQuizHistory(row); }}>
                <FontAwesomeIcon icon={faHistory} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('button.delete')}>
              <DeleteButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleDeleteQuiz(row.id); }}>
                <FontAwesomeIcon color="var(--error-color)" icon={faTrashAlt} size="xs" />
              </DeleteButton>
            </Tooltip>
          </Grid>

          {row.isIndependent && (
            <Grid item>
              <Tooltip title={translate('common.linkItem')}>
                <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleCreateLink(row); }}>
                  <FontAwesomeIcon icon={faLink} size="xs" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

        </Grid>
      </TableCell>
    )
  }
]);

export const QuizList = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const displayModal = useModal();
  const history = useHistory();
  const { quizStore, editionStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [listSize, setListSize] = useState();

  const filterKey = 'quizList';

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  const toggleExportQuizButton = useCallback(() => {
    if (quizList.length > 0 && !isLoading) {
      return setIsButtonDisabled(false);
    }
    return setIsButtonDisabled(true);
  }, [isLoading, quizList]);

  useEffect(() => {
    toggleExportQuizButton();
  }, [toggleExportQuizButton]);

  const loadQuizList = useCallback(() => {
    const finalFilters = {};
    filters.forEach(filter => {
      if (filter.key === 'isCertifying') {
        finalFilters[filter.key] = true;
      } else if (filter.key === 'category') {
        finalFilters[filter.key] = filter.label;
      } else {
        finalFilters[filter.key] = filter.label;
      }
    });

    setIsLoading(true);

    QuizService.getQuizList({ freeSearch: search, ...finalFilters }, currentPage)
      .then(response => {
        let allQuiz = response.content;
        if (currentPage > 0) {
          allQuiz = [].concat(...quizList, allQuiz);
        }
        setQuizList(allQuiz);
        setMaxPage(response.totalPages);
        setListSize(response.totalElements);
      })
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [quizList, search, filters, currentPage, enqueueSnackbar]);

  const refreshQuizList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 0) {
      loadQuizList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, loadQuizList]);

  useEffect(() => {
    refreshQuizList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffect(() => {
    loadQuizList();
    // eslint-disable-next-line
  }, [currentPage]);

  const handleLoadMore = useCallback(() => {
    if (!isLoading && currentPage + 1 < maxPage) {
      return setCurrentPage(currentPage + 1);
    }
    return null;
  }, [isLoading, currentPage, maxPage]);

  useEffect(() => {
    quizStore.toggleSubmitQuizForm(false);
  }, [quizStore]);

  const handleViewQuiz = useCallback(quiz => {
    editionStore.setEditionMode(false);
    history.push({
      pathname: API_ROUTES.ADMIN_QUIZ_DETAIL(quiz.id),
      state: {
        isEditionMode: false
      }
    });
  }, [history, editionStore]);

  const handleViewQuizHistory = useCallback(quiz => displayModal({
    type: 'HISTORY_MODAL',
    dataService: () => QuizService.getQuizHistory(quiz.id),
    viewLink: instanceId => {
      editionStore.setEditionMode(true);
      history.push({
        pathname: API_ROUTES.ADMIN_QUIZ_DETAIL(quiz.id),
        search: `?instance=${instanceId}`,
        state: {
          isEditionMode: false
        }
      });
    }
  }), [displayModal, editionStore, history]);

  const handleEditQuiz = useCallback(quiz => {
    editionStore.setEditionMode(true);
    editionStore.setFormType('quiz');
    history.push({
      pathname: API_ROUTES.ADMIN_QUIZ_DETAIL(quiz.id),
      state: {
        isEditionMode: true
      }
    });
  }, [history, editionStore]);

  const handleCopyQuiz = useCallback(quiz => {
    editionStore.setEditionMode(true);
    editionStore.setFormType('quiz');
    quizStore.loadQuiz(quiz.id, null, true);
    history.push({
      pathname: ROUTES.ADMIN_QUIZ_CREATION,
      state: {
        isEditionMode: true,
        isCopyMode: true
      }
    });
  }, [history, editionStore, quizStore]);

  const handleCreateLink = useCallback(quiz => {
    const text = `${DATAFORM_URL}quiz/${quiz.id}`;
    if (!navigator.clipboard) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        //
      }
      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar(translate('confirms.linkCopied'), { variant: 'success' });
    });
  }, [enqueueSnackbar]);

  const handleDeleteQuiz = useCallback(quizId => {
    displayModal({
      type: 'WARNING',
      title: translate('warnings.warning'),
      text: translate('warnings.quiz.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        QuizService.deleteQuiz(quizId)
          .then(() => {
            enqueueSnackbar(translate('confirms.quizList.delete'), { variant: 'success' });
            if (currentPage > 1) setCurrentPage(0);
            else refreshQuizList();
          })
          .catch(error => enqueueSnackbar(error.message || error, { variant: 'error' }));
      }
    });
  }, [currentPage, displayModal, enqueueSnackbar, refreshQuizList]);

  const handleCreateQuiz = useCallback(() => {
    editionStore.setEditionMode(true);
    editionStore.setFormType('quiz');
    quizStore.newQuiz();
    history.push({
      pathname: ROUTES.ADMIN_QUIZ_CREATION,
      state: {
        isEditionMode: true
      }
    });
  }, [history, editionStore, quizStore]);

  const handleExportQuiz = useCallback(() => {
    setIsExporting(true);

    const searchFilters = {
      freeSearch: search
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of filters) {
      searchFilters[filter.key] = filter.label;
    }

    const MAX_SIZE_EXPORT = 1500;
    if (listSize <= MAX_SIZE_EXPORT) {
      QuizService.exportQuiz(searchFilters)
        .then(response => {
          const downloadLink = document.createElement('a');
          downloadLink.download = response.name;
          downloadLink.href = DocumentHelper.getExcelWithBase64(response.base64Content);
          downloadLink.click();
        })
        .catch(() => enqueueSnackbar(translate('errors.exportQuizError'), { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => setIsExporting(false));
    } else {
      QuizService.exportQuizAsync(searchFilters)
        .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success', autoHideDuration: 5000 }))
        .catch(response => enqueueSnackbar(response || translate('errors.exportQuizError'), { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => {
          setIsExporting(false);
        });
    }
  }, [search, filters, enqueueSnackbar, listSize]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <QuizListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderButtonContainer = () => (
    <Grid item>
      {isButtonDisabled
        ? (
          <Tooltip title={translate('errors.noDataToExport')}>
            <span>
              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={<FontAwesomeIcon icon={faFileExport} />}
                style={{ marginRight: 10 }}
                variant="contained"
              >
                {translate('button.export')}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={translate('quiz.exportQuiz')}>
            <span>
              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={(
                  <FontAwesomeIcon
                    icon={isExporting ? faSpinner : faFileExport}
                    spin={isExporting}
                  />
                )}
                style={{ marginRight: 10 }}
                variant="contained"
                onClick={handleExportQuiz}
              >
                {translate('button.export')}
              </Button>
            </span>
          </Tooltip>
        )}
      <Button
        color="primary"
        startIcon={<FontAwesomeIcon icon={faPollH} />}
        variant="contained"
        onClick={handleCreateQuiz}
      >
        {translate('button.createQuiz')}
      </Button>
    </Grid>
  );

  return (
    <>
      <Wrapper>
        <PageTitle
          title={translate('pageQuizList.title')}
          titleRight={renderButtonContainer()}
        />

        <GenericListFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-quizList-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltipInfo="pageQuizList.searchTooltip"
          withDrawer
        />

        <div data-tour="step-admin-quizList">
          {!isLoading && quizList.length === 0
            ? (
              <Grid alignItems="center" container direction="column">
                <FontAwesomeIcon color="var(--primary-color)" icon={faPollH} size="3x" />
                <TextError>{translate('errors.noQuiz')}</TextError>
              </Grid>
            ) : (
              <GenericTable
                dataCy="quizList"
                hasMore={currentPage + 1 < maxPage}
                headers={
                  getListHeaders(
                    handleViewQuiz,
                    handleViewQuizHistory,
                    handleEditQuiz,
                    handleCopyQuiz,
                    handleCreateLink,
                    handleDeleteQuiz
                  )
                }
                isLoading={isLoading}
                loadMore={handleLoadMore}
                rows={quizList}
                total={listSize}
              />
            )}
        </div>

        {isLoading && quizList.length === 0 && <SkeletonLine />}
      </Wrapper>
    </>
  );
});
