import { format } from 'date-fns';
import i18n from 'i18next';
import { isMobileOnly } from 'react-device-detect';
import shortid from 'shortid';
import {
  DocumentHelper, FormHelper,
  RequestHelper, UserHelper
} from './helpers';
import { KeyCloakUtils } from './keycloak/keycloakUtils';

export const pluralize = (string, length, defaultValue) => (
  length > 1 ? `${string}s` : (defaultValue || string)
);

export const isMobile = () => isMobileOnly;

export const formatDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (!date) return '';
  return format(new Date(date), dateFormat);
};

const translationNotFound = 'errors.translationNotFound';

export const translate = (key, options) => {
  if (i18n.exists(key)) {
    const translation = i18n.t(key, options);
    if (translation) {
      return translation;
    }
  }
  if (i18n.exists(translationNotFound)) {
    return i18n.t(translationNotFound);
  }
  return translationNotFound.substring(translationNotFound.lastIndexOf('.') + 1);
};

export const replaceElementInNewList = (list, newItem, equalityFunction) => list.map(element => (equalityFunction(newItem, element) ? newItem : element));

export const generateTempId = () => `tempId -${shortid.generate()}`;

export const isTempId = id => id.startsWith('tempId');

export const checkRegexMismatch = (rule, value) => {
  // If no rule is specified, the regex is always considered a match
  if (!rule) {
    return false;
  }
  // If no value is specified, the regex is always considered a mismatch
  if (!value) {
    return true;
  }
  return !new RegExp(`^${rule}$`).test(value);
};

export {
  DocumentHelper,
  FormHelper, KeyCloakUtils, RequestHelper,
  UserHelper
};
