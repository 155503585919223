import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: ${props => props.width};
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

export const RequiredSelectHack = ({ value, width }) => (
  <StyledRequiredSelectHack
    autoComplete="off"
    required
    tabIndex={-1}
    value={value}
    width={width}
    // eslint-disable-next-line react/jsx-no-bind
    onChange={() => {}}
  />
);

RequiredSelectHack.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  width: PropTypes.string
};

RequiredSelectHack.defaultProps = {
  value: '',
  width: '100%'
};
