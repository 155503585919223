import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import {
  Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Tooltip
} from '@material-ui/core';
import { CustomTooltip, SelectField } from '../../_commons';
import { TextField } from '../../forms/inputs/InputField/InputField';

export const TranslationListFilters = ({ currentFilters, setCurrentFilters }) => {
  const typeValue = currentFilters.find(f => f.key === 'categories') || null;
  const getLabel = value => `pageTranslationList.filter.${value}`;
  const getTooltip = value => `pageTranslationList.filter.tooltip.${value}`;

  const searchItems = ['keySearch', 'sourceSearch', 'targetSearch'];

  const typeValues = [
    { key: 'categories', value: 'FRONT_LABEL', label: translate(getLabel('FRONT_LABEL')) },
    { key: 'categories', value: 'SERVER_MESSAGE', label: translate(getLabel('SERVER_MESSAGE')) },
    { key: 'categories', value: 'REFERENCE_DATA', label: translate(getLabel('REFERENCE_DATA')) }
  ];

  const stateValues = [
    { key: 'toTranslate', value: 'toTranslate', label: translate(getLabel('toTranslate')) },
    { key: 'toSubmit', value: 'toSubmit', label: translate(getLabel('toSubmit')) },
    { key: 'toDecide', value: 'toDecide', label: translate(getLabel('toDecide')) },
    { key: 'toDeploy', value: 'toDeploy', label: translate(getLabel('toDeploy')) },
    { key: 'toSendBack', value: 'toSendBack', label: translate(getLabel('toSendBack')) }
  ];

  const handleChangeFilter = (item, checked) => {
    const clearedFilters = currentFilters.filter(i => i.key !== item.key);
    checked && clearedFilters.push(item);
    setCurrentFilters(clearedFilters);
  };

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    const cleanedFilters = currentFilters.filter(f => f.key !== name);
    value && cleanedFilters.push({ key: name, value, label: value });
    setCurrentFilters(cleanedFilters);
  }, [currentFilters, setCurrentFilters]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid alignItems="center" container direction="row" item spacing={1}>
        {Boolean(typeValue) && (
          <Grid item>
            <CustomTooltip text={translate(getTooltip(typeValue.value))} />
          </Grid>
        )}
        <Grid item xs>
          <SelectField
            label="pageTranslationList.filter.translationType.label"
            name="type"
            noMargin
            options={typeValues}
            placeholder="pageTranslationList.filter.translationType.placeholder"
            value={typeValue}
            onChange={item => handleChangeFilter(item || { key: 'categories' }, Boolean(item))}
          />
        </Grid>
      </Grid>
      {searchItems.map(searchItem => (
        <Grid item key={`${searchItem}_name`}>
          <TextField
            InputProps={{ autoComplete: 'off' }}
            label={translate(getLabel(searchItem))}
            name={searchItem}
            value={currentFilters.find(f => f.key === searchItem)?.value || null}
            onChange={handleChangeInput}
          />
        </Grid>
      ))}
      <Grid alignItems="center" container direction="row" item spacing={1}>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {translate('pageTranslationList.filter.translationState.label')}
            </FormLabel>
            <FormGroup>
              {stateValues.map(stateValue => (
                <Tooltip key={stateValue.key} title={translate(getTooltip(stateValue.value))}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={Boolean(currentFilters.find(f => f.key === stateValue.key))}
                        color="primary"
                        name={stateValue.value}
                        onChange={event => handleChangeFilter(stateValue, event.target.checked)}
                      />
                    )}
                    label={stateValue.label}
                  />
                </Tooltip>
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

TranslationListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
