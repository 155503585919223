import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionGroupUrl = `${API_V2_URL}/institution-groups`;

// GET
const getInstitutionGroup = institutionGroupId => RequestHelper.GET(`${institutionGroupUrl}/${institutionGroupId}`);

// POST
const createInstitutionGroup = (institutionGroup, activity) => RequestHelper.POST(
  `${institutionGroupUrl}?activity=${activity}`,
  institutionGroup
);

const updateInstitutionGroup = (institutionGroupId, institutionGroup, activity) => RequestHelper.PUT(
  `${institutionGroupUrl}/${institutionGroupId}?activity=${activity}`,
  institutionGroup
);

// DELETE
const deleteInstitutionGroup = institutionGroupId => RequestHelper.DELETE(`${institutionGroupUrl}/${institutionGroupId}`);

export const InstitutionGroupService = {
  getInstitutionGroup,
  createInstitutionGroup,
  updateInstitutionGroup,
  deleteInstitutionGroup
};
