import { Datepicker } from 'components';
import { isValid } from 'date-fns';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { QUESTION_TYPES_FROM_API, buildDefaultAndTraineeAnswers } from '../../QuizHelper';

const DateQuestion = observer(({ currentQuestionData, isReadOnlyQuestion }) => {
  const { dateInput } = QUESTION_TYPES_FROM_API;

  const { examinationStore } = useStores();

  const { currentPage } = examinationStore;

  const [currentValue, setCurrentValue] = useState(null);

  const buildAnswers = useCallback(() => {
    setCurrentValue(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleChangeDate = useCallback(date => {
    setCurrentValue(date);

    isValid(date)
      ? examinationStore.toggleQuestionValidity({ questionType: dateInput, status: true })
      : examinationStore.toggleQuestionValidity({ questionType: dateInput, status: false });

    examinationStore.setTraineeAnswers(currentPage, currentQuestionData, date);
  }, [currentPage, currentQuestionData, examinationStore, dateInput]);

  return (
    <>
      <Datepicker
        autoComplete="off"
        dataCy="dateQuestion"
        disabled={isReadOnlyQuestion}
        label="common.writeDate"
        name="dateQuestion"
        value={currentValue}
        onChange={date => handleChangeDate(date)}
      />
    </>
  );
});

export default DateQuestion;

DateQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

DateQuestion.defaultProps = {
  currentQuestionData: {}
};
