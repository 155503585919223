import { DocumentHelper, translate } from 'utils';

const QUIZ_STATUS = {
  notRunning: 'notRunning',
  running: 'running',
  ended: 'ended',
  displayingResults: 'displayingResults'
};

const EXAM_KEY = 'examId';

const QUESTION_TYPES_FROM_API = {
  checkBox: 'checkbox',
  commentInput: 'comment',
  dateInput: 'date',
  dropDown: 'dropdown',
  emailInput: 'email',
  imageRadioPicker: 'imagepicker',
  phoneInput: 'phone',
  radioGroup: 'radiogroup',
  scoreRating: 'rating',
  textInput: 'text'
};

const {
  checkBox, radioGroup, dropDown, textInput, commentInput, imageRadioPicker, scoreRating, dateInput, emailInput, phoneInput
} = QUESTION_TYPES_FROM_API;

const QUESTION_TYPES = {
  mcq: 'BASIC',
  dropDown: 'SELECT',
  yesNo: 'YES_NO',
  trueFalse: 'TRUE_FALSE',
  rating: 'SCORE',
  shortInput: 'SHORT_INPUT',
  longInput: 'LONG_INPUT',
  imagePicker: 'IMAGE'
};

const { imagePicker } = QUESTION_TYPES;

const buildImagePickers = quiz => {
  quiz.pages.forEach(page => {
    page.questions.forEach(question => {
      if (question.questionType === imagePicker) {
        question.choices.map(choice => {
          // eslint-disable-next-line no-param-reassign
          choice.imageLink = DocumentHelper.getPNGWithBase64(choice.imageLink);
          return choice;
        });
        return null;
      }
      return page;
    });
    return quiz.pages;
  });
  return quiz;
};

const editionModeImageQuestionBuilder = questions => questions.map(q => {
  if (q.questionType.value === 'IMAGE') {
    q.choices.map(choice => {
      if (!choice.imageLink.startsWith('data:image')) {
        choice.imageLink = DocumentHelper.getPNGWithBase64(choice.imageLink);
        return choice;
      }
      return null;
    });
    return q;
  }
  return q;
});

const getSmallDescription = (description, size = 28) => {
  if (!description) return '';
  if (description.length <= size) return description;

  return `${description.substring(0, size)}...`;
};

const buildDefaultAndTraineeAnswers = questionData => {
  const { type, answers, traineeAnswer } = questionData;

  const newDefaultAnswers = [...answers];
  let selectValue = 0;

  switch (type) {
    case (checkBox):
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          answerText: newDefaultAnswers[index].answerText,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0
            ? Boolean(traineeAnswer.find(ans => ans === answer.answerText))
            : false
        };
        return newDefaultAnswers;
      });
      break;
    case (radioGroup): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          answerText: newDefaultAnswers[index].answerText,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0 ? Boolean(traineeAnswer === answer.answerText) : false
        };
        return newDefaultAnswers;
      });
      break;
    }
    case (dropDown): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          label: newDefaultAnswers[index].answerText,
          value: selectValue += 1,
          answerText: newDefaultAnswers[index].answerText,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0 ? Boolean(traineeAnswer === answer.answerText) : false
        };
        return newDefaultAnswers;
      });
      break;
    }
    case (textInput):
    case (commentInput):
    case (dateInput):
    case (emailInput):
    case (phoneInput): {
      const freeAnswer = traineeAnswer && traineeAnswer.length !== 0 ? traineeAnswer : '';
      return freeAnswer;
    }
    case (imageRadioPicker): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          answerPicture: DocumentHelper.getPNGWithBase64(newDefaultAnswers[index].answerPicture),
          pictureId: newDefaultAnswers[index].id,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0 ? Boolean(traineeAnswer.includes(answer.id)) : false
        };
        return newDefaultAnswers;
      });
      break;
    }
    case scoreRating: {
      const freeRatingAnswer = {
        maxValue: answers[0].maxValue,
        maxValueLabel: answers[0].maxValueLabel,
        minValue: answers[0].minValue,
        minValueLabel: answers[0].minValueLabel,
        answeredValue: traineeAnswer || null,
        isItemAnswered: traineeAnswer && traineeAnswer >= 0
          ? Boolean(traineeAnswer >= answers[0].minValue && traineeAnswer <= answers[0].maxValue)
          : false
      };
      return freeRatingAnswer;
    }
    default:
      return null;
  }

  return newDefaultAnswers;
};

const buildQuizWarningMessage = quizModel => {
  const { allowRepeat, repeatLimit, remainingTries } = quizModel;
  const canRepeatTheQuizInfinite = allowRepeat && !repeatLimit;
  const canRepeatTheQuiz = allowRepeat && remainingTries <= repeatLimit;

  if (canRepeatTheQuizInfinite) {
    return {
      warning: translate('quiz.infiniteTries'),
      isStartDisabled: false
    };
  }

  if (canRepeatTheQuiz) {
    if (remainingTries > 1) {
      return {
        warning: translate('quiz.remainingTries', { remainingTries }),
        isStartDisabled: false
      };
    }

    if (remainingTries === 1) {
      return {
        warning: translate('quiz.onlyOneTryLeft'),
        isStartDisabled: false
      };
    }
  }

  if (!allowRepeat && remainingTries === 1) {
    return {
      warning: translate('quiz.onlyOneTry'),
      isStartDisabled: false
    };
  }

  return {
    warning: translate('quiz.noMoreTry'),
    isStartDisabled: true
  };
};

const findSmallestCategories = currentPage => {
  let smallestCat = [];
  let questionCountSum = currentPage?.categoryOptions?.length === 1 ? currentPage?.categoryOptions[0]?.questionCount : 0;

  const ascQuestionCategories = currentPage?.categoryOptions?.length > 0
    && [...currentPage.categoryOptions]
      .sort((prevValue, currValue) => (prevValue.questionCount > currValue.questionCount ? 1 : -1));

  if (ascQuestionCategories && ascQuestionCategories.length > 1) {
    for (let i = 0; i < currentPage.nbCategories; i++) {
      questionCountSum += ascQuestionCategories[i].questionCount;
    }
    for (let i = 0; i < currentPage.nbCategories; i++) {
      if (currentPage.nbQuestions > questionCountSum) {
        smallestCat.push(ascQuestionCategories[i]);
      } else {
        smallestCat = [];
      }
    }
  } else if (ascQuestionCategories.length === 1 && ascQuestionCategories[0].questionCount < currentPage.nbQuestions) {
    questionCountSum = ascQuestionCategories[0].questionCount;

    smallestCat = [...ascQuestionCategories];
  } else {
    smallestCat = [];
  }

  return { questionCountSum, smallestCat };
};

const isAnonymous = data => {
  let isQuizAnonymous = { field: null, status: false };

  if (data.trainee && data.trainee.length > 0) {
    isQuizAnonymous.field = data.trainee;
    return isQuizAnonymous;
  }
  if (data.traineeEmail && data.traineeEmail.length > 0) {
    isQuizAnonymous.field = `${data.traineeFirstName} ${data.traineeLastName}`;
    return isQuizAnonymous;
  }
  isQuizAnonymous = { field: null, status: true };
  return isQuizAnonymous;
};

export {
  EXAM_KEY,
  QUESTION_TYPES_FROM_API, QUIZ_STATUS, buildDefaultAndTraineeAnswers, buildImagePickers, buildQuizWarningMessage, editionModeImageQuestionBuilder, findSmallestCategories, getSmallDescription, isAnonymous
};
