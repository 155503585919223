import {
  faDesktopAlt,
  faFileExclamation,
  faStoreAlt,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from '@material-ui/core';
import {
  CompanyList,
  SubscriptionList,
  UserList,
  Wrapper
} from 'components';
import { PurchaseOrderList } from 'components/PurchaseOrder/PurchaseOrderList/PurchaseOrderList';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Link, useLocation
} from 'react-router-dom';
import shortid from 'shortid';
import { UserHelper, translate } from 'utils';
import { ROUTES, VALIDATOR_ROLES } from 'utils/constants';

const PageAdministration = () => {
  const location = useLocation();

  const ADMINISTRATION_TABS = [
    {
      component: <Tab
        component={Link}
        data-cy="adminCompany"
        data-tour="step-admin-company"
        icon={<FontAwesomeIcon icon={faStoreAlt} size="2x"/>}
        key={shortid.generate()}
        label={translate('pageAdmin.tabCompany')}
        to={ROUTES.COMPANY_LIST}
      />,
      accessRights: VALIDATOR_ROLES,
      urlName: 'societes',
      childComponent: <CompanyList/>
    },
    {
      component: <Tab
        component={Link}
        data-cy="adminUsers"
        data-tour="step-admin-users"
        icon={<FontAwesomeIcon icon={faUsers} size="2x"/>}
        key={shortid.generate()}
        label={translate('pageAdmin.tabUser')}
        to={ROUTES.USER_LIST}
      />,
      accessRights: VALIDATOR_ROLES,
      urlName: 'utilisateurs',
      childComponent: <UserList/>
    },
    {

      component: <Tab
        component={Link}
        data-cy="adminSubscriptions"
        data-tour="step-admin-subscriptions"
        icon={<FontAwesomeIcon icon={faDesktopAlt} size="2x"/>}
        key={shortid.generate()}
        label={translate('button.accessApp')}
        to={ROUTES.SUBSCRIPTION_LIST}
      />,
      accessRights: VALIDATOR_ROLES,
      urlName: 'demande-acces',
      childComponent: <SubscriptionList/>
    },
    {
      component: <Tab
        component={Link}
        data-cy="adminPurchaseOrder"
        data-tour="step-admin-purchase-order"
        icon={<FontAwesomeIcon icon={faFileExclamation} size="2x"/>}
        key={shortid.generate()}
        label={translate('button.purchaseOrder')}
        to={ROUTES.PURCHASE_ORDER_LIST}
      />,
      accessRights: VALIDATOR_ROLES,
      urlName: 'bons-de-commande',
      childComponent: <PurchaseOrderList/>
    }
  ];

  const currentLocation = location.pathname.split('/');
  const accessibleTabs = ADMINISTRATION_TABS.filter(tab => UserHelper.hasAccessRight(tab.accessRights));
  const currentTabMapped = accessibleTabs.findIndex(tab => tab.urlName === currentLocation[currentLocation.length - 1]);

  const [currentTab, setCurrentTab] = useState(currentTabMapped > 0 ? currentTabMapped : 0);

  const { editionStore } = useStores();

  const resetEditionMode = useCallback(() => {
    runInAction(() => {
      editionStore.editionMode = false;
    });
  }, [editionStore]);

  useEffect(() => {
    currentTabMapped > 0 ? setCurrentTab(currentTabMapped) : setCurrentTab(0);

    resetEditionMode();
  }, [currentTabMapped, resetEditionMode]);

  const handleTabChange = useCallback((_, tab) => {
    setCurrentTab(tab);
  }, []);

  return (
    <Wrapper>
      <Tabs
        aria-label={translate('pageAdmin.title')}
        centered
        data-cy="adminTabs"
        data-tour="step-admin-more"
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        {accessibleTabs.map(tab => tab.component)}
      </Tabs>
      <section>
        {accessibleTabs[currentTab].childComponent}
      </section>
    </Wrapper>
  );
};

export default PageAdministration;
