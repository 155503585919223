import { faDesktopAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import {
  GenericListContainer, SkeletonLine, TextError
} from 'components';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { UserService } from 'services';
import { translate } from 'utils';
import SubscriptionTable from './SubscriptionTable';

export const SubscriptionList = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionListSize, setSubscriptionListSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);

  const getSubscriptionList = useCallback(() => {
    setIsLoading(true);
    UserService.getSubscriptions({ freeSearch: '', page: currentPage })
      .then(response => {
        if (response) {
          let allSubscriptions = response.content;
          if (currentPage > 1) {
            allSubscriptions = [].concat(...subscriptionList, allSubscriptions);
          }
          setSubscriptionList(allSubscriptions);
          setSubscriptionListSize(response.totalElements);
          setMaxPage(response.totalPages);
        }
      })
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  // eslint-disable-next-line
  }, [currentPage]);

  const refreshSubscriptionList = useCallback(() => {
    if (currentPage === 1) {
      getSubscriptionList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, getSubscriptionList]);

  useEffect(() => {
    refreshSubscriptionList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSubscriptionList();
  }, [currentPage, getSubscriptionList]);

  const handleLoadMore = useCallback(() => {
    if (!isLoading && currentPage + 1 < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [isLoading, currentPage, maxPage]);

  const handleRowClick = useCallback(row => history.push(`/administration/demande-acces/${row.hashId}`), [history]);

  return (
    <GenericListContainer>
      <PageTitle title={translate('pageSubscriptionList.title')} />

      <div data-tour="step-admin-subscriptionList">
        {!isLoading && subscriptionList.length === 0
          ? (
            <Grid alignItems="center" container direction="column">
              <FontAwesomeIcon color="var(--primary-color)" icon={faDesktopAlt} size="3x" />
              <TextError>{translate('errors.noSubscriptions')}</TextError>
            </Grid>
          ) : (
            <SubscriptionTable
              dataCy="subscriptionList"
              hasMore={(currentPage + 1) < maxPage}
              isFromAdmin
              loadMore={handleLoadMore}
              subscriptions={subscriptionList}
              total={subscriptionListSize}
              onRowClick={handleRowClick}
            />
          )}
      </div>

      {isLoading && subscriptionList.length === 0 && <SkeletonLine />}
    </GenericListContainer>
  );
};
