import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const StyledContainer = styled(Grid)`
  background-color: var(--warning-color);
  border-radius: 2px;
  padding: 10px;
`;

const StyledFormActions = styled('div')`
  align-items: center;
`;

export const WarningHeader = (({ children, icon }) => (
  <StyledFormActions id="warning">
    <StyledContainer alignItems="center" container justifyContent="center">
      <Grid item justifyContent="center">
        <FontAwesomeIcon
          color="var(--white)"
          icon={icon}
        />
      </Grid>
      <Grid item justifyContent="center" marginLeft="10px">
        {children}
      </Grid>
    </StyledContainer>
  </StyledFormActions>
));

WarningHeader.defaultProps = {
  icon: faExclamationTriangle
};
