import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, IconButton,
  PopperPlacementType,
  PropTypes,
  Tooltip
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React, { useCallback, useRef, useState } from 'react';
import { translate } from 'utils';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const DropdownButton = ({
  buttonStyle = {},
  children,
  color = 'primary',
  disabled = false,
  icon,
  iconSize = 'xs',
  isIconOnly = false,
  isLoading = false,
  label = '',
  placement = 'bottom-end',
  popperMargin = '0',
  tooltip
}: {
  buttonStyle?,
  children,
  color?: PropTypes.Color,
  disabled?: boolean,
  icon,
  iconSize?: SizeProp,
  isIconOnly?: boolean,
  isLoading?: boolean,
  label?: string,
  placement?: PopperPlacementType,
  popperMargin?: string,
  tooltip?: string
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const button = (isIconOnly)
    ? (
      <span ref={anchorRef}>
        <IconButton
          color={color}
          disabled={disabled || isLoading}
          style={buttonStyle}
          onClick={handleToggle}
        >
          <FontAwesomeIcon fixedWidth icon={isLoading ? faSpinner : icon} size={iconSize} spin={isLoading} />
        </IconButton>
      </span>
    )
    : (
      <Button
        color={color}
        disabled={disabled || isLoading}
        ref={anchorRef}
        startIcon={icon && <FontAwesomeIcon icon={isLoading ? faSpinner : icon} spin={isLoading} />}
        style={buttonStyle}
        variant="contained"
        onClick={handleToggle}
      >
        {translate(label)}
      </Button>
    );

  return (
    <>
      {tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button }
      <Popper
        anchorEl={anchorRef.current}
        open={isOpen}
        placement={placement}
        style={{ zIndex: 110 }}
        transition
        onClick={handleClose}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top center' }}
          >
            <Paper style={{ padding: '1rem', margin: popperMargin }}>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownButton;
