import { RequestHelper } from 'utils';
import { API_V2_URL } from 'utils/constants';

const quizUrl = `${API_V2_URL}/quiz`;
const examinationsUrl = `${API_V2_URL}/examinations`;

// GET
const getQuiz = quizId => RequestHelper.GET(`${quizUrl}/${quizId}`);
const getQuizOptions = () => RequestHelper.GET(`${quizUrl}/options`);

const getQuizCopy = quizId => RequestHelper.GET(`${quizUrl}/${quizId}/copy`);

// We want the specified quiz instance but with the last version of the questions for the history
const getQuizInstance = quizInstanceId => RequestHelper
  .GET(`${quizUrl}/instance/${quizInstanceId}?instanceDate=${new Date().toISOString()}`);

const getQuizHistory = quizId => RequestHelper.GET(`${quizUrl}/${quizId}/history`);

// POST
const createQuiz = quiz => RequestHelper.POST(`${quizUrl}`, quiz);

// GET LIST
const getQuizList = (filters, page = 0) => RequestHelper.POST(`${quizUrl}/filter?page=${page}`, filters);

const getExamination = examId => RequestHelper.GET(`${examinationsUrl}/${examId}`);

// POST
const startQuiz = ({ quizId, sequenceId, moduleId }) => RequestHelper
  .POST(`${examinationsUrl}/start`, { quizId, sequenceId, moduleId });

// PUT
const updateQuiz = (quizId, quiz) => RequestHelper.PUT(`${quizUrl}/${quizId}`, quiz);

const confirmQuestionsPage = (examinationId, question) => RequestHelper
  .PUT(`${examinationsUrl}/${examinationId}/question`, question);

const nextPrevQuiz = (examinationId, page) => RequestHelper.PUT(`${examinationsUrl}/${examinationId}/page`, page);

// DELETE
const deleteQuiz = quizId => RequestHelper.DELETE(`${quizUrl}/${quizId}`);

const exportQuiz = filters => RequestHelper.POST(`${quizUrl}/export`, filters);

const exportQuizAsync = filters => RequestHelper.POST(`${quizUrl}/async-export`, filters);

export const QuizService = {
  getQuizList,
  getExamination,
  getQuiz,
  getQuizCopy,
  getQuizInstance,
  getQuizHistory,
  createQuiz,
  startQuiz,
  updateQuiz,
  confirmQuestionsPage,
  nextPrevQuiz,
  deleteQuiz,
  exportQuiz,
  exportQuizAsync,
  getQuizOptions
};
