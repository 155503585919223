/**
 * asForm Higher Order Component
 */
import React, { useCallback } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

/* eslint no-param-reassign: ["error", { "props": false }] */
const asForm = MyComponent => observer(({ formData, onChange, ...props }) => {
  const updateProperty = useCallback((key, value) => {
    if (onChange) {
      onChange(value);
    }

    runInAction(() => {
      formData[key] = value;
    });
  }, [formData, onChange]);

  return <MyComponent {...props} formData={formData} updateProperty={updateProperty} />;
});

export default asForm;
