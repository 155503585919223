import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, TextField, Typography
} from '@material-ui/core';
import {
  DeleteAnswerButton,
  GenericAnswer, GenericCheckBox, GenericRadio
} from 'components/forms/_commons';

import { translate } from 'utils';
import { QUESTION_TYPE } from 'utils/constants';
import { Text } from 'components/_commons';

const useStyles = makeStyles(() => ({
  marginBottom: {
    marginBottom: 15
  }
}));

function AnswerGenerator({
  onAnswerType, onHandleRadioChange, answerText, maxValue, maxValueLabel, minValue, minValueLabel, isCorrect,
  handleChangeAnswer, handleDelete
}) {
  const {
    mcq, dropDown, yesNo, trueFalse, rating, shortInput, longInput, dateInput, emailInput, phoneInput
  } = QUESTION_TYPE;

  const classes = useStyles();

  switch (onAnswerType) {
    case mcq:
      return (
        <GenericAnswer
          answerContent={(
            <TextField
              fullWidth
              inputProps={{ maxLength: 300 }}
              label={translate('common.answer')}
              name="answerText"
              required
              value={answerText}
              variant="outlined"
              onChange={handleChangeAnswer}
            />
            )}
          checkTypeButton={<GenericCheckBox isCorrect={isCorrect} onHandleChangeAnswer={handleChangeAnswer} />}
          deleteAnswerBtn={<DeleteAnswerButton onClickDelete={handleDelete} />}
        />
      );
    case dropDown:
      return (
        <GenericAnswer
          answerContent={(
            <TextField
              fullWidth
              inputProps={{ maxLength: 300 }}
              label={translate('common.answer')}
              name="answerText"
              required
              value={answerText}
              variant="outlined"
              onChange={handleChangeAnswer}
            />
                )}
          checkTypeButton={<GenericRadio isCorrect={isCorrect} onHandleRadioChange={onHandleRadioChange} />}
          deleteAnswerBtn={<DeleteAnswerButton onClickDelete={handleDelete} />}
        />
      );
    case yesNo:
    case trueFalse:
      return (
        <GenericAnswer
          answerContent={(
            <TextField
              fullWidth
              inputProps={{ maxLength: 300 }}
              label={translate('common.answer')}
              name="answerText"
              required
              value={answerText}
              variant="outlined"
              onChange={handleChangeAnswer}
            />
              )}
          checkTypeButton={(<GenericRadio isCorrect={isCorrect} onHandleRadioChange={onHandleRadioChange} />)}
          deleteAnswerBtn={null}
        />
      );
    case rating:
      return (
        <GenericAnswer
          answerContent={(
            <Grid alignItems="center" container direction="row">
              <Grid alignItems="center" className={classes.marginBottom} container direction="row">
                <Grid item xs={4}>
                  <Typography>
                    {translate('question.graduationFrom')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    inputProps={{ min: 1 }}
                    label="Min"
                    name="minValue"
                    type="number"
                    value={minValue}
                    variant="outlined"
                    onChange={event => {
                      if (Number(event.target.value) < 1) return 1;
                      return handleChangeAnswer(event);
                    }}
                  />
                </Grid>
                <Grid container item justifyContent="center" xs={2}>
                  <Typography>
                    {translate('question.graduationTo')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    inputProps={{ min: Number(Number(minValue) + 1) }}
                    label="Max"
                    name="maxValue"
                    type="number"
                    value={maxValue}
                    variant="outlined"
                    onChange={event => {
                      if (Number(event.target.value) < 1 || Number(event.target.value) < minValue) return Number(Number(minValue) + 1);
                      return handleChangeAnswer(event);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid alignItems="center" className={classes.marginBottom} container direction="row">
                <Grid item xs={2}>
                  <Typography component="label">
                    Min
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    label={translate('common.guide')}
                    name="minValueLabel"
                    placeholder={translate('question.minLabel')}
                    required
                    value={minValueLabel}
                    variant="outlined"
                    onChange={handleChangeAnswer}
                  />
                </Grid>
              </Grid>
              <Grid alignItems="center" container direction="row">
                <Grid item xs={2}>
                  <Typography component="label">
                    Max
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    label={translate('common.guide')}
                    name="maxValueLabel"
                    placeholder={translate('question.maxLabel')}
                    required
                    value={maxValueLabel}
                    variant="outlined"
                    onChange={handleChangeAnswer}
                  />
                </Grid>
              </Grid>
            </Grid>
                )}
          checkTypeButton={null}
          deleteAnswerBtn={null}
        />
      );
    case shortInput:
    case longInput:
    case dateInput:
    case emailInput:
    case phoneInput:
      return <Text color="var(--grey)" fontStyle="italic">{translate('question.noResponseToEnter')}</Text>;
    default:
      return (<div />);
  }
}

export default AnswerGenerator;

AnswerGenerator.propTypes = {
  onAnswerType: PropTypes.string,
  answerText: PropTypes.string,
  maxValueLabel: PropTypes.string,
  minValueLabel: PropTypes.string,
  isCorrect: PropTypes.bool,
  handleChangeAnswer: PropTypes.func,
  onHandleRadioChange: PropTypes.func,
  handleDelete: PropTypes.func
};

AnswerGenerator.defaultProps = {
  answerText: '',
  handleChangeAnswer: () => {},
  handleDelete: () => {},
  isCorrect: null,
  maxValueLabel: '',
  minValueLabel: '',
  onAnswerType: '',
  onHandleRadioChange: () => {}
};
