import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { translate } from 'utils';
import { Link } from 'react-router-dom';
import {
  APP_PROFILE, ASSISTANCE_EMAIL, ROUTES, ASSISTANCE_EMAIL_AFF
} from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faCookieBite } from '@fortawesome/pro-solid-svg-icons';
import { Wrapper } from 'components';
import { isMobile } from 'react-device-detect';

const StyledFooter = styled.footer`
  margin-top: 40px;
  text-align: center;

  @media (max-width: 1081px) {
    padding-bottom: 40px;
  }
`;

const StyledDivider = styled(Divider)`
  display: inline-block;
  width: 2px;
  background-color: #7e7e7e !important;
  height: 14px !important;
`;

export const Footer = () => {
  const isAFF = APP_PROFILE === 'AFF';

  return (
    <Wrapper>
      <StyledFooter>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid
              alignItems="center"
              container
              justifyContent="center"
              spacing={2}
              wrap="wrap"
            >
              <Grid item>
                {isAFF ? (
                  <a href="https://aff-froid.com/a-propos" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                    {translate('common.personalData')}
                  </a>
                ) : (
                  <Link to={ROUTES.RGPD}>
                    <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                    {translate('common.personalData')}
                  </Link>
                )}
              </Grid>

              {!isMobile && (
              <Grid item>
                <StyledDivider orientation="vertical" />
              </Grid>
              )}

              <Grid item>
                <Link to={ROUTES.COOKIES}>
                  <FontAwesomeIcon className="mr1" icon={faCookieBite} />
                  {translate('link.rgpd.cookies')}
                </Link>
              </Grid>

              {!isMobile && (
              <Grid item>
                <StyledDivider orientation="vertical" />
              </Grid>
              )}

              <Grid item>
                {isAFF ? (
                  <a href={`mailto:${ASSISTANCE_EMAIL_AFF}`}>
                    <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                    {`${translate('common.assistance')} (${ASSISTANCE_EMAIL_AFF})`}
                  </a>
                ) : (
                  <a href={`mailto:${ASSISTANCE_EMAIL}`}>
                    <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                    {`${translate('common.assistance')} (${ASSISTANCE_EMAIL})`}
                  </a>
                )}
              </Grid>

              {/* <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a
                href={`${DATATECNEA_URL}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIconStyled icon={faLink} />
                {translate('common.datatecneaLink')}
              </a>
            </Grid> */}
            </Grid>
          </Grid>

          <Grid item>
            {isAFF ? (
              <img alt="AFF_logo" src={`${process.env.PUBLIC_URL}/assets/images/companies/AFF_logo.jpg`} />
            ) : (
              <img alt="TECNEA_GROUP" src={`${process.env.PUBLIC_URL}/assets/images/companies/TECNEA_GROUP.png`} />
            )}
          </Grid>
          <Grid item>
            <img
              alt={translate('menu.logoAlt')}
              className="supralog"
              src={`${process.env.PUBLIC_URL}/assets/images/bg/${isMobile ? 'footer-mobile' : 'footer'}.png`}
            />
          </Grid>
          {/* <FooterSupralog /> */}
        </Grid>
      </StyledFooter>
    </Wrapper>
  );
};
