import {
  FormControl,
  FormControlLabel, Grid, Radio, RadioGroup
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useStores } from 'hooks';
import { translate } from 'utils';
import styled from 'styled-components';
import { buildDefaultAndTraineeAnswers } from '../../QuizHelper';
import { CheckMark } from '../_commons';

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0 !important;
  background-color: ${props => {
    if (props.disabled && props.checked) {
      return 'var(--grey)';
    }
    if (props.checked) {
      return 'var(--primary-color)';
    }
    return 'var(--white)';
  }};
`;

const ImageQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { isCorrect, isAnswered, title } = currentQuestionData;

  const { examinationStore } = useStores();

  const { currentPage } = examinationStore;

  const [defaultAnswers, setDefaultAnswers] = useState([]);

  const buildAnswers = useCallback(() => {
    setDefaultAnswers(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleRadioChange = useCallback((event, index) => {
    const { checked } = event.target;

    const newDefaultAnswers = defaultAnswers.map((ans, indx) => ({
      ...ans,
      isItemAnswered: index === indx ? checked : false
    }));
    const trainAnswers = newDefaultAnswers.filter(defaultAnswer => defaultAnswer.isItemAnswered).map(ans => ans.pictureId);

    setDefaultAnswers(newDefaultAnswers);

    examinationStore.setTraineeAnswers(currentPage, currentQuestionData, trainAnswers[0]);
  }, [currentPage, currentQuestionData, defaultAnswers, examinationStore]);

  return (
    <FormControl style={{ marginTop: 10 }}>
      <RadioGroup className="pdf-export__content__question__image-question" name={title} row style={{ alignItems: 'center' }}>
        {defaultAnswers && defaultAnswers.map((defaultAnswer, index) => (
          <Grid
            className="pdf-export__content__question--print-inline"
            item
            key={shortid.generate()}
          >
            {withCorrection && isAnswered && !isCorrect && (
              <Grid item>
                <CheckMark
                  isDefaultAnswerCorrect={defaultAnswer.isDefaultAnswerCorrect}
                  isQuestionAnswered={isAnswered}
                />
              </Grid>
            )}

            <Grid item>
              <StyledFormControlLabel
                checked={defaultAnswer.isItemAnswered}
                control={(
                  <Radio
                    checked={defaultAnswer.isItemAnswered}
                    color="primary"
                    disabled={isReadOnlyQuestion}
                    name={`radio-answer-${index}`}
                    style={{ display: 'none' }}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={event => handleRadioChange(event, index)}
                  />
                )}
                disabled={isReadOnlyQuestion}
                label={(
                  <img
                    alt={`${translate('common.IMAGE')}-${index}`}
                    src={defaultAnswer.answerPicture}
                    style={{
                      margin: 5,
                      width: '100%',
                      maxWidth: 250,
                      height: 'auto'
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
      </RadioGroup>
    </FormControl>
  );
});

export default ImageQuestion;

ImageQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

ImageQuestion.defaultProps = {
  currentQuestionData: {}
};
