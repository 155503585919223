import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { translate } from 'utils';
import shortid from 'shortid';

export const TextAddress = ({ address }) => {
  const address2 = address.address2 ? `(${address.address2})` : '';
  return (
    <Typography variant="body2">
      {`${address.address1} ${address2}`}
      <br />
      {`${address.postalCode || ''} ${address.city || ''} ${address.country ? address.country.label : ''}`}
    </Typography>
  );
};

const CellAddress = () => ({
  name: 'address',
  label: translate('common.address'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {row.address && row.address.city ? (
        <TextAddress address={row.address} />
      ) : '-'}
    </TableCell>
  )
});

export default CellAddress;
