import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const languagesUrl = `${API_V2_URL}/languages`;
const addLanguagesUrl = `${API_V2_URL}/application-languages/add`;
const activateLanguagesUrl = `${API_V2_URL}/application-languages/activate`;
const deactivateLanguagesUrl = `${API_V2_URL}/application-languages/deactivate`;
const remainingLanguagesUrl = `${API_V2_URL}/languages/remaining`;

const getLanguageList = filters => RequestHelper.POST(`${languagesUrl}`, filters);

const saveLanguage = language => RequestHelper.POST(`${languagesUrl}/save`, language);

const getLanguageListForApplication = requestParams => RequestHelper.GET(`${languagesUrl}/options?${requestParams}`);

const getLanguageListForRoleForApplication = requestParams => RequestHelper.GET(
  `${languagesUrl}/translation-role/options?${requestParams}`
);

const addLanguageForApplication = requestParams => RequestHelper.POST(`${addLanguagesUrl}?${requestParams}`);

const activateLanguageForApplication = requestParams => RequestHelper.POST(`${activateLanguagesUrl}?${requestParams}`);

const deactivateLanguageForApplication = requestParams => RequestHelper.POST(`${deactivateLanguagesUrl}?${requestParams}`);

const getRemainingLanguagesForApplication = requestParams => RequestHelper.POST(`${remainingLanguagesUrl}?${requestParams}`);

export const LanguageService = {
  getLanguageList,
  saveLanguage,
  getLanguageListForApplication,
  getLanguageListForRoleForApplication,
  addLanguageForApplication,
  activateLanguageForApplication,
  deactivateLanguageForApplication,
  getRemainingLanguagesForApplication
};
