import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarMinus,
  faCalendarPlus,
  faSearch,
  faSlidersH,
  faSpinner,
  faTimes,
  faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import {
  Button, Chip, Drawer, Grid, IconButton, InputAdornment, TextField
} from '@material-ui/core';
import { formatDate, FormHelper, translate } from 'utils';
import { FilterHelper } from 'utils/helpers/FilterHelper';
import { DateHelper } from 'utils/helpers/DateHelper';
import { CustomTooltip } from '..';

const InputAndFilters = styled(Grid).attrs({
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-radius: var(--borderRadius);

  .MuiTextField-root {
    flex: 1;
  }

  input {
    &:disabled {
      cursor: not-allowed;
    }
  }

  & > button {
    margin-left: 1rem;

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
    }
  }
`;

const TagList = styled(Grid)`
  flex: 1;
  overflow-x: auto;

  div:not(:last-child),
  svg:not(.MuiSvgIcon-root) {
    margin-right: 0.5rem;
  }
`;

const FilterDrawerMain = styled.main`
  width: 100%;
  min-width: var(--wrapperWidthMin);
  flex: 1;
  margin: 0 auto;
  padding: 0 2rem;

  h2 {
    justify-content: flex-start;
  }
`;

const FilterDrawerButtons = styled(Grid).attrs({
  alignItems: 'center',
  justifyContent: 'flex-end'
})`
  padding: 2rem;

  @media (min-width: 481px) {
    button:first-child {
      margin-right: 2rem;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;

    button:first-child {
      margin-bottom: 2rem;
    }
  }
`;

const DeleteButton = styled(Button)`
  &:hover {
    color: var(--error-color);
  }
`;

export const GenericListFilters = ({
  ComponentFilter,
  dataTour,
  filters, setFilters,
  search, setSearch,
  setCurrentPage,
  filterKey,
  withDrawer,
  disabled,
  tooltipInfo
}) => {
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [internalSearch, setInternalSearch] = useState(search);
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearch = FormHelper.useDebounce(internalSearch, 1000);

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  useEffect(() => {
    setSearch(debouncedSearch);
    setIsSearching(false);
  }, [setSearch, debouncedSearch]);

  const handleInputChange = useCallback(input => {
    setIsSearching(true);
    setInternalSearch(input.target.value);
  }, []);

  const saveFilters = useCallback(() => {
    FilterHelper.setFilters(filterKey, currentFilters);
  }, [filterKey, currentFilters]);

  const removeFilters = useCallback(() => {
    setFilters([]);
    setCurrentFilters([]);
    FilterHelper.removeFilters(filterKey);
  }, [setFilters, filterKey]);

  const resetFilters = useCallback(() => {
    setFilters(filters);
    setCurrentFilters(filters);
    setIsFiltersDrawerOpen(false);
  }, [filters, setFilters]);

  const setNewFilters = useCallback(() => {
    setFilters(currentFilters);
    setIsFiltersDrawerOpen(false);
    setCurrentPage(0);
    saveFilters();
  }, [currentFilters, setFilters, saveFilters, setCurrentPage]);

  const handleDeleteSearch = useCallback(() => setInternalSearch(''), []);

  const handleOpenDrawer = useCallback(() => setIsFiltersDrawerOpen(true), []);

  const handleCloseDrawer = useCallback(() => setIsFiltersDrawerOpen(false), []);

  return (
    <>
      <aside data-tour={dataTour}>
        <InputAndFilters container>
          {tooltipInfo && (
            <CustomTooltip text={translate(tooltipInfo)} />
          )}
          <TextField
            disabled={disabled}
            InputProps={{
              autoComplete: 'off',
              endAdornment: internalSearch !== '' && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleDeleteSearch}>
                    <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="xs" />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {isSearching
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : <FontAwesomeIcon color="var(--primary-color)" icon={faSearch} />}
                </InputAdornment>
              )
            }}
            label={translate('common.searchElement')}
            name="search"
            value={internalSearch}
            variant="outlined"
            onChange={handleInputChange}
          />
          {withDrawer && (
            <Button
              color="secondary"
              data-cy="genericListFilters"
              disabled={disabled}
              startIcon={<FontAwesomeIcon icon={faSlidersH} />}
              variant="contained"
              onClick={handleOpenDrawer}
            >
              {translate('button.filters')}
            </Button>
          )}
        </InputAndFilters>

        {filters && filters.length > 0 && (
          <Grid alignItems="center" container justifyContent="space-between" style={{ marginBottom: '2rem' }}>
            <TagList container data-cy="genericListFiltersList">
              {filters.map(filter => {
                if (!filter.label) return false;
                let formattedFilterLabel;
                if (filter.label instanceof Array && filter.label.length > 0) {
                  formattedFilterLabel = filter.label.map(filt => filt.label).join(', ');
                } else if (filter.label instanceof Date || DateHelper.isIsoDate(filter.label)) {
                  formattedFilterLabel = (
                    <>
                      <FontAwesomeIcon icon={filter.key === 'startDate' ? faCalendarMinus : faCalendarPlus} />
                      {formatDate(filter.label)}
                    </>
                  );
                } else if (filter.value && filter.value.label) {
                  formattedFilterLabel = filter.value.label;
                } else if (typeof filter.label === 'object') {
                  formattedFilterLabel = filter.label.name;
                } else {
                  formattedFilterLabel = filter.label;
                }

                if (filter.translationKey) {
                  formattedFilterLabel = translate(`${filter.translationKey}.${formattedFilterLabel}`);
                }

                return (
                  <Chip
                    color="primary"
                    key={shortid.generate()}
                    label={formattedFilterLabel}
                    onDelete={() => {
                      setFilters(filters.filter(f => f.key !== filter.key));
                      setCurrentFilters(filters.filter(f => f.key !== filter.key));
                    }}
                  />
                );
              })}
            </TagList>
            {filters.length > 0 && (
              <DeleteButton
                startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
                onClick={removeFilters}
              >
                {translate('button.removeFilters')}
              </DeleteButton>
            )}
          </Grid>

        )}
      </aside>

      {withDrawer && (
        <Drawer anchor="right" id="filterDrawer" open={isFiltersDrawerOpen} onClose={handleCloseDrawer}>
          <Grid container justifyContent="flex-end">
            <Button onClick={resetFilters}>
              <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="2x" />
            </Button>
          </Grid>
          <FilterDrawerMain style={{ maxWidth: 500 }}>
            {ComponentFilter && ComponentFilter({ currentFilters, setCurrentFilters })}
          </FilterDrawerMain>
          <FilterDrawerButtons container>
            <Button data-cy="genericListFilterCancel" variant="text" onClick={resetFilters}>
              {translate('button.cancel')}
            </Button>
            <Button color="primary" data-cy="genericListFilterValidate" variant="contained" onClick={setNewFilters}>
              {translate('button.validateFilters')}
            </Button>
          </FilterDrawerButtons>
        </Drawer>
      )}
    </>
  );
};

GenericListFilters.propTypes = {
  ComponentFilter: PropTypes.func,
  dataTour: PropTypes.string,
  disabled: PropTypes.bool,
  filterKey: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.instanceOf(Date),
      PropTypes.arrayOf(PropTypes.shape({}))
    ])
  })),
  search: PropTypes.string,
  setFilters: PropTypes.func,
  setSearch: PropTypes.func,
  setCurrentPage: PropTypes.func,
  tooltipInfo: PropTypes.string,
  withDrawer: PropTypes.bool
};

GenericListFilters.defaultProps = {
  ComponentFilter: null,
  dataTour: '',
  disabled: false,
  filterKey: 'generic_',
  filters: [],
  search: '',
  setCurrentPage: () => {},
  setFilters: () => {},
  setSearch: () => {},
  tooltipInfo: '',
  withDrawer: false
};
