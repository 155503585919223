import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

import { IconButton, Tooltip } from '@material-ui/core';

export const CustomTooltip = ({
  text, size, icon, color
}) => (
  <Tooltip
    disableFocusListener
    disableTouchListener
    title={text}
  >
    <IconButton
      aria-label="informations"
      color="primary"
      size={size === 'xs' ? 'small' : 'medium'}
    >
      <FontAwesomeIcon color={color} icon={icon} size={size} />
    </IconButton>
  </Tooltip>
);

CustomTooltip.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.shape({}),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired,
  size: PropTypes.string
};

CustomTooltip.defaultProps = {
  color: 'var(--tecnea-blue)',
  icon: faQuestionCircle,
  size: null
};
