import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import styled from 'styled-components';
import {
  addMinutes, differenceInMinutes, formatDuration, intervalToDuration
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

const StyledTimer = styled.aside`
  color: ${props => props.color};
`;

const QuizTimer = observer(() => {
  const { examinationStore } = useStores();

  const { examination } = examinationStore;

  const { quiz, quiz: { maxTimeToFinish } } = examination;

  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();

  const [timer, setTimer] = useState(undefined);
  const [timeLeft, setTimeLeft] = useState(undefined);
  const timerInterval = useRef();

  const handleFinishQuiz = useCallback(() => {
    showModal({
      type: 'QUIZ_TIME_OUT',
      quizTitle: quiz.title,
      onConfirm: () => {
        examinationStore.finishQuizService()
          .then(exam => {
            examinationStore.setExamStatus({
              isStarted: false, isEnded: true, examinationId: exam.id, quizId: quiz.id
            });
          });
      }
    });
    // eslint-disable-next-line
  }, [quiz.title, quiz.id, showModal]);

  useEffect(() => {
    if (maxTimeToFinish > 0) {
      let timeRemaining = maxTimeToFinish;
      // If the quiz was started but not completed, start the timer with the old value
      if (examination && examination.startDate && !examination.endDate) {
        const timeSpend = differenceInMinutes(new Date(), new Date(examination.startDate));
        timeRemaining = Number(maxTimeToFinish - timeSpend);
      }

      const timerEnd = addMinutes(new Date(), timeRemaining);
      timerInterval.current = setInterval(() => {
        setTimer(() => {
          const timeLeftFormatted = intervalToDuration({
            start: new Date(),
            end: timerEnd
          });

          if (timeLeftFormatted.hours === 0 && timeLeftFormatted.minutes === 0 && timeLeftFormatted.seconds === 0) {
            setTimeLeft(0);
            return 0;
          }

          setTimeLeft(timeLeftFormatted);
          return formatDuration(timeLeftFormatted, { locale: fr });
        });
      }, 1000);

      return () => {
        clearInterval(timerInterval.current);
      };
    }

    return undefined;
    // eslint-disable-next-line
  }, [maxTimeToFinish, examination]);

  useEffect(() => {
    if (timeLeft === undefined) return;

    if (timeLeft === 0) {
      clearInterval(timerInterval.current);
      handleFinishQuiz();
      return;
    }

    // Display a warning 5 min before the end if the quiz is longer than 10 min
    if (maxTimeToFinish >= 10 && timeLeft.minutes === 5 && timeLeft.seconds === 0) {
      enqueueSnackbar(translate('quiz.timerWarning', { timeLeft: '5 min' }), { variant: 'warning' });
    }
    // Display a warning 1 min before the end
    if (timeLeft.minutes === 1 && timeLeft.seconds === 0) {
      enqueueSnackbar(translate('quiz.timerWarning', { timeLeft: '1 min' }), { variant: 'warning' });
    }
    // eslint-disable-next-line
  }, [timeLeft, enqueueSnackbar, maxTimeToFinish]);

  const getTimerColor = useCallback(() => {
    if (timeLeft === undefined) return 'var(--success-color)';

    if (timeLeft.minutes < 1 || timeLeft === 0) return 'var(--error-color)';

    if (timeLeft.minutes < 5) return 'var(--warning-color)';

    return 'var(--success-color)';
  }, [timeLeft]);

  if (!maxTimeToFinish || !quiz) {
    return null;
  }

  if (timer === undefined) {
    return <FontAwesomeIcon icon={faSpinner} size="xs" spin />;
  }

  return (
    <StyledTimer color={getTimerColor()}>
      {timer === 0
        ? 'Le temps est écoulé'
        : `Il vous reste ${timer} pour finir le quiz`}
    </StyledTimer>

  );
});

export default QuizTimer;
