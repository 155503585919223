import styled from 'styled-components';

export const Wrapper = styled.section`
  max-width: var(--wrapperWidthMax);
  min-width: var(--wrapperWidthMin);
  margin: 0 auto;
  padding: 0 2rem;
`;

export const SmallWrapper = styled(Wrapper)`
  max-width: 900px;
`;

export const FullWrapper = styled(Wrapper)`
  width: 100%;
  max-width: none;
`;
