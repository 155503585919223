import { Modal } from 'components';
import React, {
  createContext, useCallback, useContext, useState
} from 'react';

const ModalContext = createContext(null);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    onSubmit: () => {},
    onClose: () => {}
  });
  const [open, setOpen] = useState(false);

  const openModal = useCallback(
      (options) => {
        setOpen(true);
        setModalState(options);
      },
    []
  );

  const { onSubmit, onClose, ...modalProps } = modalState;

  const handleSubmit = () => {
    onSubmit && onSubmit();
    setOpen(false);
  };

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <>
      <ModalContext.Provider
        value={openModal}
      >
        {children}
      </ModalContext.Provider>

      <Modal
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        {...modalProps}
      />
    </>
  );
};
