import { faQuestionSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { TextField } from 'components/forms/inputs/InputField/InputField';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useFormState } from 'react-use-form-state';
import { translate } from 'utils';
import { FormHelper } from 'utils/helpers';
import { HeaderModal } from './_HeaderModal';

export const QuizLoginModal = ({ onClose, onConfirm }) => {
  const [formState, { text, email }] = useFormState();

  const handleClose = useCallback(() => onClose(), [onClose]);

  const handleSubmit = useCallback(() => {
    const isFormValid = document.forms.traineeAuthenticationFormModal
      && document.forms.traineeAuthenticationFormModal.reportValidity();

    if (isFormValid) {
      onConfirm();
      onClose();
    }
  }, [onConfirm, onClose]);

  return (
    <form autoComplete="off" name="traineeAuthenticationFormModal" style={{ maxWidth: 500 }}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faQuestionSquare} />
        <Typography component="span">
          {translate('common.identification')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ position: 'relative', overflow: 'hidden' }}>
        <Grid container direction="column" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Typography>{translate('quiz.loginBeforeStart')}</Typography>
          </Grid>
          <Grid item>
            <TextField
              error={formState.errors.traineeLastName && formState.errors.traineeLastName !== null}
              label={translate('common.lastName')}
              name="traineeLastName"
              placeholder={translate('common.enterYourLastName')}
              required
              {...text('traineeLastName')}
            />
          </Grid>
          <Grid item>
            <TextField
              error={formState.errors.traineeFirstName && formState.errors.traineeFirstName !== null}
              label={translate('common.firstName')}
              name="traineeFirstName"
              placeholder={translate('common.enterYourFirstName')}
              required
              {...text('traineeFirstName')}
            />
          </Grid>
          <Grid item>
            <TextField
              error={formState.errors.traineeEmail && formState.errors.traineeEmail !== null}
              label={translate('common.email')}
              name="traineeEmail"
              placeholder={translate('common.enterYourEmail')}
              required
              {...email({
                name: 'traineeEmail',
                // Additional validation for emails because useFormState validation stops at example@example
                validate: value => FormHelper.validateEmail(value)
              })}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {translate('button.begin')}
        </Button>
      </DialogActions>
    </form>
  );
};

QuizLoginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({})
};

QuizLoginModal.defaultProps = {
  defaultValues: {}
};
