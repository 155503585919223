import {
  faCopy,
  faEdit, faFileExport, faFileImport, faHistory, faQuestionSquare, faSpinner, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  Grid,
  IconButton, TableCell,
  Tooltip
} from '@material-ui/core';
import {
  GenericListFilters,
  GenericTable,
  SkeletonLine, TextError,
  Wrapper
} from 'components';
import { ColorPreviewContainer } from 'components/CategoryList';
import { DeleteButton, DropdownButton } from 'components/_commons';
import { getSmallDescription } from 'components/_commons/Quiz/QuizHelper';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, { useCallback, useEffect, useState } from 'react';
import { QuestionService } from 'services';
import shortid from 'shortid';
import { DocumentHelper, formatDate, translate } from 'utils';
import { CATEGORY_FILTER_KEY, QUESTION_TYPE } from 'utils/constants';
import { StorageHelper } from 'utils/helpers';
import { DocumentsService } from 'services/DocumentService';
import { QuestionListFilters } from './QuestionListFilters';

const {
  mcq, dropDown, yesNo, trueFalse, imagePicker, rating, shortInput, longInput, dateInput, emailInput, phoneInput
} = QUESTION_TYPE;

const MAX_SYNC_EXPORT_SIZE = 1000;

const getListHeaders = (handleViewQuestionHistory, handleCreateEditQuestion, handleCopyQuestion, handleDeleteQuestion) => ([
  {
    name: 'questionTitle',
    label: translate('common.question'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.title}
      </TableCell>
    )
  }, {
    name: 'lastModification',
    label: translate('common.lastModification'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${formatDate(row.creationDate) || ''}`}
        <br />
        {`${row.creationUser || ''}`}
      </TableCell>
    )
  }, {
    name: 'questionCode',
    label: translate('common.code'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Tooltip title={row.code && row.code.length > 8 ? row.code : ''}>
          <span>
            {getSmallDescription(row.code, 8)}
          </span>
        </Tooltip>
      </TableCell>
    )
  }, {
    name: 'questionCategory',
    label: translate('common.category'),
    width: '125px',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.category && (
          <Tooltip title={row.category.length > 8 ? row.category : ''}>
            <Grid alignItems="center" container direction="row" justifyContent="flex-start" wrap="nowrap">
              <Grid item xs={3}>
                <ColorPreviewContainer color={row.categoryColorHex} />
              </Grid>
              <Grid item xs={9}>
                {getSmallDescription(row.category, 7)}
              </Grid>
            </Grid>
          </Tooltip>
        )}
      </TableCell>
    )
  },
  {
    name: 'questionKeywords',
    label: translate('question.keywords'),
    template: row => {
      const keywordsArray = row.keywords && row.keywords.length > 0 && row.keywords.split(',');
      return (
        <TableCell key={shortid.generate()}>
          {keywordsArray?.length > 0
            && keywordsArray.map(keyword => <Chip key={shortid.generate()} label={keyword} style={{ margin: 5 }} />)}
        </TableCell>
      );
    }

  }, {
    name: 'questionType',
    label: translate('common.type'),
    width: '10px',
    template: row => (
      <TableCell key={shortid.generate()}>
        {(row.questionType === mcq ? translate('question.mcq') : null)
          || (row.questionType === dropDown ? translate('question.dropDown') : null)
          || (row.questionType === yesNo ? translate('question.yesNo') : null)
          || (row.questionType === trueFalse ? translate('question.trueFalse') : null)
          || (row.questionType === rating ? translate('question.rating') : null)
          || (row.questionType === shortInput ? translate('question.shortInput') : null)
          || (row.questionType === longInput ? translate('question.longInput') : null)
          || (row.questionType === imagePicker ? translate('question.imagePicker') : null)
          || (row.questionType === dateInput ? translate('question.dateInput') : null)
          || (row.questionType === emailInput ? translate('question.emailInput') : null)
          || (row.questionType === phoneInput ? translate('question.phoneInput') : null)}
      </TableCell>
    )
  },
  {
    name: 'questionPoints',
    label: translate('common.points'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.points}
      </TableCell>
    )
  },
  {
    name: 'questionActions',
    label: translate('common.actions'),
    width: '175px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>

          <Grid item>
            <Tooltip title={translate('common.showItemHistory')}>
              <IconButton
                color="primary"
                edge="end"
                onClick={e => {
                  e.stopPropagation();
                  handleViewQuestionHistory(row);
                }}
              >
                <FontAwesomeIcon icon={faHistory} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('common.editItem')}>
              <IconButton
                color="primary"
                edge="end"
                onClick={e => {
                  e.stopPropagation();
                  handleCreateEditQuestion(row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('common.copyItem')}>
              <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleCopyQuestion(row); }}>
                <FontAwesomeIcon icon={faCopy} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={translate('button.delete')}>
              <DeleteButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleDeleteQuestion(row.instanceId); }}>
                <FontAwesomeIcon color="var(--error-color)" icon={faTrashAlt} size="xs" />
              </DeleteButton>
            </Tooltip>
          </Grid>

        </Grid>
      </TableCell>
    )
  }
]);

export const QuestionList = observer(() => {
  const displayModal = useModal();

  const { enqueueSnackbar } = useSnackbar();

  const { questionStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(StorageHelper.GET(CATEGORY_FILTER_KEY)
    ? [{ key: 'category', label: StorageHelper.GET(CATEGORY_FILTER_KEY) }]
    : []);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [listSize, setListSize] = useState();

  const filterKey = 'questionList';

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  const toggleExportQuestionButton = useCallback(() => {
    if (questionList.length > 0 && !isLoading) {
      return setIsButtonDisabled(false);
    }
    return setIsButtonDisabled(true);
  }, [isLoading, questionList]);

  useEffect(() => {
    toggleExportQuestionButton();
  }, [toggleExportQuestionButton]);

  const loadQuestionList = useCallback(() => {
    const finalFilters = {};
    const keywordsValues = [];

    filters.forEach(filter => {
      if (filter.key === 'keywords') {
        filter.label.forEach(filt => {
          keywordsValues.push(filt.value);
        });
        const keywordsValuesToString = keywordsValues.join(',');
        finalFilters[filter.key] = keywordsValuesToString;
      } else {
        finalFilters[filter.key] = filter.label;
      }
    });

    setIsLoading(true);
    QuestionService.getQuestionList({ freeSearch: search, ...finalFilters }, currentPage)
      .then(response => {
        let allQuestions = response.content;
        if (currentPage > 0) {
          allQuestions = [].concat(...questionList, allQuestions);
        }
        setQuestionList(allQuestions);
        setMaxPage(response.totalPages);
        setListSize(response.totalElements);
      })
      .catch(error => enqueueSnackbar((error && error.error) || error, { variant: 'error' }))
      .finally(() => {
        StorageHelper.REMOVE(CATEGORY_FILTER_KEY);
        setIsLoading(false);
      });
  }, [currentPage, search, filters, questionList, enqueueSnackbar]);

  const refreshQuestionList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 0) {
      loadQuestionList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, loadQuestionList]);

  useEffect(() => {
    refreshQuestionList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffect(() => {
    loadQuestionList();
    // eslint-disable-next-line
  }, [currentPage]);

  const handleLoadMore = useCallback(() => !isLoading && setCurrentPage(currentPage + 1), [isLoading, currentPage]);

  const createUpdateQuestion = useCallback((ques, onClose) => {
    questionStore.createUpdateQuestion(ques.id, ques)
      .then(() => {
        enqueueSnackbar(ques.id
          ? translate('confirms.questionList.update')
          : translate('confirms.questionList.create'), { variant: 'success' });
        onClose();
      })
      .catch(e => (e ? enqueueSnackbar(e, { variant: 'error' })
        : enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' })))
      .finally(() => refreshQuestionList());
  }, [refreshQuestionList, enqueueSnackbar, questionStore]);

  const handleViewQuestionHistory = useCallback(row => {
    displayModal({
      type: 'HISTORY_MODAL',
      dataService: () => QuestionService.getQuestionHistory(row.id),
      viewLink: instanceId => {
        questionStore.loadQuestionInstance(instanceId)
          .then(() => {
            displayModal({
              type: 'CREATE_QUESTION',
              onConfirm: createUpdateQuestion,
              defaultValues: {}
            });
          });
      }
    });
  }, [questionStore, displayModal, createUpdateQuestion]);

  const handleCreateEditQuestion = useCallback(row => {
    if (row.id) {
      questionStore.loadQuestion(row.id, false)
        .then(() => {
          displayModal({
            type: 'CREATE_QUESTION',
            onConfirm: createUpdateQuestion,
            defaultValues: {}
          });
        });
    } else {
      displayModal({
        type: 'CREATE_QUESTION',
        onConfirm: createUpdateQuestion,
        defaultValues: {}
      });
    }
  }, [questionStore, displayModal, createUpdateQuestion]);

  const handleCopyQuestion = useCallback(row => {
    questionStore.loadQuestion(row.id, true)
      .then(() => {
        displayModal({
          type: 'CREATE_QUESTION',
          onConfirm: createUpdateQuestion,
          defaultValues: {}
        });
      });
  }, [questionStore, displayModal, createUpdateQuestion]);

  const handleDeleteQuestion = useCallback(questionInstanceId => {
    displayModal({
      type: 'WARNING',
      title: translate('warnings.warning'),
      text: translate('warnings.question.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        QuestionService.deleteQuestion(questionInstanceId)
          .then(() => {
            enqueueSnackbar(translate('confirms.questionList.delete'), { variant: 'success' });
            if (currentPage > 1) setCurrentPage(0);
            else refreshQuestionList();
          })
          .catch(error => enqueueSnackbar(error.message || error, { variant: 'error' }));
      }
    });
  }, [currentPage, displayModal, enqueueSnackbar, refreshQuestionList]);

  const handleImportQuestions = useCallback(() => displayModal({
    type: 'IMPORT',
    closeOnSubmit: true,
    onConfirm: sendFile => {
      const timeout = new Promise((resolve) => {
        setTimeout(() => {
          resolve('timeout');
        }, 300);
      });
      const controller = new AbortController();
      const { signal } = controller;
      Promise.race([QuestionService.importQuestions(sendFile, signal), timeout])
          .then(() => {
            enqueueSnackbar(translate('confirms.questionsImported'), { variant: 'success' });
            controller.abort();
          })
          .catch(error => enqueueSnackbar(error.message || error, { variant: 'error' }));
    }
  }), [displayModal, enqueueSnackbar]);

  const handleExportQuestions = useCallback(() => {
    setIsExporting(true);

    const searchFilters = {
      freeSearch: search
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of filters) {
      searchFilters[filter.key] = filter.label;
    }

    if (listSize <= MAX_SYNC_EXPORT_SIZE) {
      QuestionService.exportQuestions(searchFilters)
        .then(response => {
          const downloadLink = document.createElement('a');
          downloadLink.download = response.name;
          downloadLink.href = DocumentHelper.getExcelWithBase64(response.base64Content);
          downloadLink.click();
        })
        .catch(() => enqueueSnackbar(translate('errors.exportQuestionsError'), { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => setIsExporting(false));
    } else {
      QuestionService.exportQuestionAsync(searchFilters)
        .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success', autoHideDuration: 5000 }))
        .catch(response => enqueueSnackbar(response || translate('errors.exportQuestionsError'), { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => {
          setIsExporting(false);
        });
    }
  }, [search, filters, enqueueSnackbar, listSize]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <QuestionListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderButtonContainer = () => (
    <Grid item>
      <span style={{ marginRight: 10 }}>
        <DropdownButton
          color="secondary"
          icon={faFileImport}
          label="button.import"
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button
                  color="primary"
                  href="#text-buttons" variant="text" onClick={
                    () => DocumentsService.getImportFile('QUESTION').catch(() => enqueueSnackbar(translate('error.importFileNotExisting'), { variant: 'error' }))
                  }
              >
                {translate('common.exampleFile')}
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title={translate('question.importQuestions')}>
                <span>
                  <Button
                    color="secondary"
                    startIcon={<FontAwesomeIcon icon={faFileImport} />}
                    variant="contained"
                    onClick={handleImportQuestions}
                  >
                    {translate('button.import')}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DropdownButton>
      </span>
      {isButtonDisabled
        ? (
          <Tooltip title={translate('errors.noDataToExport')}>
            <span>
              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={<FontAwesomeIcon icon={faFileExport} />}
                style={{ marginRight: 10 }}
                variant="contained"
              >
                {translate('button.export')}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={translate('question.exportQuestions')}>
            <span>
              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={(
                  <FontAwesomeIcon
                    icon={isExporting ? faSpinner : faFileExport}
                    spin={isExporting}
                  />
                )}
                style={{ marginRight: 10 }}
                variant="contained"
                onClick={handleExportQuestions}
              >
                {translate('button.export')}
              </Button>
            </span>
          </Tooltip>
        )}
      <Button
        color="primary"
        startIcon={<FontAwesomeIcon icon={faQuestionSquare} />}
        variant="contained"
        onClick={handleCreateEditQuestion}
      >
        {translate('button.createQuestion')}
      </Button>
    </Grid>
  );

  return (
    <>
      <Wrapper>
        <PageTitle
          title={translate('pageQuestionList.title')}
          titleRight={renderButtonContainer()}
        />

        <GenericListFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-questionList-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltipInfo="pageQuestionList.searchTooltip"
          withDrawer
        />

        <div data-tour="step-admin-questionList">
          {!isLoading && questionList.length === 0
            ? (
              <Grid alignItems="center" container direction="column">
                <FontAwesomeIcon color="var(--primary-color)" icon={faQuestionSquare} size="3x" />
                <TextError>{translate('errors.noQuestion')}</TextError>
              </Grid>
            ) : (
              <GenericTable
                dataCy="questionList"
                hasMore={currentPage + 1 < maxPage}
                headers={
                  getListHeaders(
                    handleViewQuestionHistory,
                    handleCreateEditQuestion,
                    handleCopyQuestion,
                    handleDeleteQuestion
                  )
                }
                isLoading={isLoading}
                loadMore={handleLoadMore}
                rows={questionList}
                total={listSize}
              />
            )}
        </div>

        {isLoading && questionList.length === 0 && <SkeletonLine />}
      </Wrapper>
    </>
  );
});
