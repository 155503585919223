import { LegalStatusView } from 'models/LegalStatusView';
import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const legalStatusUrl = `${API_V2_URL}/legal-status`;

const getLegalStatusByCountryId: (
  id: string
) => Promise<LegalStatusView[]> = id => RequestHelper.GET(`${legalStatusUrl}/country/${id}`);

const save = legalStatuses => RequestHelper.POST(`${legalStatusUrl}`, legalStatuses);

export const LegalStatusService = {
  getLegalStatusByCountryId,
  save
};
