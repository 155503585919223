import React, { useCallback, useState } from 'react';

import {
  Button, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import { InputField } from 'components/';
import { HeaderModal } from 'components/Modals/_HeaderModal';
import { Text } from 'components/_commons';
import PropTypes from 'prop-types';
import { translate } from 'utils';

export const TestRegexModal = ({ regex, onClose, helperText }) => {
  const [testedValue, setTestedValue] = useState('');
  const error = !(regex && testedValue.match(`^${regex}$`));

  const handleEditValue = value => {
    setTestedValue(value);
  };

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    onClose();
  }, [onClose]);

  return (
    <form onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <Typography component="span">
          {translate('modalTestRegex.title')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        <Text>{regex}</Text>
        <InputField
          error={error}
          helperText={helperText}
          label={translate('modalTestRegex.test')}
          name={`format-${regex}`}
          value={testedValue}
          onChange={(_, value) => handleEditValue(value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </form>
  );
};

TestRegexModal.propTypes = {
  regex: PropTypes.string.isRequired
};
