import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import {
 Button, DialogActions, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';
import { useFetch } from 'hooks';
import { ExaminationService, QuizService } from 'services';
import { SelectField } from 'components/_commons';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { TextError } from 'components/Text';
import PropTypes from 'prop-types';

export const ExportAnswersSingleQuizModal = ({ onClose, filters }) => {
    const [quizModel, setQuizModel] = useState(null);
    const { response: quizModels } = useFetch(() => QuizService.getQuizOptions(), []);
    const [isImporting, setIsImporting] = useState(false);
    const [error, setError] = useState(null);

    const handleChangeQuizModel = useCallback((value) => setQuizModel(value), []);

    const handleConfirm = useCallback((e) => {
        e.preventDefault();
        setError(null);
        setIsImporting(true);

        ExaminationService.exportSingleQuizAllAnswers(quizModel.value, filters)
            .then((response) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = DocumentHelper.getExcelWithBase64(response.base64Content);
                downloadLink.download = response.name;
                downloadLink.click();
                setIsImporting(false);
                return onClose();
            })
            .catch((err) => {
                setIsImporting(false);
                setError(err);
            });
    }, [onClose, setError, setIsImporting, filters, quizModel]);

    return (
      <form onSubmit={handleConfirm}>
        <DialogTitle>
          {translate('exportAnswersSingleQuizModal.title')}
        </DialogTitle>

        <DialogContent>
          {error && (
            <TextError style={{ textAlign: 'left' }}>
              {error.message}
            </TextError>
                )}

          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SelectField
                    isClearable={false}
                    label="common.quizModel"
                    name="quizModels"
                    options={quizModels}
                    required
                    value={quizModel}
                    onChange={handleChangeQuizModel}
                />
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button disabled={isImporting} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
          <Button
                color="primary"
                disabled={isImporting}
                startIcon={<FontAwesomeIcon icon={isImporting ? faSpinner : faFileExport} spin={isImporting}/>}
                type="submit"
                variant={'contained'}
            >
            {translate('button.export')}
          </Button>
        </DialogActions>
      </form>
    );
};

ExportAnswersSingleQuizModal.propTypes = {
    filters: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired
};

ExportAnswersSingleQuizModal.defaultProps = {
    closeOnSubmit: true,
    filters: null
};
