import { API_V2_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const applicationRolesUrl = `${API_V2_URL}/application-roles`;

// GET
const getOptions = (appName = null, withNames = true) => RequestHelper
  .GET(`${applicationRolesUrl}/options?application=${appName}&withNames=${withNames}`);

export const ApplicationRolesService = {
  getOptions
};
