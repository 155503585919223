import { userStore } from 'stores';

export const getDefaultHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng') || 'fr'
  };

  return headers;
};

export const httpSettings = settings => {
  const headers = settings.headers || getDefaultHeaders();
  const init = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
      case 'application/json':
        if (!(init.body instanceof FormData)) {
          init.body = JSON.stringify(init.body);
        }
        break;
      case 'application/x-www-form-urlencoded': {
        const formDataEntries: [string, any][] = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
        const formDataArray = [];
        formDataEntries.forEach(([key, value]) => {
          formDataArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        });
        init.body = formDataArray.join('&');
        break;
      } default:
        break;
    }
  }

  const userToken = userStore.getToken();

  headers.Accept = 'application/json';
  if (userToken && !headers.Authorization) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  init.headers = headers;

  return init;
};
