import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const StyledEditionContent = styled(Grid)`
  ${props => props.edition === 'true' && css`
    height: calc(100vh - var(--header-with-edition-bar-height));
    overflow-y: auto;
  `}
`;

export const EditionContent = ({
  children, editionMode, onClick
}) => (
  <StyledEditionContent
    edition={editionMode.toString()}
    item
    role="button"
    xs
    onClick={onClick}
  >
    {children}
  </StyledEditionContent>
);

EditionContent.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

EditionContent.defaultProps = {
  onClick: () => { }
};
