import { Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import React from 'react';

import { translate } from 'utils';
import { ACTIVATED_TYPES } from 'utils/constants';

export const CountryListFilters = ({ currentFilters, setCurrentFilters }) => {
  const validated = (currentFilters.find(filt => filt.key === 'activated') && currentFilters.find(filt => filt.key === 'activated').label) || null;

  return (
    <section>
      <Typography component="h2">{translate('pageCountry.countryStatus')}</Typography>
      <ToggleButtonGroup
        aria-label={translate('common.validated')}
        exclusive
        size="medium"
        value={validated}
        onChange={(event, value) => {
          if (value === null) {
            return setCurrentFilters([]);
          }
          return setCurrentFilters([{
            key: 'activated',
            label: value,
            translationKey: 'common',
            value: value === 'activated'
          }]);
        }}
      >
        {ACTIVATED_TYPES.map(type => (
          <ToggleButton
            aria-label={translate(`common.${type.key}`)}
            key={shortid.generate()}
            value={type.key}
          >
            {translate(`common.${type.key}`)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </section>
  );
};

CountryListFilters.propTypes = {
  // eslint-disable-next-line react/require-default-props
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
