import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { translate } from 'utils';
import { HeaderModal } from './_HeaderModal';
import { CustomActionButtons } from './_CustomActionButtons';

export const CreateInstitutionGroupModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, defaultActivity, parentInstitutionGroupId, parentCompanyId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(defaultValues.name);
  const isEdit = defaultValues && defaultValues.name;

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    setIsLoading(true);
    const newInstitutionGroup = {
      ...defaultValues,
      name,
      parentInstitutionGroupId,
      parentCompanyId,
      activity: defaultActivity?.value
    };
    // In case of edition do not pass parent data
    if (isEdit) {
      newInstitutionGroup.parentInstitutionGroupId = null;
      newInstitutionGroup.parentCompanyId = null;
    }
    onConfirm(newInstitutionGroup).finally(() => setIsLoading(false));
    if (closeOnSubmit) {
      onClose();
    }
  }, [closeOnSubmit, onClose, onConfirm, defaultValues, name, parentInstitutionGroupId, parentCompanyId, defaultActivity, isEdit]);

  return (
    <form autoComplete="off" name="institutionForm" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        <Typography component="span">
          {isEdit
            ? translate('modalInstitutionGroup.editInstitutionGroup')
            : translate('modalInstitutionGroup.newInstitutionGroup')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ minWidth: '500px' }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              label={translate('modalInstitutionGroup.name')}
              name="institutionGroupName"
              required
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled
              label={translate('common.activity')}
              name="institutionActivity"
              value={defaultActivity?.label || ' '}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isLoading={isLoading}
        onClose={onClose}
      />
    </form>
  );
};

CreateInstitutionGroupModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  parentInstitutionGroupId: PropTypes.string,
  parentCompanyId: PropTypes.string
};

CreateInstitutionGroupModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {},
  parentCompanyId: '',
  parentInstitutionGroupId: ''
};
