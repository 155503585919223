import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import shortid from 'shortid';
import { translate } from 'utils';
import { Document } from 'components/DocumentList/Document';
import { InputFile } from 'components/forms/inputs';

export const DocumentList = ({
     documents, onDelete, onImport, title, importLabel, titleStyles,
     sizeLimit, variant, spacing
}) => (
  <Grid container direction="column" spacing={spacing}>
    {documents.length > 0
      && (
        <>
          {title && (
            <Grid item>
              <Typography {...titleStyles}>
                {translate(title)}
              </Typography>
            </Grid>
          )}
          <Grid item style={{ width: '100%' }}>
            <Grid container direction="column" spacing={spacing - 1}>
              {documents.map((file) => (
                <Grid item key={shortid.generate()} style={{ width: '100%' }}>
                  <Document
                    deletable
                    document={file}
                    handleDelete={() => onDelete(file)}
                    name={file.document.name}
                    tooltip="common.visualizeDocument"
                    url={file.document.base64Content ?? file.document.content}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    {(!sizeLimit || documents.length < sizeLimit)
      && (
        <Grid item>
          <InputFile
            handleAddDocument={onImport}
            id="importDocumentsButton"
            labelButton={importLabel}
            maxWidth="fit-content"
            startIcon={faFile}
            variant={variant}
          />
        </Grid>
      )}
  </Grid>
  );
