import React, { useCallback, useEffect } from 'react';
import { translate } from 'utils';
import styled from 'styled-components';
import {
  Grid, Card, CardContent, CardActions, Typography, Button
} from '@material-ui/core';
import { AFF_APPS, APP_PROFILE, TECNEA_APPS } from 'utils/constants';
import { Wrapper } from 'components';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import sanitizeHtml from 'sanitize-html';

const Banner = styled.div`
  position: relative;
  padding: 8rem 0;
  background: url(${props => props.image}) no-repeat center center;
  background-size: cover;

  .shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  h1 {
    position: relative;
    color: var(--white);
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.6);
    z-index: 10;
  }
`;

const PageHome = () => {
  const isAFF = APP_PROFILE === 'AFF';

  const { editionStore } = useStores();

  const resetEditionMode = useCallback(() => {
    runInAction(() => {
      editionStore.editionMode = false;
    });
  }, [editionStore]);

  useEffect(() => {
    resetEditionMode();
  }, [resetEditionMode]);

  return (
    <>
      <Banner image={isAFF ? '/assets/images/bg/banner_aff.png' : '/assets/images/bg/banner.jpg'}>
        {/* <div className="shadow" /> */}
        <Wrapper>
          <Typography
            component="h1"
            variant="h1"
          >
            {translate(isAFF ? 'pageHome.welcomeClimeco' : 'pageHome.welcome')}
          </Typography>
        </Wrapper>
      </Banner>
      {!isAFF && (
        <Wrapper>
          <Typography align="center" component="h2" variant="h2">{translate('pageHome.appTecnea')}</Typography>
          <Grid container spacing={3}>
            {TECNEA_APPS.map(app => (
              <Grid item key={app.name} md={4} sm={12}>
                <Card>
                  <CardContent style={{ minHeight: '140px' }}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={3}>
                        <img alt={app.name} src={app.logo} width="100%" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography component="h3" variant="h5">{app.name}</Typography>
                        <Typography component="span" dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate(app.description)) }} variant="body2" />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <a href={app.link} rel="noopener noreferrer" target="_blank" title={app.name}>
                      <Button
                        color="secondary"
                        startIcon={<FontAwesomeIcon icon={faExternalLink} />}
                        variant="contained"
                      >
                        {translate('button.openApp')}
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Wrapper>
      )}
      {isAFF && (
        <Wrapper>
          <Typography align="center" component="h2" variant="h2" />
          <Grid container spacing={3}>
            {AFF_APPS.map(app => (
              <Grid item key={app.name} md={6} sm={12}>
                <Card>
                  <CardContent style={{ minHeight: '140px' }}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={3}>
                        <img alt={app.name} src={app.logo} width="100%" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography component="h3" variant="h5">{app.name}</Typography>
                        <Typography component="span" dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate(app.description)) }} variant="body2" />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <a href={app.link} rel="noopener noreferrer" target="_blank" title={app.name}>
                      <Button
                        color="secondary"
                        startIcon={<FontAwesomeIcon icon={faExternalLink} />}
                        variant="contained"
                      >
                        {translate('button.openApp')}
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Wrapper>
      )}
    </>
  );
};

export default PageHome;
