import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { QuizService } from 'services';
import { buildImagePickers, editionModeImageQuestionBuilder } from 'components/_commons/Quiz/QuizHelper';
import { quizModel } from '__fixtures__';
import { examinationStore } from './ExaminationStore';

export class QuizStore {
  constructor() {
    this.isLoading = false;
    this.isError = false;
    this.error = null;
    this.examination = { quiz: {} };
    this.maxPage = 0;
    this.quiz = {
      ...quizModel
    };
    this.quizList = [];
    this.totalPages = 0;
    this.totalQuestions = 0;
    this.totalCategories = 0;
    this.isValid = { status: false, error: '' };
    this.newQuestionsList = [];
    this.deletingQuestion = false;
    this.isFormPageValid = false;
    this.updatedPage = {};
    this.isQuizSubmitted = false;
    this.tabs = [
      {
        id: 0,
        isSelected: false
      },
      {
        id: 1,
        isSelected: false
      }
    ];

    makeObservable(this, {
      isLoading: observable,
      isError: observable,
      error: observable,
      examination: observable,
      maxPage: observable,
      totalPages: observable,
      totalQuestions: observable,
      totalCategories: observable,
      isValid: observable,
      newQuestionsList: observable,
      deletingQuestion: observable,
      updatedPage: observable,
      isFormPageValid: observable,
      tabs: observable,
      quiz: observable,
      isQuizSubmitted: observable,
      loadQuiz: action,
      loadQuizList: action,
      deletePage: action,
      toggleDeletingQuestion: action,
      movePageUp: action,
      movePageDown: action,
      moveQuestionUp: action,
      moveQuestionDown: action,
      updateContentInCurrentPage: action,
      newQuiz: action,
      getTotalPagesAndQuestions: action,
      isQuizFormValid: action,
      addContentInNewPage: action,
      updatePage: action,
      toggleSelectedTab: action,
      setIsQuizFormPageValid: action,
      addQuestionsToQuiz: action,
      updateExamination: action,
      toggleSubmitQuizForm: action
    });
  }

  updateExamination(objectField) {
    if (examinationStore.examination.quiz) {
      examinationStore.examination.quiz[objectField] = this.quiz[objectField];
    }
  }

  /**
   * Fetches the quiz details.
   */
  loadQuiz(quizId, instanceId, isCopy = false) {
    let service;
    if (instanceId) {
      service = () => QuizService.getQuizInstance(instanceId);
    } else {
      service = isCopy
        ? () => QuizService.getQuizCopy(quizId)
        : () => QuizService.getQuiz(quizId);
    }
    this.isLoading = true;
    return service()
      .then(action(quiz => {
        this.quiz = quiz;
        buildImagePickers(this.quiz);
        return this.quiz;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  /**
   * Fetches the list of quiz (not detailed).
   */
  loadQuizList({ freeSearch, name, description }) {
    if (this.quizList.length === 0) {
      this.isLoading = true;
    }

    return QuizService.getQuizList({ freeSearch, name, description }, 0)
      .then(action(response => {
        this.maxPage = response.pageable.pageSize - 1;
        this.quizList = response.content;

        this.isError = false;
        this.error = null;
      }))
      .catch(error => {
        this.error = error;
        this.isError = true;
      })
      .finally((action(() => {
        this.isLoading = false;
      })));
  }

  newQuiz() {
    this.quiz = {
      ...quizModel
    };
    runInAction(() => {
      examinationStore.examination = {
        currentPage: null,
        endDate: null,
        finalScore: null,
        id: null,
        module: null,
        sequence: null,
        startDate: null,
        trainee: null,
        unansweredQuestions: 0,
        quiz: this.quiz
      };
    });
    this.isLoading = false;
    return this.quiz;
  }

  deletePage(pageIndex) {
    let newQuizPages = [];
    newQuizPages = this.quiz.pages.filter(page => page.index !== pageIndex);

    // Update page indexes
    newQuizPages.map(pg => {
      pg.index = newQuizPages.indexOf(pg);
      return pg;
    });

    this.quiz.pages = newQuizPages;

    return this.quiz;
  }

  updatePage(page) {
    const { isRandom } = page;

    if (isRandom) {
      this.updatedPage = {
        ...this.updatedPage,
        ...page,
        questions: []

      };
    } else {
      this.updatedPage = {
        ...this.updatedPage,
        ...page,
        categoryOptions: [],
        nbQuestions: null,
        nbCategories: null
      };
    }

    return this.updatedPage;
  }

  toggleDeletingQuestion(status) {
    this.deletingQuestion = status;
    return this.deletingQuestion;
  }

  movePageUp(pageIndex) {
    const currentPageIndex = this.quiz.pages.findIndex(page => page.index === pageIndex);

    if (currentPageIndex > 0) {
      const tempPage = this.quiz.pages[currentPageIndex];

      this.quiz.pages[currentPageIndex] = this.quiz.pages[currentPageIndex - 1];
      this.quiz.pages[currentPageIndex - 1] = tempPage;

      return this.quiz;
    }
    return null;
  }

  movePageDown(pageIndex) {
    const lastIndex = this.quiz.pages.length - 1;
    const currentPageIndex = this.quiz.pages.findIndex(page => page.index === pageIndex);

    if (currentPageIndex < lastIndex) {
      const tempPage = this.quiz.pages[currentPageIndex];

      this.quiz.pages[currentPageIndex] = this.quiz.pages[currentPageIndex + 1];
      this.quiz.pages[currentPageIndex + 1] = tempPage;

      return this.quiz;
    }
    return null;
  }

  moveQuestionUp(pageIndex, questionId) {
    this.quiz.pages.map(page => {
      if (page.index === pageIndex) {
        const currentQuestionIndex = page.questions.findIndex(question => question.id === questionId);

        if (currentQuestionIndex > 0) {
          const tempQuestion = page.questions[currentQuestionIndex];
          page.questions[currentQuestionIndex] = page.questions[currentQuestionIndex - 1];
          page.questions[currentQuestionIndex - 1] = tempQuestion;

          return this.quiz;
        }
        return null;
      }
      return null;
    });
  }

  moveQuestionDown(pageIndex, questionId) {
    this.quiz.pages.map(page => {
      if (page.index === pageIndex) {
        const currentQuestionIndex = page.questions.findIndex(question => question.id === questionId);
        const lastIndex = page.questions.length - 1;

        if (currentQuestionIndex < lastIndex) {
          const tempQuestion = page.questions[currentQuestionIndex];
          page.questions[currentQuestionIndex] = page.questions[currentQuestionIndex + 1];
          page.questions[currentQuestionIndex + 1] = tempQuestion;

          return this.quiz;
        }
        return null;
      }
      return null;
    });
  }

  updateContentInCurrentPage(pageIndex) {
    const { isRandom, questions } = this.updatedPage;

    if (isRandom) {
      this.quiz.pages && this.quiz.pages.map(page => {
        if (page.index === pageIndex) {
          this.quiz.pages[page.index] = this.updatedPage;

          this.getTotalPagesAndQuestions();
          return this.quiz;
        }
        return null;
      });
    } else {
      const builtQuestions = editionModeImageQuestionBuilder(questions);

      this.quiz.pages && this.quiz.pages.map((page => {
        if (page.index === pageIndex) {
          if (this.deletingQuestion) {
            this.toggleDeletingQuestion(false);
            this.quiz.pages[page.index] = this.updatedPage;
            return page.questions;
          }

          page.questions = builtQuestions;

          this.getTotalPagesAndQuestions();
          return page.questions;
        }
        return null;
      }));

      this.getTotalPagesAndQuestions();
      return this.quiz;
    }

    this.getTotalPagesAndQuestions();
    return this.quiz;
  }

  addContentInNewPage() {
    const {
      isRandom, questions, categoryOptions, nbQuestions, nbCategories
    } = this.updatedPage;

    let pageObject = {};

    if (!isRandom) {
      const builtQuestions = editionModeImageQuestionBuilder(questions);

      pageObject = {
        name: '',
        isRandom: false,
        index: this.quiz.pages && this.quiz.pages.length,
        randomizeQuestionOrder: false,
        questions: builtQuestions,
        categoryOptions: []
      };
    }

    if (isRandom) {
      pageObject = {
        name: '',
        isRandom,
        index: this.quiz.pages && this.quiz.pages.length,
        randomizeQuestionOrder: false,
        categoryOptions,
        questions: [],
        nbQuestions,
        nbCategories
      };
    }

    if (this.quiz.pages && this.quiz.pages.length > 0) {
      this.quiz.pages.push(pageObject);
      const index = this.quiz.pages.length - 1;
      this.quiz.pages[index].index = index;

      this.getTotalPagesAndQuestions();
      return this.quiz;
    }

    this.quiz = {
      ...this.quiz,
      pages: [
        pageObject
      ]
    };

    const pageIndex = this.quiz.pages.length - 1;
    this.quiz.pages[pageIndex].index = pageIndex;

    this.getTotalPagesAndQuestions();
    this.updatedPage = {};
    return this.quiz;
  }

  getTotalPagesAndQuestions() {
    if (this.quiz.pages) {
      this.totalPages = this.quiz.pages.length;

      const questionsInArray = this.quiz.pages
        .map(page => page.questions && page.questions.length)
        .filter(element => element !== undefined);

      const categoriesInArray = this.quiz.pages
        .map(page => page.categoryOptions && page.categoryOptions.length)
        .filter(element => element !== undefined);

      this.totalQuestions = questionsInArray.reduce((a, b) => a + b, 0);
      this.totalCategories = categoriesInArray.reduce((a, b) => a + b, 0);
    } else {
      this.totalPages = 0;
      this.totalQuestions = 0;
      this.totalCategories = 0;
    }
  }

  isQuizFormValid(formData) {
    const undefinedPage = formData.pages === undefined;
    const noPage = formData?.pages?.length === 0;
    const isEmptyRegularBlocExists = formData?.pages?.find(page => page.questions?.length === 0 && page.isRandom === false);
    const isEmptyRandomBlocExists = formData?.pages?.find(page => page.categoryOptions?.length === 0 && page.isRandom === true);

    if (formData) {
      if (!formData.title) {
        this.isValid = { status: false, error: 'title' };
        return this.isValid;
      }
      if (!formData.code) {
        this.isValid = { status: false, error: 'code' };
        return this.isValid;
      }
      if (noPage || undefinedPage) {
        this.isValid = { status: false, error: 'noPages' };
        return this.isValid;
      }
      if (isEmptyRegularBlocExists) {
        this.isValid = { status: false, error: 'noQuestions' };
        return this.isValid;
      }
      if (isEmptyRandomBlocExists) {
        this.isValid = { status: false, error: 'noCategories' };
        return this.isValid;
      }

      this.isValid = { status: true, error: '' };
      return this.isValid;
    }
    this.isValid = { status: false, error: 'noData' };
    return this.isValid;
  }

  setIsQuizFormPageValid(status) {
    this.isFormPageValid = status;
  }

  toggleSubmitQuizForm(status) {
    this.isQuizSubmitted = status;
  }

  toggleSelectedTab(tabNumber) {
    this.tabs.forEach((action(tab => {
      if (tab.id === tabNumber) {
        tab.isSelected = true;
      } else {
        tab.isSelected = false;
      }
    })));
  }

  addQuestionsToQuiz(pageIndex, question) {
    this.quiz.pages.map(page => {
      if (page.index === pageIndex) {
        this.newQuestionsList = [...page.questions];
        this.newQuestionsList.push(question);
      }
      return null;
    });
  }
}

export const quizStore = new QuizStore();
