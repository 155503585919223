import { ThemeProvider } from '@material-ui/core';
import { ModalProvider } from 'hooks';
import { configure } from 'mobx';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DATATECNEA_THEME } from 'styles/theme';

import 'locales/i18n';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './app/App';
import './index.css';

configure({
  enforceActions: 'observed'
});

const StyledSnackbarButton = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
  }
`;

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <StyledSnackbarButton onClick={() => closeSnackbar(snackbarKey)}>
      X
    </StyledSnackbarButton>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={DATATECNEA_THEME}>
      <SnackbarProvider
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        autoHideDuration={100000}
        dense={isMobileOnly}
        disableWindowBlurListener
        hideIconVariant
        maxSnack={3}
        preventDuplicate
        style={{ fontSize: '1.4rem', pointerEvents: 'all' }}
      >
        <ModalProvider>
          <App />
        </ModalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

const triggerSkipWaiting = (SW, state) => {
  if (state === 'installed' || state === 'waiting') {
    SW.postMessage({ type: 'SKIP_WAITING' });
  }
};

const updateSW = registration => {
  const newSW = registration.installing || registration.waiting;

  if (newSW) {
    triggerSkipWaiting(newSW, newSW.state);
    newSW.addEventListener('statechange', event => {
      triggerSkipWaiting(newSW, event.target.state);

      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const SWConfig = {
  onUpdate: registration => {
    updateSW(registration);

    registration.addEventListener('updatefound', () => updateSW(registration));
  }
};

serviceWorkerRegistration.register(SWConfig);
