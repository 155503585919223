import { faArrowAltSquareDown, faArrowAltSquareUp, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, Tooltip, Typography
} from '@material-ui/core';
import { DeleteButton } from 'components/_commons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'components/_commons/GenericTable/GenericTable';
import { translate } from 'utils';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const StyledGridItem = styled(Grid)`
  background-color: ${props => props.color};
  padding-left: 10px;
  width: 100%;
`;

export const CustomListItem = observer(({
  startIcon, tagIcon1, tagIcon2, isMovableUp, isMovableDown, onMoveItemUp, onMoveItemDown, title, onDelete, isItemColorChanged
}) => {
  const [itemColor, setItemColor] = useState('var(--grey-lighter)');

  useEffect(() => {
    if (isItemColorChanged) {
      setItemColor('var(--error-color-lighter)');
    } else {
      setItemColor('var(--grey-lighter)');
    }
  }, [isItemColorChanged]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <StyledGridItem color={itemColor} item style={{ margin: '1px 0px' }}>
        <Grid alignItems="center" container direction="row" spacing={2} wrap="nowrap">
          <Grid item>
            {startIcon}
          </Grid>

          <Grid item xs>
            <Typography>{title}</Typography>
          </Grid>

          <Grid item>
            <Grid alignItems="center" container justifyContent="flex-end" spacing={1}>
              {tagIcon1 !== null && (
              <Grid item>
                {tagIcon1}
              </Grid>
              )}
              {tagIcon2 !== null && (
              <Grid item>
                {tagIcon2}
              </Grid>
              )}
              {isMovableDown && (
              <Grid item>
                <Tooltip title={translate('button.moveDown')}>
                  <PrimaryButton onClick={onMoveItemDown}>
                    <FontAwesomeIcon icon={faArrowAltSquareDown} size="xs" />
                  </PrimaryButton>
                </Tooltip>
              </Grid>
              )}
              {isMovableUp && (
              <Grid item>
                <Tooltip title={translate('button.moveUp')}>
                  <PrimaryButton onClick={onMoveItemUp}>
                    <FontAwesomeIcon icon={faArrowAltSquareUp} size="xs" />
                  </PrimaryButton>
                </Tooltip>
              </Grid>
              )}
              <Grid item>
                <DeleteButton onClick={onDelete}>
                  <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                </DeleteButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledGridItem>
    </Grid>
  );
});

CustomListItem.propTypes = {
  startIcon: PropTypes.node,
  tagIcon1: PropTypes.node,
  tagIcon2: PropTypes.node,
  isMovableUp: PropTypes.bool,
  isMovableDown: PropTypes.bool,
  isItemColorChanged: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMoveItemUp: PropTypes.func,
  onMoveItemDown: PropTypes.func
};

CustomListItem.defaultProps = {
  isItemColorChanged: false,
  isMovableDown: false,
  isMovableUp: false,
  onMoveItemDown: () => {},
  onMoveItemUp: () => {},
  startIcon: null,
  tagIcon1: null,
  tagIcon2: null
};
