import { RequestHelper } from 'utils';
import { API_V2_URL } from 'utils/constants';

const translationsUrl = `${API_V2_URL}/translated-data`;

const getTranslationSummary = () => RequestHelper.GET(`${translationsUrl}/count`);

const getLabels = (application, locale) => RequestHelper.GET(
  `${translationsUrl}/front-labels?application=${application}&locale=${locale}`
);

const getTranslationList = filters => RequestHelper.POST(`${translationsUrl}`, filters);

const getTranslationComparisons = (requestParams, filters) => RequestHelper.POST(`${translationsUrl}/comparison?${requestParams}`, filters);

const exportComparison = (requestParams, filters) => RequestHelper.POST(`${translationsUrl}/comparison/export?${requestParams}`, filters);

const importComparison = fileTransferModel => RequestHelper.POST(`${translationsUrl}/comparison/import`, fileTransferModel);

const openCampaign = requestParams => RequestHelper.POST(`${translationsUrl}/campaign/open?${requestParams}`);

const closeCampaign = requestParams => RequestHelper.POST(`${translationsUrl}/campaign/close?${requestParams}`);

const modifyTranslations = body => RequestHelper.POST(`${translationsUrl}/modify`, body);

const saveTranslations = body => RequestHelper.POST(`${translationsUrl}/save`, body);

const submitTranslations = requestParams => RequestHelper.POST(`${translationsUrl}/submit?${requestParams}`);

const acceptTranslations = body => RequestHelper.POST(`${translationsUrl}/accept`, body);

const refuseTranslations = body => RequestHelper.POST(`${translationsUrl}/refuse`, body);

const acceptTranslationsByFilter = (body, sourceLocale) => (
  RequestHelper.POST(`${translationsUrl}/to-validate/accept?sourceLocale=${sourceLocale}`, body)
);

const refuseTranslationsByFilter = (body, sourceLocale) => (
  RequestHelper.POST(`${translationsUrl}/to-validate/refuse?sourceLocale=${sourceLocale}`, body)
);

const resetToValidateTranslationsByFilter = (body, sourceLocale) => (
  RequestHelper.POST(`${translationsUrl}/to-validate/reset?sourceLocale=${sourceLocale}`, body)
);

const dismissTranslations = requestParams => RequestHelper.POST(`${translationsUrl}/dismiss?${requestParams}`);

const validateTranslations = requestParams => RequestHelper.POST(`${translationsUrl}/validate?${requestParams}`);

export const I18nService = {
  getTranslationSummary,
  getTranslationList,
  getTranslationComparisons,
  exportComparison,
  importComparison,
  getLabels,
  openCampaign,
  closeCampaign,
  modifyTranslations,
  saveTranslations,
  submitTranslations,
  acceptTranslations,
  refuseTranslations,
  acceptTranslationsByFilter,
  refuseTranslationsByFilter,
  resetToValidateTranslationsByFilter,
  dismissTranslations,
  validateTranslations
};
