import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import { Text } from 'components';
import { Typography } from '@material-ui/core';

const Header = styled.header`
  margin-bottom: 2rem;
`;

const FormHeader = ({ title, subtitle, subSubTitle }) => (
  <Header>
    <Typography component="p" style={{ fontWeight: 'bold', marginBottom: 10 }} variant="h5">{translate(title)}</Typography>
    {subtitle && <Text margin="0">{translate(subtitle)}</Text>}
    {subSubTitle && <Text margin="0">{translate(subSubTitle)}</Text>}
  </Header>
);

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subSubTitle: PropTypes.string
};

FormHeader.defaultProps = {
  subSubTitle: '',
  subtitle: ''
};

export default FormHeader;
