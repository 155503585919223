import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { DocumentHelper, translate } from 'utils';
import {
  Button, Chip, Grid, Tooltip, Typography
} from '@material-ui/core';
import { faAsterisk, faCheckSquare, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { runInAction } from 'mobx';
import styled from 'styled-components';
import { useModal, useStores } from 'hooks';
import {
  CheckBoxQuestion, CommentQuestion, DropdownQuestion, ImageQuestion, RadioGroupQuestion, RatingQuestion, TextQuestion
} from '..';
import { QUESTION_TYPES_FROM_API, QUIZ_STATUS } from '../QuizHelper';
import { DateQuestion, EmailQuestion, PhoneQuestion } from './QuestionTypes';

const StyledQuestionNumber = styled(Typography)`
  min-width: 25px;
  margin-right: 8px !important;
  padding: 0 5px;
  font-weight: 600 !important;
  background: var(--grey-lighter);
  border: 1px solid var(--colored-subtitle-color);
`;

const QuestionNumber = observer(({ questionId, currentPageData }) => {
  const { examinationStore } = useStores();

  const [quesNumber, setQuesNumber] = useState(1);
  const { questionNumbers, currentPage } = examinationStore;

  const getQuestionNumber = useCallback(() => {
    let page = currentPage;

    if (currentPageData && Object.keys(currentPageData).length > 0) {
      page = currentPageData;
    }

    questionNumbers && questionNumbers.forEach(number => {
      if (number.pageIndex === page?.index && number.questionId === questionId) {
        setQuesNumber(number.questionNumber);
      }
      return null;
    });
  }, [questionNumbers, currentPage, questionId, currentPageData]);

  useEffect(() => {
    getQuestionNumber();
  }, [getQuestionNumber]);

  return (
    <Grid item>
      <StyledQuestionNumber variant="h5">{`${quesNumber}.`}</StyledQuestionNumber>
    </Grid>
  );
});

const QuizQuestion = observer(({ currentQuestionData, currentPageData, quizStatus }) => {
  const { running, ended, displayingResults } = QUIZ_STATUS;
  const {
    checkBox, radioGroup, dropDown, textInput, commentInput, imageRadioPicker, scoreRating,
    dateInput, emailInput, phoneInput
  } = QUESTION_TYPES_FROM_API;

  const displayModal = useModal();

  const { examinationStore } = useStores();

  const {
    examination, currentPage, pageWithTraineeAnswers, isQuestionValid
  } = examinationStore;

  const { quiz: { displayResults, displayValidation, forceValidation } } = examination;

  const {
    title, titleImage, isAnswered, isCorrect, isRequired, type, traineeAnswer, reference, answers
  } = currentQuestionData;

  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyAnswer, setIsEmptyAnswer] = useState(false);
  const [isFreeAnswer, setIsFreeAnswer] = useState(false);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [showCorrection, setShowCorrection] = useState(false);
  const [isReadOnlyQuestion, setIsReadOnlyQuestion] = useState(false);
  const [isCurrentQuestionValid, setIsCurrentQuestionValid] = useState(false);
  const [currentQuestionType, setCurrentQuestionType] = useState('');

  useEffect(() => {
    setIsEmptyAnswer(traineeAnswer === null || (traineeAnswer && traineeAnswer.length === 0));
    setIsFreeAnswer(type === textInput || type === commentInput || type === scoreRating || type === dateInput
      || type === emailInput || type === phoneInput
      || (answers && answers.every((a) => !a.isCorrect)));

    isQuestionValid.forEach(q => {
      if (q.questionType === currentQuestionData.type) {
        setIsCurrentQuestionValid(q.status);
        setCurrentQuestionType(q.questionType);
      }
    });
  }, [
    traineeAnswer,
    type, textInput,
    commentInput, answers,
    scoreRating, dateInput,
    emailInput, phoneInput,
    isQuestionValid,
    currentQuestionData
  ]);

  const toggleShowCorrection = useCallback(() => {
    if ((quizStatus === running && forceValidation === true && displayValidation === true)
      || (quizStatus === ended && displayResults === true)
    || (quizStatus === displayingResults)) {
      return setShowCorrection(true);
    }
    return setShowCorrection(false);
  }, [quizStatus, running, ended, displayingResults, forceValidation, displayValidation, displayResults]);

  const toggleReadOnlyQuestion = useCallback(() => {
    // If the question has already been answered and validated, it cannot be edited
    if (isAnswered && forceValidation) {
      return setIsReadOnlyQuestion(true);
    }
    // If the quiz is over, the question cannot be edited
    if (quizStatus === QUIZ_STATUS.ended) {
      return setIsReadOnlyQuestion(true);
    }
    // If quiz is in display results mode
    if (quizStatus === QUIZ_STATUS.displayingResults) {
      return setIsReadOnlyQuestion(true);
    }
    // Else the question can be edited
    return setIsReadOnlyQuestion(false);
  }, [isAnswered, forceValidation, quizStatus]);

  useEffect(() => {
    toggleReadOnlyQuestion();
    toggleShowCorrection();
  }, [toggleReadOnlyQuestion, toggleShowCorrection]);

  useEffect(() => {
    examinationStore.canGoNextPage();
  }, [currentPage, examinationStore]);

  const toggleTraineeSelectedAnswer = useCallback(() => {
    const answeringQuestion = pageWithTraineeAnswers.questions
      .find(q => currentPage.id === pageWithTraineeAnswers.id && q.id === currentQuestionData.id);

    const noTraineeAnswer = answeringQuestion?.traineeAnswer === null;

    const answeredCheckbox = answeringQuestion?.traineeAnswer
      && Array.isArray(answeringQuestion.traineeAnswer) && answeringQuestion.traineeAnswer.length > 0;

    const answeredText = answeringQuestion?.traineeAnswer
      && !Array.isArray(answeringQuestion.traineeAnswer) && answeringQuestion.traineeAnswer.length > 0;

    const answeredRating = answeringQuestion?.traineeAnswer
      && typeof answeringQuestion?.traineeAnswer === 'number';

    const answeredDate = answeringQuestion?.traineeAnswer
      && answeringQuestion?.traineeAnswer instanceof Date;

    if (answeredCheckbox) {
      setIsAnswerSelected(true);
    } else if (answeredText && isFreeAnswer) {
      setIsAnswerSelected(true);
    } else if (answeredText) {
      setIsAnswerSelected(true);
    } else if (answeredRating) {
      setIsAnswerSelected(true);
    } else if (answeredDate) {
      setIsAnswerSelected(true);
    } else if (noTraineeAnswer) {
      setIsAnswerSelected(false);
    } else {
      setIsAnswerSelected(false);
    }
  }, [pageWithTraineeAnswers, currentQuestionData, currentPage, isFreeAnswer]);

  useEffect(() => {
    if (pageWithTraineeAnswers && pageWithTraineeAnswers.questions && pageWithTraineeAnswers.questions.length > 0) {
      toggleTraineeSelectedAnswer();
    }
  }, [pageWithTraineeAnswers, toggleTraineeSelectedAnswer]);

  const validateAnswer = useCallback((pageIndex, questionId) => {
    setIsLoading(true);
    runInAction(() => {
      examinationStore.updateQuestionService(pageIndex, questionId)
        .finally(() => {
          runInAction(() => {
            examinationStore.canGoNextPage();
          });

          setIsLoading(false);
        });
    });
  }, [examinationStore]);

  const showWarningModal = useCallback((pageIndex, questionId) => displayModal({
    type: 'WARNING',
    title: translate('quiz.noAnswerSelected'),
    text: translate('quiz.noAnswerSelectedConfirmation'),
    buttonCancel: translate('button.cancel'),
    buttonConfirm: translate('button.iAmSure'),
    onConfirm: () => validateAnswer(pageIndex, questionId)
  }), [displayModal, validateAnswer]);

  const handleValidate = useCallback((pageIndex, questionId) => {
    toggleTraineeSelectedAnswer();

    if (isAnswerSelected) {
      return validateAnswer(pageIndex, questionId);
    }
    return showWarningModal(pageIndex, questionId);
  }, [isAnswerSelected, showWarningModal, validateAnswer, toggleTraineeSelectedAnswer]);

  const renderSpecificQuestion = quesType => {
    switch (quesType) {
      case checkBox:
        return (
          <CheckBoxQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case radioGroup:
        return (
          <RadioGroupQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case dropDown:
        return (
          <DropdownQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case textInput:
        return (
          <TextQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case commentInput:
        return (
          <CommentQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case imageRadioPicker:
        return (
          <ImageQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case scoreRating:
        return (
          <RatingQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case dateInput:
        return (
          <DateQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case emailInput:
        return (
          <EmailQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      case phoneInput:
        return (
          <PhoneQuestion
            currentQuestionData={currentQuestionData}
            isReadOnlyQuestion={isReadOnlyQuestion}
            withCorrection={showCorrection}
          />
        );
      default:
        return null;
    }
  };

  const renderSpecificCorrectionTag = useCallback(() => {
    if (isAnswered && isCorrect && !isFreeAnswer && !isEmptyAnswer) {
      return (
        <Chip
          className="pdf-export__content__question__chip"
          label={translate('quiz.correctAnswer')}
          style={{
            backgroundColor: 'var(--success-color)',
            color: 'var(--white)'
          }}
        />
      );
    }
    if (isAnswered && !isCorrect && !isFreeAnswer && !isEmptyAnswer) {
      return (
        <Chip
          className="pdf-export__content__question__chip"
          label={translate('quiz.incorrectAnswer')}
          style={{
            backgroundColor: 'var(--error-color)',
            color: 'var(--white)'
          }}
        />
      );
    }
    if (isAnswered && !isFreeAnswer && isEmptyAnswer) {
      return (
        <Chip
          className="pdf-export__content__question__chip"
          label={translate('quiz.incorrectAnswerNotAnswered')}
          style={{
            backgroundColor: 'var(--error-color)',
            color: 'var(--white)'
          }}
        />
      );
    }
    if (isAnswered && isFreeAnswer) {
      return (
        <Chip
          className="pdf-export__content__question__chip"
          label={translate('quiz.freeAnswer')}
          style={{
            backgroundColor: 'var(--grey-dark)',
            color: 'var(--white)'
          }}
        />
      );
    }
    return null;
  }, [isAnswered, isCorrect, isEmptyAnswer, isFreeAnswer]);

  return (
    <Grid className="pdf-export__content__question" item>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid className="pdf-export__content__question--print-inline" container direction="row" spacing={1}>
            <QuestionNumber
              currentPageData={currentPageData || currentPage}
              questionId={currentQuestionData.id}
            />

            {(quizStatus === ended || quizStatus === displayingResults) && (
              <Grid item>
                {reference && reference.length > 0
                  && (
                    <Typography variant="h6">
                      {` - ${reference} - `}
                    </Typography>
                  )}
              </Grid>
            )}

            <Grid item>
              <Typography variant="h6">
                {title}
                {isRequired && (
                  <FontAwesomeIcon color="var(--error-color)" icon={faAsterisk} size="xs" />
                )}
              </Typography>
            </Grid>

            {showCorrection && (
              <Grid item>
                {renderSpecificCorrectionTag()}
              </Grid>
            )}
          </Grid>
        </Grid>

        {titleImage && (
          <Grid item xs={12}>
            <img
              alt={titleImage}
              src={DocumentHelper.getPNGWithBase64(titleImage)}
              style={{ marginTop: 10, maxHeight: 250, maxWidth: 250 }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {renderSpecificQuestion(type)}
        </Grid>
        {quizStatus === running && forceValidation && (
          <Grid item xs={12}>
            {isAnswerSelected ? (
              <Button
                color="secondary"
                disabled={
                  isLoading
                  || isAnswered
                  || (currentQuestionType === currentQuestionData.type && !isCurrentQuestionValid)
                }
                id="quiz-validate-btn"
                startIcon={<FontAwesomeIcon icon={isLoading ? faSpinner : faCheckSquare} spin={isLoading} />}
                style={{ marginBottom: 20 }}
                variant="contained"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleValidate(currentPage.index, currentQuestionData.id)}
              >
                {translate(isAnswered ? 'forms.element.quiz.savedAnswer' : 'button.saveAnswer')}
              </Button>
            )
              : (
                <Tooltip title={isAnswered ? '' : translate('errors.validateQuestion')}>
                  <span>
                    <Button
                      color="secondary"
                      disabled={isRequired && !isAnswered}
                      id="quiz-validate-btn"
                      startIcon={<FontAwesomeIcon icon={faCheckSquare} />}
                      style={{ marginBottom: 20 }}
                      variant="contained"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => handleValidate(currentPage.index, currentQuestionData.id)}
                    >
                      {translate(isAnswered ? 'forms.element.quiz.savedAnswer' : 'button.saveAnswer')}
                    </Button>
                  </span>
                </Tooltip>
              )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default QuizQuestion;

QuizQuestion.propTypes = {
  pageId: PropTypes.string,
  currentPageData: PropTypes.shape({}),
  currentQuestionData: PropTypes.shape({}),
  quizStatus: PropTypes.string.isRequired
};

QuizQuestion.defaultProps = {
  currentPageData: {},
  currentQuestionData: {},
  pageId: ''
};

QuestionNumber.propTypes = {
  currentPageData: PropTypes.shape({}),
  questionId: PropTypes.string
};

QuestionNumber.defaultProps = {
  currentPageData: {},
  questionId: ''
};
