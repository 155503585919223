import {
  faDownload, faFile,
  faUserCircle
} from '@fortawesome/pro-regular-svg-icons';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid, IconButton,
  makeStyles, Tooltip, Typography
} from '@material-ui/core';
import {
  InfoLine,
  SkeletonDetailUser,

  TextError,
  Wrapper
} from 'components';
import { CustomCardTitle } from 'components/_commons/Card/CustomTitle/CustomCardTitle';
import { CompanyDetailCard } from 'components/Company/CompanyDetailCard/CompanyDetailCard';
import { DocumentDisplay } from 'components/Document/DocumentDisplay';
import { InstitutionDetailCard } from 'components/Institution/InstitutionDetailCard/InstitutionDetailCard';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  CompanyService, UserService
} from 'services';
import { PurchaseOrderService } from 'services/PurchaseOrderService';
import shortid from 'shortid';
import { userStore } from 'stores';
import { DocumentHelper, formatDate, translate } from 'utils';
import { MAX_FILE_SIZE, ROUTES } from 'utils/constants';

const useStyles = makeStyles(() => ({
  gridItem: {
    minWidth: '350px',
    marginTop: '4rem'
  },
  action: {
    justifyContent: 'flex-end'
  }
}));

const EditButton = ({ condition = true, handleAction }) => (
  condition && (
    <Button
      aria-label={translate('button.edit')}
      color="secondary"
      variant="contained"
      onClick={handleAction}
    >
      {translate('button.edit')}
    </Button>
  )
);

const SubscriptionDetail = () => {
  const { id } = useParams();
  const classes = useStyles();
  const displayModal = useModal();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const purchaseOrderInputFile = useRef(null);
  const additionalFileInputFile = useRef(null);

  const [refreshSubscription, setRefreshSubscription] = useState(false);

  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
() => {
    setIsLoading(true);
    UserService.getSubscriptionDetail(id)
      .then(responseSubscriptionDetail => {
        if (responseSubscriptionDetail.company) {
          CompanyService.getCompany(responseSubscriptionDetail.company.id)
            .then(responseCompany => setSubscriptionDetail({ ...responseSubscriptionDetail, company: responseCompany }));
        } else {
          setSubscriptionDetail(responseSubscriptionDetail);
        }
      }).finally(() => setIsLoading(false));
  },
  [refreshSubscription, id]
);

  const handleChangePurchaseOrder = useCallback((event) => {
    DocumentHelper.handleFileUpload(event, false, MAX_FILE_SIZE)
      .then(doc => {
        PurchaseOrderService.changePurchaseOrder(
          subscriptionDetail.hashId,
          { ...doc, base64Content: DocumentHelper.getDocumentWithoutBase64(doc) }
        )
          .then(() => {
            setSubscriptionDetail({
              ...subscriptionDetail,
              purchaseOrder: { ...doc, base64Content: DocumentHelper.getDocumentWithoutBase64(doc) }
            });
            enqueueSnackbar(translate('subscriptionRequest.purchaseOrder.modified'), { variant: 'success' });
          })
          .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      });
  }, [subscriptionDetail, enqueueSnackbar]);

  const handleChangeAdditionalFile = useCallback((event) => {
    const oldDocId = event.target.name;
    DocumentHelper.handleFileUpload(event, false, MAX_FILE_SIZE)
      .then(doc => {
        PurchaseOrderService.changeAdditionalFiles(
          subscriptionDetail.hashId,
          [...subscriptionDetail.additionalFiles.documents, {
            ...doc,
            base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
          }].filter(newDoc => newDoc.id !== oldDocId)
        )
          .then((resp) => {
            setSubscriptionDetail(
              {
                ...subscriptionDetail,
                additionalFiles: resp
              }
            );
            enqueueSnackbar(translate('subscriptionRequest.additionalFiles.modified'), { variant: 'success' });
          })
          .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      });
  }, [subscriptionDetail, enqueueSnackbar]);

  const handleFileClick = useCallback(e => {
    e.target.value = '';
  }, []);

  if (isLoading) {
    return <SkeletonDetailUser />;
  }

  if (!isLoading && !subscriptionDetail) {
    return (
      <Grid alignContent="center" container direction="column" justifyContent="center">
        <TextError>{translate('errors.noSubscriptionDetail')}</TextError>
        <Link to={ROUTES.USER_LIST}>
          <Button color="secondary" variant="contained">
            {translate('button.backToAdminPage')}
          </Button>
        </Link>
      </Grid>
    );
  }

  const editUser = form => {
    userStore.updateStart();
    UserService.updateUser(form.person.id, form).then(() => {
      displayModal({
        type: 'CONFIRM',
        text: translate('confirms.userDetail.update'),
        onConfirm: () => setRefreshSubscription(!refreshSubscription)
      });
    }).catch(error => {
      enqueueSnackbar((error && error.message) || error, { variant: 'error' });
    }).finally(() => userStore.updateEnd());
  };

  const handleEditUser = () => displayModal({
    type: 'UPDATE_USER', onConfirm: editUser, defaultValues: { ...subscriptionDetail.userAccount, company: subscriptionDetail.company }, closeOnSubmit: false
  });

  const isValidationEnabled = Boolean(subscriptionDetail.userAccount.person.validated
    && subscriptionDetail.institution && subscriptionDetail.institution.validated
    && subscriptionDetail.company && subscriptionDetail.company.validated);

  const validateSubscription = () => {
    UserService.validateSubscription(id)
      .then(() => displayModal({
        type: 'CONFIRM',
        text: translate('confirms.subscriptionDetail.validate'),
        onConfirm: () => history.push(ROUTES.SUBSCRIPTION_LIST)
      }))
      .catch(error => displayModal({ type: 'ERROR', text: error }));
  };

  const rejectSubscriptionRequest = () => {
    UserService.rejectSubscription(id)
      .then(() => displayModal({
        type: 'CONFIRM',
        text: translate('confirms.subscriptionDetail.reject'),
        onConfirm: () => history.push(ROUTES.SUBSCRIPTION_LIST)
      }))
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  };

  const rejectSubscription = () => {
    displayModal({
      type: 'WARNING',
      text: translate('warnings.rejectSubscription'),
      buttonCancel: translate('button.cancel'),
      buttonConfirm: translate('button.confirm'),
      onConfirm: rejectSubscriptionRequest
    });
  };

  const displayPurchaseOrderCard = (purchaseOrderDocument, sectionLabel, inputRef) => {
    if (!purchaseOrderDocument) return <></>;
    const documentName = purchaseOrderDocument.name;
    const documentData = purchaseOrderDocument
      && DocumentHelper.getDocumentWithBase64(purchaseOrderDocument.name, purchaseOrderDocument.content ?? purchaseOrderDocument.base64Content);
    return (
      <Grid item style={{ marginBottom: '20px' }}>
        <Card>
          <CardHeader
            action={
              <Grid container direction="row">
                <Grid item>
                  <Tooltip title={translate('button.replaceDocument')}>
                    <IconButton onClick={() => {
                      inputRef.current.name = purchaseOrderDocument.id;
                      inputRef.current.click();
                    }}>
                      <FontAwesomeIcon icon={faPen} size="sm" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {!DocumentHelper.isPdf({ name: documentName }) && (
                <Grid item>
                  <Tooltip title={translate('button.download')}>
                    <IconButton onClick={() => DocumentHelper.downloadDocumentFromBase64(documentData, documentName)}>
                      <FontAwesomeIcon icon={faDownload} size="sm" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              </Grid>
            }
            style={{ backgroundColor: 'var(--grey-light)' }}
            title={(
              <Typography align="center" component="h2" style={{ textTransform: 'uppercase' }} variant="h4">
                <FontAwesomeIcon icon={faFile} style={{ marginRight: '1rem' }} />
                {sectionLabel}
              </Typography>
            )}
          />
          <CardContent style={{ alignContent: 'center', padding: 0 }}>
            <DocumentDisplay
              data={documentData}
              documentName={documentName}
              downloadLabel={translate('common.documentTooBig')}
              height="80vh"
              width="100%"
            />
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Wrapper>
      <PageTitle
        args={{ app: subscriptionDetail.application }}
        subtitle={translate('pageSubscriptionDetail.subtitle', { date: formatDate(subscriptionDetail.date) })}
        title={translate('pageSubscriptionDetail.title', { app: subscriptionDetail.application.label })}
        titleRight={(
          <Grid
            alignItems="flex-end"
            container
            direction="column"
            spacing={1}
            style={{ marginTop: '1rem' }}
          >
            <Grid item>
              <Button
                color="primary"
                disabled={!isValidationEnabled}
                key={shortid.generate()}
                variant="contained"
                onClick={validateSubscription}
              >
                {translate('button.validateSubscription')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                key={shortid.generate()}
                variant="contained"
                onClick={rejectSubscription}
              >
                {translate('button.rejectSubscription')}
              </Button>
            </Grid>
          </Grid>
        )}
      />

      <Grid container spacing={4} style={{ marginBottom: '20px' }}>
        {subscriptionDetail.company && (
          <Grid className={classes.gridItem} item md={6} xs={12}>
            <CompanyDetailCard
              afterEdit={() => setRefreshSubscription(!refreshSubscription)}
              canEdit
              company={{
                ...subscriptionDetail.company,
                databilanAccountLimit: subscriptionDetail.accountsNeeded
              }}
              withDatabilanAcc={subscriptionDetail.application.value === 'DATABILAN_OPERATOR'}
            />
          </Grid>
        )}

        {subscriptionDetail.institution && (
          <Grid className={classes.gridItem} item md={6} xs={12}>
            <InstitutionDetailCard
              afterEdit={() => setRefreshSubscription(!refreshSubscription)}
              canEdit
              institution={subscriptionDetail.institution}
            />
          </Grid>
        )}

        {subscriptionDetail.userAccount && (
          <Grid className={classes.gridItem} item md={6} xs={12}>
            <Card>
              <CustomCardTitle
                icon={faUserCircle}
                title={translate('common.userName')}
                validated={subscriptionDetail.userAccount.person.validated}
              />
              <CardHeader
                avatar={(
                  <Avatar>
                    {`${subscriptionDetail.userAccount.person.firstName.charAt(0)}
                      ${subscriptionDetail.userAccount.person.lastName.charAt(0)}`}
                  </Avatar>
                )}
                subheader={translate('pageSubscriptionDetail.subtitle', {
                  date: formatDate(subscriptionDetail.userAccount.person.creationDate)
                })}
                title={`${subscriptionDetail.userAccount.person.firstName} ${subscriptionDetail.userAccount.person.lastName}`}
              />
              <CardContent>
                {/* <InfoLine
                    info={<Chip label={subscriptionDetail.wantedRole.label} />}
                    label="pageUserDetail.wantedRoles"
                  /> */}
                {subscriptionDetail.userAccount.person && subscriptionDetail.userAccount.person.contact
                  && (
                    <>
                      <InfoLine info={subscriptionDetail.userAccount.person.contact.contactName} label="common.contactName" />
                      <InfoLine
                        displayIf={subscriptionDetail.userAccount.person.contact.contactEmails}
                        info={(
                          <a href={`mailto:${subscriptionDetail.userAccount.person.contact.contactEmails}`}>
                            {subscriptionDetail.userAccount.person.contact.contactEmails}
                          </a>
                        )}
                        label="common.email"
                      />
                      <InfoLine
                        displayIf={subscriptionDetail.userAccount.person.contact.phoneNumber}
                        info={<a href={`tel:${subscriptionDetail.userAccount.person.contact.phoneNumber}`}>{subscriptionDetail.userAccount.person.contact.phoneNumber}</a>}
                        label="common.phone"
                      />
                    </>
                  )}
              </CardContent>
              <CardActions className={classes.action}>
                <EditButton
                  handleAction={handleEditUser}
                />
              </CardActions>
            </Card>
          </Grid>
        )}
      </Grid>
      {
        subscriptionDetail?.purchaseOrder
        && displayPurchaseOrderCard(subscriptionDetail?.purchaseOrder, translate('common.purchaseOrder'), purchaseOrderInputFile)
      }
      {
        subscriptionDetail?.additionalFiles?.documents?.map((additionalFile, index) => additionalFile
          && displayPurchaseOrderCard(additionalFile, `${translate('common.purchaseOrderComplement')} ${index + 1}`, additionalFileInputFile))
      }
      <input
        id="purchaseOrder_input"
        name="purchaseOrder"
        ref={purchaseOrderInputFile}
        style={{ visibility: 'hidden' }}
        type="file"
        onChange={handleChangePurchaseOrder}
        onClick={handleFileClick}
      />
      <input
        id="additionalFile_input"
        name="additionalFile"
        ref={additionalFileInputFile}
        style={{ visibility: 'hidden' }}
        type="file"
        onChange={handleChangeAdditionalFile}
        onClick={handleFileClick}
      />
    </Wrapper>
  );
};

export default SubscriptionDetail;
