import { faChevronSquareLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NEW_TAB_FROM_CATEGORY_LIST, ROUTES } from 'utils/constants';
import { StorageHelper } from 'utils/helpers';

export const PageSubtitle = ({ subtitle }) => (
  <Typography align="left" component="h2" style={{ textTransform: 'uppercase' }} variant="h4">
    {subtitle}
  </Typography>
);

const StyledPageTitle = styled(Grid)`
  position: relative;
  min-height: 80px;

  h1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
  }

  button {
    position: relative;
    z-index: 1;
  }
`;

export const PageTitle = ({
  title,
  subtitle = '',
  titleLeft,
  titleRight,
  onGoBack
}: {
  onGoBack?: () => void,
  subtitle?: string,
  title: string,
  titleLeft?,
  titleRight?
}) => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    if (onGoBack) {
      return onGoBack();
    }
    const isNewTab = StorageHelper.GET(NEW_TAB_FROM_CATEGORY_LIST) === 'true';
    if (isNewTab) {
      StorageHelper.REMOVE(NEW_TAB_FROM_CATEGORY_LIST);
      return history.push({
        pathname: ROUTES.ADMIN_CATEGORIES,
        state: {}
      });
    }
    return history.goBack();
  }, [history, onGoBack]);

  const DefaultBackArrow = () => (
    <Button onClick={handleGoBack}>
      <FontAwesomeIcon
        color="var(--primary-color)"
        icon={faChevronSquareLeft}
        size="2x"
        style={{ marginRight: 0 }}
      />
    </Button>
  );

  return (
    <StyledPageTitle alignItems="center" container justifyContent="flex-start">
      <Grid item xs={1}>
        {titleLeft || <DefaultBackArrow />}
      </Grid>
      <Grid item {...(titleRight ? { lg: 4, xs: 3 } : {})}>
        <Typography variant="h2">
          {title}
          <br />
          {subtitle && (<Typography component="small">{subtitle}</Typography>)}
        </Typography>
      </Grid>
      {titleRight && (
        <Grid item lg={7} xs={8}>
          <Grid alignItems="center" container direction="row" justifyContent="flex-end">
            {titleRight}
          </Grid>
        </Grid>
      )}
    </StyledPageTitle>
  );
};
