import { RequestHelper } from 'utils';
import { API_V2_URL } from 'utils/constants';

const activityUrl = `${API_V2_URL}/activities`;

const getAllActivities = () => RequestHelper.GET(activityUrl);

export const ActivityService = {
  getAllActivities
};
