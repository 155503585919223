import { API_V2_URL, INSEE_API_TOKEN } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const companyUrl = `${API_V2_URL}/companies`;
const inseeUrl = 'https://api.insee.fr/entreprises/sirene/V3.11';
// Docs : https://api.insee.fr/catalogue/site/themes/wso2/subthemes/insee/pages/item-info.jag?name=Sirene&version=V3&provider=insee

const customHeadersInseeApi = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${INSEE_API_TOKEN}`
};

// GET

// INSEE
const getCompanyBySiren = (siren = '') => RequestHelper.GET(`${inseeUrl}/siren/${siren}`, customHeadersInseeApi);

const getInstitutionBySiret = (siret = '') => RequestHelper.GET(`${inseeUrl}/siret/${siret}`, customHeadersInseeApi);

const getCompany = companyId => RequestHelper.GET(`${companyUrl}/${companyId}`);
const isPublic = companyId => RequestHelper.GET(`${companyUrl}/${companyId}/public`);

const getCompanyTree = (companyId, activity) => RequestHelper.GET(`${companyUrl}/${companyId}/tree?activity=${activity}`);

const getParentCompany = companyId => RequestHelper.GET(`${companyUrl}/${companyId}/parent`);

// POST
const getCompanyList = (filter, page = 0) => RequestHelper.POST(`${companyUrl}/filter?page=${page}`, filter);

const getCompanyStructureLevels = companyId => RequestHelper.POST(`${companyUrl}/${companyId}/structureLevels`);
const getCompanyStructureLevelsWithAactivity = (companyId, activity) => RequestHelper.POST(`${companyUrl}/${companyId}/structureLevels?activity=${activity}`);

const getCompanyOptions = (params = {}) => RequestHelper.POST(`${companyUrl}/options`, params);

const createCompany = company => RequestHelper.POST(`${companyUrl}`, company);

const exportCompanies = (params = {}) => RequestHelper.POST(`${companyUrl}/export`, params);

const exportCompaniesAsynchronous = (params = {}) => RequestHelper.POST(`${companyUrl}/export-asynchronous`, params);

// PUT
const updateCompany = (id, company) => RequestHelper.PUT(`${companyUrl}/${id}`, company);

// DELETE
const deleteCompany = companyId => RequestHelper.DELETE(`${companyUrl}/${companyId}`);

export const CompanyService = {
  getCompanyBySiren,
  getInstitutionBySiret,
  getCompany,
  getCompanyTree,
  getParentCompany,
  getCompanyList,
  getCompanyOptions,
  createCompany,
  updateCompany,
  exportCompanies,
  exportCompaniesAsynchronous,
  getCompanyStructureLevels,
  getCompanyStructureLevelsWithAactivity,
  deleteCompany,
  isPublic
};
