import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DocumentHelper, translate } from 'utils';

export const DocumentDisplay = ({
  data, documentName, downloadLabel, height, width
}) => {
  const isPdf = DocumentHelper.isPDFWithBase64(data);
  const isChrome = navigator.userAgent.includes('Chrome');
  const documentSize = atob(data.split(',')[1]).length / 1000;
  const documentIsTooBig = documentSize > 6000;
  const documentIsTooBigForChrome = documentSize > 1500;
  const canBeDownload = documentIsTooBig || (isChrome && documentIsTooBigForChrome);

  const downloadDocument = useCallback(() => {
    DocumentHelper.downloadDocumentFromBase64(data, documentName);
  }, [data, documentName]);

  const PDFContainer = styled.object`
    width: 100%;
    min-height: ${isPdf && '100%'};
    padding: ${!isPdf && '15px'};
  `;

  return (
    <div style={{ overflowX: 'hidden', overflowY: isPdf ? 'hidden' : 'auto', height: '100%' }}>
      <div style={{ height, width }}>
        {canBeDownload
          ? (
            <Grid alignItems="center" container direction="column" item justifyContent="center" spacing={2} style={{ height: '100%' }}>
              <Grid item>
                <Typography>{downloadLabel}</Typography>
              </Grid>
              <Grid item>
                <Typography>{documentName}</Typography>
              </Grid>
              <Grid item>
                <Tooltip title={translate('button.download')}>
                  <IconButton onClick={() => downloadDocument()}>
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="xl"
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )
          : (
            <PDFContainer data={data} />
          )}
      </div>
    </div>
  );
};
