import { DateHelper } from 'utils/helpers/DateHelper';
import { StorageHelper } from 'utils/helpers/StorageHelper';

const FILTER_PREFIX = 'filters_';

const setFilters = (filterKey, filters) => StorageHelper.SET(`${FILTER_PREFIX}${filterKey}`, filters);

const getFilters = filterKey => {
  const storedFilter = StorageHelper.GET(`${FILTER_PREFIX}${filterKey}`);

  if (storedFilter) {
    return JSON.parse(StorageHelper.GET(`${FILTER_PREFIX}${filterKey}`));
  }

  return null;
};

const removeFilters = filterKey => {
  StorageHelper.REMOVE(`${FILTER_PREFIX}${filterKey}`);
};

const deleteStoredFilters = () => {
  Object.entries(localStorage)
    .map(key => key[0])
    .filter(key => key.startsWith('filters_'))
    .forEach(filter => localStorage.removeItem(filter));
};

const initializeDefaultFilters = () => {
  if (!getFilters('resultsList')) {
    setFilters('resultsList', [{
      key: 'minDate',
      label: DateHelper.getDateOneYearInThePast()
    }]);
  }
};

export const FilterHelper = {
  getFilters,
  setFilters,
  deleteStoredFilters,
  initializeDefaultFilters,
  removeFilters
};
