import {
  action, makeObservable, observable
} from 'mobx';
import { QuestionService } from 'services';

export class QuestionStore {
  constructor() {
    this.isSpinning = false;
    this.question = { };

    makeObservable(this, {
      isSpinning: observable,
      question: observable,
      loadQuestion: action,
      purgeQuestion: action,
      createUpdateQuestion: action
    });
  }

  /**
   * Fetches the question details.
   *
   * @param {string} questionId
   * @param {boolean} isCopy
   */
  loadQuestion(questionId, isCopy) {
    const service = isCopy ? QuestionService.getQuestionCopy : QuestionService.getQuestion;
    this.isSpinning = true;
    return service(questionId)
      .then(action(question => {
        this.question = {
          ...question
        };
      }))
      .finally(action(() => { this.isSpinning = false; }));
  }

  /**
   * @param {string} questionInstanceId
   */
  loadQuestionInstance(questionInstanceId) {
    this.isSpinning = true;
    return QuestionService.getQuestionInstance(questionInstanceId)
      .then(action(question => {
        this.question = {
          ...question
        };
      }))
      .finally(action(() => { this.isSpinning = false; }));
  }

  /**
   * @param {string} questionId
   * @param {object} question
   */
  createUpdateQuestion(questionId, question) {
    this.isSpinning = true;
    if (questionId) {
      return QuestionService.updateQuestion(questionId, question)
        .finally(action(() => { this.isSpinning = false; }));
    }
    return QuestionService.createQuestion(question)
      .finally(action(() => { this.isSpinning = false; }));
  }

  purgeQuestion() {
    this.question = { };
    return this.question;
  }
}

export const questionStore = new QuestionStore();
