import {
  faCheck, faEye, faEyeSlash,
  faLanguage,
  faPlus,
  faTimes
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, TableCell, Tooltip
} from '@material-ui/core';
import {
  Button,
  GenericTableAccordion,
  TextError
} from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';

import {
  faEdit, faEllipsisV, faLockAlt, faLockOpenAlt
} from '@fortawesome/pro-regular-svg-icons';
import { DropdownButton } from 'components/_commons';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import { useHistory } from 'react-router-dom';
import { I18nService, LanguageService } from 'services';
import { translate } from 'utils';
import { API_ROUTES, APPLICATION_ROLES } from 'utils/constants';
import { TranslationHelper, UserHelper } from 'utils/helpers';

const getValidationIcon = row => (
  <FontAwesomeIcon
    className="mr1"
    color={TranslationHelper.isLanguageValidated(row) ? 'var(--success-color)' : 'var(--error-color)'}
    fixedWidth
    icon={TranslationHelper.isLanguageValidated(row) ? faCheck : faTimes}
  />
);

const actionIconButton = (onClick, icon, label) => (
  <Button
    edge="end"
    startIcon={<FontAwesomeIcon icon={icon} />}
    type="secondary"
    onClick={onClick}
  >
    {label}
  </Button>
);

const listHeadersAccordion = handleAddLanguage => [
  {
    name: 'applicationLabel',
    template: row => (
      <div style={{ marginTop: 6, marginBottom: 6 }}>
        { getValidationIcon(row) }
        { (row.applicationLabel || '-') }
      </div>
    )
  },
  {
    name: 'applicationTotal',
    template: row => (row.total ?? '-')
  },
  {
    name: 'applicationMissing',
    template: row => (row.missing ?? '-')
  },
  {
    name: 'applicationSuggested',
    template: row => (row.suggested ?? '-')
  },
  {
    name: 'applicationAddLanguage',
    template: row => (
      <div>
        {UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN]) && (
        <Tooltip title={translate('pageTranslationSummary.action.label.addLanguage')}>
          <IconButton color="primary" edge="end" style={{ padding: 6 }} onClick={event => handleAddLanguage(row, event)}>
            <FontAwesomeIcon fixedWidth icon={faPlus} size="xs" />
          </IconButton>
        </Tooltip>
        )}
      </div>
    )
  }
];

const listHeaders = (handleShowDetails, handleChangeState) => [
  {
    name: translate('pageTranslationSummary.column.language'),
    label: 'pageTranslationSummary.column.language',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container>
          <Grid item>
            { getValidationIcon(row) }
          </Grid>
          <Grid item>
            { translate(`languages.${row.locale}`) || '-' }
          </Grid>
          <Grid item>
            <Tooltip
              title={translate(`pageTranslationSummary.tooltip.${row.campaignOpen ? 'campaignOpen' : 'campaignClosed'}`)}
            >
              <span>
                {(row.campaignOpen)
                  ? <FontAwesomeIcon className="ml1" icon={faLockOpenAlt} />
                  : <FontAwesomeIcon className="ml1" icon={faLockAlt} />}
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={translate(`pageTranslationSummary.tooltip.${row.languageVisible ? 'languageShown' : 'languageHidden'}`)}
            >
              <span>
                {(row.languageVisible)
                  ? <FontAwesomeIcon className="ml1" icon={faEye} />
                  : <FontAwesomeIcon className="ml1" icon={faEyeSlash} />}
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    )
  },
  {
    name: translate('pageTranslationSummary.column.total'),
    label: 'pageTranslationSummary.column.total',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.total ?? '-'}
      </TableCell>
    )
  }, {
    name: translate('pageTranslationSummary.column.missing'),
    label: 'pageTranslationSummary.column.missing',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.missing ?? '-'}
      </TableCell>
    )
  }, {
    name: translate('pageTranslationSummary.column.suggested'),
    label: 'pageTranslationSummary.column.suggested',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.suggested ?? '-'}
      </TableCell>
    )
  }, {
    name: translate('pageTranslationSummary.column.edit'),
    label: TranslationHelper.isValidator() ? 'pageTranslationSummary.column.actions' : 'pageTranslationSummary.column.edit',
    template: row => (
      <TableCell key={shortid.generate()}>
        {TranslationHelper.isValidatorForAppAndLanguage(row.application, row.locale)
          ? (
            <DropdownButton
              buttonStyle={{ padding: 6 }}
              color="primary"
              icon={faEllipsisV}
              iconSize="xs"
              isIconOnly
              placement="bottom"
              popperMargin="6px"
            >
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {actionIconButton(
                    () => handleShowDetails(row),
                    faEdit,
                    translate('pageTranslationSummary.column.edit')
                  )}
                </Grid>
                <Grid item>
                  {(row.campaignOpen)
                    ? actionIconButton(
                      () => handleChangeState(row, I18nService.closeCampaign),
                      faLockOpenAlt,
                      translate('pageTranslationSummary.action.label.closeCampaign')
                    )
                    : actionIconButton(
                      () => handleChangeState(row, I18nService.openCampaign),
                      faLockAlt,
                      translate('pageTranslationSummary.action.label.openCampaign')
                    )}
                </Grid>
                <Grid item>
                  {(row.languageVisible)
                    ? actionIconButton(
                      () => handleChangeState(row, LanguageService.deactivateLanguageForApplication),
                      faEyeSlash,
                      translate('pageTranslationSummary.action.label.hideLanguage')
                    )
                    : actionIconButton(
                      () => handleChangeState(row, LanguageService.activateLanguageForApplication),
                      faEye,
                      translate('pageTranslationSummary.action.label.showLanguage')
                    )}
                </Grid>
              </Grid>
            </DropdownButton>
          )
          : (
            <Tooltip title={translate('pageTranslationSummary.column.edit')}>
              <IconButton color="primary" edge="end" style={{ padding: 6 }} onClick={() => handleShowDetails(row)}>
                <FontAwesomeIcon fixedWidth icon={faEdit} size="xs" />
              </IconButton>
            </Tooltip>
          )}
      </TableCell>
    )
  }
];

const TranslationSummary = () => {
  const history = useHistory();
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryList, setSummaryList] = useState({});
  const [defaultExpanded, setDefaultExpanded] = useState([]);

  const loadSummary = useCallback(() => {
    setIsLoading(true);
    I18nService.getTranslationSummary().then(resp => {
      const formattedTranslations = TranslationHelper.formatSummary(resp);
      setSummaryList(formattedTranslations);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    loadSummary();
  }, [loadSummary]);

  const saveApplicationOpen = useCallback(() => {
    const applicationOpen = document.querySelectorAll('[data-application][data-open="true"]');
    setDefaultExpanded(Object.values(applicationOpen).map(app => app.dataset.application));
  }, []);

  const handleShowDetails = useCallback(row => {
    history.push(API_ROUTES.ADMIN_TRANSLATION_LIST_DETAIL(row.application, localStorage.getItem('i18nextLng'), row.locale));
  }, [history]);

  const handleChangeState = useCallback((row, serviceFunction) => {
    setIsLoading(true);
    saveApplicationOpen();
    serviceFunction(`application=${row.application}&locale=${row.locale}`)
      .then(() => loadSummary())
      .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [loadSummary, saveApplicationOpen, enqueueSnackbar]);

  const handleAddLanguage = (row, event) => {
    saveApplicationOpen();
    event.stopPropagation();
    displayModal({
      type: 'ADD_LANGUAGE',
      applicationName: row.application,
      onConfirm: language => {
        setIsLoading(true);
        LanguageService.addLanguageForApplication(`application=${row.application}&locale=${language.locale}`)
          .then(() => loadSummary())
          .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
          .finally(() => setIsLoading(false));
      }
    });
  };

  const TranslationSummaryTable = ({ listHeadersTable, loading }) => (
    !loading && Object.entries(summaryList).length === 0
      ? (
        <Grid alignItems="center" container direction="column">
          <FontAwesomeIcon color="var(--primary-color)" icon={faLanguage} size="3x" />
          <TextError>{translate('errors.noTranslationSummary')}</TextError>
        </Grid>
      ) : (
        <GenericTableAccordion
          dataCy="summaryList"
          defaultExpanded={defaultExpanded}
          headers={listHeadersTable}
          headersAccordion={listHeadersAccordion(handleAddLanguage)}
          id="summaryList"
          isLoading={loading}
          rows={summaryList}
          total={summaryList.length}
        />
      )
  );

  return (
    <>
      <PageTitle title={translate('pageTranslationSummary.title')} />

      <div data-tour="step-admin-toolList">
        <TranslationSummaryTable
          listHeadersTable={listHeaders(handleShowDetails, handleChangeState)}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default TranslationSummary;
