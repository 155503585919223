import React, { useCallback, useState } from 'react';

import { TableCell } from '@material-ui/core';
import {
  GenericListContainer, GenericListFilters, GenericTable, SkeletonLine
} from 'components/_commons';
import { useEffectAfterRender } from 'hooks/UseEffectAfterRender';
import useInfiniteLoading from 'hooks/useInfiniteList';
import { PageTitle } from 'pages';
import { useHistory } from 'react-router-dom';
import { PurchaseOrderService } from 'services/PurchaseOrderService';
import shortid from 'shortid';
import { formatDate, translate } from 'utils';
import { PurchaseOrderListFilters } from './PurchaseOrderListFilters';

const listHeaders = () => [
  {
    name: 'identifier',
    label: translate('pagePurchaseOrderList.listHeader.identifier'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.institutionIdentifier || '-'}
      </TableCell>
    )
  },
  {
    name: 'institutionName',
    label: translate('pagePurchaseOrderList.listHeader.institutionName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.institutionName || '-'}
      </TableCell>
    )
  },
  {
    name: 'transmissionDate',
    label: translate('pagePurchaseOrderList.listHeader.transmissionDate'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {formatDate(row.requestTransmissionDate)}
      </TableCell>
    )
  }
];

export const PurchaseOrderList = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(
    [{
      key: 'validationStatus',
      value: 'TO_VALIDATE',
      label: translate('print.enum.ValidationStatus.TO_VALIDATE')
    }]
  );

  const loadList = useCallback(pageNumber => PurchaseOrderService.filterPurchaseOrderList(
    pageNumber,
    {
      freeSearch: search,
      institutionName: filters.find(filt => filt.key === 'institutionName')?.label || '',
      institutionIdentifier: filters.find(filt => filt.key === 'identifier')?.label || '',
      validationStatus: filters.find(filt => filt.key === 'validationStatus')?.value || null
    }
  ), [filters, search]);

  const {
    items,
    hasMore,
    isLoading,
    totalSize,
    reloadList,
    loadMore
  } = useInfiniteLoading(loadList);

  useEffectAfterRender(() => reloadList(), [filters, search]);

  const onRowClick = useCallback(row => history.push(`/administration/bons-de-commande/${row.requestId}`), [history]);

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <PurchaseOrderListFilters currentFilters={currentFilters} setCurrentFilters={setCurrentFilters} />
  ), []);
  return (
    <GenericListContainer>
      <PageTitle title={translate('pagePurchaseOrderList.title')} />
      <GenericListFilters
        ComponentFilter={renderFilters}
        dataTour="step-admin-purchaseOrderFilters"
        filters={filters}
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
        tooltipInfo="pagePurchaseOrderList.searchTooltip"
        withDrawer
      />
      <GenericTable
        dataCy="pagePurchaseOrderList"
        hasMore={hasMore}
        headers={listHeaders()}
        isLoading={isLoading}
        loadMore={loadMore}
        rows={items}
        total={totalSize}
        onRowClick={onRowClick}
      />
      {isLoading && items.length === 0 && <SkeletonLine />}

    </GenericListContainer>
  );
};
