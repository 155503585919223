import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, InputAdornment, TextField as TextFieldMat } from '@material-ui/core';
import { InfoTooltip } from 'components';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';

export const AdornmentLoader = () => (
  <InputAdornment position="end">
    <FontAwesomeIcon icon={faSpinner} spin />
  </InputAdornment>
);

export const TooltipWrapper = observer(({ tooltip, children }: any) => (
  <Grid alignItems="center" container>
    <Grid item xs={10}>
      {children}
    </Grid>
    <Grid item xs={2}>
      <InfoTooltip label={tooltip} />
    </Grid>
  </Grid>
));

export const TextField = ({
  name, value, label, onChange, tooltip, ...props
}) => {
  const input = (
    <TextFieldMat
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      label={label}
      margin="dense"
      name={name}
      value={value == null ? '' : value}
      variant="outlined"
      onChange={onChange}
      {...props}
    />
  );

  return tooltip ? <TooltipWrapper tooltip={tooltip}>{input}</TooltipWrapper> : input;
};

export const InputField = observer(({
  tooltip, onChange, ...props
}: any) => {
  const changeHandler = useCallback(event => {
    onChange(event.target.name, event.target.value);
  }, [onChange]);

  const input = (
    <TextField
      InputLabelProps={{
        shrink: true
      }}
      onChange={changeHandler}
      {...props}
    />
  );

  return tooltip ? <TooltipWrapper tooltip={tooltip}>{input}</TooltipWrapper> : input;
});
