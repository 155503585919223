import React from 'react';
import { Wrapper, TranslationSummary } from 'components';

const PageAdministrationTranslations = () => (
  <Wrapper>
    <TranslationSummary />
  </Wrapper>
);

export default PageAdministrationTranslations;
