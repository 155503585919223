/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import sanitizeHtml from 'sanitize-html';
import { HeaderModal } from './_HeaderModal';

export const QuizTimeOutModal = observer(({ onConfirm, onClose, quizTitle }) => {
  const handleTerminate = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  return (
    <>
      <HeaderModal onClose={null}>
        {translate('quiz.timeOut')}
      </HeaderModal>

      <DialogContent style={{ maxWidth: 640 }}>
        <Grid alignItems="center" container direction="column" spacing={2}>
          <Grid item>
            <FontAwesomeIcon color="var(--warning-color)" icon={faClock} size="3x" />
          </Grid>

          <Grid item>
            <Typography
              component="p"
              style={{ color: 'var(--warning-color)', fontWeight: 600 }}
              variant="h5"
            >
              00:00:00
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              align="center"
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('quiz.timeOutModalMessage', { quizTitle })) }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleTerminate}>
          {translate('button.terminate')}
        </Button>
      </DialogActions>
    </>
  );
});

QuizTimeOutModal.propTypes = {
  onClose: PropTypes.func
};

QuizTimeOutModal.defaultProps = {
  onClose: () => { }
};
