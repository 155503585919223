import { faQuestionSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogActions, DialogContent, Divider, Grid, TextField, Typography
} from '@material-ui/core';
import { Button } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import { translate } from 'utils';
import { HeaderModal } from './_HeaderModal';

const ColorPreviewContainer = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: ${props => props.color};
  box-shadow: 1px 1px 5px 1px grey;
`;

export const CategoryModal = ({ onClose, onConfirm, defaultValues }) => {
  const [formState, { text }] = useFormState(defaultValues);

  const [bgColor, setBgColor] = useState(defaultValues.colorHex ? defaultValues.colorHex : '#7B7D7D');

  const handleColorChange = color => setBgColor(color.hex);

  const handleSubmit = useCallback(e => {
      e.preventDefault();
      const isFormValid = document.forms.categoryForm && document.forms.categoryForm.reportValidity();

      if (isFormValid) {
        onConfirm({
          ...formState.values,
          colorHex: bgColor
        });
        return onClose();
      }

      return null;
  }, [onConfirm, onClose, formState.values, bgColor]);

  return (
    <form autoComplete="off" name="categoryForm">
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faQuestionSquare} />
        <Typography component="span">
          {defaultValues.name ? translate('pageCategoryList.updateCategory') : translate('button.createCategory')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        <Grid container direction="row" justifyContent="center" style={{ width: 300 }}>
          <Grid item style={{ marginTop: 10, marginBottom: 30 }} xs={12}>
            <TextField
              fullWidth
              label={translate('pageCategoryList.categoryName')}
              name="name"
              required
              {...text('name')}
              inputProps={{ maxLength: 150 }}
              variant="outlined"
            />
          </Grid>
          <Grid container direction="row" justifyContent="center" style={{ marginBottom: 30 }}>
            <Grid item style={{ marginBottom: 10 }} xs={12}>
              <Typography variant="button">{translate('category.previewCategory')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ColorPreviewContainer color={bgColor} />
            </Grid>
          </Grid>
          <Divider />
          <Grid item style={{ marginBottom: 10 }} xs={12}>
            <Typography variant="button">{translate('category.selectColor')}</Typography>
          </Grid>
          <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
            <ChromePicker
              color={bgColor}
              disableAlpha
              onChange={handleColorChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {translate('button.save')}
        </Button>
      </DialogActions>
    </form>
  );
};

CategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({})
};

CategoryModal.defaultProps = {
  defaultValues: {}
};
