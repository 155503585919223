import { faCalendarPlus, faUserPlus, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Grid, TableCell, Typography
} from '@material-ui/core';
import {
  GenericListContainer, GenericListFilters, GenericTable, SkeletonLine,
  TextError
} from 'components';
import { useModal } from 'hooks';
import { useEffectAfterRender } from 'hooks/UseEffectAfterRender';
import useInfiniteLoading from 'hooks/useInfiniteList';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserService } from 'services';
import shortid from 'shortid';
import { formatDate, translate } from 'utils';
import { API_ROUTES } from 'utils/constants';
import { UserListFilters } from './UserListFilters';

const listHeaders = () => [
  {
    name: 'fullName',
    label: translate('common.lastName'),
    isSortable: true,
    template: row => (
      <TableCell key={shortid.generate()} style={{ maxWidth: '250px', minWidth: '150px' }}>
        <Typography style={{ wordBreak: 'break-word' }}>
          {row.fullName || '-'}
        </Typography>
      </TableCell>
    )
  }, {
    name: 'email',
    label: translate('common.email'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.email || '-'}
      </TableCell>
    )
  }, {
    name: 'login',
    label: translate('common.login'),
    template: row => (
      <TableCell key={shortid.generate()} style={{ maxWidth: '200px', minWidth: '150px' }}>
        <Typography style={{ wordBreak: 'break-word' }}>
          {row.login || '-'}
        </Typography>
      </TableCell>
    )
  }, {
    name: 'creationDate',
    label: translate('common.creationDate'),
    isSortable: true,
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.creationDate && (
          <span>
            <FontAwesomeIcon icon={faCalendarPlus} style={{ marginRight: '1rem' }} />
            {formatDate(row.creationDate)}
          </span>
        )}
      </TableCell>
    )
  }, {
    name: 'company',
    label: translate('common.company'),
    sortOn: 'label',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.companyName || ''}
      </TableCell>
    )
  }, {
    name: 'accountActivated',
    label: translate('common.userAccount'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.hasAccount ? translate('common.yes') : translate('common.no')}
        {(row.hasAccount && !row.accountActivated) && ` (${translate('common.deactivated')})`}
      </TableCell>
    )
  }
];

const defaultAppFilter = appName => {
  if (!appName) {
    return null;
  }

  return [
    {
      key: 'application',
      label: appName,
      value: {
        value: appName,
        label: appName
      }
    }
  ];
};

export const UserList = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const displayModal = useModal();
  const originApp = location.search && location.search.split('=')[1].toUpperCase();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(defaultAppFilter(originApp) ?? []);

  const getUserList = useCallback(page => {
    let finalFilters = {
      companyName: '',
      application: null,
      roles: []
    };

    filters.forEach(filter => {
      if (filter.key === 'application' || filter.key === 'roles') {
        finalFilters = {
          ...finalFilters,
          [filter.key]: filter.value
        };
      }
      if (filter.key === 'companyName') {
        const { label } = filter;
        finalFilters = {
          ...finalFilters,
          [filter.key]: label
        };
      }
    });

    return UserService.getUserList({ freeSearch: search, ...finalFilters }, page);
  }, [filters, search]);

  const {
    items, hasMore, isLoading, totalSize, reloadList, loadMore
  } = useInfiniteLoading(getUserList);

  useEffectAfterRender(() => {
    reloadList();
  }, [filters, search]);

  const handleRowClick = useCallback(row => history.push(API_ROUTES.USER_DETAIL(row.personId)), [history]);

  const createUser = useCallback(form => {
    UserService.createUser(form)
      .then(response => {
        enqueueSnackbar(translate('confirms.userList.createAdmin'), { variant: 'success' });
        handleRowClick({ personId: response });
      })
      .catch(error => displayModal({ type: 'ERROR', text: error }));
  }, [enqueueSnackbar, handleRowClick, displayModal]);

  const displayModalCreateUser = useCallback(() => displayModal({
    type: 'CREATE_USER',
    onConfirm: createUser,
    closeOnSubmit: true
  }), [displayModal, createUser]);

  const renderButtonContainer = () => (
    <div className="buttonsContainer">
      <Button
        color="primary"
        data-cy="createNewUser"
        startIcon={<FontAwesomeIcon icon={faUserPlus} />}
        variant="contained"
        onClick={displayModalCreateUser}
      >
        {translate('button.create')}
      </Button>
    </div>
  );

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <UserListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  return (
    <GenericListContainer>
      <PageTitle title={translate('pageUserList.title')} titleRight={renderButtonContainer()} />
      <GenericListFilters
        ComponentFilter={renderFilters}
        dataTour="step-admin-userFilters"
        filters={filters}
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
        tooltipInfo="pageUserList.searchTooltip"
        withDrawer
      />

      <div data-tour="step-admin-userList">
        {!isLoading && items.length === 0
          ? (
            <Grid alignItems="center" container direction="column">
              <FontAwesomeIcon color="var(--primary-color)" icon={faUsers} size="3x" />
              <TextError>{translate('errors.noUser')}</TextError>
              <Button
                color="primary"
                data-cy="createNewUser"
                startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                variant="contained"
                onClick={displayModalCreateUser}
              >
                {translate('button.createUser')}
              </Button>
            </Grid>
          ) : (
            <GenericTable
              dataCy="userList"
              hasMore={hasMore}
              headers={listHeaders()}
              isLoading={isLoading}
              loadMore={loadMore}
              rows={items}
              total={totalSize}
              onRowClick={handleRowClick}
            />
          )}
        {isLoading && items.length === 0 && <SkeletonLine />}

      </div>
    </GenericListContainer>
  );
};
