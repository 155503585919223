import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHistory } from '@fortawesome/pro-regular-svg-icons';
import {
  DialogActions, DialogContent, IconButton, makeStyles, TableCell, Typography
} from '@material-ui/core';
import { Button, GenericTable, SkeletonLine } from 'components';
import { translate } from 'utils';
import { useFetch } from 'hooks';
import shortid from 'shortid';
import { HeaderModal } from './_HeaderModal';

const useStyles = makeStyles(() => ({
  historyGrid: {
    minHeight: 320,
    maxHeight: 420,
    width: 600
  }
}));

const getHeaders = (viewLink, onClose) => ([
  {
    name: 'creationDate',
    label: translate('common.creationDate'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.creationDate && new Date(row.creationDate).toLocaleString()}
      </TableCell>
    )
  }, {
    name: 'creationUser',
    label: translate('common.creationUser'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.creationUser}
      </TableCell>
    )
  }, {
    name: 'view',
    label: translate('button.viewEdit'),
    width: '10px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <IconButton
          edge="end"
          onClick={e => {
            e.stopPropagation();
            onClose();
            viewLink(row.id);
          }}
        >
          <FontAwesomeIcon icon={faEdit} size="xs" />
        </IconButton>
      </TableCell>
    )
  }]);

export const HistoryModal = ({
  onClose,
  dataService,
  viewLink
}) => {
  const classes = useStyles();
  const { response: historyRows, isLoading } = useFetch(() => dataService(), []);

  return (
    <>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faHistory} />
        <Typography component="span">
          {translate('modalHistory.history')}
        </Typography>
      </HeaderModal>

      <DialogContent>
        {isLoading
          ? (
            <SkeletonLine />
          ) : (
            <div className={classes.historyGrid}>
              <GenericTable
                dataCy="historyList"
                hasMore={false}
                headers={getHeaders(viewLink, onClose)}
                loadMore={() => {
                }}
                rows={historyRows}
                total={historyRows.length}
              />
            </div>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

HistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired
};
