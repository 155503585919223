import {
  faArrowAltSquareDown, faArrowAltSquareUp, faCopy, faFile, faListOl, faRandom, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Tooltip, Typography,
  makeStyles
} from '@material-ui/core';
import { InputField } from 'components/';
import { QuestionManualSelect } from 'components/Modals/QuestionBlockModal/QuestionManualSelect';
import { QuestionRandomSelect } from 'components/Modals/QuestionBlockModal/QuestionRandomSelect';
import { CustomTooltip, SwitchOrder } from 'components/_commons';
import { DeleteButton, PrimaryButton } from 'components/_commons/GenericTable/GenericTable';
import { asForm } from 'components/forms/hoc';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { translate } from 'utils';

const useStyles = makeStyles(() => ({
  page: {
    margin: 10,
    padding: 10,
    border: 'solid',
    borderRadius: 5,
    borderColor: 'var(--grey)'
  }
}));

const QuizPage = observer(({ formData, pages, updateProperty }) => {
  const classes = useStyles();
  const { quizStore } = useStores();

  const handleMovePageUp = useCallback(pageIndex => {
    quizStore.movePageUp(pageIndex);
  }, [quizStore]);

  const handleMovePageDown = useCallback(pageIndex => {
    quizStore.movePageDown(pageIndex);
  }, [quizStore]);

  const deletePage = useCallback(pageId => {
    quizStore.deletePage(pageId);
  }, [quizStore]);

  const manualSelectIcon = () => (
    <span className="fa-layers">
      <FontAwesomeIcon icon={faFile} transform="grow-20" />
      <FontAwesomeIcon icon={faListOl} transform="grow-1 down-3" />
    </span>
  );

  const randomSelectIcon = () => (
    <span className="fa-layers">
      <FontAwesomeIcon icon={faCopy} transform="grow-22" />
      <FontAwesomeIcon icon={faRandom} transform="right-5" />
    </span>
  );

  const renderSpecificBlock = page => {
    if (page.isRandom) {
      return (
        <QuestionRandomSelect formData={formData} />
      );
    }
    return (
      <QuestionManualSelect componentLocation="quizPageEditor" formData={formData} />
    );
  };

  return (
    <Grid
      className={classes.page}
      container
      direction="row"
    >
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        style={{ borderBottom: 'solid', color: 'var(--grey)' }}
      >
        <Grid item>
          <Grid alignItems="center" container direction="row" justifyContent="flex-start" spacing={2} wrap="nowrap">
            <Grid item style={{ marginLeft: 5 }}>
              {formData.isRandom ? randomSelectIcon() : manualSelectIcon()}
            </Grid>
            <Grid item>
              <Typography className="bold" style={{ color: 'var(--black)' }} variant="body2">
                {formData.isRandom
                  ? translate('quiz.randomCategoryQuestionSelection')
                  : translate('quiz.manualQuestionSelection')}
              </Typography>
              <Typography variant="body2">
                {formData.isRandom
                  ? translate('quiz.oneQuestionPerPageFormat')
                  : translate('quiz.nQuestionPerPageFormat')}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              {formData.isRandom
                ? <CustomTooltip size="sm" text={translate('quiz.tooltip.randomQuestionSelection')} />
                : <CustomTooltip size="sm" text={translate('quiz.tooltip.manualQuestionSelection')} />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid alignItems="center" container direction="row" justifyContent="flex-end">
            <Grid item>
              <Tooltip title={translate('button.moveUp')}>
                <PrimaryButton onClick={() => handleMovePageUp(formData.index)}>
                  <FontAwesomeIcon icon={faArrowAltSquareUp} size="xs" />
                </PrimaryButton>
              </Tooltip>
              <Tooltip title={translate('button.moveDown')}>
                <PrimaryButton onClick={() => handleMovePageDown(formData.index)}>
                  <FontAwesomeIcon icon={faArrowAltSquareDown} size="xs" />
                </PrimaryButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography>
                |
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={translate('button.delete')}>
                <DeleteButton onClick={() => deletePage(formData.index)}>
                  <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                </DeleteButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid alignItems="center" container justifyContent="space-between" style={{ padding: 10, marginBottom: 20 }}>

        <Grid item xs={5}>
          <InputField
            inputProps={{
              autoComplete: 'off',
              maxLength: 150
            }}
            label={translate('common.name')}
            name="name"
            placeholder={translate('quiz.pageName')}
            value={formData.name}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item style={{ paddingLeft: 20 }} xs={5}>
          <Tooltip title={translate('quiz.tooltip.randomInfoToolTip')}>
            <span>
              <SwitchOrder
                name="randomizeQuestionOrder"
                value={formData.randomizeQuestionOrder || false}
                onChange={updateProperty}
              />
            </span>
          </Tooltip>
        </Grid>

      </Grid>

      {renderSpecificBlock(formData)}

      <Grid container justifyContent="flex-end">
        <Grid item style={{ margin: '10px 0px' }}>
          <Typography className="bold">
            {`Page : ${pages.indexOf(formData) + 1}/${pages.length}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>

  );
});

export default asForm(QuizPage);

QuizPage.propTypes = {
  formData: PropTypes.shape({}),
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  updateProperty: PropTypes.func
};

QuizPage.defaultProps = {
  formData: {},
  pages: [],
  updateProperty: () => { }
};
