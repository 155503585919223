import {
  IconButton,
  Table, TableCell, TableHead, TableRow,
  Typography
} from '@material-ui/core';
import { HeaderType } from 'models/HeaderType';
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller'; // Docs : https://www.npmjs.com/package/react-infinite-scroller
import styled from 'styled-components';
import { translate } from 'utils';
import { SkeletonSection } from 'components/index';
import { SkeletonTable } from '../Skeletons';

export const DeleteButton = styled(IconButton)`
  &:hover {
    color: var(--error-color);
  }
`;

export const PrimaryButton = styled(IconButton)`
  &:hover {
    color: var(--primary-color-dark);
  }
`;

const GenericTableContainer = styled.div`
  position: relative;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: auto;
`;

const TotalRows = styled(Typography)`
  font-size: 1.4rem;
  color: var(--grey-dark);
  text-align: right;

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const TableRowComponent: <T>(_: {
  hasRowClick?: boolean,
  headers: HeaderType<T>[],
  hover?: boolean,
  keyValue: React.Key,
  onRowClick?: (row: T) => void,
  row: T
}) => JSX.Element = ({
  hasRowClick = false,
  headers,
  hover = true,
  keyValue,
  onRowClick = () => { },
  row
}) => {
    const handleClick = useCallback(() => onRowClick(row), [onRowClick, row]);

    return (
      <TableRow
        hover={hover}
        key={keyValue}
        style={{ cursor: hasRowClick ? 'pointer' : 'initial' }}
        onClick={handleClick}
      >
        {headers.map((column, index) => (
          column.template(row, index)
        ))}
      </TableRow>
    );
  };

export const GenericTable: <T>(_: {
  dataCy?: string,
  dataTour?: string,
  displayTotal?: boolean,
  fixedSize?: boolean,
  hasMore?: boolean,
  headers: HeaderType<T>[],
  hover?: boolean,
  id?: string,
  isBlurLoading?:boolean,
  isLoading?: boolean,
  loadMore?: any,
  onRowClick?: (row: T) => void,
  rows?: T[],
  stickyHeader?: boolean,
  total?: number
}) => JSX.Element = ({
  dataCy = '',
  dataTour = '',
  displayTotal = true,
  fixedSize = false,
  hasMore = false,
  headers,
  hover = true,
  id = null,
  isBlurLoading,
  isLoading,
  loadMore,
  onRowClick,
  rows = [],
  stickyHeader = true,
  total = 0
}) => {
    const handleRowClick = useCallback(row => onRowClick && onRowClick(row), [onRowClick]);

    if (rows.length === 0) return null;

    return (
      <GenericTableContainer data-cy={dataCy} data-tour={dataTour}>
        {displayTotal && total > 0 && (
          <TotalRows gutterBottom>
            {`${rows.length} / ${total || 0} ${translate('common.totalDisplayed')}`}
          </TotalRows>
        )}

        <Table
          size="small"
          stickyHeader={stickyHeader}
          style={fixedSize ? { tableLayout: 'fixed', width: '100%' } : {}}
        >
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={`header_${id}_${header.name}`} style={{ width: header.width || 'initial' }}>
                  {header.label}
                  {header.headerRight ?? ''}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <InfiniteScroll
            element="tbody"
            hasMore={hasMore}
            id={id}
            initialLoad={false}
            loader={isLoading && loadMore && <SkeletonTable columns={headers.length} key={0} />}
            loadMore={page => loadMore && hasMore && loadMore(page)}
            pageStart={1}
            style={{ minHeight: '1200px' }}
            threshold={50}
          >
            {rows.map((row, i) => (
              <TableRowComponent
                hasRowClick={!!onRowClick}
                headers={headers}
                hover={hover}
                key={i}
                keyValue={i}
                row={row}
                onRowClick={handleRowClick}
              />
            ))}
          </InfiniteScroll>
        </Table>
        {isBlurLoading && <SkeletonSection backgroundColor="rgba(0,0,0,0)" style={{ backdropFilter: 'blur(3px)' }} />}
      </GenericTableContainer>
    );
  };
