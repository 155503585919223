import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, DialogTitle, Grid, Typography
} from '@material-ui/core';
import { InputField } from 'components/';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { translate } from 'utils';

export const WarningModal = ({
  onClose, onConfirm, onCancel, onAction, text, title, buttonCancel, buttonConfirm, buttonAction, withComment, confirmOnly
}) => {
  const [comment, setComment] = useState('');
  const handleClose = useCallback(() => onClose(), [onClose]);

  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [onCancel, handleClose]);

  const handleAction = useCallback(() => {
    onAction();
    handleClose();
  }, [onAction, handleClose]);

  const handleConfirm = useCallback(() => {
    onConfirm(withComment && comment);
    handleClose();
  }, [onConfirm, handleClose, withComment, comment]);

  return (
    <>
      <DialogTitle className={confirmOnly ? '' : 'warningModal'}>
        {!confirmOnly && <FontAwesomeIcon icon={faExclamationTriangle} />}
        {title || translate('warnings.warning')}
      </DialogTitle>

      <DialogContent style={{ maxWidth: '500px' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />
          </Grid>
          {withComment && (
            <Grid item>
              <InputField
                label={translate('common.justifyComment')}
                value={comment}
                onChange={(_, value) => setComment(value)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        {buttonCancel && (
        <Button onClick={handleCancel}>{buttonCancel}</Button>
        )}
        {buttonAction && (
        <Button onClick={handleAction}>{buttonAction}</Button>
        )}
        <Button
          color="primary"
          id="warningModalConfirm"
          onClick={onConfirm ? handleConfirm : handleClose}
        >
          {buttonConfirm || translate('confirms.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};

WarningModal.propTypes = {
  buttonAction: PropTypes.any,
  buttonCancel: PropTypes.any,
  buttonConfirm: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

WarningModal.defaultProps = {
  buttonAction: '',
  buttonCancel: '',
  buttonConfirm: '',
  onAction: null,
  onCancel: () => {},
  onConfirm: null,
  text: '',
  title: ''
};
