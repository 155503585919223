import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { DocumentHelper, translate } from 'utils';
import { Grid, Typography } from '@material-ui/core';
import { InputFile } from 'components/forms/inputs';
import { CustomIcon } from 'components/_commons';
import { faFile } from '@fortawesome/pro-light-svg-icons';

const PDFContainer = styled.object`
  min-width: 60vw;
  min-height: 70vh;
  max-width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const PDFDocument = observer(({
  documentContent,
  setDocumentContent
}) => {
  const [pdfBase64, setPdfBase64] = useState(documentContent);
  useEffect(
    () => {
      if (documentContent) {
        setPdfBase64(documentContent);
      }
    },
    [documentContent, setPdfBase64]
  );

  const handleImportFile = useCallback(
    doc => {
      if (doc) {
        setDocumentContent(DocumentHelper.getDocumentWithoutBase64(doc));
      }
    },
    [setDocumentContent]
  );

  return (
    <Grid alignItems="center" container direction="column" style={{ marginTop: '20px' }}>
      <Grid item spacing={3}>
        <Typography align="center">{translate('pageCountry.document.intervention.name')}</Typography>
        <Grid container direction="column">
          <Grid item spacing={5} style={{ margin: '20px' }}>
            <InputFile
              accept="application.pdf"
              fileAccepted=".pdf"
              handleAddDocument={handleImportFile}
              id="importFile"
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {(pdfBase64)
          ? <PDFContainer data={DocumentHelper.getPDFWithBase64(pdfBase64)} type="application/pdf" />
          : (
            <Grid alignItems="center" container direction="column" style={{ marginTop: '20px' }}>
              <CustomIcon
                color="var(--primary-color)"
                icon={faFile}
                secondSize="3x"
                size="3x"
              />
              <Typography style={{ color: 'var(--primary-color)' }}>{translate('modalDisplayPDF.noDisplay')}</Typography>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
});

export default PDFDocument;
