import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CustomTooltip } from 'components/_commons/Tooltip/Tooltips';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { translate } from 'utils';

const ValidatedIcon = ({ validated }) => (
  <CustomTooltip
    color={validated ? 'var(--success-color)' : 'var(--warning-color)'}
    icon={validated ? faCheckCircle : faExclamationTriangle}
    text={validated ? translate('common.validated') : translate('common.unvalidated')}
  />
);

export const CustomCardTitle = ({
  children = undefined, title, validated = undefined, icon = undefined, backgroundColor = '#dadada'
}) => (
  <Typography
    align="center"
    component="h2"
    style={{
      padding: '1rem', borderBottom: '1px solid #eeeeee', backgroundColor, textTransform: 'uppercase'
    }}
    variant="h4"
  >
    {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />}
    {title}
    {validated !== undefined && <ValidatedIcon validated={validated} />}
    {children && (
    <div style={{ textAlign: 'end', marginTop: '-27px' }}>
      {children}
    </div>
    ) }
  </Typography>
);
