import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from '@material-ui/core';
import { translate } from 'utils';

const GenericCheckBox = ({ isCorrect, onHandleChangeAnswer }) => (
  <Tooltip title={translate('pageQuestionList.isAnswerCorrect')}>
    <Checkbox
      checked={Boolean(isCorrect)}
      color="primary"
      name="isCorrect"
      onChange={onHandleChangeAnswer}
    />
  </Tooltip>
);

GenericCheckBox.propTypes = {
  isCorrect: PropTypes.bool,
  onHandleChangeAnswer: PropTypes.func.isRequired
};

GenericCheckBox.defaultProps = {
  isCorrect: false
};

export default GenericCheckBox;
