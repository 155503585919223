import {
  action, makeObservable, observable
} from 'mobx';

export class EditionStore {
  constructor() {
    this.editionMode = true;
    this.editionPreviewMode = false;
    this.elementEdited = null;
    this.rootElementEdited = null;
    this.quizFormType = 'quiz';
    this.quizView = true;

    makeObservable(this, {
      editionMode: observable,
      editionPreviewMode: observable,
      elementEdited: observable,
      rootElementEdited: observable,
      quizFormType: observable,
      quizView: observable,
      setFormType: action,
      setEditionMode: action,
      toggleEdition: action,
      togglePreview: action,
      setElementEdited: action,
      deleteFocusedElement: action,
      reset: action
    });
  }

  setFormType(formType) {
    this.quizFormType = formType;
  }

  setEditionView(status) {
    this.quizView = status;
  }

  setEditionMode(status = true) {
    this.editionMode = status;
  }

  toggleEdition() {
    this.editionMode = !this.editionMode;
  }

  togglePreview() {
    this.toggleEdition();
    this.editionPreviewMode = !this.editionPreviewMode;
  }

  deleteFocusedElement() {
    if (this.elementEdited && this.elementEdited.onDelete) {
      this.elementEdited.onDelete();
    } else {
      // eslint-disable-next-line
      console.error('The function onDelete is not defined or there is no element being edited');
    }
  }

  setElementEdited(elementId, data, type, onDelete = null, isRoot = false) {
    this.elementEdited = {
      elementId, data, type, onDelete
    };

    if (isRoot === true) {
      this.rootElementEdited = {
        elementId, data, type, onDelete
      };
    }
  }

  reset() {
    this.editionMode = false;
    this.editionPreviewMode = false;
    this.elementEdited = null;
    this.rootElementEdited = null;
  }
}

export const editionStore = new EditionStore();
