import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  Grid, Typography
} from '@material-ui/core';
import { useStores } from 'hooks';
import { translate } from 'utils';
import { QuizHeader, QuizPage, QuizQuestion } from '../index';
import { QUIZ_STATUS } from '../QuizHelper';

const QuizEndPage = observer(({ quizStatus }) => {
  const { ended, displayingResults } = QUIZ_STATUS;

  const { examinationStore } = useStores();
  const { examination, showCorrectedAnswers } = examinationStore;

  const { quiz: { pages } } = examination;

  return (
    <>
      {quizStatus === ended ? <QuizHeader quizStatus={ended} /> : <QuizHeader quizStatus={displayingResults} />}

      {showCorrectedAnswers && (
        <>
          <Grid alignItems="center" container justifyContent="center">
            <Grid item>
              <Typography style={{ fontWeight: 'bolder', color: 'var(--black)' }} variant="h5">{translate('quiz.answers')}</Typography>
            </Grid>
          </Grid>
          {pages.map(page => (
            <QuizPage
              currentPageData={page}
              key={page.index}
              quizStatus={quizStatus}
            >
              {page.questions.map(question => (
                <QuizQuestion
                  currentPageData={page}
                  currentQuestionData={question}
                  key={question.id}
                  quizStatus={quizStatus}
                />
              ))}
            </QuizPage>
          ))}
        </>
      )}
    </>
  );
});

export default QuizEndPage;
