import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  DialogContent, Divider, Grid, Paper, Typography
} from '@material-ui/core';
import { HeaderModal } from './_HeaderModal';

const StyledDialogContent = styled(DialogContent)`
  max-width: 600px;
  min-height: 245px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 83px;
  width: 83px;
`;

const StyledDivider = styled(Divider)`
  margin: 5px 0 !important;
`;

export const BrowserPopupModal = ({
  onClose, title, primaryText, secondaryText
}) => (
  <>
    <HeaderModal className="errorModal" onClose={onClose}>
      {title}
    </HeaderModal>
    <StyledDialogContent>
      <Typography>
        {primaryText}
      </Typography>
      <StyledDivider />
      <Typography gutterBottom variant="subtitle2">
        {secondaryText}
      </Typography>
      <Grid
        container
        justifyContent="center"
        spacing={3}
      >
        <Grid item>
          <Paper>
            <a href="https://www.google.com/intl/fr_fr/chrome/" rel="noopener noreferrer" target="_blank">
              <StyledDiv>
                <img alt="Google Chrome icon" src={`${process.env.PUBLIC_URL}/assets/images/favicons/google_chrome_icon.png`} />
              </StyledDiv>
              <Typography align="center">
                Chrome
              </Typography>
            </a>
          </Paper>
        </Grid>
        <Grid item>
          <Paper>
            <a href="https://www.mozilla.org/fr/firefox/new/" rel="noopener noreferrer" target="_blank">
              <StyledDiv>
                <img alt="Firefox icon" src={`${process.env.PUBLIC_URL}/assets/images/favicons/firefox_icon.png`} />
              </StyledDiv>
              <Typography align="center">
                Firefox
              </Typography>
            </a>
          </Paper>
        </Grid>
      </Grid>
    </StyledDialogContent>
  </>
);

BrowserPopupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired
};
