import React from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

const StyledEditionPanel = styled(Grid)`
  height: calc(100vh - var(--header-with-edition-bar-height));
  overflow-y: auto;
  border-right: 1px solid var(--grey-light);
  ${props => props.edition !== 'true' && css`display: none;`}
  max-width: var(--sidebar-width);
`;

export const EditionPanel = observer(({ children, editionMode }) => (
  <>
    <StyledEditionPanel edition={editionMode.toString()} item>
      {children}
    </StyledEditionPanel>
  </>
));

EditionPanel.propTypes = {
  editionMode: PropTypes.bool.isRequired
};
