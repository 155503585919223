import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography, Tooltip
} from '@material-ui/core';
import {
  APPLICATION_ROLES, ROUTES, ENVIRONMENT, APP_VERSION, REVISION, CREATED, API_ROUTES
} from 'utils/constants';
import { translate, UserHelper } from 'utils';

import {
  faSignOutAlt, faUserCircle, faUsersCog, faPollH
} from '@fortawesome/pro-regular-svg-icons';
import shortid from 'shortid';
import FooterSupralog from 'components/Footer/FooterSupralog';
import { format, parseISO } from 'date-fns';
import { useStores } from 'hooks';

const SideMenuContainer = styled.div`
  display: flex;
  flex: 1;

  a:hover svg {
    color: var(--tecnea-blue);
  }

  ul {
    width: 100%;
  }
`;

const SideMenu = observer(({ isOpen, setToggleMenu }) => {
  const { userStore } = useStores();
  const { scope } = userStore;
  const handleToggleMenu = useCallback(() => setToggleMenu(false), [setToggleMenu]);

  const CONDITIONNAL_ITEMS = [
    {
      condition: UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.VALIDATOR]),
      component: <ListItem
        button
        component={Link}
        data-cy="headerMenuAdministration"
        key={shortid.generate()}
        to={ROUTES.COMPANY_LIST}
        onClick={handleToggleMenu}
      >
        <ListItemIcon><FontAwesomeIcon icon={faUsersCog} size="lg"/></ListItemIcon>
        <ListItemText primary={translate('menu.admin')}/>
      </ListItem>
    },
    {
      condition: UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.DATAFORM_CONTENT_MANAGER]),
      component: <ListItem
        button
        component={Link}
        data-cy="headerMenuAdministrationQuiz"
        key={shortid.generate()}
        to={ROUTES.ADMIN_QUIZ}
        onClick={handleToggleMenu}
      >
        <ListItemIcon><FontAwesomeIcon icon={faPollH} size="lg"/></ListItemIcon>
        <ListItemText primary={translate('menu.adminQuiz')}/>
      </ListItem>
    }
  ];

  const logOutUser = useCallback(() => {
    userStore.disconnectUser();
  }, [userStore]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleToggleMenu}>
      <SideMenuContainer>
        <List>
          <ListItem
            button
            component={Link}
            data-cy="headerMenuUser"
            to={API_ROUTES.USER_DETAIL(scope?.personId)}
            onClick={handleToggleMenu}
          >
            <ListItemIcon><FontAwesomeIcon icon={faUserCircle} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.profil')} />
          </ListItem>
          {CONDITIONNAL_ITEMS.map(item => item.condition && item.component)}
          <Divider />
          <ListItem
            button
            component={Link}
            data-cy="headerMenuLogout"
            to=""
            onClick={logOutUser}
          >
            <ListItemIcon><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.disconnect')} />
          </ListItem>
        </List>
      </SideMenuContainer>

      <Typography align="center">
        {`${ENVIRONMENT} - ${APP_VERSION}`}
        <br />
        <Tooltip interactive leaveDelay={1000} title={REVISION}>
          <Typography component="span">
            {`Révision: ${REVISION.substring(0, 5)}`}
          </Typography>
        </Tooltip>
        <br />
        {`Créé le: ${format(parseISO(CREATED), 'dd/MM/yyyy à HH:mm:ss')}`}
      </Typography>

      <FooterSupralog borderPosition="top" />
    </Drawer>
  );
});

export default withTranslation()(SideMenu);
