import {
  faDotCircle,
  faExclamationTriangle,
  faPlusSquare,
  faQuestionSquare
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  ListItemText,
  Paper,
  Tooltip
} from '@material-ui/core';
import { StyledListItem } from 'components';
import { Text } from 'components/_commons';
import { PrimaryButton } from 'components/_commons/GenericTable/GenericTable';
import debounce from 'debounce-promise';
import { useModal, useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { QuestionService } from 'services';
import { translate } from 'utils';
import { CustomListItem } from './CustomListItem';

export const QuestionManualSelect = observer(({ formData, componentLocation }) => {
  const { index } = formData;

  const { enqueueSnackbar } = useSnackbar();
  const displayModal = useModal();

  const { quizStore, questionStore } = useStores();

  const { quiz } = quizStore;

  const [questions, setQuestions] = useState(formData.questions || []);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [error, setError] = useState('');

  const checkFormValidity = useCallback(() => {
    if (questions.length === 0) {
      quizStore.setIsQuizFormPageValid(false);
      return setError(translate('quiz.noQuestionsInPage'));
    }
    quizStore.setIsQuizFormPageValid(true);
    return setError('');
  }, [quizStore, questions]);

  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);

  // Update data whether it comes from a quiz form or Modal.
  const updatePage = useCallback((key, data) => {
    Object.keys(formData).length > 0
      ? runInAction(() => { quiz.pages[index][key] = data[key]; })
      : quizStore.updatePage({ isRandom: false, ...data })
          && quizStore.updateContentInCurrentPage(index);
  }, [formData, quizStore, quiz, index]);

  const handleAddQuestion = useCallback(value => {
    const newQuestions = [...questions];

    newQuestions.push(value);
    setQuestions(newQuestions);

    const newQuestionsTmp = [...selectedQuestions];
    newQuestionsTmp.push(value);
    setSelectedQuestions(newQuestionsTmp);

    updatePage('questions', { questions: newQuestions });
  }, [questions, selectedQuestions, updatePage]);

  const handleFilterOptions = useCallback(opt => !questions.find(q => q.id === opt.data.id), [questions]);

  const createQuestion = useCallback((ques, onClose) => {
    questionStore.createUpdateQuestion(ques.id, ques)
      .then(response => {
        QuestionService.getQuestion(response)
          .then(questionResponse => {
            handleAddQuestion(questionResponse);
            onClose();
            enqueueSnackbar(translate('confirms.questionList.createdAndOnList'), { variant: 'success' });
          });
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  }, [enqueueSnackbar, questionStore, handleAddQuestion]);

  const handleCreateQuestion = useCallback(
() => displayModal({
    type: 'CREATE_QUESTION',
    onConfirm: createQuestion,
    defaultValues: {}
  }),

  [displayModal, createQuestion]
);

  const getAsyncOptions = useCallback(inputValue => new Promise(resolve => {
    QuestionService
      .getQuestionListForOptions({ freeSearch: inputValue }, 0)
      .then(response => resolve(response.content));
  }), []);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      return e.preventDefault();
    }
    return null;
  };

  const handleMoveQuestionUp = useCallback((pageIndex, questionId) => {
    setQuestions(formData.questions);
    quizStore.moveQuestionUp(pageIndex, questionId);
  }, [quizStore, formData]);

  const handleMoveQuestionDown = useCallback((pageIndex, questionId) => {
    setQuestions(formData.questions);
    quizStore.moveQuestionDown(pageIndex, questionId);
  }, [quizStore, formData]);

  const handleDeleteQuestion = useCallback(questionId => {
    quizStore.toggleDeletingQuestion(true);

    const newQuestionsList = [...questions].filter(ques => ques.id !== questionId);

    setQuestions(newQuestionsList);
    setSelectedQuestions(newQuestionsList);

    updatePage('questions', { questions: newQuestionsList });
  }, [questions, quizStore, updatePage]);

  const createAndAddNewQuestionInCurrentPage = () => (
    <Paper style={{ padding: 10 }} variant="outlined">
      <Grid alignItems="center" container direction="column" justifyContent="center">
        <Grid item>
          <PrimaryButton onClick={handleCreateQuestion}>
            <FontAwesomeIcon icon={faPlusSquare} size="lg" />
          </PrimaryButton>
        </Grid>
        <Grid item>
          <Text fontSize="14px">{translate('quiz.addQuestionInCurrentPage')}</Text>
        </Grid>
      </Grid>
    </Paper>
  );

  const CustomOptionQuestion = ({ data, innerProps }) => (
    <StyledListItem {...innerProps}>
      <ListItemText primary={data.title} />
    </StyledListItem>
  );

  return (
    <Grid alignItems="center" container direction="row" justifyContent="center">
      <Grid container direction="column" justifyContent="flex-start" wrap="nowrap">

        {error && (
        <Grid alignItems="center" container>
          <Grid item xs={11}>
            <Text color="var(--error-color)">
              <FontAwesomeIcon icon={faExclamationTriangle} size="lg" style={{ color: 'var(--error-color)' }} />
              {' '}
              {error}
            </Text>
          </Grid>
        </Grid>
        )}

        <Grid item>
          <Text fontWeight="bold">{translate('quiz.searchQuestions')}</Text>
        </Grid>

        <Grid item style={{ width: '70%', marginBottom: 20 }}>
          <Tooltip placement="top" title={translate('quiz.tooltip.questionSearchToolTip')}>
            <AsyncSelect
              cacheOptions
              closeMenuOnSelect
              components={{ Option: CustomOptionQuestion }}
              defaultOptions
              filterOption={handleFilterOptions}
              loadingMessage={() => translate('common.loading')}
              loadOptions={inputValue => debouncedLoadOptions(inputValue)}
              name="questions"
              noOptionsMessage={() => translate('errors.noOptions')}
              placeholder={translate('quiz.addQuestions')}
              styles={{ menu: base => ({ ...base, zIndex: 2000 }) }}
              value={translate('quiz.addQuestions')}
              onChange={handleAddQuestion}
              onKeyDown={handleKeyDown}
            />
          </Tooltip>
        </Grid>
      </Grid>

      {componentLocation === 'modal' && (
        <Grid container direction="column" justifyContent="flex-start" wrap="nowrap">
          <Grid item xs={4}>
            <Text fontWeight="bold">{translate('quiz.questionNotExists')}</Text>
          </Grid>
          <Grid item style={{ maxWidth: 205, marginBottom: 15 }}>
            <Button
              color="primary"
              fullWidth
              startIcon={<FontAwesomeIcon icon={faQuestionSquare} />}
              variant="contained"
              onClick={handleCreateQuestion}
            >
              {translate('button.createQuestion')}
            </Button>
          </Grid>
        </Grid>
      )}
      {questions && questions.length > 0
        && (
        <Grid container justifyContent="flex-start">
          <Text fontWeight="bold" textTransform="uppercase">{translate('pageQuizList.selectedQuestions')}</Text>

          {questions.map(question => (
            <CustomListItem
              isMovableDown={componentLocation !== 'modal'}
              isMovableUp={componentLocation !== 'modal'}
              key={question.id}
              startIcon={<FontAwesomeIcon icon={faDotCircle} size="xs" />}
              title={question.title}
              onDelete={() => handleDeleteQuestion(question.id)}
              onMoveItemDown={() => handleMoveQuestionDown(index, question.id)}
              onMoveItemUp={() => handleMoveQuestionUp(index, question.id)}
            />
          ))}
        </Grid>
        )}
      {componentLocation !== 'modal' && (
      <Grid item style={{ marginTop: 20 }}>
        {createAndAddNewQuestionInCurrentPage()}
      </Grid>
      )}
    </Grid>
  );
});

QuestionManualSelect.propTypes = {
  formData: PropTypes.shape({}),
  componentLocation: PropTypes.string.isRequired
};

QuestionManualSelect.defaultProps = {
  formData: {}
};
