import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.article`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;

  /* All the children */
  & > * {
    margin-right: 2rem;
  }

  /* The center div with the texts */
  div:nth-child(2) {
    flex: 1;
  }
`;

export const CustomSkeletonList = () => (
  <SkeletonContainer>
    <Skeleton height={40} variant="rect" width={40} />
    <div>
      <Skeleton height={15} variant="text" />
      <Skeleton height={15} variant="text" />
      <Skeleton height={15} variant="text" />
      <Skeleton height={15} variant="text" />
    </div>
    <Skeleton height={50} variant="rect" width={30} />
    <Skeleton height={40} variant="rect" width={40} />
  </SkeletonContainer>
);

export const SkeletonList = () => (
  <Grid container direction="column">
    <CustomSkeletonList />
    <CustomSkeletonList />
  </Grid>

);
