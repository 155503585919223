import { RequestHelper } from 'utils';
import { API_V2_URL } from 'utils/constants';

const questionUrl = `${API_V2_URL}/questions`;

// GET
const getQuestion = questionId => RequestHelper.GET(`${questionUrl}/${questionId}`);

const getQuestionHistory = questionId => RequestHelper.GET(`${questionUrl}/${questionId}/history`);

const getQuestionInstance = questionInstanceId => RequestHelper.GET(`${questionUrl}/instance/${questionInstanceId}`);

const getQuestionCopy = questionId => RequestHelper.GET(`${questionUrl}/${questionId}/copy`);

const getQuestionType = () => RequestHelper.GET(`${questionUrl}/types`);

// POST
const createQuestion = question => RequestHelper.POST(`${questionUrl}`, question);

const getQuestionList = (filters, page = 0) => RequestHelper.POST(`${questionUrl}/filter?page=${page}`, filters);

const getQuestionListForOptions = (filters, page = 0) => RequestHelper.POST(`${questionUrl}/options?page=${page}`, filters);

// PUT
const updateQuestion = (questionId, question) => RequestHelper.PUT(`${questionUrl}/${questionId}`, question);

// DELETE
const deleteQuestion = questionId => RequestHelper.DELETE(`${questionUrl}/${questionId}`);

const importQuestions = file => RequestHelper.POST(`${questionUrl}/import`, file);

const exportQuestions = filters => RequestHelper.POST(`${questionUrl}/export`, filters);

const exportQuestionAsync = filters => RequestHelper.POST(`${questionUrl}/async-export`, filters);

export const QuestionService = {
  getQuestionList,
  getQuestionListForOptions,
  getQuestion,
  getQuestionHistory,
  getQuestionInstance,
  getQuestionCopy,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  getQuestionType,
  importQuestions,
  exportQuestions,
  exportQuestionAsync
};
