import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
import { TextField } from 'components/forms/inputs/InputField/InputField';
import { Datepicker, SelectField } from 'components';
import { ExaminationService } from 'services';
import { useSnackbar } from 'notistack';

export const QuizResultsListFilters = ({ currentFilters, setCurrentFilters }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [trainees, setTrainees] = useState([]);
  const [isTraineeOptsLoading, setIsTraineeOptsLoading] = useState(false);

  const trainee = (currentFilters && currentFilters.find(filt => filt.key === 'trainee')
    && currentFilters.find(filt => filt.key === 'trainee').value) || null;

  const quizName = (currentFilters && currentFilters.find(filt => filt.key === 'quizName')
        && currentFilters.find(filt => filt.key === 'quizName').label) || '';

  const minDate = (currentFilters && currentFilters.find(filt => filt.key === 'minDate')
        && currentFilters.find(filt => filt.key === 'minDate').label) || null;

  const maxDate = (currentFilters && currentFilters.find(filt => filt.key === 'maxDate')
        && currentFilters.find(filt => filt.key === 'maxDate').label) || null;

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    if (value) {
      if (currentFilters) {
        setCurrentFilters([...currentFilters.filter(filt => filt.key !== name), { key: name, label: value }]);
      } else {
        setCurrentFilters([{ key: name, label: value }]);
      }
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleSelect = useCallback(value => {
    if (value) {
      if (currentFilters) {
        setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'trainee'), {
          key: 'trainee',
          label: value.label || value,
          value
        }]);
      } else {
        setCurrentFilters([{ key: 'trainee', label: value.label || value, value }]);
      }
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'trainee')]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleChangeDate = ({ date, key }) => {
    if (currentFilters) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== key), { key, label: date }]);
    } else {
      setCurrentFilters([{ key, label: date }]);
    }
  };

  const getTrainees = useCallback(() => {
    setIsTraineeOptsLoading(true);
    ExaminationService.getTraineesList()
      .then(response => setTrainees(response))
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }))
      .finally(() => setIsTraineeOptsLoading(false));
  }, [enqueueSnackbar]);

  useEffect(() => {
    getTrainees();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container direction="column">
      {isTraineeOptsLoading ? (
        <Grid container justifyContent="center" style={{ marginBottom: 15 }}>
          <CircularProgress style={{ color: 'var(--primary-color)' }} />
        </Grid>
      ) : (
        <SelectField
          label="common.intern"
          name="trainee"
          options={trainees}
          placeholder="common.selectIntern"
          value={trainee}
          onChange={handleSelect}
        />
      )}
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('quiz.quizName')}
        name="quizName"
        value={quizName}
        onChange={handleChangeInput}
      />
      <Datepicker
        dataCy="quizResultsMinDate"
        fullWidth
        label="common.minDate"
        name="minDate"
        value={minDate}
        onChange={date => handleChangeDate({ date, key: 'minDate' })}
      />
      <br />
      <Datepicker
        dataCy="quizResultsMaxDate"
        fullWidth
        label="common.maxDate"
        name="maxDate"
        value={maxDate}
        onChange={date => handleChangeDate({ date, key: 'maxDate' })}
      />
    </Grid>
  );
};

QuizResultsListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
