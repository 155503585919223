import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { translate } from 'utils';
import { processError } from 'utils/helpers';
import sanitizeHtml from 'sanitize-html';

export const ErrorModal = ({
  onClose, afterClose, text, title
}) => {
  const handleClose = useCallback(() => {
    onClose();
    afterClose && afterClose();
  }, [onClose, afterClose]);

  return (
    <>
      <DialogTitle className="errorModal">
        <FontAwesomeIcon icon={faTimes} />
        {translate(title)}
      </DialogTitle>

      <DialogContent dangerouslySetInnerHTML={{ __html: sanitizeHtml(processError(text)) }} />

      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </>
  );
};

ErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  afterClose: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

ErrorModal.defaultProps = {
  afterClose: () => {},
  text: '',
  title: 'common.error'
};
