import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/pro-regular-svg-icons';
import {
  DialogActions, DialogContent, Typography, Button
} from '@material-ui/core';
import ReactQuill from 'react-quill'; // Docs: https://github.com/zenoamaro/react-quill || https://quilljs.com/
import 'react-quill/dist/quill.snow.css';
import { translate } from 'utils';
import styled from 'styled-components';
import { HeaderModal } from './_HeaderModal';

const StyledDialogContent = styled(DialogContent)`
  min-height: 400px;
  width: 600px;
  overflow: hidden;
  margin: 0 15px;
  padding: 15px;
`;

const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  ['link'],
  [{ list: 'ordered' }, { list: 'bullet' }]
];
const editorModules = {
  toolbar: toolbarOptions
};

export const EditHTMLModal = ({
  onClose, onConfirm, defaultValue, text
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback(val => setValue(val), []);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    onConfirm(value);

    return onClose();
  }, [onConfirm, onClose, value]);

  return (
    <form autoComplete="off" name="editHTMLForm">
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faCode} />
        <Typography component="span">
          {defaultValue
            ? translate('pageTranslationList.editHtml.editTranslation')
            : translate('pageTranslationList.editHtml.addTranslation')}
        </Typography>
      </HeaderModal>

      <StyledDialogContent id="edit-html-modal">
        <Typography gutterBottom>
          {translate(text)}
        </Typography>
        <ReactQuill
          bounds="#edit-html-modal"
          modules={editorModules}
          style={{ height: '300px' }}
          value={value}
          onChange={handleChange}
        />
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {translate('button.save')}
        </Button>
      </DialogActions>
    </form>
  );
};
