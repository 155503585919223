import { API_V2_URL, APP_PROFILE } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const devValues = [
  {
    id: '17c1238d-fb14-439d-8c5c-69daf15d9170',
    name: 'DATABILAN',
    locale: '',
    isActivated: true
  },
  {
    id: '8dcdfc6d-1280-44c7-8e79-62c5db7291b2',
    name: 'DATAFORM',
    locale: '',
    isActivated: true
  }
];

const valuesPromise = new Promise(resolve => {
  resolve(devValues);
});

// If you want to have a stub for DATATECNEA answer to that request, you need to set the environment variable
// REACT_APP_PROFILE to LOCAL
const getActiveApplications = () => (APP_PROFILE === 'LOCAL'
  ? valuesPromise
  : RequestHelper.GET(`${API_V2_URL}/applications/active`));

export const DataTecneaService = {
  getActiveApplications
};
