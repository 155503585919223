import React, { useCallback } from 'react';
import { translate } from 'utils';
import PropTypes from 'prop-types';
import { TextField } from 'components/forms/inputs/InputField/InputField';
import { Grid } from '@material-ui/core';

export const CategoryListFilters = ({ currentFilters, setCurrentFilters }) => {
  const categoryName = (currentFilters.find(filt => filt.key === 'name')
    && currentFilters.find(filt => filt.key === 'name').label) || '';

  const colorHex = (currentFilters.find(filt => filt.key === 'colorHex')
    && currentFilters.find(filt => filt.key === 'colorHex').label) || '';

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name), { key: name, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name)]);
    }
  }, [currentFilters, setCurrentFilters]);

  return (
    <Grid container direction="column">
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.name')}
        name="name"
        value={categoryName}
        onChange={handleChangeInput}
      />
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.hexaCode')}
        name="colorHex"
        value={colorHex}
        onChange={handleChangeInput}
      />
    </Grid>
  );
};

CategoryListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
