import { Grid, Tooltip } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FullWrapper } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';

const Container = styled.div`
  position: relative;
`;

const Nav = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  max-height: ${props => (props.isCollapsed ? 0 : 'var(--header-nav-height)')};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  background: #f2fafe;
  z-index: var(--zindexBig);
  overflow: hidden;
  transition: all 0.3s;
`;

const Collapser = styled.div`
  position: absolute;
  right: 2rem;
  bottom: -3rem;
  width: 60px;
  height: 60px;
  padding: 3.5rem 0 0rem 0;
  text-align: center;
  color: var(--primary-color);
  border-radius: 50%;
  background: #f2fafe;
  cursor: pointer;
  z-index: var(--zindexMedium);
`;

export const HeaderEditionNav = ({ children, fullWidth }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  const tooltipLabel = isCollapsed ? translate('button.showAdminNav') : translate('button.hideAdminNav');

  return (
    <Container>
      <Nav isCollapsed={isCollapsed}>
        <FullWrapper style={{ padding: '1rem 2rem' }}>
          <Grid alignItems="center" container justifyContent="flex-end">
            <Grid item style={{ width: fullWidth ? '100%' : 'initial' }}>
              {children}
            </Grid>
          </Grid>
        </FullWrapper>
      </Nav>
      <Collapser onClick={handleCollapse}>
        <Tooltip aria-label={tooltipLabel} title={tooltipLabel}>
          <div>
            <FontAwesomeIcon icon={isCollapsed ? faAngleDoubleDown : faAngleDoubleUp} size="lg" />
          </div>
        </Tooltip>
      </Collapser>
    </Container>
  );
};

HeaderEditionNav.propTypes = {
  fullWidth: PropTypes.bool
};

HeaderEditionNav.defaultProps = {
  fullWidth: false
};
