import { Grid } from '@material-ui/core';
import { Datepicker } from 'components/_commons';
import { TextField } from 'components/forms/inputs/InputField/InputField';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { translate } from 'utils';

export const QuestionListFilters = ({ currentFilters, setCurrentFilters }) => {
  const title = currentFilters.find(filt => filt.key === 'title')?.label || '';
  const category = currentFilters.find(filt => filt.key === 'category')?.label || '';
  const createdBefore = currentFilters.find(filt => filt.key === 'createdBefore')?.label || null;
  const createdAfter = currentFilters.find(filt => filt.key === 'createdAfter')?.label || null;

  const getKeywords = useCallback(() => currentFilters.find(filt => filt.key === 'keywords')?.label || [], [currentFilters]);

  const [keywordInput, setKeywordInput] = useState('');

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name), { key: name, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleKeywordChange = useCallback(inputValue => {
    if (inputValue.length <= 100) {
      return setKeywordInput(inputValue);
    }
    return null;
  }, []);

  const updateExistingKeywordFilter = useCallback(values => {
    const newCurrentFilters = [...currentFilters];
    newCurrentFilters.forEach(filt => {
      if (filt.key === 'keywords') {
        filt.label = values;
      }
    });
    return setCurrentFilters(newCurrentFilters);
  }, [currentFilters, setCurrentFilters]);

  const handleKeyDown = useCallback(event => {
    const { value } = event.target;
    const newKeywordList = [...getKeywords()];

    if (!value) return null;

    if (getKeywords().find(keyword => keyword.value === value)) return null;

    newKeywordList.push({ label: value, value });

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        setKeywordInput('');

        if (currentFilters.find(filt => filt.key === 'keywords')) return updateExistingKeywordFilter(newKeywordList);
        return setCurrentFilters([...currentFilters, { key: 'keywords', label: newKeywordList }]);
      default:
        return null;
    }
  }, [updateExistingKeywordFilter, currentFilters, setCurrentFilters, getKeywords]);

  const handleQuestionKeywords = useCallback(values => {
    updateExistingKeywordFilter(values || []);
  }, [updateExistingKeywordFilter]);

  return (
    <Grid container direction="column">
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.title')}
        name="title"
        tooltip=""
        value={title}
        onChange={handleChangeInput}
      />
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.category')}
        name="category"
        style={{ marginBottom: 15 }}
        tooltip=""
        value={category}
        onChange={handleChangeInput}
      />
      <CreatableSelect
        components={{ DropdownIndicator: null }}
        inputValue={keywordInput}
        isClearable
        isMulti
        menuIsOpen={false}
        name="keywords"
        placeholder={translate('question.keywordsLabel')}
        value={getKeywords()}
        onChange={handleQuestionKeywords}
        onInputChange={handleKeywordChange}
        onKeyDown={handleKeyDown}
      />
      <Datepicker
        dataCy="questionListFilterCreatedAfter"
        fullWidth
        label="common.createdAfter"
        name="createdAfter"
        required={false}
        style={{ flex: 1 }}
        value={createdAfter}
        onChange={date => setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'createdAfter'), { key: 'createdAfter', label: date }])}
        onError={() => {}}
      />
      <Datepicker
        dataCy="questionListFilterCreatedBefore"
        fullWidth
        label="common.createdBefore"
        name="createdBefore"
        required={false}
        style={{ flex: 1 }}
        value={createdBefore}
        onChange={date => setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'createdBefore'), { key: 'createdBefore', label: date }])}
        onError={() => {}}
      />
    </Grid>
  );
};

QuestionListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
