import { Grid } from '@material-ui/core';
import { SkeletonMain } from 'components';
import { EditionContent, EditionPanel } from 'components/Edition';
import { ParentForm } from 'components/forms/ParentForm';
import { useStores } from 'hooks';
import { useQueryParams } from 'hooks/useQueryParams';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { UserHelper } from 'utils';
import { QUIZ_EDITION_ROLES } from 'utils/constants';
import { QuizEditionNav } from './QuizEditionNav';
import QuizEditionView from './QuizEditionView';
import QuizPageEditionView from './QuizPageEditionView';

const QuizDetail = observer(({
  quizId, isEditionMode, defaultViewMode
}) => {
  const {
    editionStore, userStore, quizStore, examinationStore
  } = useStores();
  const { isConnected } = userStore;
  const { editionMode, quizFormType } = editionStore;
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParams();
  const instanceId = queryParams.get('instance');

  const [isTempEditionMode, setIsTempEditionMode] = useState(editionMode);
  const [viewMode, setViewMode] = useState(defaultViewMode);

  const loadQuiz = useCallback(
() => quizStore.loadQuiz(quizId, instanceId)
    .catch(err => enqueueSnackbar(err.message || err, { variant: 'error' })),
  [quizStore, quizId, instanceId, enqueueSnackbar]
);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    !isEditionMode && editionStore.reset();

    if (quizId) {
      loadQuiz();
    }
  }, [isEditionMode, loadQuiz, editionStore, quizId]);

  const formObject = {
    data: quizStore.quiz,
    type: quizFormType
  };

  if (quizStore.isLoading) {
    return <SkeletonMain />;
  }

  return (
    <section data-testid="quiz-section">
      {isConnected && UserHelper.hasAccessRight(QUIZ_EDITION_ROLES) && (
        <QuizEditionNav
          isEditionMode={isTempEditionMode}
          setIsTempEditionMode={setIsTempEditionMode}
          setViewMode={setViewMode}
          viewMode={viewMode}
        />
      )}
      <Grid container>
        <EditionPanel editionMode={editionMode}>
          <br />
          {isConnected && editionMode
            && <ParentForm {...formObject} />}
        </EditionPanel>
        <EditionContent editionMode={editionMode}>
          {viewMode === 'quiz' ? (
            <QuizEditionView quizDisplay={examinationStore.examination?.quiz} />
          ) : (
            <QuizPageEditionView formData={quizStore.quiz} />
          )}
        </EditionContent>
      </Grid>
    </section>
  );
});

export default QuizDetail;

QuizDetail.propTypes = {
  quizId: PropTypes.string,
  isEditionMode: PropTypes.bool.isRequired,
  isInstanceMode: PropTypes.bool,
  defaultViewMode: PropTypes.string.isRequired
};

QuizDetail.defaultProps = {
  isInstanceMode: false,
  quizId: ''
};
