import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Switch } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

const GenericSwitch = observer(({
  titleSwitch, leftSwitch, rightSwitch,
  onChange, name, checked, toolTip, disabled, switchStyle, mainStyle, onClickFunction
}) => {
  const onHandleChange = useCallback(event => {
    onChange(event.target.name, event.target.checked);
  }, [onChange]);

  return (
    <Grid alignItems="center" container direction="row" justifyContent="space-between" style={mainStyle}>
      <Grid item xs>
        <Typography variant="subtitle2">
          {titleSwitch}
          {toolTip}
        </Typography>
      </Grid>
      <Grid alignItems="center" container direction="row" style={switchStyle}>
        <Grid item>
          <Typography variant="subtitle2">{leftSwitch}</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={checked}
            color="primary"
            disabled={disabled}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name={name}
            onChange={onHandleChange}
            onClick={onClickFunction}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{rightSwitch}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

GenericSwitch.propTypes = {
  titleSwitch: PropTypes.string,
  leftSwitch: PropTypes.string,
  rightSwitch: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  widthRow: PropTypes.string,
  mainStyle: PropTypes.shape({}),
  switchStyle: PropTypes.shape({}),
  onClickFunction: PropTypes.func

};

GenericSwitch.defaultProps = {
  checked: false,
  leftSwitch: '',
  mainStyle: { padding: '5px 0px' },
  name: '',
  onClickFunction: () => {},
  rightSwitch: '',
  switchStyle: { width: 117, padding: '0px 5px' },
  titleSwitch: ''
};

export default GenericSwitch;
