import React, { useCallback, useState, useEffect } from 'react';
import { translate } from 'utils';
import {
  QuestionList, QuizList, Wrapper, QuizResultsList, CategoryList
} from 'components';
import { Tab, Tabs } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListAlt, faPoll, faPollH, faQuestionSquare
} from '@fortawesome/pro-regular-svg-icons';
import shortid from 'shortid';

const TAB_MAPPING = [
  'administration-quiz',
  'categories',
  'questions',
  'examinations'
];

const PageAdministrationQuiz = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split('/');
  const currentTabMapped = TAB_MAPPING.findIndex(url => url === currentLocation[currentLocation.length - 1]);
  const [currentTab, setCurrentTab] = useState(currentTabMapped > 0 ? currentTabMapped : 0);

  const TABS_COMPONENTS = [<QuizList />, <CategoryList />, <QuestionList />, <QuizResultsList />];

  useEffect(() => {
    currentTabMapped > 0 ? setCurrentTab(currentTabMapped) : setCurrentTab(0);
  }, [currentTabMapped]);

  const handleTabChange = useCallback((_, tab) => setCurrentTab(tab), []);

  return (
    <Wrapper>
      <Tabs
        aria-label={translate('pageAdministrationQuiz.title')}
        centered
        data-cy="adminQuizTabs"
        data-tour="step-admin-quiz"
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        <Tab
          component={Link}
          data-cy="adminQuiz"
          data-tour="step-admin-quiz"
          icon={<FontAwesomeIcon icon={faPollH} size="2x" />}
          key={shortid.generate()}
          label={translate('pageAdministrationQuiz.tabQuiz')}
          to={ROUTES.ADMIN_QUIZ}
        />,
        <Tab
          component={Link}
          data-cy="adminCategory"
          data-tour="step-admin-category"
          icon={<FontAwesomeIcon icon={faListAlt} size="2x" />}
          key={shortid.generate()}
          label={translate('pageAdministrationQuiz.tabCategory')}
          to={ROUTES.ADMIN_CATEGORIES}
        />,
        <Tab
          component={Link}
          data-cy="adminQuestion"
          data-tour="step-admin-question"
          icon={<FontAwesomeIcon icon={faQuestionSquare} size="2x" />}
          key={shortid.generate()}
          label={translate('pageAdministrationQuiz.tabQuestion')}
          to={ROUTES.ADMIN_QUESTION}
        />,
        <Tab
          component={Link}
          data-cy="adminResults"
          data-tour="step-admin-results"
          icon={<FontAwesomeIcon icon={faPoll} size="2x" />}
          key={shortid.generate()}
          label={translate('pageAdministrationQuiz.tabResults')}
          to={ROUTES.ADMIN_RESULTS}
        />
      </Tabs>

      <section>
        {TABS_COMPONENTS[currentTab]}
      </section>
    </Wrapper>
  );
};

export default PageAdministrationQuiz;
