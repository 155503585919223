import { MAPBOX_TOKEN } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

// Docs : https://docs.mapbox.com/api/search/geocoding/
const mapboxUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

// GET
const getAddressList = (query = '', country = '') => RequestHelper.GET(
  `${mapboxUrl}/${query}.json?access_token=${MAPBOX_TOKEN}&limit=10&country=${country}`
);

export const AddressService = {
  getAddressList
};
