import { isChrome, isFirefox } from 'react-device-detect';

const COOKIES = {
  DISPLAY: 'OUTDATED_DISPLAY',
  REPEAT: 'OUTDATED_REPEAT',
  TIMER: 'OUTDATED_TIMER'
};

const cookieValue = cookieName => document.cookie
  .split('; ')
  .find(row => row.startsWith(`${cookieName}=`))
  .split('=')[1];

const modalDisplayControl = modalDisplayer => {
  if (!isChrome && !isFirefox) {
    const expireHourTimer = new Date();
    const expireMonthTimer = new Date();
    const expireYearTimer = new Date();

    expireHourTimer.setHours(expireHourTimer.getHours() + 4);
    expireMonthTimer.setMonth(expireMonthTimer.getMonth() + 1);
    expireYearTimer.setFullYear(expireYearTimer.getFullYear() + 1);

    let repeatCookieSum = 0;
    if (document.cookie.indexOf(COOKIES.DISPLAY) === -1) {
      if (document.cookie.indexOf(COOKIES.REPEAT) === -1) {
        document.cookie = `${COOKIES.REPEAT}=0; expires=${expireYearTimer.toUTCString()}`;
      }
      if (Number(cookieValue(COOKIES.REPEAT)) === 10) {
        document.cookie = `${COOKIES.DISPLAY}=true; expires=${expireMonthTimer.toUTCString()}`;
        document.cookie = `${COOKIES.REPEAT}=0; expires=${expireYearTimer.toUTCString()}`;
      } else if (document.cookie.indexOf(COOKIES.TIMER) === -1) {
        repeatCookieSum = (Number(cookieValue(COOKIES.REPEAT)) + 1);
        document.cookie = `${COOKIES.REPEAT}=${repeatCookieSum}; expires=${expireYearTimer.toUTCString()}`;
        document.cookie = `${COOKIES.TIMER}=true; expires=${expireHourTimer.toUTCString()}`;
        modalDisplayer();
      }
    } else {
      document.cookie = `${COOKIES.REPEAT}=0; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
      document.cookie = `${COOKIES.TIMER}=true; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    }
  }
};

export const BrowserDetectionHelper = {
  modalDisplayControl
};
