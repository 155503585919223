import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl, FormControlLabel, InputLabel, Radio, RadioGroup
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { translate } from 'utils';

const StyledSwitchTriple = styled.div`
  position: relative;
  width: 90px;
  background-color: var(--grey-light);
  transition: all var(--transitionTime);
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

  ${props => props.value === 'validated' && css`
    background-color: #8aca8d;
  `}

  ${props => props.value === 'refused' && css`
    background-color: #f97575;
  `}

  ${props => props.value !== 'unset' && css`
    label .MuiRadio-root {
      opacity: 0;
    }
  `}

  .MuiFormControlLabel-root {
    width: 30px;
    margin: 0;
  }
  .MuiFormGroup-root,
  .MuiRadio-root {
    width: 100%;
  }
  .MuiRadio-root {
    height: 22px;
  }
`;

const StyledSlider = styled.span`
  position: absolute;
  width: 26px;
  height: 26px;
  left: 50%;
  top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-dark);
  transition: all var(--transitionTime);
  transform: translateX(-13px);
  border-radius: 50%;

  ${props => props.value === 'refused' && css`
    left: 0;
    transform: translateX(0);
    background-color: var(--error-color);
  `}
  ${props => props.value === 'validated' && css`
    left: 100%;
    transform: translateX(-26px);
    background-color: var(--success-color);
  `}

  svg {
    color: var(--white);
  }
`;

const ValidationSwitchTriple = observer(({
  label = '',
  name,
  onChange,
  value
}: {
  label?: string,
  name: string,
  onChange: (value: string) => void,
  value: string
}) => {
  const handleChange = useCallback(event => {
    let newValue = event.target.value;
    if (newValue === 'unset') {
      newValue = value === 'refused' ? 'validated' : 'refused';
    }
    return onChange(newValue);
  }, [onChange, value]);

  return (
    <>
      {label && <InputLabel shrink>{translate(label)}</InputLabel>}
      <StyledSwitchTriple value={value}>
        <FormControl className="formControl">
          <RadioGroup
            aria-labelledby={translate(label)}
            name={name}
            row
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              control={<Radio icon={<FontAwesomeIcon color="var(--error-color)" icon={faTimes} size="xs" />} />}
              label=""
              value="refused"
            />
            <FormControlLabel
              className="unset"
              control={<Radio />}
              label=""
              value="unset"
            />
            <FormControlLabel
              control={<Radio icon={<FontAwesomeIcon color="var(--success-color)" icon={faCheck} size="xs" />} />}
              label=""
              value="validated"
            />
          </RadioGroup>
          <StyledSlider value={value}>
            {value === 'refused' && <FontAwesomeIcon data-value="refused" icon={faTimes} />}
            {value === 'validated' && <FontAwesomeIcon data-value="validated" icon={faCheck} />}
          </StyledSlider>
        </FormControl>
      </StyledSwitchTriple>
    </>
  );
});

export default ValidationSwitchTriple;
