import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

export const InfoTooltip = ({ label, iconColor }) => (
  <Tooltip aria-label={label} title={label}>
    <IconButton aria-label={label}>
      <FontAwesomeIcon color={iconColor} icon={faQuestionCircle} size="xs" />
    </IconButton>
  </Tooltip>
);

InfoTooltip.propTypes = {
  iconColor: PropTypes.string,
  label: PropTypes.string
};

InfoTooltip.defaultProps = {
  iconColor: 'var(--primary-color)',
  label: ''
};
