import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Wrapper } from 'components';
import {
  Card, CardContent, CardHeader, Typography
} from '@material-ui/core';

import { GenericSwitch } from 'components/forms/_commons';
import { translate } from 'utils';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';
import sanitizeHtml from 'sanitize-html';

const TitlePage = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 2rem 0;
  color: var(--tecnea-blue);
  font-size: 2.4rem;
  text-transform: uppercase;
  text-align: center;
`;

const PageCookies = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    setAccepted(CookiesHelper.getGAConsent());
  }, []);

  const toggleAccepted = useCallback(() => {
    const currentConsent = accepted;
    CookiesHelper.setGACookie(!accepted);
    setAccepted(prev => !prev);
    if (currentConsent) {
      window.location.reload();
    }
  }, [accepted]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <TitlePage>{translate('commons.rgpd.cookies.pageTitle')}</TitlePage>
      <Typography
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('commons.rgpd.cookies.pageIntro')) }}
        style={{ fontSize: '1.7rem' }}
      />
      <Card style={{ marginTop: 30 }}>
        <CardHeader
          action={(
            <GenericSwitch
              checked={accepted}
              leftSwitch={translate('button.refuse')}
              mainStyle={{}}
              name="accepted"
              rightSwitch={translate('button.accept')}
              switchStyle={{}}
              toolTip={null}
              onChange={toggleAccepted}
              onClickFunction={event => {
                event.stopPropagation();
              }}
            />
          )}
          title="Google analytics"
        />
        <CardContent>
          <Typography>{translate('commons.rgpd.googleAnalytics.pageDescription')}</Typography>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default PageCookies;
