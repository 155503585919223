import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { translate } from 'utils';
import { DeleteButton } from 'components';

export const DeleteAnswerButton = ({ onClickDelete }) => (
  <>
    <Grid item>
      <Typography>
        |
      </Typography>
    </Grid>
    <Grid item>
      <Tooltip title={translate('button.delete')}>
        <DeleteButton onClick={onClickDelete}>
          <FontAwesomeIcon icon={faTrashAlt} size="xs" />
        </DeleteButton>
      </Tooltip>
    </Grid>
  </>
);

export const GenericAnswer = ({
  answerContent, checkTypeButton, deleteAnswerBtn
}) => (
  <Grid alignItems="center" container>
    <Grid item xs={12}>
      {answerContent}
    </Grid>
    <Grid item style={{ marginBottom: 15 }} xs={12}>
      <Grid alignItems="center" container direction="row" justifyContent="flex-end">
        <Grid item xs>
          {checkTypeButton !== null ? (
            <Typography variant="subtitle2">
              {translate('question.isCorrectAnswer')}
            </Typography>
          ) : (
            null
          )}
        </Grid>
        <Grid item xs={2}>
          {checkTypeButton}
        </Grid>
        {deleteAnswerBtn}
      </Grid>
    </Grid>
  </Grid>
);

GenericAnswer.propTypes = {
  answerContent: PropTypes.node.isRequired,
  checkTypeButton: PropTypes.node,
  deleteAnswerBtn: PropTypes.node
};

GenericAnswer.defaultProps = {
  checkTypeButton: {},
  deleteAnswerBtn: {}
};
