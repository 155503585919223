import React, { useCallback, useEffect } from 'react';
import {
  faPlusSquare
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, makeStyles, Paper, Typography
} from '@material-ui/core';
import { PrimaryButton } from 'components/_commons/GenericTable/GenericTable';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import FormHeader from 'components/forms/FormHeader/FormHeader';
import QuizPage from './QuizPage';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 17,
    padding: 10
  }
}));

const QuizPageEditionView = observer(({ formData }) => {
  const { pages } = formData;

  const classes = useStyles();
  const displayModal = useModal();
  const { quizStore } = useStores();

  useEffect(() => {
    if (formData) {
      quizStore.isQuizFormValid(formData);
    }
  }, [quizStore, formData]);

  const addContentInNewPage = useCallback(() => {
    quizStore.addContentInNewPage();
  }, [quizStore]);

  const handleAddQuestionInNewPage = useCallback(() => displayModal({
    type: 'CREATE_QUESTION_BLOCK',
    onConfirm: addContentInNewPage,
    defaultValues: {},

    afterClose: () => displayModal({
      type: 'CREATE_QUESTION',
      onConfirm: {},
      defaultValues: {},

      afterClose: () => displayModal({
        type: 'CREATE_QUESTION_BLOCK',
        onConfirm: addContentInNewPage,
        defaultValues: {}
      })
    })
  }), [displayModal, addContentInNewPage]);

  const addNewQuestionBlockButton = () => (
    <Grid alignItems="center" container justifyContent="center">
      <Paper style={{ padding: 10 }} variant="outlined">
        <Grid alignItems="center" container direction="column" justifyContent="center">
          <Grid item>
            <PrimaryButton onClick={handleAddQuestionInNewPage}>
              <FontAwesomeIcon icon={faPlusSquare} size="lg" />
            </PrimaryButton>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{translate('quiz.addQuestionsInNewPage')}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  return (
    <Grid className={classes.container} container>
      <Grid item style={{ paddingLeft: 10 }}>
        <FormHeader
          subtitle="quiz.viewPagesInfos"
          title="quiz.viewPagesTitle"
        />
      </Grid>

      {pages && pages.map(page => (
        <QuizPage formData={page} key={page.id || page.index} pages={pages} />
      ))}

      {addNewQuestionBlockButton()}
    </Grid>
  );
});

export default QuizPageEditionView;

QuizPageEditionView.propTypes = {
  formData: PropTypes.shape({}),
  updateProperty: PropTypes.func
};

QuizPageEditionView.defaultProps = {
  formData: {},
  updateProperty: () => { }
};
