import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller'; // Docs : https://www.npmjs.com/package/react-infinite-scroller

import { ListItem } from '@material-ui/core';

import { SkeletonList } from 'components';

const StyledGenericListItem = styled.div`
  max-width: 100%;
  border-bottom: 2px solid rgba(108, 123, 138, 0.08);
  background: var(--white);
  border-radius: 4px;
  transition: .3s ease;

  a {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;

    &:hover {
      background-color: initial;
    }
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
  }

  @media (min-width: 1081px) {
    width: 49%;
    margin-bottom: 1rem;
    border: 2px solid rgba(108, 123, 138, 0.08);

    &:not(:nth-child(2n)) {
      margin-right: 1rem;
    }
  }
`;

export const GenericListItem = ({
  link, children, action, dataCy
}) => {
  const handleItemClick = useCallback(e => { e.preventDefault(); action(); }, [action]);

  return (
    <StyledGenericListItem>
      <ListItem
        button
        component={Link}
        data-cy={dataCy}
        to={link}
        onClick={action ? handleItemClick : null}
      >
        {children}
      </ListItem>
    </StyledGenericListItem>
  );
};

GenericListItem.propTypes = {
  children: PropTypes.element.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired,
  action: PropTypes.func,
  dataCy: PropTypes.string
};

GenericListItem.defaultProps = {
  action: null,
  dataCy: ''
};

export const GenericListContainer = styled.section`
  padding-bottom: 50px;

  h1 {
    position: relative;
    height: 42px;

    span {
      vertical-align: sub;
    }
  }

  .buttonsContainer {
    a:not(:last-child),
    button:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .MuiAvatar-root img {
    width: 100%;
  }
`;

const GenericListSection = styled.section`
  display: flex;
  flex-flow: row wrap;

  @media (min-width: 1081px) {
    max-width: var(--wrapperWidthMax);
    margin: 0 auto;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const GenericList = ({
  children, dataCy, dataTour, hasMore, loadMore, id
}) => {
  const handleLoadMore = useCallback(page => loadMore(page), [loadMore]);

  return (
    <InfiniteScroll
      hasMore={hasMore}
      initialLoad={false}
      loader={<SkeletonList key={0} />}
      loadMore={handleLoadMore}
      pageStart={1}
      threshold={100}
    >
      <GenericListSection data-cy={dataCy} data-tour={dataTour} id={id}>
        {children}
      </GenericListSection>
    </InfiniteScroll>
  );
};

GenericList.propTypes = {
  children: PropTypes.element.isRequired,
  dataCy: PropTypes.string,
  dataTour: PropTypes.string,
  hasMore: PropTypes.bool,
  id: PropTypes.string,
  loadMore: PropTypes.func
};

GenericList.defaultProps = {
  dataCy: '',
  dataTour: '',
  hasMore: false,
  id: '',
  loadMore: () => {}
};
