import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

import { Typography } from '@material-ui/core';
import { translate } from 'utils';
import { ExaminationService } from 'services';
import { runInAction } from 'mobx';

import { QuizHeader } from '..';
import { QUIZ_STATUS } from '../QuizHelper';

const QuizStartPage = observer(() => {
  const { notRunning } = QUIZ_STATUS;

  const { enqueueSnackbar } = useSnackbar();
  const { quizStore, examinationStore } = useStores();

  const { examination, examinationState, isExaminationLoading } = examinationStore;

  const { isQuizStarted, isQuizEnded } = examinationState;

  useEffect(() => {
    if (examination?.quiz?.pages?.length > 0) {
      examinationStore.setCurrentPage(examination.quiz.pages[0]);
    }
  }, [examinationStore, examination]);

  useEffect(() => {
    if (quizStore.quiz && quizStore.quiz.id) {
      examinationStore.toggleLoadingExamination(true);

      ExaminationService.getExaminationTest(quizStore.quiz)
        .then(response => {
          runInAction(() => {
            examinationStore.setExamination(response);
            examinationStore.setCurrentPage(response.quiz.pages[0]);
            examinationStore.getTotalPagesAndQuestions();
            examinationStore.getQuestionNumbering();
          });
        })
        .catch(error => enqueueSnackbar(error, { variant: 'error' }))
        .finally(() => examinationStore.toggleLoadingExamination(false));
    }
  }, [isQuizStarted, isQuizEnded, quizStore, enqueueSnackbar, examinationStore]);

  if (!isExaminationLoading && !examination) {
    return <Typography>{translate('errors.noQuiz')}</Typography>;
  }

  if (!examination) return null;

  return (
    <>
      {examination.quiz && <QuizHeader quizStatus={notRunning} />}
    </>
  );
});

export default QuizStartPage;
