import { Switch, withStyles } from '@material-ui/core';

export const ActivationSwitch = withStyles({
  switchBase: {
    color: 'var(--error-color)',
    '&$checked': {
      color: 'var(--success-color)'
    },
    '&$checked + $track': {
      backgroundColor: 'var(--success-color)'
    },
    transition: 'all 0.3s'
  },
  checked: {},
  track: { backgroundColor: 'var(--error-color)' }

})(Switch);

export const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: 'var(--success-color)'
    },
    '&$checked + $track': {
      backgroundColor: 'var(--success-color)'
    },
    transition: 'all 0.3s'
  },
  checked: {},
  track: {}

})(Switch);
