import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { translate } from 'utils';
import sanitizeHtml from 'sanitize-html';

export const ConfirmModal = ({
  onClose, onConfirm, text, title
}) => {
  const handleConfirm = useCallback(() => {
    if (onConfirm) onConfirm();
    return onClose();
  }, [onConfirm, onClose]);

  return (
    <>
      <DialogTitle className="confirmModal">
        <FontAwesomeIcon icon={faCheckCircle} />
        {translate(title)}
      </DialogTitle>

      <DialogContent dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />

      <DialogActions>
        <Button color="primary" onClick={handleConfirm}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

ConfirmModal.defaultProps = {
  onConfirm: null,
  text: '',
  title: 'common.actionSuccessful'
};
