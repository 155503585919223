import { observer } from 'mobx-react-lite';

import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, Paper, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InputField } from 'components';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { CountryService, LanguageService } from 'services';
import { translate } from 'utils';
import { SelectItemWithLeftElement, Text } from '../_commons';
import { HeaderModal } from './_HeaderModal';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 15,
    height: 350
  },
  paperSecondColumn: {
    overflowY: 'auto'
  }
}));

const frenchLocale = 'fr_FR';

export const CreateCountryModal = observer(({
  countries, givenCountry, setGivenCountry, onConfirm, onClose
}) => {
  const [countryItem, setCountryItem] = useState(givenCountry
    ? ({
      countryCode: givenCountry.key,
      label: givenCountry?.country
    }) : null);
  const classes = useStyles();
  const { i18nStore } = useStores();
  const { currentLanguage } = i18nStore;
  const [storedCountries, setStoredCountries] = useState(countries || null);
  const [frenchName, setFrenchName] = useState(givenCountry?.name ?? '');
  const [region, setRegion] = useState(givenCountry ? givenCountry.region : null);
  const [countrySelectItems, setCountrySelectItems] = useState([]);
  const [translations, setTranslations] = useState({});
  const [isUnique, setIsUnique] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [isFetchingAllCountries, setIsFetchingAllCountries] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [languageList, setLanguageList] = useState([]);

  const loadLanguages = useCallback(() => {
    LanguageService.getLanguageList()
      .then(resp => setLanguageList(resp))
      .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }));
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadLanguages();
  }, [loadLanguages]);

  useEffect(() => {
    if (givenCountry) {
      setFrenchName(givenCountry.translations.filter(trad => trad.code === frenchLocale)[0].label);
    }
  }, [givenCountry]);

  const isAlreadyExisting = useCallback(c => (
    (c.key === countryItem?.countryCode && (c.region === region || (c.region === null && region === '')))
  ), [countryItem, region]);

  useEffect(() => {
    if (!storedCountries) {
      CountryService.getCountryList()
        .then(resp => setStoredCountries(resp.filter(c => c.id !== givenCountry?.id)))
        .catch(error => error && enqueueSnackbar(error?.message, { variant: 'error' }));
    }
  }, [countries, enqueueSnackbar, storedCountries, givenCountry]);

  useEffect(() => {
    const fetchCountries = (lng, catchFunc) => {
      fetch(`https://flagcdn.com/${lng}/codes.json`)
        .then(resp => resp.json())
        .then(json => {
          const countriesItems = Object.entries(json).map(item => ({
            countryCode: item[0],
            label: item[1]
          }));
          setCountrySelectItems(countriesItems);
        })
        .catch(catchFunc)
        .finally(() => setIsFetchingAllCountries(false));
    };
    const fetchFrench = () => fetchCountries('fr', error => error && enqueueSnackbar(error?.message, { variant: 'error' }));
    setIsFetchingAllCountries(true);
    fetchCountries(currentLanguage.split('_')[0], fetchFrench);
  }, [currentLanguage, enqueueSnackbar]);

  useEffect(() => {
    setIsCountrySelected(countryItem != null);
  }, [countryItem]);

  useEffect(() => {
    if (storedCountries) {
      setIsUnique(!storedCountries.some(c => isAlreadyExisting(c)));
    }
  }, [storedCountries, region, isAlreadyExisting, countryItem]);

  const updateTranslations = country => {
    if (country?.translations) {
      const newTranslations = {};
      country.translations.forEach(translation => {
        translation.code !== frenchLocale && (newTranslations[translation.code] = translation.label);
      });
      setTranslations(newTranslations);
    }
  };

  useEffect(() => {
    updateTranslations(givenCountry);
  }, [givenCountry]);

  const handleChangeCountry = useCallback(value => {
    setCountryItem(value);
    const newCountry = storedCountries.filter(c => c.key === value.countryCode)[0];
    if (newCountry) {
      updateTranslations(newCountry);
      setFrenchName(newCountry.translations.filter(trad => trad.code === frenchLocale)[0].label);
    } else {
      setFrenchName('');
      setTranslations({});
    }
  }, [storedCountries]);

  const handleChangeRegion = useCallback(value => {
    setRegion(value);
  }, []);

  const handleChangeName = useCallback(value => {
    setFrenchName(value);
    setIsNameValid(/[a-zA-Z _-]+$/i.test(value));
  }, []);

  const handleChangeTranslation = useCallback((editedLanguageLocale, value) => {
    const newTranslations = { ...translations };
    newTranslations[editedLanguageLocale] = value;
    setTranslations(newTranslations);
  }, [translations]);

  const toTranslationList = useCallback(() => {
    const translationList = Object.entries(translations).map(l => ({ code: l[0], label: l[1] }));
    translationList.push({ code: frenchLocale, label: frenchName });
    return translationList;
  }, [translations, frenchName]);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    const newCountry = {
      ...givenCountry,
      id: givenCountry?.id,
      key: countryItem?.countryCode,
      region,
      translations: toTranslationList(),
      activated: givenCountry?.activated || false
    };
    onConfirm(newCountry);
    givenCountry && setGivenCountry(newCountry);
    onClose();
  }, [countryItem, region, onClose, onConfirm, toTranslationList, givenCountry, setGivenCountry]);

  const displayInfoTabContent = useCallback(() => (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <SelectItemWithLeftElement
          isFlagElement
          isLoading={isFetchingAllCountries}
          label="modalCreateCountry.country"
          options={countrySelectItems}
          placeholder="modalCreateCountry.placeholder.country"
          required
          value={countryItem}
          onChange={handleChangeCountry}
        />
      </Grid>
      <Grid item>
        <InputField
          label={translate('pageCountry.column.region')}
          name="country_region"
          value={region}
          onChange={(_, value) => handleChangeRegion(value)}
        />
      </Grid>
    </Grid>
  ), [region, countrySelectItems, countryItem, handleChangeRegion, handleChangeCountry, isFetchingAllCountries]);

  return (
    <form autoComplete="off">
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faFlag} />
        <Typography component="span">
          {givenCountry
            ? translate('modalCreateCountry.titleModify')
            : translate('modalCreateCountry.titleCreate')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ overflow: 'hidden', minWidth: '50em' }}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} variant="outlined">
              {displayInfoTabContent()}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={`${classes.paper} ${classes.paperSecondColumn}`} variant="outlined">
              <Grid container direction="column" spacing={3}>
                <Typography align="center" variant="h5">
                  <Text fontWeight="bold">
                    {translate('country.translations')}
                  </Text>
                </Typography>
                <Grid item>
                  <Text fontWeight="bold" margin="0 0 8px 0">
                    {translate('modalCreateCountry.frenchName')}
                  </Text>
                  <InputField
                    error={!isNameValid}
                    label={translate('languages.fr_FR')}
                    name="country_name"
                    required
                    value={frenchName}
                    onChange={(_, value) => handleChangeName(value)}
                  />
                </Grid>
                <Grid container direction="column" item>
                  <Grid item>
                    <Text fontWeight="bold" margin="0 0 8px 0">
                      {translate('menu.translations')}
                    </Text>
                  </Grid>

                  <Grid container direction="column" item spacing={3}>
                    {languageList && languageList.map(lng => (lng.locale !== frenchLocale) && (
                      <Grid item key={`name-${lng.locale}`}>
                        <InputField
                          label={translate(`languages.${lng.locale}`)}
                          value={translations[lng.locale]}
                          onChange={(_, value) => handleChangeTranslation(lng.locale, value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!isUnique && <Typography color="error">{translate('modalCreateCountry.alreadyExist')}</Typography>}
        <Button onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!isUnique || !isCountrySelected || !isNameValid || !frenchName}
          onClick={handleValidateModal}
        >
          {givenCountry
            ? translate('button.validate')
            : translate('button.create')}
        </Button>
      </DialogActions>
    </form>
  );
});
