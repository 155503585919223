import React, { useCallback, useState } from 'react';
import { translate } from 'utils';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControl, FormControlLabel, Grid, ListItemAvatar, ListItemText, Tooltip
} from '@material-ui/core';
import { TextField } from 'components/forms/inputs/InputField/InputField';
import shortid from 'shortid';
import AsyncSelect from 'react-select/async';
import { Datepicker, StyledListItem } from 'components/_commons';
import { ColorPreviewContainer } from 'components/CategoryList';
import { CategoryService } from 'services';
import debounce from 'debounce-promise';

export const QuizListFilters = ({ currentFilters, setCurrentFilters }) => {
  const quizTitle = currentFilters.find(filt => filt.key === 'title')?.label || '';
  const description = currentFilters.find(filt => filt.key === 'description')?.label || '';
  const code = currentFilters.find(filt => filt.key === 'code')?.label || '';
  const createdBefore = currentFilters.find(filt => filt.key === 'createdBefore')?.label || null;
  const createdAfter = currentFilters.find(filt => filt.key === 'createdAfter')?.label || null;

  const [isCertifying, setIsCertifying] = useState(!!currentFilters.find(filter => filter.key === 'isCertifying'
    && filter.label !== null));
  const [selectedCategory, setSelectedCategory] = useState((currentFilters.find(filt => filt.key === 'category')
    && currentFilters.find(filt => filt.key === 'category').label) || {});

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name), { key: name, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleChangeCheckbox = useCallback(event => {
    const { checked } = event.target;

    setIsCertifying(checked);
    if (checked) {
      setCurrentFilters([...currentFilters, { key: 'isCertifying', label: translate('quiz.certifyingQuiz') }]);
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'isCertifying')]);
    }
  }, [setCurrentFilters, currentFilters]);

  const handleFilterOptions = useCallback(option => selectedCategory?.id !== option.data.id, [selectedCategory]);

  const getAsyncOptions = useCallback(inputValue => new Promise(resolve => {
    CategoryService.getCategoriesList({ freeSearch: inputValue, page: 0 })
      .then(response => resolve(response.content));
  }), []);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const handleSelectCategory = useCallback(value => {
    if (value) {
      const selectedCat = currentFilters.find(filt => filt.key === 'category' && filt.label.name === selectedCategory.name);

      selectedCat
        ? setCurrentFilters([...currentFilters, currentFilters.find(filt => filt.key === 'category').label = value])
        : setCurrentFilters([...currentFilters, { key: 'category', label: value }]);
    } else {
      setCurrentFilters(currentFilters.filter(filt => filt.key !== 'category'));
    }

    setSelectedCategory(value);
  }, [setCurrentFilters, currentFilters, selectedCategory]);

  const handleChangeDate = ({ date, key }) => {
    key && setCurrentFilters([...currentFilters.filter(filt => filt.key !== key), { key, label: date }]);
  };

  const CustomOptionCategories = ({ data, innerProps }) => (
    <StyledListItem {...innerProps} style={{ cursor: 'pointer' }}>
      <ListItemAvatar>
        <ColorPreviewContainer color={data.colorHex} />
      </ListItemAvatar>
      <ListItemText primary={data.name} />
    </StyledListItem>
  );

  return (
    <Grid container direction="column">
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.title')}
        name="title"
        tooltip={null}
        value={quizTitle}
        onChange={handleChangeInput}
      />
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.description')}
        name="description"
        tooltip={null}
        value={description}
        onChange={handleChangeInput}
      />
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('common.code')}
        name="code"
        tooltip={null}
        value={code}
        onChange={handleChangeInput}
      />
      <Grid style={{ margin: '15px 0px' }}>
        <Tooltip placement="top" title={translate('question.categoryToolTip')}>
          <AsyncSelect
            cacheOptions
            closeMenuOnSelect
            components={{ Option: CustomOptionCategories }}
            defaultOptions
            filterOption={handleFilterOptions}
            isClearable
            loadingMessage={() => translate('common.loading')}
            loadOptions={inputValue => debouncedLoadOptions(inputValue)}
            name="categories"
            noOptionsMessage={() => translate('errors.noOptions')}
            placeholder={translate('question.chooseCategory')}
            styles={{ menu: base => ({ ...base, zIndex: 2000 }) }}
            value={selectedCategory && Object.keys(selectedCategory).length > 0 && {
              value: selectedCategory.id,
              label: (
                <Grid alignItems="center" container direction="row" wrap="nowrap">
                  <Grid item style={{ width: '14%' }}>
                    <ColorPreviewContainer color={selectedCategory.colorHex} />
                  </Grid>
                  <Grid item style={{ width: '86%' }}>
                    <ListItemText primary={selectedCategory.name} />
                  </Grid>
                </Grid>
              )
            }}
            onChange={handleSelectCategory}
          />
        </Tooltip>
      </Grid>
      <FormControl>
        <FormControlLabel
          control={(
            <Checkbox
              checked={isCertifying}
              color="primary"
              name="isCertifying"
              value={isCertifying}
              onChange={event => handleChangeCheckbox(event)}
            />
          )}
          key={shortid.generate()}
          label={translate('quiz.certifyingQuiz')}
        />
      </FormControl>
      <Datepicker
        dataCy="quizListFilterCreatedAfter"
        fullWidth
        label="common.createdAfter"
        name="createdAfter"
        required={false}
        value={createdAfter}
        onChange={date => handleChangeDate({ date, key: 'createdAfter' })}
        onError={() => {}}
      />
      <Datepicker
        dataCy="quizListFilterCreatedBefore"
        fullWidth
        label="common.createdBefore"
        name="createdBefore"
        required={false}
        value={createdBefore}
        onChange={date => handleChangeDate({ date, key: 'createdBefore' })}
      />
    </Grid>
  );
};

QuizListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
