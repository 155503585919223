/* eslint-disable react/jsx-no-bind */

// Docs : https://geo.api.gouv.fr/adresse
import {
  faMapMarkerAltSlash,
  faSearch,
  faSpinner,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { FormElement } from 'components';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState
} from 'react';
import { AddressService } from 'services';
import styled from 'styled-components';
import { FormHelper, translate } from 'utils';

const AutocompleteContainer = styled.section`
  position: relative;
  z-index: 1;
`;

const AddressList = styled.ul`
  position: absolute;
  top: 56px;
  width: 100%;
  max-height: 250px;
  margin: 0;
  padding: 0;
  background: var(--white);
  border: 1px solid #eeeeee;
  overflow: auto;
`;

const AddressItem = styled.li`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  padding: 1rem;
  cursor: pointer;

  &:hover, &:focus {
    background-color: #eeeeee;
  }
`;

const ENTER_KEYCODE = 13;

export const AddressAutocomplete = ({
  formState, inputTypes
}) => {
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressListIsVisible, setAddressListIsVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const debouncedQuery = FormHelper.useDebounce(query, 500);

  useEffect(() => {
    const getAddressList = async () => {
      if (debouncedQuery) {
        setIsSearching(true);
        const results = await AddressService.getAddressList(debouncedQuery).then(response => response.features);
        setAddressList(results);
        setAddressListIsVisible(true);
        setIsSearching(false);
      } else {
        setAddressList([]);
      }
    };

    getAddressList();
  }, [debouncedQuery]);

  useEffect(() => {
    if (selectedAddress && selectedAddress.city) {
      formState.setField('address1', selectedAddress.name);
      formState.setField('postalCode', selectedAddress.postcode);
      formState.setField('city', selectedAddress.city);
      setAddressListIsVisible(false);
    }
  // eslint-disable-next-line
  }, [selectedAddress]);

  const handleChangeQuery = useCallback(event => setQuery(event.target.value), []);

  const handleClearQuery = useCallback(() => {
    setQuery('');
    setAddressList([]);
    setIsSearching(false);
  }, []);

  const handleSelectAddress = useCallback(address => setSelectedAddress(address.properties), []);

  const handleShowAddressList = useCallback(() => setAddressListIsVisible(true), []);

  const handleHideAddressList = useCallback(() => setAddressListIsVisible(false), []);

  return (
    <>
      <ClickAwayListener onClickAway={handleHideAddressList}>
        <AutocompleteContainer>
          <TextField
            autoComplete="new-password"
            fullWidth
            InputProps={{
              endAdornment: query !== '' && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClearQuery}>
                    <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="xs" />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {isSearching
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : <FontAwesomeIcon color="var(--primary-color)" icon={faSearch} />}
                </InputAdornment>
              )
            }}
            label={translate('common.addressAutocomplete')}
            value={query}
            variant="outlined"
            onChange={handleChangeQuery}
            onFocus={handleShowAddressList}
          />
          {addressListIsVisible && (
            <AddressList>
              {addressList.length > 0 && addressList.map(address => (
                <Fragment key={address.properties.id}>
                  <AddressItem
                    tabIndex={0}
                    onClick={() => handleSelectAddress(address)}
                    onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && handleSelectAddress(address)}
                  >
                    <div style={{ minWidth: '120px' }}>
                      <Chip label={translate(`common.${address.properties.type}`)} />
                    </div>
                    <div>
                      <Typography style={{ fontWeight: 600 }}>{address.properties.label}</Typography>
                      <Typography variant="subtitle2">{address.properties.context}</Typography>
                    </div>
                  </AddressItem>
                  <Divider />
                </Fragment>
              ))}
              {addressList.length === 0 && debouncedQuery && !isSearching && (
                <Typography align="center" style={{ padding: '2rem' }}>
                  <FontAwesomeIcon icon={faMapMarkerAltSlash} size="2x" />
                  <br />
                  {translate('errors.noAddressList')}
                </Typography>
              )}
            </AddressList>
          )}
        </AutocompleteContainer>
      </ClickAwayListener>
      <FormElement>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              error={formState.errors.address1 && formState.errors.address1 !== null}
              label={translate('common.address')}
              name="address1"
              required={FormHelper.isFieldRequired({
                fieldsToCheck: [formState.values.postalCode, formState.values.city]
              })}
              {...inputTypes.text('address1')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              label={translate('common.address2')}
              name="address2"
              {...inputTypes.text('address2')}
            />
          </Grid>
        </Grid>
      </FormElement>
      <FormElement>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={8} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.city && formState.errors.city !== null}
                  label={translate('common.city')}
                  name="city"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.postalCode]
                  })}
                  {...inputTypes.text('city')}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.postalCode && formState.errors.postalCode !== null}
                  label={translate('common.postalCode')}
                  name="postalCode"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.city]
                  })}
                  {...inputTypes.text('postalCode')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              error={formState.errors.country && formState.errors.country !== null}
              label={translate('common.country')}
              name="country"
              {...inputTypes.text('country')}
            />
          </Grid>
        </Grid>
      </FormElement>
    </>
  );
};

AddressAutocomplete.propTypes = {
  formState: PropTypes.shape({}).isRequired,
  inputTypes: PropTypes.shape({})
};

AddressAutocomplete.defaultProps = {
  inputTypes: {}
};
