import { faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  Avatar, Button, Card, CardActions, CardContent, CardHeader
} from '@material-ui/core';
import { InfoLine } from 'components/_commons';
import { CustomCardTitle } from 'components/_commons/Card/CustomTitle/CustomCardTitle';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { CompanyService } from 'services';
import { translate } from 'utils';

export const CompanyDetailCard = ({
  company, canEdit, afterEdit, withDatabilanAcc = true
}) => {
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const editCompany = form => {
    CompanyService.updateCompany(form.id, form).then(() => {
      displayModal({
        type: 'CONFIRM',
        text: translate('confirms.companyList.update'),
        onConfirm: afterEdit
      });
    }).catch(error => {
      enqueueSnackbar((error && error.message) || error, { variant: 'error' });
    });
  };

  const handleEditCompany = () => displayModal({
    type: 'CREATE_COMPANY', onConfirm: editCompany, defaultValues: company, closeOnSubmit: false, withDatabilanAcc
  });

  const companyPrimaryIdentifier = company?.identifiers?.filter(ident => ident.primary)?.[0];
  const companySecondaryIdentifiers = company?.identifiers?.filter(ident => !ident.primary);

  return (
    <Card>
      <CustomCardTitle
        icon={faStoreAlt}
        title={translate('common.company')}
        validated={company.validated}
      />
      <CardHeader
        avatar={(
          <Avatar>
            {company.name.charAt(0)}
          </Avatar>
        )}
        subheader={`${companyPrimaryIdentifier?.name} : ${companyPrimaryIdentifier?.value}`}
        title={company.name + ((company.legalStatus) ? ` (${company.legalStatus?.name})` : '')}
      />
      <CardContent>
        {companySecondaryIdentifiers?.map(identifier => (
          <InfoLine info={identifier.value} key={identifier.idValue} label={identifier.name} translateLabel={false} />
        ))}
        {company.contact && company.contact.contactName && (
          <>
            <InfoLine info={company.contact.contactName} label="common.contactName" />
            <InfoLine info={company.contact.email} label="common.email" />
            <InfoLine info={company.contact.phoneNumber} label="common.phone" />
          </>
        )}
        <InfoLine
          displayIf={company.website}
          info={(
            <a
              href={company.website}
              rel="noopener noreferrer"
              target="_blank"
            >
              {company.website}
            </a>
          )}
          label="common.website"
        />
        <InfoLine
          displayIf={!!company.country}
          info={company.country.label}
          label="common.country"
        />
        <InfoLine info={company.databilanAccountLimit} label="common.accountsNeeded" />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        {canEdit && (
          <Button
            aria-label={translate('button.edit')}
            color="secondary"
            variant="contained"
            onClick={handleEditCompany}
          >
            {translate('button.edit')}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
