import React from 'react';
import styled from 'styled-components';

const StyledFlag = styled.img`
  vertical-align: middle;
  box-shadow: 0 2px 6px var(--grey);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  margin: auto;
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const Flag = ({
  alt = 'country-flag',
  countryCode,
  isInsideSelect = false,
  style,
  width = 40
}: {
  alt?: string,
  countryCode?: string,
  isInsideSelect?: boolean,
  style?,
  width?: number
}) => {
  const FlagImg = () => (
    <StyledFlag
      alt={alt}
      src={isInsideSelect ? `https://flagcdn.com/w40/${countryCode}.png` : `https://flagcdn.com/${countryCode}.svg`}
      style={style}
      width={isInsideSelect ? 'auto' : width}
    />
  );
  return (
    <FlagContainer style={{ height: isInsideSelect ? '40px' : 'auto', width: isInsideSelect ? 'auto' : width }}>
      <FlagImg />
    </FlagContainer>
  );
};

export default Flag;
