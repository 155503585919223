import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import {
  Avatar, Button, Card, CardActions, CardContent, CardHeader
} from '@material-ui/core';
import { InfoLine, TextAddress } from 'components/_commons';
import React from 'react';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import { InstitutionService } from 'services';
import { CustomCardTitle } from 'components/_commons/Card/CustomTitle/CustomCardTitle';

export const InstitutionDetailCard = ({
 institution, canEdit, afterEdit, operatorsCount
}) => {
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const editInstitution = form => InstitutionService.updateInstitution(form.id, form).then(() => {
    displayModal({
      type: 'CONFIRM',
      text: translate('confirms.institutionList.update'),
      onConfirm: afterEdit
    });
  }).catch(error => {
    enqueueSnackbar((error && error.message) || error, { variant: 'error' });
  });

  const handleEditInstitution = () => displayModal({
    type: 'CREATE_INSTITUTION', onConfirm: editInstitution, defaultValues: institution, closeOnSubmit: false
  });

  const institutionPrimaryIdentifier = institution?.identifiers?.filter(ident => ident.primary)?.[0];
  const institutionSecondaryIdentifiers = institution?.identifiers?.filter(ident => !ident.primary);

  return (
    <Card>
      <CustomCardTitle
        icon={faBuilding}
        title={translate('common.institution')}
        validated={institution.validated}
      />
      <CardHeader
        avatar={(
          <Avatar>
            {institution.name.charAt(0)}
          </Avatar>
        )}
        subheader={`${institutionPrimaryIdentifier?.name} : ${institutionPrimaryIdentifier?.value}`}
        title={institution.name}
      />
      <CardContent>
        {institutionSecondaryIdentifiers?.map(identifier => (
          <InfoLine info={identifier.value} key={identifier.idValue} label={identifier.name} translateLabel={false} />
        ))}
        <InfoLine info={institution.attestationNumber} label="common.attestationNumber" />
        {institution.contact && institution.contact.contactName && (
          <>
            <InfoLine info={institution.contact.contactName} label="common.contactName" />
            <InfoLine info={institution.contact.email} label="common.email" />
            <InfoLine info={institution.contact.phoneNumber} label="common.phone" />
          </>
        )}
        <InfoLine
          displayIf={institution.address && institution.address.city}
          info={<TextAddress address={institution.address} />}
          label="common.address"
        />
        <InfoLine
          displayIf={operatorsCount}
          info={operatorsCount}
          label="common.operatorsCount"
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        {canEdit && (
          <Button
            aria-label={translate('button.edit')}
            color="secondary"
            variant="contained"
            onClick={handleEditInstitution}
          >
            {translate('button.edit')}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
