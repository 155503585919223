import { RequestHelper } from 'utils';
import { API_V2_URL } from 'utils/constants';

const categoryUrl = `${API_V2_URL}/categories`;

// SEARCH
const getCategoriesList = ({
  page = 0, freeSearch = '', name = '', colorHex = ''
} = {}) => RequestHelper.POST(`${categoryUrl}/filter?page=${page}`, { freeSearch, name, colorHex });

// Category list options
const getCategoryListForOptions = ({ input = '' } = {}) => RequestHelper.GET(`${categoryUrl}/options?input=${input}`);

const getCategory = categoryId => RequestHelper.GET(`${categoryUrl}/${categoryId}`);

// POST
const createCategory = category => RequestHelper.POST(`${categoryUrl}`, category);

// PUT
const updateCategory = (categoryId, category) => RequestHelper.PUT(`${categoryUrl}/${categoryId}`, category);

// DELETE
const deleteCategory = categoryId => RequestHelper.DELETE(`${categoryUrl}/${categoryId}`);

export const CategoryService = {
  getCategoriesList,
  getCategoryListForOptions,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory
};
