import React, { lazy, Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
 APPLICATION_ROLES, QUIZ_EDITION_ROLES, ROUTES, TRANSLATION_ROLES, VALIDATOR_ROLES
} from 'utils/constants';
import { Header, SkeletonMain } from 'components';
import { userStore } from 'stores';
import { KeyCloakUtils, UserHelper } from 'utils';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';

const PageHome = lazy(() => import('pages/PageHome'));
const PageAdministration = lazy(() => import('pages/PageAdministration'));
const PageAdministrationQuiz = lazy(() => import('pages/PageAdministrationQuiz'));
const PageAdministrationTranslations = lazy(() => import('pages/PageAdministrationTranslations'));
const PageAdministrationTranslationList = lazy(() => import('pages/PageAdministrationTranslationList'));
const PageAdministrationLanguages = lazy(() => import('pages/PageAdministrationLanguages'));
const PageAdministrationCountry = lazy(() => import('pages/PageAdministrationCountry'));
const PageCountry = lazy(() => import('pages/PageCountry'));
const PageQuiz = lazy(() => import('pages/PageQuiz'));
const PageQuizResults = lazy(() => import('pages/PageQuizResults'));
const PageRGPD = lazy(() => import('pages/PageRGPD'));
const PageStructureLevelTree = lazy(() => import('pages/PageStructureLevelTree'));
const PageCookies = lazy(() => import('pages/PageCookies'));
const RegisterForm = lazy(() => import('components/RegisterForm/RegisterForm'));
const UserDetail = lazy(() => import('components/Users/UserDetail/UserDetail'));
const SubscriptionDetail = lazy(() => import('components/Subscriptions/SubscriptionDetail/SubscriptionDetail'));
const PurchaseOrderDetail = lazy(() => import('components/PurchaseOrder/PurchaseOrderDetail/PurchaseOrderDetail'));

const PageTestButtons = lazy(() => import('pages/PageTestButtons'));

const SkeletonPage = () => (
  <>
    <Header />
    <SkeletonMain />
  </>
);

const PrivateRoute = ({
 Component, allowedRoles = Object.values(APPLICATION_ROLES), isRolesNeeded = true, ...rest
}) => {
  const { isConnected } = userStore;
  if (CookiesHelper.getChangePasswordStatus()) {
    CookiesHelper.setChangePasswordCookie('false');
    const keycloak = KeyCloakUtils.currentKeycloakInstance();
    keycloak.login();
  }
  const handleRouteRender = useCallback(props => (isConnected && ((isRolesNeeded && UserHelper.hasAccessRight(allowedRoles)) || !isRolesNeeded)
    ? <Component {...props} />
    : <Redirect to={ROUTES.HOME} />
    // eslint-disable-next-line
    ), [isConnected, Component]);

  return (
    <Route {...rest} render={handleRouteRender} />
  );
};

const UnauthenticatedRoute = ({ Component, ...rest }) => {
  const { isConnected } = userStore;
  const handleRouteRender = useCallback(props => (isConnected
          ? <Redirect to={ROUTES.HOME} />
          : <Component {...props} />
      // eslint-disable-next-line
  ), [isConnected, Component]);

  return (
    <Route {...rest} render={handleRouteRender} />
  );
};

PrivateRoute.propTypes = {
  Component: PropTypes.shape({}).isRequired
};

export const Routes = () => (
  <Suspense fallback={SkeletonPage()}>
    <Switch>
      <PrivateRoute Component={UserDetail} exact isRolesNeeded={false} path={`${ROUTES.USER_DETAIL}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={SubscriptionDetail} path={`${ROUTES.SUBSCRIPTION_DETAIL}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PurchaseOrderDetail} path={`${ROUTES.PURCHASE_ORDER_DETAIL}`} />

      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={`${ROUTES.GROUP_LIST}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={`${ROUTES.COMPANY_LIST}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={`${ROUTES.INSTITUTION_LIST}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={`${ROUTES.USER_LIST}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={`${ROUTES.SUBSCRIPTION_LIST}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={`${ROUTES.PURCHASE_ORDER_LIST}`} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministration} exact path={ROUTES.ADMIN} />

      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageStructureLevelTree} exact path={ROUTES.COMPANY_TREE_PARAMS} />

      <PrivateRoute allowedRoles={TRANSLATION_ROLES} Component={PageAdministrationTranslations} exact path={ROUTES.ADMIN_TRANSLATIONS} />
      <PrivateRoute allowedRoles={TRANSLATION_ROLES} Component={PageAdministrationTranslationList} exact path={ROUTES.ADMIN_TRANSLATION_LIST_PARAMS} />

      <PrivateRoute allowedRoles={TRANSLATION_ROLES} Component={PageAdministrationLanguages} exact path={ROUTES.ADMIN_LANGUAGES} />

      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageAdministrationQuiz} exact path={ROUTES.ADMIN_QUIZ} />
      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageAdministrationQuiz} exact path={ROUTES.ADMIN_RESULTS} />
      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageAdministrationQuiz} exact path={ROUTES.ADMIN_QUESTION} />
      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageAdministrationQuiz} exact path={ROUTES.ADMIN_CATEGORIES} />

      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageQuiz} exact path={ROUTES.ADMIN_QUIZ_CREATION} />
      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageQuiz} exact path={ROUTES.ADMIN_QUIZ_DETAIL} />

      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageAdministrationCountry} exact path={ROUTES.ADMIN_COUNTRIES} />
      <PrivateRoute allowedRoles={VALIDATOR_ROLES} Component={PageCountry} exact path={ROUTES.ADMIN_COUNTRIES_DETAIL_PARAMS} />

      <PrivateRoute allowedRoles={QUIZ_EDITION_ROLES} Component={PageQuizResults} exact path={ROUTES.ADMIN_QUIZ_RESULTS_DETAIL} />

      <UnauthenticatedRoute Component={RegisterForm} exact path={ROUTES.REGISTER} />

      <Route component={PageRGPD} exact path={ROUTES.RGPD} />
      <Route component={PageCookies} exact path={ROUTES.COOKIES} />

      <Route component={PageTestButtons} exact path={ROUTES.TEST_BUTTONS} />
      <Route component={PageHome} exact path={ROUTES.HOME} />

      <Redirect to={ROUTES.HOME} />
    </Switch>
  </Suspense>
);
