import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box,
  Button,
  Card,

  CardContent,
  CardHeader,
  Grid,
  IconButton, Tooltip,
  Typography
} from '@material-ui/core';
import { CustomIcon, Text, Wrapper } from 'components/_commons';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputField } from 'components/';
import { SubtitlePage } from 'components/Text';
import { GreenSwitch } from 'components/_commons/Switch';
import { useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import { CountryService, LanguageService } from 'services';
import styled from 'styled-components';
import {
  generateTempId, isTempId, replaceElementInNewList, translate
} from 'utils';
import { FRENCH_LOCALE } from 'utils/constants';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IdentifierView } from 'models/IdentifierView';
import sanitizeHtml from 'sanitize-html';

const StyledWarning = styled(Typography)`
  padding-left: 15px;
  font-size: small;
  color: var(--warning-color);
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &:hover {
    text-decoration: none;
    background-color: #dde8f6;
  }
`;

const RuleBlock = ({
  rule, setRule, nameKey, languages
}) => {
  const displayModal = useModal();
  const { i18nStore } = useStores();
  const { currentLanguage } = i18nStore;
  const [descriptions, setDescriptions] = useState([]);
  const [frenchDescription, setFrenchDescription] = useState(rule?.translations?.find(translation => translation.code === FRENCH_LOCALE)?.label ?? '');
  const [isAccordionExpand, setIsAccordionExpand] = useState(false);
  const hasFrench = Boolean(languages?.find(lng => (lng.locale === FRENCH_LOCALE)));
  const completion = {
    amount: rule?.translations?.filter(translation => Boolean(translation.label)).length || 0,
    total: languages?.length || 0
  };
  let isFormatARegex;
  try {
    ''.match(rule?.format);
    isFormatARegex = true;
  } catch (_) {
    isFormatARegex = false;
  }

  useEffect(() => {
    !rule?.format && setIsAccordionExpand(false);
    // eslint-disable-next-line
  }, [rule?.format]);

  useEffect(() => {
    setDescriptions(rule?.translations || []);
    setFrenchDescription(rule?.translations?.find(translation => translation.code === FRENCH_LOCALE)?.label ?? '');
    // eslint-disable-next-line
  }, [rule?.translations]);

  const handleChangeTranslation = useCallback((editedLanguageLocale, value) => {
    const translations = [...descriptions.filter(t => t.code !== editedLanguageLocale)];
    translations.push({ code: editedLanguageLocale, label: value });
    setRule({ ...rule, translations });
  }, [rule, setRule, descriptions]);

  const handleChangeFrenchTranslation = useCallback(value => {
    setFrenchDescription(value);
    handleChangeTranslation(FRENCH_LOCALE, value);
  }, [handleChangeTranslation]);

  const switchAccordionExpansion = useCallback(expanded => {
    setIsAccordionExpand(expanded);
  }, [setIsAccordionExpand]);

  return (
    <Grid container direction="column" item spacing={1}>
      <Grid alignItems="center" container direction="row" item spacing={2}>
        <Grid item xs>
          <InputField
            error={!isFormatARegex}
            key={`input-${nameKey}`}
            label={translate('pageCountry.rule.regex')}
            name={`format-${nameKey}`}
            value={rule?.format}
            onChange={(_, value) => setRule({ ...rule, format: value })}
          />
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            disabled={!rule?.format}
            variant="contained"
            onClick={() => displayModal({
              type: 'TEST_REGEX',
              regex: (isFormatARegex && (rule?.format || '')) || '',
              helperText: descriptions.find(dsc => dsc.code === currentLanguage)?.label
            })}
          >
            {translate('button.test')}
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ visibility: (!rule?.format) ? 'visible' : 'hidden' }}>
        <StyledWarning>{translate('pageCountry.rule.emptyFormat')}</StyledWarning>
      </Grid>
      <Grid item>
        <Box border={1} borderColor="#dde8f6">
          <Accordion disabled={!rule?.format} expanded={isAccordionExpand} onChange={(event, expanded) => switchAccordionExpansion(expanded)}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography>{translate('pageCountry.rule.descriptions')}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{translate('pageCountry.rule.completed', completion)}</Typography>
                </Grid>
                <Grid item>
                  { rule?.format
                      && (
                      <Typography style={frenchDescription ? { color: 'var(--success-color)' } : { color: 'var(--error-color)' }}>
                        {translate('pageCountry.rule.mandatory', completion)}
                      </Typography>
                      )}
                </Grid>
              </Grid>
            </StyledAccordionSummary>
            <AccordionDetails>
              {hasFrench && (
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Text fontWeight="bold" margin="0 0 8px 0">
                    {translate('pageCountry.rule.frenchDescription')}
                  </Text>
                  <InputField
                    label={translate(`languages.${FRENCH_LOCALE}`)}
                    name="language_name"
                    required
                    value={frenchDescription}
                    onChange={(_, value) => handleChangeFrenchTranslation(value)}
                  />
                </Grid>
                {(languages?.length > 1) && (
                <Grid container direction="column" item>
                  <Grid item>
                    <Text fontWeight="bold" margin="0 0 8px 0">
                      {translate('menu.translations')}
                    </Text>
                  </Grid>
                  <Grid container direction="column" item spacing={3}>
                    {languages && languages.map(lng => (lng.locale !== FRENCH_LOCALE) && (
                    <Grid item key={`name-${lng.locale}`}>
                      <InputField
                        label={translate(`languages.${lng.locale}`)}
                        value={descriptions.find(dsc => dsc.code === lng.locale)?.label}
                        onChange={(_, value) => handleChangeTranslation(lng.locale, value)}
                      />
                    </Grid>
                    ))}
                  </Grid>
                </Grid>
                )}
              </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>
    </Grid>
  );
};

const CardRule = ({
  identifierField, rule, updateField, languageList, deleteField
}) => {
  const updateIdentifierField = updatedRule => {
    updateField({ ...identifierField, rule: updatedRule });
  };

  const handleRequiredChange = useCallback((_, value) => {
    updateField({ ...identifierField, required: value });
  }, [identifierField, updateField]);

  const handleDeleteCard = () => {
    deleteField(identifierField);
  };

  return (
    <Grid container direction="column" item spacing={1}>
      <Card>
        <CardHeader
          action={(
            <IconButton onClick={handleDeleteCard}>
              <FontAwesomeIcon color="var(--error-color)" icon={faTrashAlt} size="xs" />
            </IconButton>
              )}
          style={{ backgroundColor: 'whitesmoke' }}
          title={(
            <Grid alignItems="center" container direction="row" item spacing={2}>
              <Grid item xs>
                <InputField
                  label={translate('label.identifierField.name')}
                  required
                  style={{ backgroundColor: '#ffffff' }}
                  value={identifierField.name}
                  onChange={(_, value) => updateField({ ...identifierField, name: value })}
                />
              </Grid>
              <Grid item>
                <Tooltip title={translate('tooltip.identifierField.required')}>
                  <GreenSwitch checked={identifierField.required} onChange={handleRequiredChange} />
                </Tooltip>
                <Typography align="center">{translate('pageCountry.identifierField.mandatory')}</Typography>
              </Grid>
            </Grid>
              )}
        />
        <CardContent>
          <RuleBlock
            languages={languageList}
            nameKey={identifierField.idField}
            rule={rule}
            setRule={updateIdentifierField}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export const CountryRules = observer(({
  isLoading,
  levelType,
  secondaryIdentifierFields,
  setSecondaryIdentifierFields,
  primaryIdentifierField,
  setPrimaryIdentifierField,
  pageTitle
}: {
  isLoading: boolean,
  levelType: string,
  pageTitle: string,
  primaryIdentifierField: IdentifierView,
  secondaryIdentifierFields: IdentifierView[],
  setPrimaryIdentifierField: (identifier: IdentifierView) => void,
  setSecondaryIdentifierFields: (identifiers: IdentifierView[]) => void
}) => {
  const displayModal = useModal();
  const [initRuleTranslationFilled, setInitRuleTranslationFilled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [languageList, setLanguageList] = useState([]);
  const [languagesLoading, setLanguagesLoading] = useState(true);

  useEffect(() => {
    LanguageService.getLanguageList()
      .then(resp => setLanguageList(resp))
      .catch(error => error && enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => setLanguagesLoading(false));
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (!primaryIdentifierField || !secondaryIdentifierFields || !initRuleTranslationFilled) {
      return;
    }
    const frRuleDescriptionFilled = [{
      id: primaryIdentifierField.idField,
      filled: !primaryIdentifierField.rule?.format
        || primaryIdentifierField.rule?.translations?.some(translation => translation.code === FRENCH_LOCALE && translation.label)
    }];
    secondaryIdentifierFields.filter(identifier => identifier.required).forEach(identifier => {
      identifier.rule?.translations?.some(translation => translation.code === FRENCH_LOCALE && translation.label)
        ? frRuleDescriptionFilled.push({ id: identifier.idField, filled: true })
        : frRuleDescriptionFilled.push({ id: identifier.idField, filled: false });
    });
    setInitRuleTranslationFilled(true);
  }, [initRuleTranslationFilled, primaryIdentifierField, secondaryIdentifierFields]);

  const updatePrimaryIdentifierField = useCallback(updatedRule => {
    setPrimaryIdentifierField({ ...primaryIdentifierField, rule: updatedRule });
  }, [primaryIdentifierField, setPrimaryIdentifierField]);

  const updateSecondaryIdentifierFields = useCallback(identifierField => {
    setSecondaryIdentifierFields(replaceElementInNewList(
      secondaryIdentifierFields,
      identifierField,
      (a, b) => a.idField === b.idField
    ));
  }, [secondaryIdentifierFields, setSecondaryIdentifierFields]);

  const createSecondaryIdentifier = useCallback(() => {
    const identifierField: IdentifierView = {
      idField: generateTempId(), name: '', required: false, primary: false, type: levelType
    };
    setSecondaryIdentifierFields([...secondaryIdentifierFields, identifierField]);
  }, [levelType, secondaryIdentifierFields, setSecondaryIdentifierFields]);

  const deleteIdentifierField = useCallback(identifierField => {
    displayModal({
      type: 'WARNING',
      title: translate('warnings.warning'),
      text: translate('warnings.identifierField.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        if (!isTempId(identifierField.idField)) {
          CountryService.deleteIdentifier(identifierField.idField).then(() => {
            enqueueSnackbar(translate('confirms.identifierField.delete'), { variant: 'success' });
            const updatedFields = secondaryIdentifierFields.filter(identifier => identifier.idField !== identifierField.idField);
            setSecondaryIdentifierFields(updatedFields);
          }).catch(error => enqueueSnackbar(error, { variant: 'error' }));
        } else {
          const updatedFields = secondaryIdentifierFields.filter(identifier => identifier.idField !== identifierField.idField);
          setSecondaryIdentifierFields(updatedFields);
        }
      }
    });
  }, [displayModal, enqueueSnackbar, secondaryIdentifierFields, setSecondaryIdentifierFields]);

  return (
    <Wrapper>
      <SubtitlePage dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('pageCountry.rule.preTitle')) }} />
      <PageTitle title={translate(pageTitle)} />
      {(isLoading || languagesLoading)
        ? (
          <Grid alignItems="center" container direction="column">
            <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="5x" spin />
          </Grid>
        ) : (
          <Grid alignItems="center" container direction="column" spacing={8}>

            <Grid container direction="column" item spacing={4}>
              <Grid item>
                <SubtitlePage fontSize="1.6rem">{translate('label.identifierField.primary')}</SubtitlePage>
              </Grid>
              <Grid item>
                <InputField
                  label={translate('label.identifierField.name')}
                  required
                  value={primaryIdentifierField?.name}
                  onChange={(_, value) => setPrimaryIdentifierField({ ...primaryIdentifierField, name: value })}
                />
              </Grid>
              <RuleBlock
                languages={languageList}
                nameKey="common.companyIdent"
                rule={primaryIdentifierField?.rule}
                setRule={updatePrimaryIdentifierField}
              />
              <Grid item>
                <SubtitlePage fontSize="1.6rem">{translate('label.identifierField.secondary')}</SubtitlePage>
                <Typography align="center" style={{ color: 'grey' }}>
                  {translate('pageCountry.identifierField.descriptionSecondary')}
                </Typography>
              </Grid>
              {secondaryIdentifierFields.length ? secondaryIdentifierFields.map(identifierField => (
                <CardRule
                  deleteField={deleteIdentifierField}
                  identifierField={identifierField}
                  key={identifierField.idField}
                  languageList={languageList}
                  rule={identifierField?.rule}
                  updateField={updateSecondaryIdentifierFields}
                />
              )) : (
                <Grid item>
                  <Typography align="center">{translate('pageCountry.identifierField.noSecondary')}</Typography>
                </Grid>
              )}
              <Grid container direction="column" item spacing={1}>
                <Button
                  color="secondary"
                  startIcon={<CustomIcon icon={faPlus} />}
                  style={{ paddingTop: 20, paddingBottom: 20 }}
                  variant="contained"
                  onClick={createSecondaryIdentifier}
                >
                  {translate('label.identifierField.addSecondary')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
    </Wrapper>
  );
});
