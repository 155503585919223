import { RequestHelper } from 'utils';
import {
  API_V2_URL, DATABILAN_API_URL, DATAC2E_API_URL, DATAFORM_API_URL, setCurrentVersion
} from 'utils/constants';

const userAccountsUrl = `${API_V2_URL}/user-accounts`;
const userSubscriptionsUrl = `${API_V2_URL}/user-subscriptions`;

const databilanUsersUrl = `${DATABILAN_API_URL}/${setCurrentVersion()}/user-accounts`;
const datac2eUsersUrl = `${DATAC2E_API_URL}/${setCurrentVersion()}/user-accounts`;
const dataformUsersUrl = `${DATAFORM_API_URL}/${setCurrentVersion()}/user-accounts`;

// GET
const getCurrentScope = () => RequestHelper.GET(`${userAccountsUrl}/current/scope`);

const getStructureLevelOptions = (application, userAccountId) => RequestHelper.GET(`${userAccountsUrl}/${userAccountId}/structure_level/options?application=${application}`);

const getUser = personModelId => RequestHelper.GET(`${userAccountsUrl}/${personModelId}`);

const getSubscriptionDetail = hashId => RequestHelper.GET(`${userSubscriptionsUrl}/${hashId}`);

const getApplications = () => RequestHelper.GET(`${userSubscriptionsUrl}/applications`);

const getUserSubscriptions = hashId => RequestHelper.GET(`${userSubscriptionsUrl}/user/${hashId}`);

const getAppNamesOptions = () => RequestHelper.GET(`${API_V2_URL}/applications/options`);

const getDatabilanUser = id => RequestHelper.GET(`${databilanUsersUrl}/${id}`);
const updateDatabilanRoles = (id, roles) => RequestHelper.PUT(`${databilanUsersUrl}/${id}/roles`, roles);
const getDatac2eUser = id => RequestHelper.GET(`${datac2eUsersUrl}/${id}`);
const updateDatac2eRoles = (id, roles) => RequestHelper.PUT(`${datac2eUsersUrl}/${id}/roles`, roles);
const getDataformUser = id => RequestHelper.GET(`${dataformUsersUrl}/${id}`);
const updateDataformRoles = (id, roles) => RequestHelper.PUT(`${dataformUsersUrl}/${id}/roles`, roles);
const getDatatecneaUser = id => RequestHelper.GET(`${userAccountsUrl}/${id}?application=DATATECNEA`);
const updateDatatecneaRoles = (id, roles) => RequestHelper.PUT(`${userAccountsUrl}/${id}/roles/DATATECNEA`, roles);
const updateDatafluidesRoles = (id, roles) => RequestHelper.PUT(`${userAccountsUrl}/${id}/roles/DATAFLUIDES`, roles);

const getRolesLanguages = id => RequestHelper.GET(`${userAccountsUrl}/${id}/role-languages`);
const updateRoleLanguages = (hashId, roleLabel, languages) => RequestHelper
  .PUT(`${userAccountsUrl}/${hashId}/${roleLabel}/role-languages`, languages);

const resetPassword = () => RequestHelper.GET(`${userAccountsUrl}/resetPassword`);

// POST
const getUserList = (filter, page = 0) => RequestHelper.POST(`${userAccountsUrl}/filter?page=${page}`, filter);

const getAccessibleRolesWithStructureLevelByAppAndActivity = (companyId) => RequestHelper.GET(
    `${userAccountsUrl}/${companyId}/rolesByStructureLevel`
);
const createUser = user => RequestHelper.POST(`${userAccountsUrl}`, user);

const importUsers = document => RequestHelper.POST(`${userAccountsUrl}/import`, document);

const registerUser = (user = {}) => RequestHelper.POST(`${userSubscriptionsUrl}/user/sign-up`, user);

const saveSubscription = (subscription = {}) => RequestHelper.POST(userSubscriptionsUrl, subscription);

const getSubscriptions = (params = { page: 0 }) => RequestHelper.POST(`${userSubscriptionsUrl}/filter?page=${params.page}`, params);

const validateSubscription = hashId => RequestHelper.POST(`${userSubscriptionsUrl}/validate/${hashId}`, {});

// PUT
// Update the main user object, throw error if it fails
const updateUser = (personId, user) => RequestHelper.PUT(`${userAccountsUrl}/${personId}/person`, user)
// Once the main object is updated, update possible secondary elements while ignoring errors
// (Since the main object is already updated, we need to try to update all secondary elements)
  .then(() => RequestHelper.PUT(`${databilanUsersUrl}/${personId}/operator`, user)
    // eslint-disable-next-line
    .catch(error => console.log(error))
    .then(() => RequestHelper.PUT(`${dataformUsersUrl}/${personId}/trainee`, user))
    // eslint-disable-next-line
    .catch(error => console.log(error))
    .then(() => RequestHelper.PUT(`${datac2eUsersUrl}/${personId}/inspector`, user))
    // eslint-disable-next-line
    .catch(error => console.log(error)));

const updateLocale = locale => RequestHelper.PUT(`${userAccountsUrl}/locale?locale=${locale}`);

// PATCH
const updateInstitution = institution => RequestHelper.PATCH(`${userAccountsUrl}/current/institution`, institution);

// DELETE
const rejectSubscription = hashId => RequestHelper.DELETE(`${userSubscriptionsUrl}/${hashId}`);

export const UserService = {
  getCurrentScope,
  getStructureLevelOptions,
  getUser,
  getDatabilanUser,
  updateDatabilanRoles,
  getDatac2eUser,
  updateDatac2eRoles,
  getDataformUser,
  updateDataformRoles,
  getDatatecneaUser,
  updateDatatecneaRoles,
  updateDatafluidesRoles,
  getRolesLanguages,
  updateRoleLanguages,
  getSubscriptionDetail,
  getApplications,
  getUserSubscriptions,
  getAppNamesOptions,
  getUserList,
  getAccessibleRolesWithStructureLevelByAppAndActivity,
  createUser,
  importUsers,
  registerUser,
  saveSubscription,
  getSubscriptions,
  validateSubscription,
  updateUser,
  updateLocale,
  updateInstitution,
  rejectSubscription,
  resetPassword
};
