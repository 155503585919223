import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  DialogActions, DialogContent, DialogTitle,
  FormControlLabel, FormGroup,
  Grid
} from '@material-ui/core';
import { Text } from 'components/_commons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { isChrome, isFirefox } from 'react-device-detect';
import { translate } from 'utils';

export const QuizCertifyingWarningModal = ({
  onConfirm, onClose, afterClose
}) => {
  const [isRecommendedBrowser, setIsRecommendedBrowser] = useState(false);

  const handleClose = useCallback(() => onClose(), [onClose]);

  useEffect(() => {
    setIsRecommendedBrowser(isChrome || isFirefox);
  }, []);

  const handleSubmit = useCallback(() => {
    const isFormValid = document.forms.traineeConfirmation && document.forms.traineeConfirmation.reportValidity();

    if (isFormValid) {
      afterClose();
      onConfirm && onConfirm();
      onConfirm && onClose();
    }
    return null;
  }, [onConfirm, onClose, afterClose]);

  return (
    <form autoComplete="off" name="traineeConfirmation" style={{ maxWidth: 500 }}>
      <DialogTitle className="warningModal">
        <FontAwesomeIcon className="mr1" icon={faExclamationTriangle} />
        {translate('quiz.certifyingQuiz')}
      </DialogTitle>

      <DialogContent style={{ position: 'relative', overflow: 'hidden' }}>
        <Grid container direction="column" justifyContent="flex-start">
          <Grid item>
            <Text fontWeight="bold">
              <FontAwesomeIcon className="mr1" icon={faInfoCircle} />
              {translate('quiz.thisIsCertifyingQuiz')}
            </Text>
          </Grid>
          <Grid item>
            <Text fontWeight="bold">
              <FontAwesomeIcon className="mr1" icon={faInfoCircle} />
              {translate('quiz.timedQuiz')}
            </Text>
          </Grid>
          <Grid item>
            <Text fontWeight="bold">
              <FontAwesomeIcon className="mr1" icon={faInfoCircle} />
              {translate('quiz.noRollback')}
            </Text>
          </Grid>
          {!isRecommendedBrowser && (
            <Grid item>
              <Text color="var(--error-color)" fontWeight="bold">
                <FontAwesomeIcon className="mr1" icon={faExclamationTriangle} />
                {translate('common.notRightBrowser')}
              </Text>
            </Grid>
          )}
          <Grid item>
            <Text fontWeight="bold">{translate('common.iConfirmThat')}</Text>
            <FormGroup>
              <FormControlLabel control={<Checkbox color="primary" required />} label={translate('common.imAloneForTest')} />
              <FormControlLabel control={<Checkbox color="primary" required />} label={translate('common.iDoNotHaveHelpForTheTest')} />
              <FormControlLabel control={<Checkbox color="primary" required />} label={translate('common.iWillStayInMyPage')} />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {translate('button.begin')}
        </Button>
      </DialogActions>
    </form>
  );
};

QuizCertifyingWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  afterClose: PropTypes.func,
  defaultValues: PropTypes.shape({})
};

QuizCertifyingWarningModal.defaultProps = {
  afterClose: () => { },
  defaultValues: {}
};
