// Redux store
import { userStore } from 'stores';

const hasAccessRight = (requiredRoles = []) => {
  const { userConnected } = userStore;

  if (userConnected && userConnected.resource_access && userConnected.resource_access['tecnea-commons']) {
    const userRoles = Object.values(userConnected.resource_access).map(client => client.roles || []).flat();
    if (userRoles && userRoles.length > 0) {
      return requiredRoles.some(role => userRoles.includes(role));
    }
  }

  return false;
};

const hasLanguageAccessForRole = (role, locale) => {
  const { rolesLanguages } = userStore;
  return rolesLanguages && rolesLanguages.find(roleLng => role.includes(roleLng.role.value))
    ?.languages.find(language => language.locale === locale);
};

const getParams = () => {
  const { userConnected } = userStore;

  if (userConnected && userConnected.tecnea) {
    return userConnected.tecnea;
  }

  return {};
};

const getDefaultCountry = address => {
  const { scope } = userStore;
  return address?.country ?? scope?.country;
};

export const UserHelper = {
  getParams,
  hasAccessRight,
  hasLanguageAccessForRole,
  getDefaultCountry
};
