import {
  faCommentLines,
  faQuestionSquare,
  faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogActions, DialogContent, Grid,
  ListItemAvatar, ListItemText,
  Paper, Switch,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { buildAnswerType, defaultMcqAnswer } from '__fixtures__';
import {
  DeleteButton, SelectField, StyledListItem
} from 'components';
import AnswerGenerator from 'components/AnswerGenerator/AnswerGenerator';
import { ColorPreviewContainer } from 'components/CategoryList';
import { getSmallDescription } from 'components/_commons/Quiz/QuizHelper';
import { DeleteAnswerButton, GenericAnswer, GenericRadio } from 'components/forms/_commons';
import { InputFile } from 'components/forms/inputs';
import debounce from 'debounce-promise';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { useFormState } from 'react-use-form-state';
import { CategoryService, QuestionService } from 'services';
import { DocumentHelper, translate } from 'utils';
import { QUESTION_TYPE } from 'utils/constants';
import { HeaderModal } from './_HeaderModal';

const useStyles = makeStyles(() => ({
  paperContainer: {
    height: 570
  },
  paper: {
    padding: 15
  },
  paperThirdColumn: {
    paddingTop: 15,
    overflowY: 'scroll'
  },
  marginBottom: {
    marginBottom: 15
  },
  paddingBottom: {
    paddingBottom: 30
  }
}));

const CustomOptionCategories = ({ data, innerProps }) => (
  <StyledListItem {...innerProps} style={{ cursor: 'pointer' }}>
    <ListItemAvatar>
      <ColorPreviewContainer color={data.colorHex} />
    </ListItemAvatar>
    <ListItemText primary={data.name} />
  </StyledListItem>
);

export const QuestionModal = observer(({
  onClose, afterClose, onConfirm
}) => {
  const DEFAULT_ANSWERS_NUMBER = 4;
  const IMAGE_PICKER_DEFAULT_ANSWERS_NUMBER = 3;
  const MIN_ANSWERS = 2;
  const DROPDOWN_MIN_ANSWERS = 3;

  const {
    mcq, dropDown, yesNo, trueFalse, imagePicker, rating, shortInput, longInput, dateInput, emailInput, phoneInput
  } = QUESTION_TYPE;

  const buildDefaultAnswer = useCallback(
ansType => !(ansType === mcq
      || ansType === dropDown
      || ansType === imagePicker),
  [mcq, dropDown, imagePicker]
);

  const buildKeywordOptions = useCallback(keywords => {
    if (keywords && keywords.length === 0) return null;
    return keywords && keywords.map(keyword => ({ label: keyword, value: keyword }));
  }, []);

  const classes = useStyles();
  const displayModal = useModal();

  const { questionStore } = useStores();

  const { question } = questionStore;
  const {
    questionType, keywords, answers, isRequired, titleImage, category, isOld
  } = question;

  const [formState, { text, number }] = useFormState(question);

  const [questionTypesSelect, setQuestionTypesSelect] = useState([]);
  const [answerType, setAnswerType] = useState(questionType || { value: 'BASIC', label: 'QCM' });
  const [questionKeyWords, setQuestionKeyWords] = useState(buildKeywordOptions(keywords) || []);
  const [questionAnswers, setQuestionAnswers] = useState(answers && answers.length > 0
    ? answers : defaultMcqAnswer);
  const [error, setError] = useState('');
  const [isQuestionRequired, setIsQuestionRequired] = useState(isRequired || false);
  const [fileImage, setFileImage] = useState(titleImage
    ? DocumentHelper.getPNGWithBase64(titleImage)
    : '');
  const [selectedCategory, setSelectedCategory] = useState(category || {});
  const [isButtonDisabled, setIsButtonDisabled] = useState(buildDefaultAnswer(answerType.value));
  const [keywordInput, setKeywordInput] = useState('');
  const [open, setOpen] = useState(false);
  const [questionIsOld] = useState(isOld);
  const [disableScore, setDisableScore] = useState(false);

  const getRefData = useCallback(() => {
    QuestionService.getQuestionType()
      .then(response => setQuestionTypesSelect(response));
  }, []);

  const resetQuestion = useCallback(() => {
    questionStore.purgeQuestion();
  }, [questionStore]);

  useEffect(() => {
    resetQuestion();
    getRefData();
  }, [resetQuestion, getRefData]);

  const isBinary = useCallback(quesType => quesType === yesNo || quesType === trueFalse, [yesNo, trueFalse]);

  const isFree = useCallback(quesType => quesType === rating || quesType === shortInput || quesType === longInput
    || quesType === emailInput || quesType === dateInput || quesType === phoneInput, [

    longInput, rating, shortInput, dateInput, phoneInput, emailInput
  ]);

  const toggleDisableScoreTxtField = useCallback(() => {
    if (questionType) {
      if (isFree(questionType?.value)) {
        formState.setField('points', '');
        return setDisableScore(true);
      }
      return setDisableScore(false);
    }
    return null;
  }, [questionType, formState, isFree]);

  useEffect(() => {
    toggleDisableScoreTxtField();
  }, [toggleDisableScoreTxtField]);

  const handleAddAnswer = useCallback(() => {
    const { value } = answerType;

    const buildedAnswer = buildAnswerType(value);
    setQuestionAnswers([...questionAnswers, buildedAnswer]);
  }, [questionAnswers, answerType]);

  const handleChangeAnswer = useCallback((event, index) => {
    const { name, value, checked } = event.target;

    const newAnswers = [...questionAnswers];
    newAnswers[index] = {
      ...newAnswers[index],
      [name]: name === 'isCorrect' ? checked : value
    };

    setQuestionAnswers(newAnswers);
  }, [questionAnswers]);

  const handleFilterOptions = useCallback(option => selectedCategory.id !== option.data.id, [selectedCategory]);

  const handleDelete = useCallback(index => {
    const { value } = answerType;
    const newAnswers = [...questionAnswers].filter((a, i) => i !== index);
    setQuestionAnswers(newAnswers);

    if (isBinary(value)) {
      setIsButtonDisabled(false);
    }

    if (isFree(value)) {
      setIsButtonDisabled(false);
    }
  }, [isBinary, isFree, questionAnswers, answerType]);

  const handleQuestionTypeSelect = useCallback(option => {
    const { value } = option;
    setIsButtonDisabled(!value);

    if (value === mcq || value === dropDown) {
      const mcqDropDownAnswer = buildAnswerType(value);
      const definedAnswers = [];

      for (let i = 1; i <= DEFAULT_ANSWERS_NUMBER; i++) {
        definedAnswers.push(mcqDropDownAnswer);
      }

      setQuestionAnswers('');
      setQuestionAnswers(definedAnswers);
      setDisableScore(false);
    }

    if (value === imagePicker) {
      const imagePickerAnswer = buildAnswerType(value);
      const definedAnswers = [];

      for (let i = 1; i <= IMAGE_PICKER_DEFAULT_ANSWERS_NUMBER; i++) {
        definedAnswers.push(...imagePickerAnswer);
      }

      setQuestionAnswers('');
      setQuestionAnswers(definedAnswers);
      setDisableScore(false);
    }

    if (isBinary(value)) {
      const defaultBinary = [];

      const buildedAnswer = buildAnswerType(value);
      defaultBinary.push(...buildedAnswer);

      setQuestionAnswers('');
      setQuestionAnswers(defaultBinary);
      setIsButtonDisabled(true);
      setDisableScore(false);
    }

    if (value === rating) {
      const ratingAnswer = buildAnswerType(value);
      setQuestionAnswers('');
      setQuestionAnswers(ratingAnswer);
      setIsButtonDisabled(true);
      formState.setField('points', '');
      setDisableScore(true);
    }

    if (isFree(value)) {
      const buildedAnswer = buildAnswerType(value);
      setQuestionAnswers(buildedAnswer);
      setIsButtonDisabled(true);
      formState.setField('points', '');
      setDisableScore(true);
    }

    setAnswerType(option);
  }, [mcq, dropDown, rating, imagePicker, isFree, isBinary, formState]);

  const handleQuestionKeywords = useCallback(values => setQuestionKeyWords(values || []), []);

  const handleSelectCategory = useCallback(value => {
    const isCategorySelected = selectedCategory.id === value.id;

    if (isCategorySelected) return setError(translate('errors.categoryAlreadySelected'));

    setOpen(false);
    return setSelectedCategory(value);
  }, [selectedCategory]);

  const handleDeleteSelectedCategory = useCallback(
id => setSelectedCategory(id === selectedCategory.id && {}),
    [selectedCategory]
);

  const handleSwitchChange = useCallback(event => {
    const { checked } = event.target;

    setIsQuestionRequired(checked);
  }, []);

  const handleRadioChange = useCallback((event, index) => {
    const { name, value, checked } = event.target;

    const newAnswers = [...questionAnswers];
    let needToCheck = true;
    // If we unchecked the checked answer
    if (newAnswers[index].isCorrect === true) {
      needToCheck = false;
    }
    newAnswers.forEach(answer => {
      // eslint-disable-next-line no-param-reassign
      answer.isCorrect = false;
    });
    if (needToCheck) {
      newAnswers[index] = {
        ...newAnswers[index],
        [name]: name === 'isCorrect' ? checked : value
      };
    }

    setQuestionAnswers(newAnswers);
  }, [questionAnswers]);

  const handleImportFile = useCallback(doc => {
    if (doc) {
      setFileImage(doc.base64Content);
    }
  }, []);

  const handleImportImageAnswer = useCallback((doc, index) => {
    if (doc) {
      const docWithoutBase64 = DocumentHelper.getDocumentWithoutB64FromOnlyB64(doc.base64Content);
      const newQuestionAnswers = [...questionAnswers];
      newQuestionAnswers[index] = {
        ...newQuestionAnswers[index],
        answerPicture: docWithoutBase64
      };
      setQuestionAnswers(newQuestionAnswers);
    }
  }, [questionAnswers]);

  const handleDeleteLogo = useCallback(() => {
    setFileImage(null);
  }, []);

  const handleKeywordChange = useCallback(inputValue => {
    if (inputValue.length <= 100) {
      return setKeywordInput(inputValue);
    }
    return null;
  }, []);

  const handleKeyDown = useCallback(event => {
    const { value } = event.target;
    const newQuestionKeywords = [...questionKeyWords];

    if (!value) return;

    if (newQuestionKeywords.find(keyword => keyword.value === value)) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        setKeywordInput('');
        setQuestionKeyWords([...newQuestionKeywords, { label: value, value }]);
        break;
      default:
    }
  }, [questionKeyWords]);

  const handleClose = useCallback(() => {
    afterClose();
    onClose();
  }, [onClose, afterClose]);

  const submitForm = useCallback(e => {
    e.preventDefault();

    const { value } = answerType;

    const isFormValid = document.forms.questionForm && document.forms.questionForm.reportValidity();

    if ((questionAnswers.length < MIN_ANSWERS)
      && (value === mcq
        || value === dropDown
        || value === yesNo
        || value === trueFalse
        || value === imagePicker)) {
      return setError(translate('errors.noAnswers'));
    }

    if (questionAnswers.length < DROPDOWN_MIN_ANSWERS && value === dropDown) {
      return setError(translate('errors.dropDownAnswers'));
    }

    const correctAnswer = questionAnswers.map((answer, index) => (answer.isCorrect ? index : null)).filter(a => a !== null);

    let hasNoAnswer = false;
    if ((!correctAnswer.length)
      && (value === mcq
        || value === dropDown
        || value === yesNo
        || value === trueFalse
        || value === imagePicker)) {
      hasNoAnswer = true;
    }

    let base64Content;
    if (fileImage) {
      base64Content = DocumentHelper.getDocumentWithoutB64FromOnlyB64(fileImage);
    }

    const form = {
      ...formState.values,
      questionType: answerType,
      titleImage: base64Content || '',
      isRequired: isQuestionRequired,
      keywords: questionKeyWords.map(keyword => keyword.value),
      category: Object.keys(selectedCategory).length > 0 ? selectedCategory : null,
      answers: questionAnswers
    };

    if (isFormValid) {
      if (hasNoAnswer) {
        displayModal({
          type: 'WARNING',
          title: translate('warnings.warning'),
          text: translate('warnings.question.noValidAnswer'),
          buttonCancel: translate('button.cancel'),
          buttonConfirm: translate('button.iAmSure'),
          onConfirm: () => onConfirm(form, handleClose)
        });
      } else {
        onConfirm(form, handleClose);
      }
    }
    return null;
  }, [
    onConfirm, handleClose, questionAnswers, selectedCategory, formState.values,
    fileImage, answerType, isQuestionRequired, displayModal,
    questionKeyWords, mcq, dropDown, trueFalse, yesNo, imagePicker
  ]);

  const handleSubmit = useCallback(e => {
    if (questionIsOld) {
      return displayModal({
        type: 'WARNING',
        title: translate('warnings.warning'),
        text: translate('question.youOverwritingQuestion'),
        buttonCancel: translate('button.cancel'),
        buttonConfirm: translate('button.iAmSure'),
        onConfirm: () => submitForm(e)
      });
    }

    return submitForm(e);
  }, [questionIsOld, displayModal, submitForm]);

  const getAsyncOptions = useCallback(inputValue => new Promise(resolve => {
    CategoryService.getCategoriesList({ freeSearch: inputValue, page: 0 })
      .then(response => resolve(response.content));
  }), []);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const handleCategoryKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      return e.preventDefault();
    }
    return null;
  };

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const renderFirstColumn = () => (
    <Paper className={`${classes.paperContainer} ${classes.paper}`} variant="outlined">
      <Paper className={`${classes.marginBottom}`} elevation={0}>
        <TextField
          fullWidth
          label={translate('common.question')}
          minRows={8}
          multiline
          name="title"
          required
          {...text('title')}
          variant="outlined"
        />
      </Paper>
      <Paper className={`${classes.marginBottom}`} elevation={0}>
        <TextField
          fullWidth
          label={translate('question.code')}
          name="code"
          required
          {...text('code')}
          variant="outlined"
        />
      </Paper>
      <Paper className={`${classes.paper} ${classes.marginBottom}`} variant="outlined">
        <Grid alignItems="center" container direction="row" justifyContent="space-between">
          <Grid item xs={7}>
            <Typography variant="subtitle2">
              {translate('question.isRequiredQuestion')}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            {translate('common.no')}
            <Switch
              checked={Boolean(isQuestionRequired)}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              name="isRequired"
              onChange={handleSwitchChange}
            />
            {translate('common.yes')}
          </Grid>
        </Grid>
      </Paper>
      <Grid alignItems="center" container justifyContent="center">
        {fileImage ? (
          <Button
            color="primary"
            onClick={handleDeleteLogo}
          >
            {translate('quiz.deleteLogo')}
          </Button>
        ) : (
          <InputFile
            fileAccepted=".jpg, .jpeg, .png"
            handleAddDocument={handleImportFile}
            id="importQuestionImage"
            label="question.questionImage"
            labelButton="quiz.importLogo"
            name="titleImage"
            value={fileImage}
          />
        )}
      </Grid>
      <Grid alignItems="center" container justifyContent="center">
        {fileImage
          ? <img alt={translate('quiz.quizLogo')} src={fileImage} style={{ maxHeight: 150, maxWidth: 220 }} />
          : null}
      </Grid>
    </Paper>
  );

  const renderCategoryItemContainer = categoryObject => (
    <Grid item style={{ marginTop: 20 }}>
      <Typography gutterBottom variant="button">{translate('question.selectedCategory')}</Typography>
      <Grid container direction="row" style={{ marginBottom: 20 }}>
        <Grid item key={categoryObject.id} xs={12}>
          <Paper
            elevation={0}
            style={{ backgroundColor: 'var(--grey-lighter)', margin: '0 2px 2px 0', padding: '0 10px' }}
          >
            <Grid alignItems="center" container direction="row">
              <Grid item xs={2}>
                <ColorPreviewContainer color={categoryObject.colorHex} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="inherit">{getSmallDescription(categoryObject.name)}</Typography>
              </Grid>
              <Grid item xs={2}>
                <DeleteButton onClick={() => handleDeleteSelectedCategory(categoryObject.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                </DeleteButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderSecondColumn = () => (
    <Paper className={`${classes.paperContainer} ${classes.paper}`} variant="outlined">
      <Paper elevation={0}>
        <SelectField
          isClearable={false}
          isMulti={false}
          label="question.questionType"
          messageNoOptions="question.chooseType"
          name="questionType"
          options={questionTypesSelect}
          placeholder="question.questionTypeLabel"
          required
          value={answerType}
          onChange={handleQuestionTypeSelect}
        />
      </Paper>
      <Paper elevation={0}>
        <Tooltip
          open={open}
          placement="top"
          title={translate('question.categoryToolTip')}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
        >
          <AsyncSelect
            cacheOptions
            closeMenuOnSelect
            components={{ Option: CustomOptionCategories }}
            defaultOptions
            filterOption={handleFilterOptions}
            loadingMessage={() => translate('common.loading')}
            loadOptions={inputValue => debouncedLoadOptions(inputValue)}
            name="categories"
            noOptionsMessage={() => translate('errors.noOptions')}
            placeholder={translate('question.chooseCategory')}
            styles={{ menu: base => ({ ...base, zIndex: 2000 }) }}
            value={translate('common.search')}
            onChange={handleSelectCategory}
            onKeyDown={handleCategoryKeyDown}
          />
        </Tooltip>
        {Object.keys(selectedCategory).length > 0 ? (
          renderCategoryItemContainer(selectedCategory)
        ) : (
          <div style={{ marginBottom: 20 }} />
        )}
        <Paper className={`${classes.marginBottom}`} elevation={0}>
          <TextField
            fullWidth
            label={translate('common.reference')}
            name="reference"
            {...text('reference')}
            inputProps={{ maxLength: 20 }}
            variant="outlined"
          />
        </Paper>
      </Paper>
      <Paper className={`${classes.paddingBottom}`} elevation={0}>
        <CreatableSelect
          components={{ DropdownIndicator: null }}
          inputValue={keywordInput}
          isClearable
          isMulti
          menuIsOpen={false}
          placeholder={translate('question.keywordsLabel')}
          value={questionKeyWords}
          onChange={handleQuestionKeywords}
          onInputChange={handleKeywordChange}
          onKeyDown={handleKeyDown}
        />
      </Paper>
      <Paper elevation={0}>
        <TextField
          disabled={disableScore}
          fullWidth
          inputProps={{ min: 0 }}
          label={translate('common.points')}
          name="points"
          variant="outlined"
          {...number('points')}
        />
      </Paper>
    </Paper>
  );

  const renderThirdColumn = () => (
    <Paper className={`${classes.paperContainer} ${classes.paper} ${classes.paperThirdColumn}`} variant="outlined">
      {questionAnswers && questionAnswers.map((answer, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container key={`answer_${index}`}>
          {answerType.value === imagePicker ? (
            <GenericAnswer
              answerContent={(
                <>
                  <InputFile
                    {...answer}
                    fileAccepted=".jpg, .jpeg, .png"
                    handleAddDocument={doc => handleImportImageAnswer(doc, index)}
                    id={`answer_${index}`}
                    label="question.questionImage"
                    labelButton="quiz.importLogo"
                    name="answerPicture"
                    needResize
                    value={answer.answerPicture}
                  />
                  <Grid alignItems="center" container justifyContent="center">
                    {answer.answerPicture ? (
                      <img
                        alt={translate('question.answerLogo')}
                        src={DocumentHelper.getPNGWithBase64(answer.answerPicture)}
                        style={{ marginTop: 20, maxHeight: 220, maxWidth: 320 }}
                      />
                    ) : null}
                  </Grid>
                </>
              )}
              checkTypeButton={<GenericRadio isCorrect={answer.isCorrect} onHandleRadioChange={event => handleRadioChange(event, index)} />}
              deleteAnswerBtn={<DeleteAnswerButton onClickDelete={() => handleDelete(index)} />}
            />
          ) : (
            <AnswerGenerator
              {...answer}
              handleChangeAnswer={event => handleChangeAnswer(event, index)}
              handleDelete={() => handleDelete(index)}
              onAnswerType={answerType.value}
              onHandleRadioChange={event => handleRadioChange(event, index)}
            />
          )}
        </Grid>
      ))}

      {!isButtonDisabled && (
        <Grid item>
          <Button
            color="secondary"
            fullWidth
            startIcon={<FontAwesomeIcon icon={faCommentLines} />}
            style={{ marginTop: '2rem' }}
            variant="contained"
            onClick={handleAddAnswer}
          >
            {translate('button.addAnswer')}
          </Button>
        </Grid>
      )}
    </Paper>
  );

  return (
    <form autoComplete="off" name="questionForm">
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faQuestionSquare} />
        <Typography component="span">
          {question.title ? translate('pageQuestionList.updateQuestion') : translate('button.createQuestion')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ position: 'relative', overflow: 'hidden' }}>
        {error && (<Typography color="error" style={{ marginBottom: '1rem' }}>{error}</Typography>)}
        {questionIsOld && (
          <Alert severity="warning" style={{ marginBottom: '1rem' }}>
            {translate('warnings.question.editingOldQuestion')}
          </Alert>
        )}
        <Grid container direction="row" spacing={2} wrap="nowrap">
          <Grid item xs={4}>
            {renderFirstColumn()}
          </Grid>
          <Grid item xs={4}>
            {renderSecondColumn()}
          </Grid>
          <Grid item xs={4}>
            {renderThirdColumn()}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {translate('button.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {translate('button.save')}
        </Button>
      </DialogActions>
    </form>
  );
});

QuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  afterClose: PropTypes.func,
  defaultValues: PropTypes.shape({})
};

QuestionModal.defaultProps = {
  afterClose: () => { },
  defaultValues: {}
};
