import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions } from '@material-ui/core';
import { translate, UserHelper } from 'utils';
import { APPLICATION_ROLES } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

export const CustomActionButtons = ({
  isValidated, onCancel, onClose, additionalRoles, mergeButtonAction, isLoading
}) => {
  const hasRights = UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.VALIDATOR, ...additionalRoles]);
  const isValidateMode = hasRights && !isValidated;

  const handleClick = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  const validateLabel = isValidateMode ? 'button.validateData' : 'button.save';

  return (
    <DialogActions>
      <Button
        disabled={isLoading}
        onClick={handleClick}
      >
        {translate(isValidateMode ? 'button.reportValidation' : 'button.cancel')}
      </Button>
      <Button
        color="primary"
        disabled={isLoading}
        type="submit"
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          translate(validateLabel)
        )}
      </Button>
      {mergeButtonAction && (
        <Button
          color="secondary"
          disabled={isLoading}
          onClick={mergeButtonAction}
        >
          {translate('button.merge')}
        </Button>
      )}
    </DialogActions>
  );
};

CustomActionButtons.propTypes = {
  additionalRoles: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  isValidated: PropTypes.bool,
  mergeButtonAction: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func
};

CustomActionButtons.defaultProps = {
  additionalRoles: [],
  isLoading: false,
  isValidated: true,
  mergeButtonAction: null,
  onCancel: () => {},
  onClose: () => {}
};
