import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { DocumentHelper, translate } from 'utils';
import { Text } from 'components';
import { MAX_FILE_SIZE } from 'utils/constants';

const StyledFileUpload = styled.input`
  width: 100%;
`;

export const ImportModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues
}) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();

    if (!document) {
      return setError(translate('errors.noImportFile'));
    }

    const sendFile = {
      name: document.name,
      base64Content: DocumentHelper.getDocumentWithoutB64FromOnlyB64(document.base64Content)
    };

    onConfirm(sendFile);

    if (closeOnSubmit) {
      return onClose();
    }

    return null;
  }, [onConfirm, onClose, closeOnSubmit, document]);

  const handleFileUpload = useCallback(event => {
    DocumentHelper.handleFileUpload(event, false, MAX_FILE_SIZE)
      .then(data => setDocument(data));
  }, [setDocument]);

  return (
    <form onSubmit={handleValidateModal}>
      <DialogTitle>
        <FontAwesomeIcon icon={faFileImport} />
        {translate('modalImport.import')}
      </DialogTitle>

      <DialogContent>
        {defaultValues && defaultValues.message && (
          <Text color="var(--success-color)">{defaultValues.message}</Text>
        )}

        {error && <Text color="var(--error-color)" fontWeight={600} margin="0 0 1rem 0">{error}</Text>}

        <StyledFileUpload
          accept=".xlsx, .xls, .csv"
          placeholder={translate('modalImport.import')}
          type="file"
          width="100%"
          onChange={handleFileUpload}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.import')}</Button>
      </DialogActions>
    </form>
  );
};

ImportModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

ImportModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: null
};
