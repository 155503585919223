import React, { useCallback } from 'react';
import {
  Button, Card, Grid, TextField, Typography
} from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import shortid from 'shortid';
import { PurchaseOrderService } from 'services/PurchaseOrderService';
import { DocumentList } from 'components/DocumentList/DocumentList';
import { useModal } from 'hooks';

const StepTitle = ({ text, number }) => (
  <Grid alignItems="center" container direction="row" spacing={1}>
    <Grid item>
      <Typography fontWeight="bold" variant="h5">
        {`${number}.) ${translate(text)}`}
      </Typography>
    </Grid>
  </Grid>
    );

const StepComponent = ({ children }) => (
  <Grid item style={{ width: '100%' }}>
    <Grid container direction="column" spacing={2} style={{ width: '100%' }}>
      {Array.isArray(children)
          ? children.map((child) => <Grid item key={shortid.generate()} style={{ width: '100%' }}>{child}</Grid>)
          : <Grid item style={{ width: '100%' }}>{children}</Grid>}
    </Grid>
  </Grid>
);

const getApplicationLogo = (applicationName) => (
  <img alt={`${applicationName}_LOGO`}
       src={`${process.env.PUBLIC_URL}/assets/images/favicons/${applicationName.toLowerCase()}.ico`}
       style={{ width: '25px', height: '25px' }}
  />
);

const cardComponents = {
  DATAFLUIDES: {
    accountNeededCount: false,
    logo: getApplicationLogo('DATAFLUIDES'),
    templateButtons: (onClick) => [
      <Grid item key="firstDatafluides">
        <Button
          color="primary"
          startIcon={
            <FontAwesomeIcon
              color="#ffffff"
              icon={faDownload}/>}
          variant="contained"
          onClick={() => onClick('first')}
        >
          <Typography noWrap variant="h6">{translate('registerForm.purchaseOrder.download.firstSubgroup')}</Typography>
        </Button>
      </Grid>,
      <Grid item key="secondDatafluides">
        <Button
          color="primary"
          startIcon={
            <FontAwesomeIcon
              color="#ffffff"
              icon={faDownload}/>}
          variant="contained"
          onClick={() => onClick('second')}
        >
          <Typography noWrap variant="h6">{translate('registerForm.purchaseOrder.download.secondSubgroup')}</Typography>
        </Button>
      </Grid>
    ]
},
  DATABILAN_DISTRIBUTOR: {
    accountNeededCount: true,
    logo: getApplicationLogo('DATABILAN'),
    templateButtons: (onClick) => [
      <Grid item key="operator" style={{ width: '100%' }}>
        <Button
          color="primary"
          disabled
          startIcon={
            <FontAwesomeIcon
              color="#ffffff"
              icon={faDownload}/>}
          variant="contained"
          onClick={() => onClick('DATABILAN_DISTRIBUTOR')}
        >
          <Typography noWrap variant="h6">{translate('registerForm.purchaseOrder.download.template')}</Typography>
        </Button>
      </Grid>
    ]
},
  DATABILAN_OPERATOR: {
    accountNeededCount: true,
    logo: getApplicationLogo('DATABILAN'),
    templateButtons: (onClick) => [
      <Grid item key="operator" style={{ width: '100%' }}>
        <Button
          color="primary"
          startIcon={
            <FontAwesomeIcon
              color="#ffffff"
              icon={faDownload}/>}
          variant="contained"
          onClick={() => onClick('DATABILAN_OPERATOR')}
        >
          <Typography noWrap variant="h6">{translate('registerForm.purchaseOrder.download.template')}</Typography>
        </Button>
      </Grid>]
  }
};

export const PurchaseOrderCard = ({
 activity, title, purchaseOrderFiles, setPurchaseOrderFiles, additionalFiles, xs,
                                    setAdditionalFiles, accountsNeededFieldName, accountsNeededFieldNameProps
}) => {
  const showModal = useModal();

  const getAccountNeededDatabilanComponent = useCallback(() => (
    <TextField
      autoComplete="off"
      inputProps={{ maxLength: 200, min: 1 }}
      label={translate('common.accountsNeeded')}
      name={accountsNeededFieldName}
      style={{ maxWidth: '75%' }}
      type="number"
      {...accountsNeededFieldNameProps(accountsNeededFieldName)}
    />
  ), [accountsNeededFieldName, accountsNeededFieldNameProps]);

  const downloadPurchaseOrderTemplate = useCallback((subgroup) => {
    if (activity === 'DATAFLUIDES') {
      PurchaseOrderService.getPurchaseOrderTemplate(subgroup);
    } else if (activity.includes('DATABILAN')) {
      PurchaseOrderService.getDatabilanPurchaseOrderTemplate(activity);
    }
  }, [activity]);

  const handleAddFile = useCallback((doc, files, setFiles) => {
    setFiles(
      [...files,
        {
          document: {
            ...doc,
            base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
          },
          id: shortid.generate()
        }]
    );
  }, []);

  const handleDeleteFile = useCallback((fileToDelete, files, setFiles) => showModal({
    type: 'WARNING',
    text: translate('registerForm.purchaseOrder.deleteDocument.confirmation'),
    buttonCancel: translate('button.cancel'),
    onConfirm: () => {
      if (fileToDelete.id) {
        setFiles(files.filter((file) => file.id !== fileToDelete.id));
      }
    }
  }), [showModal]);

  const renderDocumentList = useCallback((documents, onDeleteDocument, onImportDocument, { ...listProps } = {}) => (
    <DocumentList
      documents={documents}
      importLabel="button.import"
      spacing={3}
      titleStyles={{
        textAlign: 'center',
        variant: 'h6'
      }}
      onDelete={onDeleteDocument}
      onImport={onImportDocument}
      {...listProps}
    />
  ), []);

  return (
    <Grid item xs={xs}>
      <Grid alignItems="center" container direction="column">
        <Grid item>
          <Grid alignItems="center" container direction="row" spacing={1}>
            <Grid item>
              {cardComponents[activity].logo}
            </Grid>
            <Grid item>
              <Typography variant="h3">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: '100%', maxWidth: '90%' }}>
          <Card style={{ padding: 20, borderWidth: 2 }} variant="outlined">
            <Grid container direction="column" item spacing={3}>
              {cardComponents[activity].accountNeededCount
                && <Grid item>
                  { getAccountNeededDatabilanComponent() }
                </Grid>}
              <StepComponent>
                <StepTitle number={1} text="registerForm.purchaseOrder.step.template"/>
                <Grid container direction="column" spacing={2}>
                  {cardComponents[activity].templateButtons(downloadPurchaseOrderTemplate)}
                </Grid>
              </StepComponent>
              <StepComponent>
                <StepTitle number={2} text="registerForm.purchaseOrder.step.purchaseOrder"/>
                {renderDocumentList(
                  (Object.keys(purchaseOrderFiles).length ? purchaseOrderFiles : []),
                  (file) => handleDeleteFile(file, purchaseOrderFiles, setPurchaseOrderFiles),
                  (file) => handleAddFile(file, purchaseOrderFiles, setPurchaseOrderFiles),
                  { sizeLimit: 1 }
                )}
              </StepComponent>
              <StepComponent>
                <StepTitle number={3} text="registerForm.purchaseOrder.step.additionalFiles"/>
                {renderDocumentList(
                  Object.keys(additionalFiles).length ? additionalFiles : [],
                  (file) => handleDeleteFile(file, additionalFiles, setAdditionalFiles),
                  (file) => handleAddFile(file, additionalFiles, setAdditionalFiles),
                  { variant: 'outlined' }
                      )}
              </StepComponent>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
