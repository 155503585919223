import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { translate } from 'utils';

export const InfoLine = ({
  label, info, displayIf, translateLabel
}) => {
  const isDisplayed = displayIf !== undefined ? Boolean(displayIf) : Boolean(info);

  return (
    isDisplayed && (
    <Typography component="div" variant="body2">
      {label && (`${translateLabel ? translate(label) : label} : `)}
      <strong>{info}</strong>
    </Typography>
    )
  );
};

InfoLine.propTypes = {
  displayIf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number
  ]),
  label: PropTypes.string,
  translateLabel: PropTypes.bool
};

InfoLine.defaultProps = {
  displayIf: undefined,
  info: '',
  label: '',
  translateLabel: true
};
